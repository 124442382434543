import React from 'react';
import PageReinforcementStatic from '../../interface/PageReinforcementStatic';
import UIContainer from '../../interface/UIContainer';
import UISection from '../../interface/UISection';
import { withLocalize } from 'react-localize-redux';
import UIParagraph from '../../interface/UIParagraph';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';

const SeamlessIntegration = ({ isMobile, isTablet, translate }) => {
  const t = (tag) => `services.rewards.seamlessIntegration.${tag}`;
  const customUl = styled.ul`
  margin-bottom: 0;
`;
const imagesSrcAndAlt = [
  {
    src:
    '../images/pages/services-rewards/integration-option-logos/Mirakl.png',
    alt: 'Mirakl',
  },
  {
    src:
    '../images/pages/services-rewards/integration-option-logos/Commerce_Hub.png',
    alt: 'Commerce Hub',
  },
  {
    src:
    '../images/pages/services-rewards/integration-option-logos/api_logo.png',
    alt: 'API',
  },
];

  return (
    <UIContainer isMobile={isMobile} isTablet={isTablet} >
      <UISection>
        <PageReinforcementStatic
          title={translate(t('title'))}
          borderRequired={true}
          heading={'h2'}
          type={2}
          alignment={'center'}
        />
        <UIParagraph textAlign={'center'} customStyle={{ marginBottom: '0' }}>
          {translate(t('mainParagraph'))}
        </UIParagraph>
        <ImageBoxGrid>
          {imagesSrcAndAlt.map((image, i) => (
            <ImageBoxItem Src={image.src} key={'ls-image-' + i} />
          ))}
        </ImageBoxGrid>
      </UISection>
    </UIContainer>
  );
};

export default withLocalize(SeamlessIntegration);

const ImageBoxGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  grid-gap: 2em;
  /* padding: 1em; */
`;

const ImageBoxItem = styled.div`
  background-image: url(${props => (props.Src ? props.Src : null)});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;

  display: flex;

  /* The following psedo class is used to keep image ratio for responsive. */
  &::before {
    content: ' ';
    display: block;
    height: 0;
    width: 0;
    padding-bottom: calc(1 / 2 * 100%);
  }
`;