import React from 'react';
import { Link } from 'react-router-dom';
import { colors, fonts, spacing } from '../../../../../../../defaults/styles';

const styles = {
  heading: {
    color: 'gray',
    fontWeight: 'normal',
    fontSize: fonts.sizes.smallText,
  },
  data: {
    color: 'black',
    fontSize: fonts.sizes.text,
  },
  right: {
    textAlign: 'left',
  },
};

const Data = ({ heading, data, position = 'left', href }) => (
  // <div style={position === 'right' ? styles.right : null}>
  <div
    style={{
      display: 'grid',
      gridTemplateColumns: '125px 1fr',
      justifyContent: 'space-between',
      marginBottom: spacing.small / 2,
    }}
  >
    <span style={styles.heading}>{heading}</span>
    {href 
    ? <Link to={href} style={{ ...styles.data, color: colors.primary.normal }}>{data}</Link>
    : <span style={{ ...styles.data }}>{data}</span>
    }
    
  </div>
);

export default Data;
