import React from 'react';
import { lsStyled as styled } from '../../../tools/helpers/lsStyled';
import { withLocalize } from 'react-localize-redux';
import { colors } from '../ui/common';
import Select from 'react-select';

const InputDiv = styled.div`
    // margin: 0.5rem 0 0.5rem 0;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const InputLabel = styled.label`
    color: ${colors.gray.normal};
    font-size: 0.8rem;
`;

const InputLabelRequired = styled.sup`
    color: ${colors.primary.dark};
    margin-left: 0.5rem;
`;

const CheckoutSelect = ({ 
    value, 
    onChange = () => {},
    theme = () => {},
    error = false,
    required = false,
    label,
    translate,
    options,
    placeholder
}) => {
    return (
        <div style={{ display: 'flex' }}>
            <InputDiv>
                <InputLabel>
                    {label}
                    {required && <InputLabelRequired>* ({translate('labels.required')})</InputLabelRequired>}
                </InputLabel>
                <Select
                    value={value}
                    isClearable={true}
                    options={options}
                    placeholder={placeholder}
                    theme={theme}
                    required={required}
                    error={error}
                    onChange={onChange}
                />
            </InputDiv>
        </div>
    )
}

export default withLocalize(CheckoutSelect);