import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  margin: 0 auto;
  padding-top: ${props => (props.noPadding ? 0 : '0.5em')};
  padding-bottom: ${props => (props.noPadding ? 0 : '0.5em')};
  width: ${props => (!props.fullWidth ? '1024px' : '100%')};

  ${props => (props.customStyle ? props.customStyle : null)}
`;

const UIContainer = ({
  children,
  fullWidth = null,
  noPadding = false,
  style = null,
}) => {
  return (
    <Container fullWidth={fullWidth} noPadding={noPadding} customStyle={style}>
      {children}
    </Container>
  );
};

export default UIContainer;
