import React from 'react';

import PageReinforcementStatic from '../../interface/PageReinforcementStatic';
import UIContainer from '../../interface/UIContainer';
import UISection from '../../interface/UISection';
import UIParagraph from '../../interface/UIParagraph';
import { Translate, withLocalize } from 'react-localize-redux';
import { BrandCarousel } from './BrandCarousel';
import { BrandsContainer } from '../../../smart';
import { colors, fonts } from './../../../../defaults/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDolly,
  faWarehouse,
  faGift,
} from '@fortawesome/pro-solid-svg-icons';
import styled from 'styled-components';
import Button from './../../interface/Button';

const IconUL = styled.ul`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${props => (props.isMobile ? 'column' : null)};
  align-items: stretch;
  justify-content: center;
  width: 100%;
`;

const IconLI = styled.li`
  display: grid;
  grid-template-rows: auto 1fr;
  align-items: start;
  justify-content: center;

  height: 100%;
  margin: 10px;
  padding: 20px;
  text-align: center;
  flex: 0 0 calc(33% - 20px);

  /* :first-child {
    margin-left: 0;
    padding-left: 0;
  }
  :nth-child(3) {
    margin-right: 0;
    padding-right: 0;
  }
  :first-child, 
  :nth-child(3) {
    flex-basis: calc(33% - 10px);
  } */
`;

const CorporateGiftsCatalogueButtonStyle = styled.div `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Catalog = ({ isMobile, isTablet, translate, history }) => {
  const t = (tag) => `services.gifting.catalog.${tag}`;

  const iconStyles = {
    color: colors.primary.normal,
    fontSize: fonts.sizes.heading * 2.2,
    margin: 'auto',
  };
  const mobileIconStyles = {
    color: colors.primary.normal,
    fontSize: fonts.sizes.heading * 4.5,
    margin: 'auto',
  };

  const itemsList = [
    {
      icon: faGift,
      paragraph: 'icon1',
    },
    {
      icon: faDolly,
      paragraph: 'icon2',
    },
    {
      icon: faWarehouse,
      paragraph: 'icon3',
    },
  ];

  return (
    <UIContainer noPadding isMobile={isMobile} isTablet={isTablet}>
      <UISection noPadding>
        <PageReinforcementStatic
          subTitle={translate(t('subTitle'))}
          borderRequired={false}
          heading={'h2'}
          type={'1'}
          alignment={'center'}
        />
        <UIParagraph textAlign={'center'}>
          <Translate id={t('mainParagraph')} />
        </UIParagraph>
        <IconUL isMobile={isMobile} isTablet={isTablet}>
          {itemsList.map((item, index) => (
            <IconLI key={index}>
              <FontAwesomeIcon
                icon={item.icon}
                style={isMobile ? mobileIconStyles : iconStyles}
              />
              <PageReinforcementStatic
                borderRequired={true}
                heading={'h3'}
                type={'3'}
                alignment={'center'}
              />
              <UIParagraph customStyle={{ lineHeight: 1.2, marginBottom: 0 }}>
                <Translate id={t(item.paragraph)} />
              </UIParagraph>
            </IconLI>
          ))}
        </IconUL>
      </UISection>

      <UISection>
        <CorporateGiftsCatalogueButtonStyle>
          <Button
            url="/corporate-gifts"
            borderRadius={3}
            color={colors.primary.normal}
            size={18}
            fill={true}
            isMobile={isMobile}
            isTablet={isTablet}
          >
            <Translate id={t('corporateGiftsCatalogue')} />
          </Button>
        </CorporateGiftsCatalogueButtonStyle>
      </UISection>

      <UISection>
        <PageReinforcementStatic
          subTitle={translate(t('inspiringBrands'))}
          borderRequired={false}
          heading={'h2'}
          type={'1'}
          alignment={'center'}
        />
      </UISection>

      <BrandsContainer>
        <BrandCarousel
          isMobile={isMobile}
          isTablet={isTablet}
          history={history}>
        </BrandCarousel>
      </BrandsContainer>
    </UIContainer>
  );
};

export default withLocalize(Catalog);
