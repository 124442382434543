import React, { useContext } from 'react';
import { withLocalize } from 'react-localize-redux';
import UIMain from '../../interface/UIMain';
import UISection from '../../interface/UISection';
import UIContainer from '../../interface/UIContainer';
import { ResizeAwareContext } from '../../../smart/ResizeContext';
import Intro from './Intro';
import VendorAgreementSection from './VendorAgreementSection';
import { VendorAgreementContainer } from '../../../smart/Users';
import AcceptAgreement from './AcceptAgreement';

const VendorAgreementPage = ({ translate, history }) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);
  document.title = translate('vendorAgreement.title');

  return (
    <UIMain isMobile={isMobile} isTablet={isTablet}>
      <UIContainer isMobile={isMobile} isTablet={isTablet}>
        <UISection noPadding isMobile={isMobile} isTablet={isTablet}>
            <VendorAgreementContainer>
                <Intro isMobile={isMobile}/>
                <VendorAgreementSection isMobile={isMobile}/>
                <AcceptAgreement isMobile={isMobile} history = {history}/>
            </VendorAgreementContainer>
        </UISection>
      </UIContainer>
    </UIMain>
  );
};

export default withLocalize(VendorAgreementPage);