import React, { useState } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { colors, spacing } from './../../../../defaults/styles';
import { Banner } from './Banner';
import './carousel.min.css';

const BoxHeight = (spacing.containerWidth * 300) / 1100;
const BannerBox = styled.div`
  border: 1px solid ${colors.gray.light};
  margin-bottom: ${spacing.marginStandard};
  ${'' /* height: 300px;  /* width: 1100px; */}
  height: ${BoxHeight}px;
  position: relative;
`;

export const BannerSlideShow = ({ isMobile, isTablet, list, lang, history }) => {

  const goToListing = (e) => {
    if (e.linkType === 'brand') {
      history.push(
        {
          pathname: `/${lang}/catalog`,
          search:
            (e ? `&brand=${e.linkId}` : '')
        });
    }
    else if (e.linkType === 'product') {
      history.push(`/${lang}/product/${e.linkId}`);
    }
    else if (e.linkType === 'outside') {
      if (lang === 'fr') {
        window.open(e.linkId2, '_blank');
      } else {
        window.open(e.linkId, '_blank');
      }
    }
  };
  const [isMouseOverImage, setIsMouseOverImage] = useState(false);

  const handleMouseEnter = () => {
    setIsMouseOverImage(true);
  };

  const handleMouseLeave = () => {
    setIsMouseOverImage(false);
  };

  if (list && list.length > 0) {
    return (
      <BannerBox>
        <Carousel
          infiniteLoop
          autoPlay
          showThumbs={false}
          showArrows={false}
          stopOnHover={false}
          transitionTime={1000}
          showStatus={false}
          onClickItem = {event => goToListing(list[event])}
        >
          {list.map(item => (
            <div  key={item.id}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  style={{ cursor: isMouseOverImage ? 'pointer' : 'default' }}>
              <Banner isMobile={isMobile} isTablet={isTablet} content={item}/>
            </div>
          ))}
        </Carousel>
      </BannerBox>
    );
  } else {
    return <></>;
  }
};