import React, {useEffect} from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { lsStyled as styled } from './../../../../../tools/helpers/lsStyled';
import { fonts } from '../../../../../defaults/styles';

const CompanyInfo = ({ isMobile }) => {
  const t = (tag) => `footer.trademark.${tag}`;
  
  return (
    <RootP isMobile={isMobile}>
      <Tooltip content= {<Translate id={t("version")} />}>
        <strong>
          <Translate id={t("LoyaltySource")} />
        </strong>
      </Tooltip>
      <Translate id={t("desc")} />
    </RootP>
  );
};
export default withLocalize(CompanyInfo);

const Tooltip = ({ children, content }) => {
  return (
    <>
      {children}
      <span className="tooltip-content">{content}</span>
    </>
  );
};

// == Styles == //
// FIXME: <div> cannot appear as a descendant of <p>
const RootP = styled.p `
  font-size: ${fonts.sizes.footerItems}px;

  /* Additional styles for the tooltip */
  .tooltip-container {
    position: relative;
    display: inline-block;
  }

  .tooltip-content {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    padding: 5px;
    border-radius: 4px;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.2s linear;
  }

  .tooltip-container:hover .tooltip-content {
    visibility: visible;
    opacity: 1;
  }
`;
