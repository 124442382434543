import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { loadVendorAgreement } from '../../../redux/actions/userActions'
import { withLocalize } from 'react-localize-redux';
import { useParams } from 'react-router-dom';

export const VendorAgreementContainer = ({ 
    loadVendorAgreement,
    activeLanguage,
    ...props 
}) => {
    const { token } = useParams();
    const [vendorAgreement, setVendorAgreement] = useState({ ...props.vendorAgreement })

    useEffect(() => { loadVendorAgreement(token); }, [ loadVendorAgreement ])
    useEffect(() => { setVendorAgreement({...props.vendorAgreement}); }, [props.vendorAgreement]);
    
    const childrenWithProps = React.Children.map(props.children, child =>
      React.cloneElement(child, { vendorAgreement })
    )

    return <>{childrenWithProps}</>
  }
  
  VendorAgreementContainer.propTypes = {
    loadVendorAgreement: propTypes.func.isRequired,
  }
  
  function mapStateToProps({ vendorAgreement }) {
    return {
        vendorAgreement,
    }
  }
  
  const mapDispatchToProps = {
    loadVendorAgreement,
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withLocalize(VendorAgreementContainer))