import * as types from './types';
import { order } from '../../tools/api';
import { beginApiCall, apiCallError } from './apiStatusActions';

export const loadOrdersSuccess = orders => {
  return { type: types.LOAD_ORDERS_SUCCESS, orders };
};

export const loadOrders = rawParams => {
  let params = rawParams;
  if (params.page) params = { ...params, page: parseInt(params.page) - 1 };
  return dispatch => {
    dispatch(beginApiCall());
    return order
      .getOrders(params)
      .then(({ json: orders }) => {
        dispatch(loadOrdersSuccess(orders));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
};
