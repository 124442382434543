import * as types from '../actions/types'
import { orders } from './initialState'

export default function orderReducer(state = orders, actions) {
  switch (actions.type) {
    case types.LOAD_ORDERS_SUCCESS:
      return actions.orders
    default:
      return state
  }
}
