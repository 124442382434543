import * as types from './types'
import { brand } from '../../tools/api'
import { beginApiCall, apiCallError } from './apiStatusActions'

export const loadBrandsSuccess = brands => {
  return { type: types.LOAD_BRANDS_SUCCESS, brands }
}

export const loadBrands = (language) => {
  return dispatch => {
    dispatch(beginApiCall())
    return brand
      .getBrands(language)
      .then(({ json: brands }) => {
        dispatch(loadBrandsSuccess(Object.entries(brands)))
      })
      .catch(error => {
        dispatch(apiCallError(error))
        throw error
      })
  }
}