import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import { faShareAlt } from '@fortawesome/pro-regular-svg-icons';
import { spacing, colors } from '../../../../../../../defaults/styles';
import { lsStyled as styled } from '../../../../../../../tools/helpers/lsStyled';
import {
  getAccessTokenToCreateProject,
  createSingleZoomProject,
  getItAccountShortToken,
  ZOOM_REFERER
} from '../../../../../../../tools/api/zoomCatalog';
import { Translate, withLocalize } from 'react-localize-redux';
import { loadInfo } from '../../../../../../../redux/actions/userActions';
import ZoomPopup from './ZoomPopup';

const ZoomCatalog = ({
  user,
  translate,
  isMobile,
  sku,
  userInfo,
  loadInfo
}) => {
  const t = (tag) => `product.info.${tag}`;

  const logInRequired = user && !user.loggedIn;

  const [hovered, setHovered] = useState(false);
  const [viewUrl, setViewUrl] = useState("");
  const [editUrl, setEditUrl] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [zoomStudioUserToken, setZoomStudioUserToken] = useState("");
  const [zoomStudioItToken, setZoomStudioItToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!logInRequired) {
      loadInfo();
    }
  }, [loadInfo, logInRequired]);

  const appendErrorMessage = (newMessage) => {
    setErrorMessage((prevMessage) => {
      return prevMessage + (prevMessage ? '\n' : '') + newMessage
    });
  };

  const fixEditUrl = (editUrl) => {
    try {
      // Check if the URL contains "localhost:3000"
      if (editUrl.includes('localhost:3000')) {
        // Replace "localhost:3000-quickcanvas" with "loyaltysource-quickcanvas"
        return editUrl.replace('localhost:3000-quickcanvas', ZOOM_REFERER);
      }
      // Check if the URL contains "preprod.loyaltysource.com"
      if (editUrl.includes('preprod.loyaltysource.com')) {
        // Replace "preprod.loyaltysource.com-quickcanvas" with "loyaltysource-quickcanvas"
        return editUrl.replace('preprod.loyaltysource.com-quickcanvas', ZOOM_REFERER);
      }
      // Also change in production environment
      if (editUrl.includes('www.loyaltysource.com')) {
        // Replace "loyaltysource.com-quickcanvas" with "loyaltysource-quickcanvas"
        return editUrl.replace('www.loyaltysource.com-quickcanvas', ZOOM_REFERER);
      }
      return editUrl;
    } catch (error) {
      console.error('Error while fixing edit_url:', error.message);
      return editUrl;
    }
  }

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const itToken = await getItAccountShortToken();
        setZoomStudioItToken(itToken);

        if (isMounted && userInfo && Object.keys(userInfo).length > 0) {
          const data = await getAccessTokenToCreateProject(userInfo.username);
          setZoomStudioUserToken(data.user_token);
        }
      } catch (error) {
        setZoomStudioUserToken(null);
      }
    };

    if (userInfo && Object.keys(userInfo).length > 0) {
      fetchData();
    }

    // Cleanup function to cancel any ongoing tasks when component unmounts
    return () => {
      isMounted = false;
    };
  }, [userInfo])

  const generateDesign = async (sku) => {
    createSingleZoomProject(zoomStudioUserToken ? zoomStudioUserToken : zoomStudioItToken, sku)
      .then(data => {
        const fixedEditUrl = fixEditUrl(data.edit_url);

        setEditUrl(fixedEditUrl);
        setViewUrl(data.viewer_url);
      })
      .catch(error => {
        console.error('Error while generating zoom project:', error);
        appendErrorMessage('Error while generating zoom project:', error);
      });
  }

  const onGenerateClick = () => {
    generateDesign(sku);
    setShowPopup(true);
  }

  return logInRequired ? <></> : (
    !(zoomStudioUserToken || zoomStudioItToken) ? <></> : <div>
      <Button
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        hovered={hovered}
        isMobile={isMobile}
      >
        <Translate>
          {({ translate }) => (
            <TextWrapper
              onClick={onGenerateClick}
              title={translate(t(`productPresentationHover`))}
            >
              <IconBox hovered={hovered} >
                <div className="animation">
                  <FontAwesomeIcon color={colors.primary.dark} icon={faShareAlt} />
                </div>
                <FontAwesomeIcon color={colors.primary.normal} icon={faShareAlt} />
              </IconBox>
              {translate(t('productPresentation'))}
            </TextWrapper>
          )}
        </Translate>
      </Button>
      <ZoomPopup
        showPopup={showPopup}
        setShowPopup={setShowPopup}
        isMobile={isMobile}
        user={user}
        editUrl={editUrl}
        viewUrl={viewUrl}
        zoomStudioUserToken={zoomStudioUserToken}
        zoomStudioItToken={zoomStudioItToken}
        errorMessage={errorMessage}
        setErrorMessage={setErrorMessage}
        appendErrorMessage={appendErrorMessage}
      />
    </div>
  );
};
const Button = styled.div`
  color: ${colors.primary.normal};
  cursor: pointer;
  max-width: ${props => props.isMobile ? '95%' : '250px'};
  padding-top: ${spacing.small}px;
  padding-bottom: ${spacing.small}px;
  text-decoration: underline;
  text-decoration-color: ${props => props.hovered ? 'inherit' : 'transparent'};
  transition: all 250ms ease-in-out 0s;
`;

const TextWrapper = styled.div`
  display: grid;
  font-size: 0.8rem;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  white-space: nowrap; 
  justify-content: center;
  grid-gap: 0.25rem;
`;

const IconBox = styled.div`
  position: relative;
  height: 0.8rem;
  width: 0.8rem;

  > * {
    position: absolute;
    top: 0;
    left: 0;
  }

  .animation {
    opacity: ${props => props.hovered && props.isInShoppingList
    ? 0
    : props.hovered && !props.isInShoppingList
      ? 1
      : props.isInShoppingList
        ? 1
        : 0
  };
    transition: opacity 250ms;
  }
`;

function mapStateToProps({ userInfo }) {
  return { userInfo };
}
const mapDispatchToProps = {
  loadInfo
};

export default withLocalize(connect(
  mapStateToProps,
  mapDispatchToProps
)(ZoomCatalog));