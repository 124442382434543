import React, { useState, useEffect } from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/free-solid-svg-icons';
import { report } from '../../../../tools/api';
import ZachButton from '../../interface/ZachButton';
import { toast } from 'react-toastify';
import { Translate, withLocalize } from 'react-localize-redux';
import PageTitle from '../../interface/PageTitle';
import { sizing } from './../../../../defaults/styles';

const ReportListPage = ({
  reports = false,
  totalPages = 0,
  setReportParams,
  activeLanguage,
  totalElements = 20,
  pageable = { pageSize: 20 },
}) => {
  const t = (tag) => `report.${tag}`;
  const locale =
    activeLanguage && activeLanguage.code ? activeLanguage.code : 'fr';

  const [loading, setLoading] = useState(true);
  const [reportLoading, setReportLoading] = useState({
    product: false,
    order: false,
  });
  const [params, setParams] = useState({
    page: 0,
    size: 20,
    sort: '',
  });

  const downloadReport = async reportID => {
    return report.getOne(reportID).then(async stream => {
      const blob = new Blob([await stream.blob()], {
        type: 'text/csv',
      });
      // console.log({ blob });
      const href = window.URL.createObjectURL(blob);
      const download = `${reportID}.csv`;

      const a = document.createElement('a');
      a.style = 'display: none';
      a.href = href;
      a.download = download;
      a.click();
      window.URL.revokeObjectURL(href);
    });
  };

  useEffect(() => {
    setReportParams({ ...params });
  }, [params, setReportParams]);

  useEffect(() => {
    if (reports !== false) setLoading(false);
    else setLoading(true);
  }, [reports]);

  const columns = [
    {
      Header: <Translate id={t('columnDownload')} />,
      accessor: 'generated',
      Cell: ({ original }) => {
        return (
          <div style={{ textAlign: 'center' }}>
            {original.generated && (
              <FontAwesomeIcon
                icon={faFileDownload}
                style={{ color: 'blue', cursor: 'pointer' }}
                onClick={() => {
                  setLoading(true);
                  downloadReport(original.id)
                    .then(() => setLoading(false))
                    .catch(() => setLoading(false));
                }}
              />
            )}
          </div>
        );
      },
      width: 70,
    },
    {
      Header: <Translate id={t('columnType')} />,
      accessor: 'module',
      width: 150,
    },
    {
      Header: <Translate id={t('columnID')} />,
      accessor: 'id',
      width: 500,
    },
    {
      Header: <Translate id={t('columnRequestDate')} />,
      accessor: 'creationDate',
      Cell: ({ original }) => {
        return (
          <>
            {new Date(original.creationDate).toLocaleString(locale, {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              timeZoneName: 'short',
            })}
          </>
        );
      },
    },
    {
      Header: <Translate id={t('columnCompletionDate')} />,
      accessor: 'completedDate',
      Cell: ({ original }) => {
        return (
          <>
            {new Date(original.completedDate).toLocaleString(locale, {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              timeZoneName: 'short',
            })}
          </>
        );
      },
    },
    {
      Header: <Translate id={t('columnLanguage')} />,
      accessor: 'language',
      sortable: false,
      width: 70,
      Cell: ({ original }) => {
        return (
          <div style={{ textAlign: 'center' }}>
            {original.language.toUpperCase()}
          </div>
        );
      },
    },
  ];

  const requestReport = async reportType => {
    return report
      .requestReport(reportType, locale)
      .then(({ status, json }) => {
        if (status > 299 || status < 200) throw new Error(json.message);
        toast.success('Great');
      })
      .catch(err => toast.error(err.message));
  };

  // console.log({ reports });

  return (
    <main style={{ marginBottom: sizing.paddingEq }}>
      <Translate>
        {({ translate }) => <PageTitle text={translate(t('reports'))} />}
      </Translate>
      <div
        style={{
          padding: '0 20px 20px',
          marginLeft: '100px',
          marginRight: '100px',
          fontSize: '10px !important',
        }}
      >
        <header style={{ marginBottom: 12, textAlign: 'right' }}>
          <ZachButton
            style={{ marginLeft: '20px' }}
            onClick={() => {
              setReportLoading({ ...reportLoading, order: true });
              requestReport('order').then(() => {
                setReportLoading({ ...reportLoading, product: false });
              });
            }}
          >
            <Translate>
              {({ translate }) =>
                translate(
                  t(
                    reportLoading.order
                      ? 'requestingReport'
                      : 'requestOrderReport'
                  )
                )
              }
            </Translate>
          </ZachButton>
          <ZachButton
            style={{ marginLeft: '20px' }}
            onClick={() => {
              setReportLoading({ ...reportLoading, product: true });
              requestReport('product').then(() => {
                setReportLoading({ ...reportLoading, product: false });
              });
            }}
          >
            <Translate>
              {({ translate }) =>
                translate(
                  t(
                    reportLoading.product
                      ? 'requestingReport'
                      : 'requestProductReport'
                  )
                )
              }
            </Translate>
          </ZachButton>
        </header>
        <ReactTable
          style={{ display: 'block', fontSize: '12px' }}
          data={reports || []}
          columns={columns}
          pageSize={
            totalElements < pageable.pageSize
              ? totalElements
              : pageable.pageSize
          }
          pageSizeOptions={[
            totalElements >= 5 ? 5 : undefined,
            totalElements > 10 ? 10 : undefined,
            totalElements > 20 ? 20 : undefined,
            totalElements > 25 ? 25 : undefined,
            totalElements > 50 ? 50 : undefined,
            totalElements > 100 ? 100 : undefined,
            totalElements,
          ].filter(item => {
            return typeof item !== 'undefined';
          })}
          pages={totalPages}
          loading={loading}
          manual
          onFetchData={({ page, pageSize: size, sorted }) => {
            let sort = params.sort;
            if (sorted.length > 0) {
              const { id, desc } = sorted[0];
              sort = [id, desc ? 'desc' : 'asc'].join(',');
            }

            setParams({
              page,
              size,
              sort,
            });

            setLoading(true);
          }}
        />
      </div>
    </main>
  );
};

export default withLocalize(ReportListPage);
