export const lsFormatNum = function lsFormatNum(num, lang) {
  if (!num) return 0;
  if (!lang) lang = 'en';
  return lang !== 'en'
    ? String(num).replace(/(.)(?=(\d{3})+$)/g, '$1 ')
    : String(num).replace(/(.)(?=(\d{3})+$)/g, '$1,');
};

export const format = (num, lang = 'en', withDecimal = true) => {
  return Number(num)
      .toLocaleString(`${lang}-CA`, { 
        minimumFractionDigits: withDecimal ? 2 : 0, 
        maximumFractionDigits: withDecimal ? 2 : 0,
        style: 'currency',
        currency: 'CAD',
      });
}