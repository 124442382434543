export const BEGIN_API_CALL = 'BEGIN_API_CALL';
export const API_CALL_ERROR = 'API_CALL_ERROR';
export const LOAD_REPORT_LIST_SUCCESS = 'LOAD_REPORT_LIST_SUCCESS';
export const LOAD_REPORT_SUCCESS = 'LOAD_REPORT_SUCCESS';
export const LOAD_BRANDS_SUCCESS = 'LOAD_BRANDS_SUCCESS';
export const LOAD_CATEGORIES_SUCCESS = 'LOAD_CATEGORIES_SUCCESS';
export const LOAD_PRODUCT_FEATURES_SUCCESS = 'LOAD_PRODUCT_FEATURES_SUCCESS';
export const LOAD_CORPORATE_GIFTS_FEATURES_SUCCESS = 'LOAD_CORPORATE_GIFTS_FEATURES_SUCCESS';
export const LOAD_PRODUCTS_SUCCESS = 'LOAD_PRODUCTS_SUCCESS';
export const LOAD_PRODUCT_SUCCESS = 'LOAD_PRODUCT_SUCCESS';
export const LOAD_FEATURE_PRODUCTS_SUCCESS = 'LOAD_FEATURE_PRODUCTS_SUCCESS';
export const LOAD_USER_STATUS_SUCCESS = 'LOAD_USER_STATUS_SUCCESS';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const LOAD_ORDERS_SUCCESS = 'LOAD_ORDERS_SUCCESS';
export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';
export const RESET_SELECTED_CATEGORY = 'RESET_SELECTED_CATEGORY';
export const MODIFY_FILTERS = 'MODIFY_FILTERS';
export const SET_FILTERS_SELECTED_CATEGORY = 'SET_FILTERS_SELECTED_CATEGORY';
export const RESET_FILTERS = 'RESET_FILTERS';
export const RESET_PAGE_FILTERS = 'RESET_PAGE_FILTERS';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const SET_SELECTED_BRAND = 'SET_SELECTED_BRAND';
export const REMOVE_SELECTED_BRAND = 'REMOVE_SELECTED_BRAND';
export const SET_SELECTED_PRODUCT_FEATURE_VALUES = 'SET_SELECTED_PRODUCT_FEATURE_VALUES';
export const REMOVE_SELECTED_PRODUCT_FEATURE_VALUES = 'REMOVE_SELECTED_PRODUCT_FEATURE_VALUES';
export const RESET_SELECTED_PRODUCT_FEATURE_VALUES = 'RESET_SELECTED_PRODUCT_FEATURE_VALUES';
export const SET_PREVIOUS_CATEGORY_FILTERS = 'SET_PREVIOUS_CATEGORY_FILTERS';
export const SET_CATALOG_REQUEST_TIME = 'SET_CATALOG_REQUEST_TIME';
export const SET_PREVIOUS_FILTERS = 'SET_PREVIOUS_FILTERS';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const LOAD_CATALOG_SUCCESS = 'LOAD_CATALOG_SUCCESS';
export const LOAD_CATALOG_FOR_CORPORATE_GIFTS_SUCCESS = 'LOAD_CATALOG_FOR_CORPORATE_GIFTS_SUCCESS';
export const RESET_CATALOG = 'RESET_CATALOG';
export const RESET_CATALOG_FOR_CORPORATE_GIFTS = 'RESET_CATALOG_FOR_CORPORATE_GIFTS';
export const LOAD_LISTING_SUCCESS = 'LOAD_LISTING_SUCCESS';
export const LOAD_CART_SUCCESS = 'LOAD_CART_SUCCESS';
export const LOAD_SHOPPINGLISTS_SUCCESS = 'LOAD_SHOPPINGLISTS_SUCCESS';
export const UPDATE_SHOPPINGLISTS_SUCCESS = 'UPDATE_SHOPPINGLISTS_SUCCESS';
export const DOWNLOAD_SHOPPING_LIST_EXCEL = 'DOWNLOAD_SHOPPING_LIST_EXCEL';
export const LOAD_BLOGS_SUCCESS = 'LOAD_BLOGS_SUCCESS';
export const GET_BLOG_POST_BY_ID = 'GET_BLOG_POST_BY_ID';
export const LOAD_NEWS_SUCCESS = 'LOAD_NEWS_SUCCESS';
export const USER_PARENT_INFO_SUCCESS = 'USER_PARENT_INFO_SUCCESS';
export const LOAD_LANDING_PAGE_BANNER_SUCCESS = 'LOAD_LANDING_PAGE_BANNER_SUCCESS';
export const LOGIN_TOKEN_SUCCESS = 'LOGIN_TOKEN_SUCCESS';
export const VENDOR_AGREEMENT_SUCCESS = 'VENDOR_AGREEMENT_SUCCESS';