import React from 'react';
import styled from 'styled-components';
import {
  colors,
  fonts,
  spacing,
} from './../../../../../../../defaults/styles/';
import { Translate, withLocalize } from 'react-localize-redux';

// == Styling == //
const ButtonWrapper = styled.div`
  cursor: ${props => (props.productsPopupOpen ? 'auto' : 'pointer')};
  font-size: ${fonts.sizes.large}px;
  font-weight: ${props => (props.productsPopupOpen ? 600 : 300)};
  padding: ${spacing.verySmall}px ${spacing.small}px;
  pointer-events: ${props => (props.productsPopupOpen ? 'none' : 'auto')};
  transition: color ease-in-out 0.1s;
  user-select: none;
  order: 3;

  &:hover {
    color: ${colors.primary.normal};
  }
`;
// == /Styling == //

const ProductsPopupButton = ({
  productsPopupOpen,
  openProductsPopupClickHandler,
}) => {
  const t = (tag) => `header.popup.${tag}`;

  // const [hovering, setHovering] = useState(false);

  const handleClick = () => {
    openProductsPopupClickHandler();
  };

  return (
    <ButtonWrapper
      productsPopupOpen={productsPopupOpen}
      // onMouseEnter={() => setHovering(true)}
      // onMouseLeave={() => setHovering(false)}
      onClick={() => handleClick()}
    >
      {<Translate id={t('text')} />}
    </ButtonWrapper>
  );
};

export default withLocalize(ProductsPopupButton);
