import React from 'react'
import Item from './Item'
import { Translate, withLocalize } from 'react-localize-redux'
import { spacing } from '../../../../../../../defaults/styles'

const styles = {
  numberContainer: {
    flex: '0 0 auto',
    marginRight: spacing.small,
    marginBottom: spacing.small,
  },
}

const Numbers = ({ sku, modelNumber }) => {
  const t = (tag) => `product.info.${tag}`;
  return (
    <section
      style={{
        display: 'flex',
        flexFlow: 'row wrap',
        marginBottom: spacing.veryLarge - spacing.small,
      }}
    >
      <div style={styles.numberContainer}>
        <Item label={<Translate id={t('sku')} />} value={sku} />
      </div>
      <div style={styles.numberContainer}>
        <Item label={<Translate id={t('model')} />} value={modelNumber} />
      </div>
    </section>
  )
}

export default withLocalize(Numbers)
