import React from 'react';
import styled from 'styled-components';

import { Translate, withLocalize } from 'react-localize-redux';
import { colors, spacing, fonts } from '../../../../defaults/styles';

const Paragraph = styled.p`
  color: ${colors.primary.normal};
  font-size: ${fonts.small}px;
  margin-top: ${spacing.small}px;
  width: 100%;
`;

const ErrorMessage = ({ error = false }) => {
  const t = (tag) => `forgetPassword.error.${tag}`;

  if (error) {
    return (
      <Paragraph>
        <Translate id={t('message')} />
      </Paragraph>
    );
  }
};

export default withLocalize(ErrorMessage);
