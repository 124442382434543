import React from 'react';
import { Link } from 'react-router-dom';
import { spacing, colors } from '../../../../../defaults/styles';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';

const ThirdLevelWrapper = styled.div`
    padding-top: ${spacing.medium / 2}px;
    padding-right: ${spacing.medium}px;
    padding-bottom: ${spacing.medium / 2}px;
    padding-left: ${spacing.medium * 2}px;
    color: ${props => props.selected ? colors.primary.dark : colors.black.normal};
    user-select: none;
`;

const LinkStyle = {
    color: 'inherit',
    textDecoration: 'none'
};

const ThirdLevelCateogry = ({ 
    text, 
    selected = false, 
    onClick,
    url
}) => {
    return (
        <ThirdLevelWrapper selected={selected} onClick={onClick}>
            <Link 
                to={url} 
                style={LinkStyle} 
                onClick={(e) => e.preventDefault()}
            >
                {text}
            </Link>
        </ThirdLevelWrapper>
    );
}

export default ThirdLevelCateogry;
