import React from 'react';
import UIContainer from '../../../interface/UIContainer';
import Column from './Column';
import Logo from './Logo';
import FooterLinksLeft from './Links/FooterLinksLeft';
import FooterLinksRight from './Links/FooterLinksRight';

import CompanyInfo from './CompanyInfo';
import { spacing } from '../../../../../defaults/styles';

const Top = ({ isMobile, isTablet }) => {
  return (
    <UIContainer
      customStyle={{
        display: 'grid',
        gridTemplateColumns: isMobile ? '1fr 1fr' : 'auto auto auto 1fr',
        gridGap: (isMobile || isTablet) ? spacing.large : spacing.veryLarge * 2,
        paddingTop: spacing.medium * 3,
        paddingBottom: isMobile ? spacing.medium * 2 : spacing.medium * 3,
      }}
      isMobile={isMobile}
    >
      <Column isMobile={isMobile} mobileOrder={true}>
        <Logo />
      </Column>
      <Column isMobile={isMobile} links={true}>
        <FooterLinksLeft />
      </Column>
      <Column isMobile={isMobile} links={true}>
        <FooterLinksRight />
      </Column>
      <Column isMobile={isMobile}>
        <CompanyInfo isMobile={isMobile} />
      </Column>
    </UIContainer>
  );
};

export default Top;
