import React, { useRef } from 'react';
import Radium from 'radium';
import { spacing, sizing } from './../../../../defaults/styles/';
import { withLocalize } from 'react-localize-redux';
import LegalSectionContent from '../../interface/PageSection/LegalSectionContent';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import LegalSectionTableOfContent from '../../interface/PageSection/LegalSectionTableOfContent';
import UIContainer from '../../interface/UIContainer';
import UISection from '../../interface/UISection';

import content from '../../../../main/global-lexicon';

const TOCList = styled.ol`
  margin-top: ${spacing.veryLarge * 2}px;
  margin-bottom: ${spacing.veryLarge * 2}px;
`;

const PrivacyPolicy = ({ isMobile, isTablet, translate }) => {
  const t = (tag) => `privacy.${tag}`;
  const languageData = content.privacy;

  const scrollToSection = (ref, e) => {
    e.preventDefault();
    window.scrollTo({ top: ref.current.offsetTop - sizing.header.top - sizing.header.mid - sizing.header.btm - 20, behavior: 'smooth' });
  }

  // Cannot create ref with array, need to be done manually...
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);

  return (
    
    <UIContainer noPadding isMobile={isMobile} isTablet={isTablet}>
      <UISection noPadding isMobile={isMobile} isTablet={isTablet}>
      <TOCList>
          <LegalSectionTableOfContent 
            text={translate(t('subtitle1'))} 
            onClick={(event) => scrollToSection(section1, event)}
          />
          <LegalSectionTableOfContent 
            text={translate(t('subtitle2'))} 
            onClick={(event) => scrollToSection(section2, event)}
          />
          <LegalSectionTableOfContent 
            text={translate(t('subtitle3'))} 
            onClick={(event) => scrollToSection(section3, event)}
          />
          <LegalSectionTableOfContent 
            text={translate(t('subtitle4'))} 
            onClick={(event) => scrollToSection(section4, event)}
          />
        </TOCList>

        <section><LegalSectionContent section={0} t={t} languageData={languageData} /></section>
        <section ref={section1}><LegalSectionContent section={1} t={t} languageData={languageData} /></section>
        <section ref={section2}><LegalSectionContent section={2} t={t} languageData={languageData} /></section>
        <section ref={section3}><LegalSectionContent section={3} t={t} languageData={languageData} /></section>
        <section ref={section4}><LegalSectionContent section={4} t={t} languageData={languageData} /></section>
      </UISection>
    </UIContainer>
  );
};

export default Radium(withLocalize(PrivacyPolicy));
