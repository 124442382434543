import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { fonts, colors, spacing } from './../../../../../../defaults/styles';
import { withLocalize } from 'react-localize-redux';

// This component is for individual link element

function FooterLink({ url, text, activeLanguage }) {
  const [hovering, setHovering] = useState(false);
  const lang =
    activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';

  const linkStyle = {
    color: hovering ? colors.primary.normal : colors.white.normal,
    fontSize: fonts.sizes.footerItems,
    paddingBottom: spacing.small,
    transition: 'color ease-in-out 0.1s',
    textDecoration: 'none',
  };
  
  return (
    url.indexOf('http') 
      ?
      <Link
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        style={linkStyle}
        to={'/' + lang + url}
      >
        {text}
      </Link>
      :
      <a
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        style={linkStyle}
        href={url}
      >
        {text}
      </a>
  );
}

export default withLocalize(FooterLink);
