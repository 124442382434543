import colors from './colors';

/*  
  HEADING TYPO MEMO

  H1: Roboto Black, 44px
  H2: Roboto Black, 28 px (or Roboto Regular if subtext to an H1)
  H3: Roboto Bold, 22px (or Roboto Regular if subtext to H2)
  General Text: Roboto Regular, 18px, line height 1.4em 

*/

const typography = {
  h1: {
    color: colors.black.normal,
    fontSize: '44px',
    marginTop: '22px',
  },
  h2: {
    color: colors.black.normal,
    fontSize: '28px',
    fontWeight: 'bold',
    marginTop: '16px',
  },
  h3: {
    color: colors.black.normal,
    fontSize: '22px',
    fontWeight: 'bold',
    marginTop: '16px',
    marginBottom: '10px',
  },
  h4: {
    color: colors.black.normal,
    fontSize: '18px',
    fontWeight: 'bold',
  },
  h5: {
    color: colors.black.normal,
    fontSize: '10px',
    fontWeight: 'normal',
  },
  h6: {
    color: colors.black.normal,
    fontSize: '8px',
    fontWeight: 'normal',
  },
  normal: {
    fontSize: '18px',
    lineHeight: '1.4',
  },
};

export default typography;
