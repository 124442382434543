import React from 'react';
import UIPageTopTitle from '../../interface/UIPageTopTitle';
import { withLocalize } from 'react-localize-redux';

const Intro = ({ isMobile, isTablet, translate }) => {
  return (
    <UIPageTopTitle
      title={translate('services.rewards.title')}
      subTitle={translate('services.rewards.subTitle')}
      isMobile={isMobile} 
      isTablet={isTablet}
    />
  );
};

export default withLocalize(Intro);
