import React from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLaptop,
  faFileChartLine,
  faScannerKeyboard,
  faUserHeadset,
  faShippingFast,
} from '@fortawesome/pro-solid-svg-icons';
import PageReinforcementStatic from './../../interface/PageReinforcementStatic';
import UIContainer from './../../interface/UIContainer';
import UISection from './../../interface/UISection';
import UIParagraph from './../../interface/UIParagraph';
import { colors, fonts } from './../../../../defaults/styles';
import { Translate, withLocalize } from 'react-localize-redux';

// == Styling == //
const IconUL = styled.ul`
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  flex-direction: ${props => (props.isMobile ? 'column' : null)};
  align-items: stretch;
  justify-content: center;
  width: 100%;
`;

const IconLI = styled.li`
  display: grid;
  grid-template-rows: auto 1fr;
  align-items: start;
  justify-content: center;

  height: 100%;
  margin: 10px;
  padding: 20px;
  text-align: center;
  flex: 0 0 calc(33% - 20px);

  /* :first-child {
    margin-left: 0;
    padding-left: 0;
  }
  :nth-child(3) {
    margin-right: 0;
    padding-right: 0;
  }
  :first-child, 
  :nth-child(3) {
    flex-basis: calc(33% - 10px);
  } */
`;

// this is just styles for FontAwesome compoenent, not using as component
const iconStyles = {
  color: colors.primary.normal,
  fontSize: fonts.sizes.heading * 2.2,
  margin: 'auto',
};
const mobileIconStyles = {
  color: colors.primary.normal,
  fontSize: fonts.sizes.heading * 4.5,
  margin: 'auto',
};
// == /Styling == //

const LastMile = ({ isMobile, isTablet, translate }) => {
  const t = (tag) => `services.fulfillment.lastMile.${tag}`;

  const itemsList = [
    {
      icon: faLaptop,
      title: 'listItems.icon1.title',
      paragraph: 'listItems.icon1.paragraph',
    },
    {
      icon: faFileChartLine,
      title: 'listItems.icon2.title',
      paragraph: 'listItems.icon2.paragraph',
    },
    {
      icon: faScannerKeyboard,
      title: 'listItems.icon3.title',
      paragraph: 'listItems.icon3.paragraph',
    },
    {
      icon: faUserHeadset,
      title: 'listItems.icon4.title',
      paragraph: 'listItems.icon4.paragraph',
    },
    {
      icon: faShippingFast,
      title: 'listItems.icon5.title',
      paragraph: 'listItems.icon5.paragraph',
    },
  ];
  return (
    <UIContainer isMobile={isMobile} isTablet={isTablet}>
      <UISection noPadding>
        <PageReinforcementStatic
          title={translate(t('title'))}
          subTitle={translate(t('subTitle'))}
          borderRequired={true}
          heading={'h2'}
          type={'1'}
          alignment={'center'}
        />
        <UIParagraph textAlign={'center'}>
          <Translate id={t('mainParagraph')} />
        </UIParagraph>
      </UISection>

      <UISection>
        <IconUL isMobile={isMobile} isTablet={isTablet}>
          {itemsList.map((item, index) => (
            <IconLI key={index}>
              <FontAwesomeIcon
                icon={item.icon}
                style={isMobile ? mobileIconStyles : iconStyles}
              />
              <PageReinforcementStatic
                title={translate(t(item.title))}
                borderRequired={true}
                heading={'h3'}
                type={'3'}
                alignment={'center'}
              />
              <UIParagraph customStyle={{ lineHeight: 1.2, marginBottom: 0 }}>
                <Translate id={t(item.paragraph)} />
              </UIParagraph>
            </IconLI>
          ))}
        </IconUL>
      </UISection>
    </UIContainer>
  );
};

export default withLocalize(LastMile);
