import React from 'react';
import PageSection from './../../interface/PageSection';
import BgPolygon from './../../interface/BgPolygon';
import { Translate, withLocalize } from 'react-localize-redux';

const WhitegloveService = ({ isMobile }) => {
  const t = (tag) => `services.legacy.whiteglove.${tag}`;
  return (
    <>
      <BgPolygon polygonTop="true" />
      <PageSection
        title={<Translate id={t('title')} />}
        paragraphs={[<Translate id={t('paragraph1')} />]}
        contentPosition="left"
        imgSrc="/images/pages/services/Whiteglove-Service.png"
        imgAlt="white-glove service"
        darkBackground="true"
        isMobile={isMobile}
        imgPrimary="true"
      />
      <BgPolygon polygonBottom="true" />
    </>
  );
};

export default withLocalize(WhitegloveService);
