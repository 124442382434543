import React, { useContext } from 'react';
import UIMain from './../../interface/UIMain';
import Intro from './Intro';
import ForgotPasswordForm from './ForgotPasswordForm';
import { ResizeAwareContext } from '../../../smart/ResizeContext';
import { withLocalize } from 'react-localize-redux';

const ForgotPasswordPage = ({ translate }) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);
  document.title = translate('forgetPassword.intro.title');

  return (
    <UIMain isMobile={isMobile} isTablet={isTablet}>
      <Intro isMobile={isMobile} isTablet={isTablet} />
      <ForgotPasswordForm isMobile={isMobile} isTablet={isTablet} />
    </UIMain>
  );
};

export default withLocalize(ForgotPasswordPage);
