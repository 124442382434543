import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { loadInfo, loadParentInfo } from '../../../redux/actions/userActions'

export const UserInfoContainer = ({ 
    loadInfo,
    loadParentInfo,
    ...props 
}) => {
    const [userInfo, setUserInfo] = useState({ ...props.userInfo })

    useEffect(() => { loadInfo(); }, [ loadInfo ])
    useEffect(() => { setUserInfo({...props.userInfo}); }, [props.userInfo]);

    const [userParentInfo, setUserParentInfo] = useState({ ...props.userParentInfo })

    useEffect(() => { loadParentInfo(); }, [ loadParentInfo ]);
    useEffect(() => { setUserParentInfo({...props.userParentInfo}); }, [props.userParentInfo]);
    
    const childrenWithProps = React.Children.map(props.children, child =>
      React.cloneElement(child, { userInfo, userParentInfo })
    )

    return <>{childrenWithProps}</>
  }
  
  UserInfoContainer.propTypes = {
    loadInfo: propTypes.func.isRequired,
    loadParentInfo: propTypes.func.isRequired,
  }
  
  function mapStateToProps({ userInfo, userParentInfo }) {
    return {
        userInfo, userParentInfo,
    }
  }
  
  const mapDispatchToProps = {
    loadInfo, loadParentInfo,
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(UserInfoContainer)