export const AUTH_TOKEN = 'authToken';
export const USER_EMAIL = 'userEmail';
export const SESSION = 'session';
export const USER_INFO = 'userInfo';
//JWT secret key
export const SECRET_KEY = "loyalty-source-client-web";

export const setCookie = (name, value, days) => {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    const expires = `expires=${date.toUTCString()}`;
    const secure = window.location.protocol === 'https:' ? 'Secure;HttpOnly;SameSite=Strict' : '';
    document.cookie = `${name}=${value};${expires};path=/;${secure}`;
};

export const setCookieTillMidnight = (name, value) => {
    const today = new Date();
    // Set the expiration time to the end of the day
    const expirationDate = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
    const expires = `expires=${expirationDate.toUTCString()}`;
    console.log("expires: ", expires)

    document.cookie = `${name}=${value};${expires};path=/`;
};

export const getCookie = name => {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
            return cookie.substring(name.length + 1);
        }
    }
    return '';
};

export const removeCookie = name => {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
};

export const removeAuthAndSession = () => {
    removeCookie(AUTH_TOKEN);
    removeCookie(USER_EMAIL);
    removeCookie(SESSION);
    removeCookie(USER_INFO);
}