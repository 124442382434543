import React, { useContext, useEffect, useState } from 'react';
import UIMain from './../../interface/UIMain';
import UIContainer from './../../interface/UIContainer';
import { spacing, colors } from '../../../../defaults/styles';
import { connect } from 'react-redux';
import { withLocalize } from 'react-localize-redux';
import { LoggedInHomePageProductSlideShow } from './LoggedInHomePageProductSlideShow';
import { BannerSlideShow } from './BannerSlideShow';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { GridLoader } from 'react-spinners';
import { ResizeAwareContext } from '../../../smart/ResizeContext';
import { getItAccountShortToken, getAccessTokenToCreateProject } from '../../../../tools/api/zoomCatalog';
import ZoomPopup from '../ProductPage/Product/Content/ZoomCatalog/ZoomPopup';
import { loadInfo } from '../../../../redux/actions/userActions';

const LoaderWrapper = styled.div`
  padding: ${spacing.veryLarge}px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100%;
`;

const LoadingGrid = (
  <LoaderWrapper>
    <GridLoader css={{ margin: 'auto' }} color={colors.main.normal} size={12} />
  </LoaderWrapper>
);

const LoggedInHomePagePresentation =
  ({ featureProducts, translate, history, activeLanguage, apiCallsInProgress, userInfo, user, loadInfo }) => {
    const { isMobile, isTablet } = useContext(ResizeAwareContext);
    const t = (tag) => `loggedInHome.${tag}`;

    const [viewUrl, setViewUrl] = useState("");
    const [editUrl, setEditUrl] = useState("");
    const [showPopup, setShowPopup] = useState(false);
    const [zoomStudioUserToken, setZoomStudioUserToken] = useState("");
    const [zoomStudioItToken, setZoomStudioItToken] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const appendErrorMessage = (newMessage) => {
      setErrorMessage((prevMessage) => {
        return prevMessage + (prevMessage ? '\n' : '') + newMessage
      });
    };

    useEffect(() => { loadInfo(); }, [ loadInfo ]);

    useEffect(() => {
      let isMounted = true;
  
      const fetchData = async () => {
        try {
          const itToken = await getItAccountShortToken();
          setZoomStudioItToken(itToken);
          
          if (isMounted && userInfo && Object.keys(userInfo).length > 0) {
            const data = await getAccessTokenToCreateProject(userInfo.username);
            setZoomStudioUserToken(data.user_token);
          }
        } catch (error) {
          setZoomStudioUserToken(null);
        }
      };
  
      if (userInfo && Object.keys(userInfo).length > 0) {
        fetchData();
      }
  
      // Cleanup function to cancel any ongoing tasks when component unmounts
      return () => {
        isMounted = false;
      };
    }, [userInfo])

    if (
      featureProducts.newProducts ||
      featureProducts.bestSellers ||
      featureProducts.features ||
      featureProducts.banners ||
      featureProducts.canadianProducts
    ) {
      const { newProducts, bestSellers, features, banners, canadianProducts } = featureProducts;

      const lang =
        activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';

      const goToListing = (e, display, displayId = undefined) => {
        e.preventDefault();
        history.push({
          pathname: `/${lang}/catalog`,
          search:
            `?display=${display}` +
            (displayId ? `&displayId=${displayId}` : ''),
        });
      };

      return (
        <UIMain isMobile={isMobile} isTablet={isTablet}>
          <UIContainer isMobile={isMobile} isTablet={isTablet}>
            <BannerSlideShow isMobile={isMobile} list={banners} history={history} lang={lang}/>

            {newProducts.length > 0 && (
              <LoggedInHomePageProductSlideShow
                isMobile={isMobile}
                isTablet={isTablet}
                list={newProducts}
                title={translate(t('new.title'))}
                counterLabel_one={translate(t('new.counterLabel_one'))}
                counterLabel_other={translate(
                  t('new.counterLabel_other')
                )}
                buttonLabel={translate(t('new.button'))}
                addLinkLabel={translate(t('new.addLinkLabel'))}
                url={'/catalog?display=NEW'}
                onClick={event => goToListing(event, 'NEW')}
                apiCallsInProgress={apiCallsInProgress}
                showPopup={showPopup}
                setShowPopup={setShowPopup}
                editUrl={editUrl}
                viewUrl={viewUrl}
                setEditUrl={setEditUrl}
                setViewUrl={setViewUrl}
                zoomStudioUserToken={zoomStudioUserToken}
                zoomStudioItToken={zoomStudioItToken}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                appendErrorMessage={appendErrorMessage}
              />
            )}
            {bestSellers.length > 0 && (
              <LoggedInHomePageProductSlideShow
                isMobile={isMobile}
                isTablet={isTablet}
                list={bestSellers}
                title={translate(t('hot.title'))}
                counterLabel_one={translate(t('hot.counterLabel_one'))}
                counterLabel_other={translate(
                  t('hot.counterLabel_other')
                )}
                buttonLabel={translate(t('hot.button'))}
                addLinkLabel={translate(t('hot.addLinkLabel'))}
                url={'/catalog?display=BEST_SELLER'}
                onClick={event => goToListing(event, 'BEST_SELLER')}
                apiCallsInProgress={apiCallsInProgress}
                showPopup={showPopup}
                setShowPopup={setShowPopup}
                editUrl={editUrl}
                viewUrl={viewUrl}
                setEditUrl={setEditUrl}
                setViewUrl={setViewUrl}
                zoomStudioUserToken={zoomStudioUserToken}
                zoomStudioItToken={zoomStudioItToken}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                appendErrorMessage={appendErrorMessage}
              />
            )}
            {canadianProducts && canadianProducts.length > 0 && (
              <LoggedInHomePageProductSlideShow
                isMobile={isMobile}
                isTablet={isTablet}
                list={canadianProducts}
                title={translate(t('canadian.title'))}
                counterLabel_one={translate(t('canadian.counterLabel_one'))}
                counterLabel_other={translate(
                  t('canadian.counterLabel_other')
                )}
                buttonLabel={translate(t('canadian.button'))}
                addLinkLabel={translate(t('canadian.addLinkLabel'))}
                url={'/catalog?display=CANADIAN'}
                onClick={event => goToListing(event, 'CANADIAN')}
                apiCallsInProgress={apiCallsInProgress}
                showPopup={showPopup}
                setShowPopup={setShowPopup}
                editUrl={editUrl}
                viewUrl={viewUrl}
                setEditUrl={setEditUrl}
                setViewUrl={setViewUrl}
                zoomStudioUserToken={zoomStudioUserToken}
                zoomStudioItToken={zoomStudioItToken}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                appendErrorMessage={appendErrorMessage}
              />
            )}
            {features.map(listing => (
              <div key={listing.id}>
                {listing.products.length > 0 && (
                  <LoggedInHomePageProductSlideShow
                    isMobile={isMobile}
                    isTablet={isTablet}
                    list={listing.products}
                    title={listing.name}
                    counterLabel_one={translate(
                      t('general.counterLabel_one')
                    )}
                    counterLabel_other={translate(
                      t('general.counterLabel_other')
                    )}
                    description={listing.description}
                    buttonLabel={translate(t('general.button'))}
                    addLinkLabel={translate(t('general.addLinkLabel'))}
                    customized={true}
                    productsLabel={translate(
                      t('general.productsLabel')
                    )}
                    url={`/catalog?display=LISTING&displayId=${listing.id}`}
                    onClick={event => goToListing(event, 'LISTING', listing.id)}
                    apiCallsInProgress={apiCallsInProgress}
                    showPopup={showPopup}
                    setShowPopup={setShowPopup}
                    editUrl={editUrl}
                    viewUrl={viewUrl}
                    setEditUrl={setEditUrl}
                    setViewUrl={setViewUrl}
                    zoomStudioUserToken={zoomStudioUserToken}
                    zoomStudioItToken={zoomStudioItToken}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    appendErrorMessage={appendErrorMessage}
                  />
                )}
              </div>
            ))}
            <ZoomPopup
              showPopup={showPopup}
              setShowPopup={setShowPopup}
              isMobile={isMobile}
              editUrl={editUrl}
              viewUrl={viewUrl}
              zoomStudioUserToken={zoomStudioUserToken}
              zoomStudioItToken={zoomStudioItToken}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              appendErrorMessage={appendErrorMessage}
            />
          </UIContainer>
        </UIMain>
      );
    } else {
      return (
        <UIMain isMobile={isMobile} isTablet={isTablet}>
          {LoadingGrid}
        </UIMain>
      );
    }
  }

  
  function mapStateToProps({ userInfo }) {
    return { userInfo };
  }

  const mapDispatchToProps = {
    loadInfo
};
  
  export default withLocalize(connect(
    mapStateToProps,
    mapDispatchToProps,
  )(LoggedInHomePagePresentation));