import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { loadShoppingLists, modifyDefaultEventHandler, modifyNameEventHandler, deleteShoppingListEventHandler, createShoppingListEventHandler, addProductIdEventHandler, removeProductIdEventHandler} from '../../../redux/actions/shoppingListActions';

const ShoppingListContainer = ({
    user,
    apiCallsInProgress,
    activeLanguage,
    shoppingLists = [],
    loadShoppingLists,
    modifyDefaultEventHandler,
    modifyNameEventHandler,
    deleteShoppingListEventHandler,
    createShoppingListEventHandler,
    addProductIdEventHandler, 
    removeProductIdEventHandler,
    children
}) => {
    useEffect(() => { loadShoppingLists(); }, []);

    // Push prop to children
    const childrenWithProps = React.Children.map(children, child =>
        React.cloneElement(child, {
            user, apiCallsInProgress, activeLanguage, shoppingLists, 
            modifyDefaultEventHandler, modifyNameEventHandler,
            deleteShoppingListEventHandler, createShoppingListEventHandler,
            addProductIdEventHandler, removeProductIdEventHandler,
        })
    );

    // Return just presentation of children
    return <>{childrenWithProps}</>;
};

function mapStateToProps({ shoppingLists, user, }) {
    return { shoppingLists, user, };
}

const mapDispatchToProps = {
    loadShoppingLists,
    modifyDefaultEventHandler,
    modifyNameEventHandler,
    deleteShoppingListEventHandler,
    createShoppingListEventHandler,
    addProductIdEventHandler, 
    removeProductIdEventHandler,
};
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
) (ShoppingListContainer);