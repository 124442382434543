import React, { useEffect } from 'react';
import { withLocalize } from 'react-localize-redux';
import { format } from '../../../../tools/helpers/lsFormatNum';

const paragraphStyle = ({ 
    margin = 0, 
    padding = 0, 
    fontSize = 16, 
    fontWeight = null, 
    textAlign = 'left' 
}) => {
    return `font-family: Roboto, verdana, arial, sans-serif; font-size:${fontSize}px; margin:${margin}; padding:${padding}; line-height:100%; line-height: 1.2em; ${fontWeight ? `font-weight: ${fontWeight};` : ''} text-align: ${textAlign}`;
}

const commonTableProperties = ({ 
    backgroundColor = 'inherit' 
}) => {
    return `cellpadding="0" cellspacing="0" style="width: 100%; box-sizing: border-box; margin: 0; padding: 0; ${backgroundColor ? `background-color: ${backgroundColor}` : ''}"`;
}

const ConfirmationEmail = ({ translate, submit, cart, sendConfirmation, activeLanguage }) => {
    const locale =
        activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';

    useEffect(() => {
        // Only when cart is available and is submitted
        if (submit && cart && cart.address) {
            sendConfirmationEmail(
                cart, 
                sendConfirmation,
                (suffix) => translate(`checkout.email.confirmation.${suffix}`),
                translate,
                locale
            );
        }
    }, [ submit ]);

    return <></>;
}

const sendConfirmationEmail = (cart, sendConfirmation, translate, rawTranslate, locale) => {
    const spacing = (size) => `<table class="spacer" cellpadding="${size}" cellspacing="0"><tr><td></td></tr></table>`;

    // HTML content for the email to send to the backend to process
    const emailContent = `
    <!DOCTYPE html>
    <html style="font-family: verdana, Arial, sans-serif; box-sizing: border-box; font-size: 16px; margin: 0;">
    <head>${htmlHeader(translate)}</head>

    <body itemscope itemtype="http://schema.org/EmailMessage" 
            style="background-color: #ffffff; font-family: Roboto, verdana, arial, sans-serif; box-sizing: border-box; font-size: 16px; -webkit-font-smoothing: antialiased; -webkit-text-size-adjust: none; width: 100% !important; height: 100%; line-height: 1.6em; margin: 0;">

        <table cellpadding="0" cellspacing="0" style="width: 100%; background-color: #ffffff; padding: 0; margin: 0;">
            <tr><td><center>

                <!--[if mso]>
                <table style="width: 600px;"><tr><td>
                <![endif]-->

                <table class="container" cellpadding="0" cellspacing="0" align="center" 
                        style="width: 100%; max-width: 600px; margin: 0 auto; background: #ffffff; text-align: left; box-sizing: border-box; padding: 0;">
                    <tr><td>
                        ${templateHeader(rawTranslate, spacing)}
                        ${templateContent(translate, spacing, cart, locale)}
                        ${templateFooter(translate, spacing, rawTranslate)}
                    </td></tr>
                </table>

                <!--[if mso]>
                </td></tr></table>
                <![endif]-->

            </center></td></tr>
        </table>
    </body>
    </html>
    `;

    sendConfirmation(translate('subject').replace('{id}', cart.productOrderNumber), cart.submitterEmail, emailContent);
}

const htmlHeader = (translate) => {
    return `
    <meta name="viewport" content="width=device-width" />
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <title>${translate('title')}</title>
    <link href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900" rel="stylesheet">
    <link href="https://fonts.googleapis.com/css?family=Caveat&display=swap" rel="stylesheet">
    <style type="text/css">
        body { 
            background-color: #ffffff; font-family: "Roboto, verdana, arial, sans-serif"; 
            -webkit-font-smoothing: antialiased; -webkit-text-size-adjust: none; width: 100% !important; 
            height: 100%; line-height: 1.6em; 
        }
        img { display: block; max-width: 100%; }
        ul { margin: 0; padding: 0; list-style: none; }
        @media only screen and (max-width: 640px) {
            body { padding: 0 !important; }
            h1 { font-size: 22px !important; }
            h2 { font-size: 18px !important; }
            .container { padding: 0 !important; width: 100% !important; }
        }
    </style>
    `;
}

const templateHeader = (rawTranslate, spacing) => {
    return `
    <table class="header" ${commonTableProperties({ backgroundColor: '$ffffff' })}>
        <tr><td class="" style="box-sizing: border-box;">
            ${spacing(25)}
            <a href="https://www.loyaltysource.com/">
                <img src="${rawTranslate('urls.logo')}" alt="Loyalty Source" style="width: 150px; height: auto;" />
            </a>
            ${spacing(10)}
        </td></tr>
    </table>
    `;
}

const templateContent = (translate, spacing, cart, locale) => {
    const { 
        submitter, productOrderNumber, address, items
    } = cart;
    const cartItems = items.filter(({statusId})=> statusId !== 4);

    const {
        fullName, addressLineOne, addressLineTwo, 
        city, province, postalCode,
    } = address;

    return `
    <table class="main-content" cellpadding="50" cellspacing="0"
            style="width: 100%; background-color:#ffffff; border: 1px solid #eeeeee;">
        <tr><td>
            <!-- Main Content Header -->
            <table ${commonTableProperties({})}>
                <tr><td style="text-align:center;">
                    <!-- Email Title -->
                    <h1 style="${paragraphStyle({ fontSize: 32, padding: 'initial', fontWeight: 500, textAlign: 'center' })}">
                        ${translate('emailTitle')}
                    </h1>
                    ${spacing(10)}
                    
                    <!-- Email Subtitle -->
                    <h2 style="${paragraphStyle({ fontSize: 24, padding: 'initial', fontWeight: 400, textAlign: 'center' })}">
                        ${translate('emailSubtitle')}
                    </h2>
                    ${spacing(10)}
                </td></tr>
            </table>
            
            <!-- Main Content Body -->
            <table ${commonTableProperties({})}>
                <tr><td>
                    <p style="${paragraphStyle({ margin: '0 initial initial initial' })}">${translate('greeting')} ${submitter},</p>
                    <p style="${paragraphStyle({ margin: '0 initial initial initial' })}">${translate('paragraph1')}</p>
                    <p style="${paragraphStyle({ margin: '0 initial initial initial' })}">${translate('summaryTitle')}</p>
                </td></tr>
            </table>
            
            ${spacing(10)}
            
            <!-- Main CTA BTN -->
            <table ${commonTableProperties({})}>
                <tr><td style="text-align:center;">
                    <!--[if mso]>
                    <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="${translate('viewOrderURL')}" style="height:50px;v-text-anchor:middle;width:500px;" arcsize="7%" stroke="f" fillcolor="#ec1c23">
                    <w:anchorlock/>
                    <center>
                    <![endif]-->
                    
                    <a href="${translate('viewOrderURL')}" 
                            style="background-color:#ec1c23;border-radius:3px;color:#ffffff;display:inline-block;font-family:sans-serif;font-size:18px;line-height:50px;text-align:center;text-decoration:none;width:500px;-webkit-text-size-adjust:none;">
                        ${translate('viewOrderLabel')}
                    </a>
                    
                    <!--[if mso]>
                    </center>
                    </v:roundrect>
                    <![endif]-->
                </td></tr>
            </table>
            
            <!-- Extra Content -->
            ${spacing(20)}
            <table ${commonTableProperties({})}>
                <tr><td style="text-align: right;">
                    <p style="${paragraphStyle({})}">
                        <strong>${translate('orderNumberPrefix')}</strong>${productOrderNumber}
                    </p>
                </td></tr>
            </table>

            <!-- Delivery -->
            ${spacing(10)}
            <table cellpadding="20" cellspacing="0" 
                    style="width:100%; background-color:#f4f6f8; box-sizing:border-box; margin:0; padding:0; border-radius:5px;">
                <tr><td>
                    <p style="${paragraphStyle({})}">
                        <strong>${translate('deliveryTitle')}</strong>
                        <br/>

                        ${fullName}
                        <br />

                        ${addressLineOne}
                        <br />

                        ${addressLineTwo 
                            ? `${addressLineTwo}<br/>`
                            : ''}

                        ${city}, ${province}<br />
                        ${postalCode}
                    </p>
                </td></tr>
            </table>
            
            ${spacing(10)}

            <!-- Items -->
            ${cartItems.map((item) => templateItem(item, translate, spacing, locale)).join(`
            `)}

            <table cellpadding="20" cellspacing="0" 
                    style="width:100%; background-color:#f4f6f8; box-sizing:border-box; margin:0; padding:0; border-radius:5px;">
                <tr><td style="text-align:right;">
                    <p style="${paragraphStyle({ fontSize: 24, textAlign: 'right' })}">
                        <strong>
                            ${translate('total')}
                            ${format(cartItems.map((item) => item.price * item.quantity)
                                .reduce((a, b) => a + b, 0), locale)}
                        </strong>
                    </p>
                </td></tr>
            </table>

            ${spacing(20)}
            
            <!-- Sign off -->
            <table ${commonTableProperties({})}>
                <tr><td>
                    <p style="${paragraphStyle({})}">
                        ${translate('signOff')}
                        <br/>

                        <span style="font-family: Caveat, verdana, arial, sans-serif; font-size: 22px; line-height:120%;">
                            ${translate('signature')}
                        </span>
                    </p>
                </td></tr>
            </table>

        </td></tr>
    </table>
    `;
}

const templateItem = (item, translate, spacing, locale) => {
    const { sku, name, quantity, price, imageUrl } = item;

    return `
    <table ${commonTableProperties({})}>
        <tr>
            <td width="100px">
                <img src="${imageUrl}" alt="${sku} - ${name}" width="80" style="width: 80%;" />
            </td>
            <td valign="top">
                <p style="${paragraphStyle({ margin: '0 0 5px 0' })}">
                    ${name}
                </p>

                <p style="${paragraphStyle({ fontSize: 12 })}">
                    <strong>${translate('sku')}</strong>${sku}
                    <br/>
                    <strong>${translate('quantity')}</strong>${quantity}
                    <br/>
                    <strong>${translate('unitPrice')}</strong>${format(price, locale)}
                </p>
            </td>

            <td valign="top" width="100px">
                <p style="${paragraphStyle({ textAlign: 'right' })}">
                    ${format(price * quantity, locale)}
                </p>
            </td>
        </tr>
    </table>

    ${spacing(15)}
    `;
}

const templateFooter = (translate, spacing, rawTranslate) => {
    return `
    <table class="footer" ${commonTableProperties({ backgroundColor: '#ffffff' })}>
        <tr><td style="box-sizing: border-box;">
            ${spacing(10)}
            <table ${commonTableProperties({})}>
                <tr>
                    <td style="line-height: 120%; vertical-align: top; width: 50%;" valign="top">
                        <p style="color: #aaaaaa; font-size: 14px; margin-top: 0;">
                            <a href="https://www.loyaltysource.com" style="font-family: Roboto, verdana, arial, sans-serif; color: #aaaa; display: block; font-size:14px;">
                                <font color="#aaaaaa">www.loyaltysource.com</font>
                            </a>
                        </p>
                        
                        <p style="font-family: Roboto, verdana, arial, sans-serif; color: #aaaaaa; font-size: 12px; font-weight:400; margin-top: 0;">
                            <!-- No content for now -->
                        </p>
                    </td>
                    
                    <td style="line-height: 120%; text-align: right; vertical-align: top; width: 50%;" valign="top">
                        <p style="font-family: Roboto, verdana, arial, sans-serif; color: #aaaaaa; font-size: 14px; margin-top: 0;">
                            Loyalty Source
                        </p>

                        <p style="font-family: Roboto, verdana, arial, sans-serif; color: #aaaaaa; font-size: 12px; font-weight:400; margin-top: 0;">
                            ${rawTranslate('footer.companyInfo.street')}
                            <br/>
                            ${rawTranslate('footer.companyInfo.city')} ${rawTranslate('footer.companyInfo.province')}
                            <br/>
                            ${rawTranslate('footer.companyInfo.postalCode')}
                        </p>
                    </td>
                </tr>
            </table>
            ${spacing(25)}
        </td></tr>
    </table>
    `;
}

export default withLocalize(ConfirmationEmail);