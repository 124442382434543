import React from 'react';
import Logo from '../Logo';
import MenuButton from './MenuButton';
import Menu from './Menu';
import { FiltersContainer } from '../../../../../smart';
import Search from '../Search';
import { sizing, spacing } from '../../../../../../defaults/styles';
import NavigateToCheckoutButton from '../../../Cart/NatigateToCartButton';

const MobileMenu = ({
  productsPopupOpen,
  openProductsPopupClickHandler,
  setSelectedCategory,
  mobileMenuOpen,
  setMobileMenuOpen,
  isMobile,
  isTablet
}) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: sizing.header.container,
        }}
      >
        <div
          style={{
            flex: '0 0 auto',
          }}
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <MenuButton open={mobileMenuOpen} />
        </div>
        <div
          style={{
            flex: isMobile ? '1 1 auto' : '1',
            paddingRight: isMobile ? '1.5em' : undefined,
          }}
        >
          <FiltersContainer>
            <Search isMobile={isMobile} isTablet={isTablet} />
          </FiltersContainer>
        </div>
        <div
          style={{
            flex: isMobile ? '0 0 auto' : '1',
            paddingRight: isMobile ? '1em' : undefined,
          }}
        >
          <NavigateToCheckoutButton isMobile={isMobile}/>
        </div>
      </div>
      <Menu
        open={mobileMenuOpen}
        setMobileMenuOpen={setMobileMenuOpen}
        setSelectedCategory={setSelectedCategory}
        openProductsPopupClickHandler={openProductsPopupClickHandler}
      />
    </>
  );
};

export default MobileMenu;
