import React, { useEffect } from 'react';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { withLocalize } from 'react-localize-redux';

const CartSubmitWrapper = styled.div`
    padding-right: ${props => props.isMobile ? '0' : '1.5rem'};
`;

const CartSubmitTitle = styled.h2`
    margin-bottom: 1.5rem;
`;

const CartSubmitParagraph = styled.p`
    line-height: 1.5rem;
    margin-bottom: 1.5rem;
`;

const CartSubmit = ({ cart, translate, isMobile }) => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        })
    }, []);
    const { productOrderNumber, submitterEmail } = cart;
    return (
        <CartSubmitWrapper isMobile={isMobile}>
            <CartSubmitTitle>{translate('checkout.submit.title')}</CartSubmitTitle>
            <CartSubmitParagraph>
                {translate('checkout.submit.paragraph1a')}
                <b>{productOrderNumber}</b>
                {translate('checkout.submit.paragraph1b')}
                <b>{submitterEmail}</b>
                {translate('checkout.submit.paragraph1c')}
            </CartSubmitParagraph>
            <CartSubmitParagraph>
                {translate('checkout.submit.paragraph2')}
            </CartSubmitParagraph>
            <CartSubmitParagraph>
                {translate('checkout.submit.paragraph3')}
            </CartSubmitParagraph>
        </CartSubmitWrapper>
    )
}

export default withLocalize(CartSubmit);