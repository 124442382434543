import React from 'react';
import styled from 'styled-components';
import Links from './Links';
import { colors, sizing } from './../../../../../defaults/styles';
import UIContainer from '../../../interface/UIContainer';

// == STYLING == //
const Aside = styled.aside`
  background-color: ${colors.black.normal};
  height: ${sizing.header.top}px;
`;
// == /STYLING == //

const Top = ({ isMobile, isTablet }) => {
  return (
    <Aside>
      <UIContainer noPadding isMobile={isMobile} isTablet={isTablet}>
        <Links isMobile={isMobile} />
      </UIContainer>
    </Aside>
  );
};

export default Top;
