import { LSFetch } from '../helpers';
import qs from 'querystring';

export const getList = ({
  page = 0,
  size = 50,
  sort = 'creationDate,desc',
}) => {
  const params = {
    page,
    size,
    sort,
  };

  return LSFetch.get('/report', { params });
};

export const getOne = reportID => {
  return LSFetch.getRaw(`/report/${reportID}`);
};

export const requestReport = (reportType, language = 'en') => {
  return LSFetch.post(`/report/${reportType}`, { queryString: { language } });
};

export const getCatalog = () => {
  return LSFetch.getRaw(`/report/product/catalog/full/xlsx`,  { 
    headers: {
      'Content-Type': 'text/octet-stream'
    },
    responseType: 'blob' 
  });
}

export const downloadCatalog = (callback = () => {}) => {
  getCatalog()
      .then(async stream => {
        const blob = new Blob([ await stream.blob()], { type: 'text/octet-stream' });
        const href = window.URL.createObjectURL(blob);
        const download = `Catalog.xlsx`;

        const a = document.createElement('a');
        a.style = 'display: none';
        a.href = href;
        a.download = download;
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(href);
          callback();
        }, 1000);
      });
}
