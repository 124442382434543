import React, { useContext } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { fonts, spacing, colors } from '../../../../defaults/styles';
import qs from 'querystring';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { ResizeAwareContext } from '../../../smart/ResizeContext';

const styles = {
  option: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    marginLeft: spacing.medium,
  },
  optionLabel: {
    whiteSpace: 'nowrap',
    fontSize: fonts.sizes.text,
  },
  select: {
    backgroundColor: colors.gray.light,
    fontSize: fonts.sizes.text,
  },
};

const optionHeightMobile = 50;
const stylesMobile = {
  option: {
    ...styles.option,
    height: optionHeightMobile,
  },
  optionLabel: {},
  select: {},
};

const FilterWrapper = styled.section`
        display: flex;
        flex: 0 0 auto;
        ${props => (props.isMobile ? 'flex-direction: column;' : '')}
        margin-bottom: ${props =>
          (props.isMobile ? spacing.medium : '')}px;
        ${props => (!props.isMobile ? 'margin-left: auto;' : '')}
    `;

const sortOptions = [
  { value: 'id,desc', lexicon: 'idDesc', loggedIn: false},
  { value: 'brand,asc', lexicon: 'brandAsc', loggedIn: false },
  { value: 'brand,desc', lexicon: 'brandDesc', loggedIn: false },
  { value: 'name,asc', lexicon: 'nameAsc', loggedIn: false },
  { value: 'name,desc', lexicon: 'nameDesc', loggedIn: false },
  { value: 'price,asc', lexicon: 'priceAsc', loggedIn: true },
  { value: 'price,desc', lexicon: 'priceDesc', loggedIn: true },
  { value: 'msrp,asc', lexicon: 'msrpAsc', loggedIn: true },
  { value: 'msrp,desc', lexicon: 'msrpDesc', loggedIn: true },
];

const Filters = ({ history, currentQuery, user, translate }) => {
  const t = (tag) => `catalog.filters.${tag}`;

  const { isMobile } = useContext(ResizeAwareContext);

  const changeAction = changedValue => {
    history.push({
      pathname: history.location.pathname,
      search: '?' + qs.stringify({ ...currentQuery, ...changedValue }),
    });
  };

  return (
    <FilterWrapper isMobile={isMobile}>
      <div style={isMobile ? stylesMobile.option : styles.option}>
        <strong style={styles.optionLabel}>
          <Translate id={t('sortBy')} />
          <select
            style={styles.select}
            onChange={({ target: { value } }) => changeAction({ sort: value })}
            value={currentQuery.sort}
          >
            {sortOptions.filter(s => !s.loggedIn || (user && s.loggedIn === user.loggedIn)).map(({ value, lexicon }) => (
              <option key={value} value={value}>
                {translate(t(lexicon))}
              </option>
            ))}
          </select>
        </strong>
      </div>
    </FilterWrapper>
  );
};

export default withLocalize(Filters);
