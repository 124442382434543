import React, { useContext } from 'react';
import styles from './styles';
import { Translate, withLocalize } from 'react-localize-redux';
import UIMain from '../../interface/UIMain';
import { ResizeAwareContext } from '../../../smart/ResizeContext';

const EnvironmentPage = ({ activeLanguage, translate }) => {
  const t = (tag) => `environment.${tag}`;
  const { isMobile, isTablet } = useContext(ResizeAwareContext);

  document.title = translate(t('title'));

  return (
    <UIMain isMobile={isMobile} isTablet={isTablet} customStyle={{ marginBottom: 0 }}>
      <style>{styles}</style>
      <div className="environment-wrapper" style={{ fontSize: '62.5%' }}>
        <div className="section intro">
          <div className="container">
            <h1>{<Translate id={t('h1')} />}</h1>
            <p>
              {<Translate id={t('introP1')} />}
              <br className="hidden-mobile" />
              {<Translate id={t('introP2')} />}
            </p>
          </div>
        </div>
        <section className="section green-planet container clearfix">
          <h2>{<Translate id={t('greenPlanetH2')} />}</h2>
          <p>{<Translate id={t('greenPlanetP')} />}</p>
        </section>

        <section className="section program">
          <div className="container">
            <h3>
              {<Translate id={t('programH3a')} />}
              <br className="hidden-mobile" />
              {<Translate id={t('programH3b')} />}
            </h3>
            <ul>
              <li>
                <p className="title">
                  {' '}
                  {<Translate id={t('programPaperlessTitle')} />}
                </p>
                <p>{<Translate id={t('programPaperlessP')} />}</p>
              </li>
              <li>
                <p className="title">
                  {<Translate id={t('programGreenTitle')} />}
                </p>
                <p>{<Translate id={t('programGreenP')} />}</p>
              </li>
              <li>
                <p className="title">
                  {<Translate id={t('programRecycleTitle')} />}
                </p>
                <p>
                  {<Translate id={t('programRecycleP')} />}{' '}
                </p>
              </li>
            </ul>
          </div>
        </section>

        <section className="section earth-friendly">
          <div className="section-top container">
            <p>{<Translate id={t('earthFriendlyP')} />}</p>
            <div className="image-box">
              {activeLanguage && activeLanguage.code === 'fr' ? (
                <img
                  src="/environment/images/svg/laBoiteRe.svg"
                  alt="Earth Friendly Box"
                />
              ) : (
                <img
                  src="/environment/images/svg/reusedBox.svg"
                  alt="Earth Friendly Box"
                />
              )}
              <img src="/environment/images/svg/Tree.svg" alt="Tree" />
            </div>
          </div>
          <div className="section-bottom clearfix">
            <div className="container">
              <h4>{<Translate id={t('earthFriendlyH4')} />}</h4>
              <div className="inner">
                <p>
                  <img
                    src="/environment/images/svg/dump.svg"
                    alt="illustration of ocean"
                  />
                  {<Translate id={t('earthFriendlyInnerL')} />}
                </p>
                <p>
                  <img
                    src="/environment/images/svg/recycledBox.svg"
                    alt="illustration of recyclable box"
                  />
                  {<Translate id={t('earthFriendlyInnerR')} />}
                </p>
              </div>
            </div>
          </div>
        </section>

        <section className="section recycling container">
          {activeLanguage && activeLanguage.code === 'fr' ? (
            <img
              src="/environment/images/fra-reduceReuseRecycle.png"
              alt="Reduce, Reuse and Recycle message"
            />
          ) : (
            <img
              src="/environment/images/reduce-reuse-recycle.png"
              alt="Reduce, Reuse and Recycle message"
            />
          )}
          <h5>{<Translate id={t('recyclingH5')} />}</h5>
          <ul className="clearfix">
            <li>
              <p>{<Translate id={t('recyclingListLeftTop')} />}</p>
              <img
                src="/environment/images/svg/how-to/pileOfBoxes.svg"
                alt="illustration of cardboard boxes"
              />
              <img
                className="borders"
                src="/environment/images/svg/how-to/dashed-line01.svg"
                alt="border"
              />
            </li>
            <li>
              <img
                src="/environment/images/svg/how-to/boxLabel.svg"
                alt="illustration of box lables"
              />
              <p>{<Translate id={t('recyclingListRightTop')} />}</p>
              <img
                className="borders"
                src="/environment/images/svg/how-to/dashed-line02.svg"
                alt="border"
              />
            </li>
            <li>
              <p>{<Translate id={t('recyclingListLeftBottom')} />}</p>
              <img
                src="/environment/images/svg/how-to/flatenedBoxes.svg"
                alt="illustration of flattened cardboard boxes"
              />
              <img
                className="borders"
                src="/environment/images/svg/how-to/dashed-line03.svg"
                alt="border"
              />
            </li>
            <li>
              <img
                src="/environment/images/svg/how-to/boxesInBin.svg"
                alt="illustration of cardboard boxes"
              />
              <p>{<Translate id={t('recyclingListRightBottom')} />}</p>
            </li>
          </ul>
        </section>
      </div>
    </UIMain>
  );
};

export default withLocalize(EnvironmentPage);
