import * as types from '../actions/types';
import { userParentInfo } from './initialState';

export default function userParentInfoReducer(state = userParentInfo, actions) {
  switch (actions.type) {
    case types.USER_PARENT_INFO_SUCCESS:
      return { ...actions.userParentInfo };
    default:
      return state;
  }
}