import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { addCartItem, removeFromCart, updateCartItem } from '../../../../../redux/actions/cartAction';
import { withLocalize } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faPlus, faMinus } from '@fortawesome/pro-regular-svg-icons';
import { faCartPlus as fasCartPlus } from '@fortawesome/pro-solid-svg-icons';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';
import { colors } from '../../../../../defaults/styles';
import ItemCounter from '../../../interface/ItemCounter';

const AddButton = styled.div`
    border-radius: 0.2rem;
    background-color: ${colors.primary.normal};
    color: ${colors.white.normal};
    cursor: pointer;
    font-size: ${props => props.isMobile ? '0.8rem' : '0.8rem'};
    margin-bottom: 1rem;
    padding: 1em;
    width: 100%;
    min-height: 42px;

    display: grid;
    grid-template-columns: ${props => props.showItemCounter ? 'repeat(3, auto)' : 'repeat(2, auto)'};
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: center;

    opacity: ${props => props.discontinued ? 0.5 : 1};
    user-select: ${props => props.discontinued ? 'none' : null};
    transition: all 0.2s;
    :hover {
        background-color: ${props => props.discontinued ? null : colors.primary.hover};
        border-color: ${props => props.discontinued ? colors.primary.dark : null};
        cursor: ${props => props.discontinued ? 'not-allowed' : 'pointer'};
        user-select: ${props => props.discontinued ? 'none' : null}
    }
`;

const IconBox=styled.div`
`;
const ButtonText = styled.div`
    text-transform: uppercase;
`;

const Indicator = styled.span`
    /* transition: all 250ms ease-in 250ms, all 500ms ease-out 0ms;
    margin-left: ${props => props.isMobile ? '0.5rem' : '0'};
    display: ${props => props.isMobile ? 'inline' : 'block'};
    font-size: ${props => props.show && !props.isMobile ? '1.2rem' : 'inherit'}; */
`;

export const AddToCheckoutButton = ({
    activeLanguage,
    isMobile,
    user,
    cart,
    sku,
    updateCartItem,
    addCartItem,
    removeFromCart,
    translate,
    discontinued,
}) => {
    const t = (tag) => `catalog.cart.${tag}`;

    const [ numberOfItemInCart, setNumberOfItemInCart ] = useState(0);
    const [ hovered, setHovered ] = useState(false);

    useEffect(()=>{
        if(!cart || !cart.items){
            setNumberOfItemInCart(0);
            return;
        }
        const item = cart.items.find((item)=>item.sku === sku);
        setNumberOfItemInCart(item ? item.quantity : 0);
    }, [ cart ]);
    return (<>{user && user.loggedIn && (
            <AddButton 
                isMobile={isMobile}
                id={`add-to-cart-button-${sku}`}
                onMouseEnter={()=>setHovered(true)}
                onMouseLeave={()=>setHovered(false)}
                onClick={({target})=>{
                    if(discontinued) { return; }
                    if(numberOfItemInCart < 99999) {
                        addCartItem(sku);
                        setNumberOfItemInCart(numberOfItemInCart + 1); 
                    }
                }}
                isHovered={hovered}
                showItemCounter={numberOfItemInCart > 0}
                discontinued={discontinued}
            >
                <IconBox>
                <FontAwesomeIcon 
                    icon={numberOfItemInCart > 0 ? fasCartPlus : faCartPlus} 
                />
                </IconBox>
                <ButtonText>
                    {translate(t('add'))}
                </ButtonText>
               
                {numberOfItemInCart > 0 && (
                    <ItemCounter
                        isDiv={!isMobile}
                        extend={isMobile?'right':'left'}
                        marginLeft={isMobile?0.5:6.1}
                        count={numberOfItemInCart}
                        activeLanguage={activeLanguage}
                        style={{ 
                            // resetting styles from the component
                            position: 'relative', 
                            top: 0, 
                            left: 0, 
                            marginLeft: 0
                        }}
                    />)}
            </AddButton>)}</>);
};

AddToCheckoutButton.propTypes = {
    cart: propTypes.object.isRequired,
};

function mapStateToProps({ user, cart }) { return { user, cart }; }
const mapDispatchToProps = { addCartItem, removeFromCart, updateCartItem, };

export default withLocalize(connect(
    mapStateToProps,
    mapDispatchToProps,
) (AddToCheckoutButton));
