const fonts = {
  // title: "'Patua One', cursive",
  content: "'Roboto', sans-serif",
  sizes: {
    small: 10,
    medium: 16,
    large: 20,
    title: 38,
    titleMobile: 30,
    subtitle: 30,
    subtitleMobile: 28,
    heading: 28,
    subHeading: 22,
    text: 16,
    smallText: 13,
    largeText: 18,
    footerItems: 14,
  },
  weights: {
    thin: 300,
    normal: 400,
    heading: 500,
    title: 600,
  },
};

export default fonts;
