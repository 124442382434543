import React, { useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import styled from 'styled-components';
// import { colors, spacing } from 'components/presentation/ui/common';
import { colors, spacing } from '../../../ui/common';
import LanguageSelector from './LanguageSelector';
import { fonts } from '../../../../../defaults/styles';

// == STYLING == //
const UL = styled.ul`
  color: ${colors.white.normal};
  display: flex;
  align-items: center;
  justify-content: ${props => (props.isMobile ? 'flex-start' : 'flex-end')};
  list-style: none;
`;
const ListItem = styled.li`
  padding: ${props =>
    props.isMobile
      ? `${spacing.base} ${spacing.base} ${spacing.base} 0`
      : spacing.base};

  &:last-child {
    padding-right: 0;
  }
`;

const Alink = styled.a`
  color: ${colors.white.normal};
  cursor: default;
  font-size: ${props =>
    props.isMobile ? fonts.sizes.small + 'px' : 'inherit'};
  text-decoration: none;
`;

const Links = ({ isMobile }) => {
  const t = (tag) => `header.${tag}`;
  // const [customerServiceLinkHover, setCustomerServiceLinkHover] = useState(
  //   false
  // );
  // const [liveChatLinkHover, setLiveChatLinkHover] = useState(false);

  return (
    <UL isMobile={isMobile}>
      <ListItem isMobile={isMobile}>
        <LanguageSelector />
      </ListItem>
      {!isMobile &&
        <ListItem>
          <Alink
            // onMouseEnter={() => setCustomerServiceLinkHover(true)}
            // onMouseLeave={() => setCustomerServiceLinkHover(false)}
            href="tel:+15146645304"
            isMobile={isMobile}
          >
            <Translate id={t("customerService")} />
            {!isMobile && ' '}514-664-5304
          </Alink>
        </ListItem>
      }
      <ListItem>
        <Alink
          href="tel:+18447299970"
          isMobile={isMobile}
        >
          {isMobile ? <Translate id={t("customerService")} /> : <Translate id={t("tollFree")} />}
          {!isMobile && ' '}1-844-729-9970
        </Alink>
      </ListItem>
    </UL>
  );
};

export default withLocalize(Links);
// export default Links;
