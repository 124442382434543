import * as types from '../actions/types';
import { user } from './initialState';

export default function userReducer(state = user, actions) {
  switch (actions.type) {
    case types.LOAD_USER_STATUS_SUCCESS:
      return { ...user, ...actions.status };
    case types.LOGIN_USER_SUCCESS:
      return { ...user, ...actions.user };
    case types.LOGOUT_USER_SUCCESS:
      return { user };
    default:
      return state;
  }
}
