import React from 'react';
import { LoginContainer } from '../../../smart';
import Logout from './Logout';

const LogoutPage = () => {
  document.title = 'Loyalty Source';

  return (
    <LoginContainer>
      <Logout />
    </LoginContainer>
  );
};

export default LogoutPage;
