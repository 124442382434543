let baseUrl = `https://api.zoomcatalog.com/`;
let canvasServiceUrl = `https://prod-quickcanvas-services.zoomcatalog.com/canvas/v1/`;
let serviceUrl = `https://prod-services.zoomcatalog.com/auth-app/v2/`;
let autologinServiceUrl = `https://prod-services.zoomcatalog.com/sso-app/v2/signin/en-US`;
let betaLibraryUrl = `https://beta-library.zoomcatalog.com/`;

export const ZOOM_CATALOG_CLIENT_ID = "zoom-api-www-lo74l.source";
export const ZOOM_CATALOG_GRANT_TYPE = "authorization_code";
export const ZOOM_CATALOG_CLIENT_SECRET = "658ac25cfa8aba278c24a47c01396d21";
export const ZOOM_CATALOG_COMPANY_ID = "3125908381353652778";
export const ZOOM_REFERER = "loyaltysource-quickcanvas";
export const ZOOM_IT_ACCOUNT = "it@klfmedia.com";

export const LAYOUT_BASIC_FOUR_PRODUCT = "45a47a3f58d24a0a8861420a4a5fade4";
export const LAYOUT_BASIC_SINGLE_PRODUCT = "0028e2c28de345bcbaf766c1343f04c6";
export const LAYOUT_BASIC_THREE_PRODUCT = "176cd3e75e2e48c9b770d5b7ef73075b";
export const LAYOUT_HALF_HALF_COLUMNS = "28f0e6e5cde742eeb5292ed2a7a176d9";
export const LAYOUT_HALF_HALF_ROWS = "62a8f331247d43bba0bf8d420b90c460";
export const LAYOUT_COVER_PAGE = "b7642a3ff3c14673b053dfd8d2aaa7f1";

export const getAccessTokenToCreateProject = async (forUsername) => {
  const requestBody = {
    "client_id": ZOOM_CATALOG_CLIENT_ID,
    "grant_type": ZOOM_CATALOG_GRANT_TYPE,
    "client_secret": ZOOM_CATALOG_CLIENT_SECRET,
    "company_id": ZOOM_CATALOG_COMPANY_ID,
    "for_username": forUsername
  }

  const response = await fetch(`${serviceUrl}auth/authorize`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      requestBody
    ),
  });

  console.log(`Get access token to create project -- Calling ${serviceUrl}auth/authorize`);
  if (!response.ok) {
    throw new Error(`Request getAccessTokenToCreateProject failed with status: ${response.status}`);
  }
  return await response.json();
}

export const getItAccountShortToken = async () => {
  const itToken = await getAccessTokenToCreateProject(ZOOM_IT_ACCOUNT);
  return itToken.user_token;
}

export const getAutoLoginToken = async (forUsername) => {
  const requestBody = {
    "client_id": ZOOM_CATALOG_CLIENT_ID,
    "grant_type": ZOOM_CATALOG_GRANT_TYPE,
    "client_secret": ZOOM_CATALOG_CLIENT_SECRET,
    "company_id": ZOOM_CATALOG_COMPANY_ID,
    "for_username": forUsername
  }

  const response = await fetch(`${baseUrl}auth/authorize`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(
      requestBody
    ),
  });
  console.log(`Get auto login token -- Calling ${baseUrl}auth/authorize`);
  if (!response.ok) {
    throw new Error(`Request getAutoLoginToken failed with status: ${response.status}`);
  }
  return await response.json();
}

// Use long user_token from getAutoLoginToken(for_username)
export const getAutoLoginRedirectUrlOld = async (userToken, uri) => {
  const response = await fetch(`${baseUrl}auth/autologin?uri=${uri}&redirect=1`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': userToken,
    },
  });
  console.log(`Get auto login redirect url -- Calling ${baseUrl}auth/autologin?uri=${uri}`);
  if (!response.ok) {
    throw new Error(`Request of getting auto login redirect url failed with status: ${response.status}`);
  }
  return await response.json();
}

// Use short user_token from getAccessTokenToCreateProject(for_username)
export const getAutoLoginRedirectUrl = (token, redirectUri) => {
  const response = `${autologinServiceUrl}?t=${token}&callback=${redirectUri}&supplier_id=${ZOOM_CATALOG_COMPANY_ID}`;
  console.log(`Jump to auto login redirect url -- ${autologinServiceUrl}?t=${token}&callback=${redirectUri}&supplier_id=${ZOOM_CATALOG_COMPANY_ID}`);

  return response;
}

export const createSingleZoomProject = async (auth, lsProductId) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': auth,
    'Referer': ZOOM_REFERER,
    'X-Zoom-Company': ZOOM_CATALOG_COMPANY_ID
  };

  const requestBody = {
    "layout_id": LAYOUT_BASIC_SINGLE_PRODUCT,
    "styles": [
      lsProductId
    ],
    "name": lsProductId
  };

  const response = await fetch(`${canvasServiceUrl}generate-project`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(
      requestBody
    ),
  });
  console.log(`Create basic single ZoomProject -- Calling ${canvasServiceUrl}generate-project`);
  if (!response.ok) {
    throw new Error(`Request failed with status: ${response.status}`);
  }
  return await response.json();
}

export const createBasicFourZoomProject = async (auth, lsProductIdList, shoppingListName) => {
  const headers = {
    'Content-Type': 'application/json',
    'Authorization': auth,
    'Referer': ZOOM_REFERER,
    'X-Zoom-Company': ZOOM_CATALOG_COMPANY_ID
  };

  const requestBody = {
    "layout_id": LAYOUT_BASIC_FOUR_PRODUCT,
    "styles": lsProductIdList,
    "name": shoppingListName
  };

  const response = await fetch(`${canvasServiceUrl}generate-project`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(
      requestBody
    ),
  });
  console.log(`Create basic four ZoomProject -- Calling ${canvasServiceUrl}generate-project`);
  if (!response.ok) {
    throw new Error(`Request failed with status: ${response.status}`);
  }
  return await response.json();
}