import * as types from '../actions/types';
import { previousFilters } from './initialState';

export default function previousCategoryFiltersReducer(
  state = previousFilters,
  actions
) {
  switch (actions.type) {
    case types.SET_PREVIOUS_FILTERS:
      return { ...actions.previousFilters };
    default:
      return state;
  }
}
