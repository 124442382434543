import React from 'react';
import styled from 'styled-components';
import { colors, fonts, spacing } from '../../../../defaults/styles';

import { Translate, withLocalize } from 'react-localize-redux';

const CSLink = styled.p`
  color: ${colors.white.normal};
  font-size: ${fonts.sizes.medium}px;
  text-align: center;
  ${props => props.aa
    ? {
      position: 'absolute',
      bottom: '2%',
      width: '100%',
    }
    : {
      marginTop: spacing.large,
    }
  };
  
`;

const Form = ({ isSample }) => {
  const t = (tag) => `login.customerService.${tag}`;

  return (
    <>
      <CSLink isSample={isSample}>
        <Translate id={t('paragraph')} />
        514-664-5304 
        <br/>
        <Translate id={t('tollFree')} />
        1-844-729-9970
      </CSLink>
    </>
  );
};

export default withLocalize(Form);
