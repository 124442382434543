import React from 'react';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import GridLoader from 'react-spinners/GridLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/pro-regular-svg-icons';
import UISection from './../../interface/UISection';
import UIContainer from './../../interface/UIContainer';
import UIGrid2cols from './../../interface/UIGrid2cols';
import PageReinforcementStatic from './../../interface/PageReinforcementStatic';
import { withLocalize } from 'react-localize-redux';
import Button from './../../interface/Button';
import Product from '../../layout/ProductsList/List/Product';
import { colors, fonts, spacing } from '../../../../defaults/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Navigation, Autoplay } from 'swiper';
import { HomeImageListContainer } from '../../../smart/'

const IconStyle = {
  fontSize: fonts.sizes.heading,
};

const imageSize = 220;
const imageLoggedInHomeSize = 'auto';
const imageSizeMobile = 200;

export const LoggedInHomePageProductSlideShow = withLocalize(
  ({
    isMobile,
    isTablet,
    title,
    description,
    buttonLabel,
    addLinkLabel,
    customized = false,
    productsLabel = null,
    list,
    onClick = () => {},
    url,
    activeLanguage,
    slideHeightCorrection,
    showPopup,
    setShowPopup,
    editUrl,
    viewUrl,
    setEditUrl,
    setViewUrl,
    zoomStudioUserToken,
    zoomStudioItToken,
    errorMessage,
    setErrorMessage,
    appendErrorMessage,
  }) => {

    const lang = activeLanguage && activeLanguage.code === 'fr' ? 'fr' : 'en';
    const viewAllLabel = `${buttonLabel} ${
      customized
        ? lang === 'en'
          ? title + ' ' + productsLabel
          : productsLabel + ' ' + title
        : ''
    }`;

    // install Swiper components
    SwiperCore.use([Navigation, Autoplay]);

    const cssSelectorNext = "nav-next-" + title.substring(0,3);
    const cssSelectorPrev = "nav-prev-" + title.substring(0,3);

    return (
      <UISection noPadding customStyle={{ marginTop: 0, marginBottom: 0 }}>
        <UIContainer
          isMobile={isMobile}
          isTablet={isTablet}
          customStyle={{
            marginBottom: 0,
            // paddingTop: isMobile ? '2em' : '4em',
            paddingRight: isMobile || isTablet ? 0 : null,
            paddingLeft: isMobile || isTablet ? 0 : null,
          }}
        >
          <UIGrid2cols
            customStyle={{
              display: isMobile ? 'inline' : 'grid',
              alignItems: 'start',
              textAlign: isMobile ? 'center' : 'inherit',
            }}
          >
            <Wrapper>
              <PageReinforcementStatic
                title={title}
                heading={'h2'}
                type={'2'}
                customStyleHeading={{
                  marginTop: 0,
                  textAlign: isMobile ? 'center' : 'inherit',
                }}
              />
              {/* Listing description if any */}
              {description && (
                <Paragraph isDescription={true}>{description}</Paragraph>
              )}
            </Wrapper>
            <Wrapper isButton={true}>
              {/* the <Button> componenent contains <Link>, and url="" is equivalent ot to='' */}
              <Button
                borderRadius={2}
                color={colors.primary.normal}
                size={18}
                fill={true}
                cta={true}
                url={url}
                onClick={onClick}
                isHeader={true}
              >
                {viewAllLabel}
              </Button>
            </Wrapper>
          </UIGrid2cols>

          {!list && (
            <UIContainer>
              <GridLoader
                css={{ margin: 'auto' }}
                size={fonts.sizes.text}
                color={colors.main.normal} />
            </UIContainer>
          )}

          <FlexDiv>
            <div className={cssSelectorPrev}>
              <ArrowBox
                arrowLeft={true}
                isTablet={isTablet}
              >
                <ArrowBoxInner>
                  <FontAwesomeIcon icon={faChevronLeft} style={IconStyle} />
                </ArrowBoxInner>
              </ArrowBox>
            </div>

            <HomeImageListContainer>
              <Swiper
                loop={true}
                slidesPerView={list.length < 4 ? list.length : 4}
                spaceBetween={15}
                autoplay={{delay: 2500}}
                navigation={{
                  nextEl: '.' + cssSelectorNext,
                  prevEl: '.' + cssSelectorPrev,
                }}
                breakpoints={{
                  1024: {
                    slidesPerView: list.length < 4 ? list.length : 4,
                    spaceBetween: 15,
                  },
                  768: {
                    slidesPerView: list.length < 3 ? list.length : 3,
                    spaceBetween: 30,
                  },
                  640: {
                    slidesPerView: list.length < 2 ? list.length : 2,
                    spaceBetween: 20,
                  },
                  320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                }}
              >
                {list.length > 0 &&
                  list.map((product,index) => (
                    <SwiperSlide key={index}>
                      <SlideDiv isMobile={isMobile} slideHeightCorrection={slideHeightCorrection}>
                        <Product
                          product={product}
                          isMobile={isMobile}
                          isTablet={isTablet}
                          linkToAdd={true}
                          linkToAddLabel={addLinkLabel}
                          loggedInHome={true}
                          showAddToShoppingListButton = {false}
                          showPopup={showPopup}
                          setShowPopup={setShowPopup}
                          editUrl={editUrl}
                          viewUrl={viewUrl}
                          setEditUrl={setEditUrl}
                          setViewUrl={setViewUrl}
                          zoomStudioUserToken={zoomStudioUserToken}
                          zoomStudioItToken={zoomStudioItToken}
                          errorMessage={errorMessage}
                          setErrorMessage={setErrorMessage}
                          appendErrorMessage={appendErrorMessage}
                        />
                      </SlideDiv>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </HomeImageListContainer>

            <div className={cssSelectorNext}>
              <ArrowBox
                arrowRight={true}
                isTablet={isTablet}
              >
                <ArrowBoxInner>
                  <FontAwesomeIcon icon={faChevronRight} style={IconStyle} />
                </ArrowBoxInner>
              </ArrowBox>
            </div>
          </FlexDiv>

        </UIContainer>
      </UISection>
    );
  }
);

// === Styling === //
const Paragraph = styled.p`
  font-size: ${props =>
    props.isDescription ? fonts.sizes.largeText + 'px' : null};
  margin-bottom: ${spacing.verySmall}px;
`;
const Wrapper = styled.div`
  justify-self: ${props => (props.isButton ? 'end' : null)};
  margin-bottom: 1em;
`;

const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
`;
const SlideDiv = styled.div`
  width: ${props =>
    props.isMobile
      ? imageSizeMobile + 'px'
      : props.loggedInHome
      ? imageLoggedInHomeSize
      : imageSize + 'px'};
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  height: ${props => (props.isMobile ? props.slideHeightCorrection ? '360px' : '480px' : props.slideHeightCorrection ? '360px' : '480px')};
`;
const ArrowBox = styled.div`
  /* height is temp */
  height: ${props => (props.isTablet ? '155px' : '220px')};
  padding-right: ${props => (props.arrowLeft ? '20px' : null)};
  padding-left: ${props => (props.arrowRight ? '20px' : null)};

  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  opacity: ${props => (props.disabled ? '0.2' : '1')};
  user-select: ${props => (props.disabled ? 'none' : 'auto')};
`;

const ArrowBoxInner = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;
