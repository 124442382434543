import React from 'react';
import PageReinforcementStatic from './../../interface/PageReinforcementStatic';
import UIContainer from './../../interface/UIContainer';
import UISection from './../../interface/UISection';
import { withLocalize } from 'react-localize-redux';

const Intro = ({ isMobile, isTablet, translate }) => {
  const t = (tag) => `contactUs.intro.${tag}`;
  return (
    <UIContainer fullWidth noPadding>
      <UISection>
        <PageReinforcementStatic
          title={translate(t('title'))}
          subTitle={translate(t('subTitle'))}
          alignment={'center'}
          borderRequired
          heading={'h1'}
          isMobile={isMobile}
          isTablet={isTablet}
        />
      </UISection>
    </UIContainer>
  );
};

export default withLocalize(Intro);
