import React, { useState, useEffect, useRef } from 'react';
import { withLocalize } from 'react-localize-redux';
import Input from '../../../interface/Input';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';
import { CartSectionUpdateButton, CartSectionCancelButton, CartSectionErrorMessage } from '../CartSummary';
import AnimationBox from './../AnimationBox';
import { selectThemeOverride } from '../../../../../tools/helpers/styling';
import CheckoutSelect from '../../../interface/CheckoutSelect';

const SectionTitle = styled.h2`
    margin-bottom: 1.25rem;
`;
const CartSectionAddressGrid = styled.div`
    ${props => !props.isMobile 
        ? 
            `display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 0.25rem 1rem;`
        : ''
    }
`;

const CartSectionButtonSection = styled.div`
    grid-column-start: 2; 
    grid-column-end: 3; 
    text-align: right;
    margin-top: 1rem;
`;

const CartSectionRow = styled.div`
    margin-bottom: 0.75rem;
`;

const CartSectionLabel = styled.span`
    font-weight: 400;
`;

const CartSectionButtonLabel = styled.span`
    font-size: 1.2em;
`;

const requiredFields = {
    firstName: true, 
    lastName: true, 
    company: false,
    addressLineOne: true,
    addressLineTwo: false,
    city: true, 
    provinceId: true,
    province: true,
    postalCode: true,
    phone: false,
    email: false,
}

const clearError = {
    firstName: false,
    lastName: false ,
    company: false,
    addressLineOne: false,
    addressLineTwo: false,
    city: false,
    provinceId: false,
    province: false,
    postalCode: false,
    phone: false,
    email: false,
}

const allRequiredFieldsWithValue = (address) => {
    let foundNullRequired = false;
    if (address) {
        Object.entries(requiredFields).forEach(([ key, value ]) => { 
            if (value === true) {
                foundNullRequired = foundNullRequired || !address[key];
            }
        });
        return !foundNullRequired;
    } else {
        return false;
    }
}

const CartSectionAddress = ({ activeLanguage, translate, cart, updateCartAddress, isMobile }) => {
    const [ disabled, setDisabled ] = useState(allRequiredFieldsWithValue(cart.address));
    const [ address, setAddress ] = useState({});
    const [ error, setError ] = useState(clearError);
    const [ enableCancelButton, setEnableCancelButton] = useState(allRequiredFieldsWithValue(cart.address));
    const [selectedProvince, setSelectedProvince] = useState(null);

    const options = [];
    //get all options for select and the selected province
    const provincesList = cart.provinces
    if(provincesList) {
        provincesList.forEach(p => {
            options.push ({
                value: p.id,
                label: p.code
            });
        });
    }


    useEffect(() => {
        const newAddress = {};
        if (cart.address) {
            Object.entries(requiredFields).forEach(([ key, _ ]) => { newAddress[key] = cart.address[key] });
        } else {
            setDisabled(false);
        }
        setAddress(newAddress);
        setEnableCancelButton(allRequiredFieldsWithValue(cart.address));
    }, [ cart.address ]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        })
    }, []);

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        })
    };

    const [collapse, setCollapse] = useState(true)

    const {
        firstName, lastName, company, addressLineOne,
        addressLineTwo, city, province, provinceId, postalCode, phone, email
    } = address;

    const validateEmail = (email) => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    }

    const validatePostalCode = (postalCode) => {    
        if(postalCode == null) {
            return false;
        }
        postalCode = postalCode.toString().trim();
    
        var us = new RegExp("^\\d{5}(-{0,1}\\d{4})?$");
        var ca = new RegExp(/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/);
    
        if (us.test(postalCode.toString()) || ca.test(postalCode.toString().replace(/\W+/g, ''))) {
            return true;
        }
        return false;
    }

    const validateProvinceId = (provinceId) => {
        const valueOfId = Number(provinceId);
        if(Number.isInteger(valueOfId)) {
            return true;
        }
        return false;
    }

    const saveAddress = () => {
        //validate all fields of the form
        let validateResult = {
            firstName: requiredFields.firstName && !address.firstName,
            lastName: requiredFields.lastName && !address.lastName ,
            company: requiredFields.company && !address.company,
            addressLineOne: requiredFields.addressLineOne && !address.addressLineOne,
            addressLineTwo: requiredFields.addressLineTwo && !address.addressLineTwo,
            city: requiredFields.city && !address.city,
            provinceId: requiredFields.provinceId && !address.provinceId,
            postalCode: requiredFields.postalCode && !address.postalCode,
            phone: requiredFields.phone && !address.phone,
            email: requiredFields.email && !address.email,
        };

        if(!validatePostalCode(address.postalCode)) validateResult.postalCode = true;
        if(address.email && !validateEmail(address.email)) validateResult.email = true;
        if(!validateProvinceId(address.provinceId)) {
            validateResult.provinceId = true;
            setSelectedProvince(null);
        } 

        if (Object.values(validateResult).every(i => i === false)) {
            updateCartAddress(address, activeLanguage.code);
            setDisabled(true);
            setError(clearError);
            setCollapse(true);
            scrollTop();
        } else {
            setError(validateResult);
        }
    }

    const clickCancel = () => {
        setAddress(cart.address 
            ? {
                firstName: cart.address.firstName,
                lastName: cart.address.lastName,
                company: cart.address.company,
                addressLineOne: cart.address.addressLineOne,
                addressLineTwo: cart.address.addressLineTwo,
                city: cart.address.city,
                province: cart.address.province,
                provinceId: cart.address.provinceId,
                postalCode: cart.address.postalCode,
                phone: cart.address.phone,
                email: cart.address.email,
            }
            : {}
        );
        setError(clearError);
        setDisabled(true);
        setCollapse(true);
        scrollTop();
    };

    // for dynamic height
    const [targetHeight, setTargetHeight] = useState(0);
    const targetRef = useRef(null);
    useEffect(() => {
        if(targetRef.current) {
            const timer = setTimeout(()=>{
                setTargetHeight(targetRef.current.clientHeight);
            }, 200);
            return () => clearTimeout(timer);
        }
    });
    return (
        <>
            <SectionTitle>{translate('checkout.shipping.title')}</SectionTitle>
            <AnimationBox collapse={collapse} isMobile={isMobile} targetHeight={targetHeight}>
            {!disabled 
                // Form
                ? ( 
                
                    <CartSectionAddressGrid isMobile={isMobile} ref={targetRef}>
                        <Input
                            value={address['firstName']}
                            label={translate('checkout.shipping.firstname')}
                            required={requiredFields['firstName']}
                            error={error.firstName}
                            onChange={(e) => setAddress({ ...address, firstName: e.target.value })}
                        />
                        <Input
                            value={address['lastName']}
                            label={translate('checkout.shipping.lastname')}
                            required={requiredFields['lastName']}
                            error={error.lastName}
                            onChange={(e) => setAddress({ ...address, lastName: e.target.value })}
                        />
                        <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                            <Input
                                value={address['company']}
                                label={translate('checkout.shipping.company')}
                                required={requiredFields['company']}
                                error={error.company}
                                onChange={(e) => setAddress({ ...address, company: e.target.value })}
                            />
                        </div>
                        <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                            <Input
                                value={address['addressLineOne']}
                                label={translate('checkout.shipping.street1')}
                                required={requiredFields['addressLineOne']}
                                error={error.addressLineOne}
                                onChange={(e) => setAddress({ ...address, addressLineOne: e.target.value })}
                            />
                        </div>
                        <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                            <Input
                                value={address['addressLineTwo']}
                                label={translate('checkout.shipping.street2')}
                                required={requiredFields['addressLineTwo']}
                                error={error.addressLineTwo}
                                onChange={(e) => setAddress({ ...address, addressLineTwo: e.target.value })}
                            />
                        </div>
                        <div style={{ gridColumnStart: 1, gridColumnEnd: 3 }}>
                            <Input
                                value={address['city']}
                                label={translate('checkout.shipping.city')}
                                required={requiredFields['city']}
                                error={error.city}
                                onChange={(e) => setAddress({ ...address, city: e.target.value })}
                            />
                        </div>
                        {/* <Input
                            value={address['province']}
                            label={translate('checkout.shipping.province')}
                            required={requiredFields['province']}
                            error={error.province}
                            onChange={(e) => setAddress({ ...address, province: e.target.value })}
                        /> */}
                        <div>
                            <CheckoutSelect
                                value={
                                    selectedProvince
                                    ? {
                                        value: selectedProvince.id,
                                        label: selectedProvince.label
                                    }
                                    : provinceId ?
                                    {
                                        value: provinceId,
                                        label: province
                                    }
                                    :
                                    null
                                }
                                label={translate('checkout.shipping.province')}
                                isClearable={true}
                                options={options}
                                placeholder={translate('checkout.shipping.selectPlaceholder')}
                                theme={theme => selectThemeOverride(theme)}
                                required={requiredFields['provinceId']}
                                error={error.provinceId}
                                onChange={selectedOption => {
                                    if(selectedOption) {
                                        setSelectedProvince({
                                            id: selectedOption.value,
                                            label: selectedOption.label,
                                        });
                                        const valueOfId = Number(selectedOption.value);
                                        if(Number.isInteger(valueOfId)) {
                                            setAddress({ 
                                                ...address, 
                                                provinceId: valueOfId, 
                                                province: selectedOption.label
                                            });
                                            
                                        } else {
                                            console.error('Invalid province id: ' + selectedOption.value);
                                        }
                                    } else {
                                        setSelectedProvince(null); 
                                        setAddress({ ...address, provinceId: null, province: null });
                                    }
                                
                                }}
                            />
                        </div>
                        <Input
                            value={address['postalCode']}
                            label={translate('checkout.shipping.postalCode')}
                            required={requiredFields['postalCode']}
                            error={error.postalCode}
                            onChange={(e) => setAddress({ ...address, postalCode: e.target.value })}
                        />
                        <Input
                            style={{ width: '95%' }}
                            value={address['phone']}
                            label={translate('checkout.shipping.phone')}
                            required={requiredFields['phone']}
                            error={error.phone}
                            onChange={(e) => setAddress({ ...address, phone: e.target.value })}
                        />
                        <Input
                            value={address['email']}
                            type={'email'}
                            label={translate('checkout.shipping.email')}
                            required={requiredFields['email']}
                            error={error.email}
                            onChange={(e) => setAddress({ ...address, email: e.target.value })}
                        />
                        {Object.values(error).some(i => i === true) &&
                            <CartSectionErrorMessage>{translate('labels.form.required.error')}</CartSectionErrorMessage>
                        }
                            <CartSectionButtonSection>
                                < CartSectionUpdateButton onClick = {
                                    () => {
                                        saveAddress();
                                    }
                                } >
                                    <CartSectionButtonLabel>
                                        {translate('labels.update')}
                                    </CartSectionButtonLabel>
                                </CartSectionUpdateButton>
                                {enableCancelButton && 
                                    <CartSectionCancelButton onClick={clickCancel}>
                                        <CartSectionButtonLabel>
                                            {translate('labels.cancel')}
                                        </CartSectionButtonLabel>
                                    </CartSectionCancelButton>
                                }
                            </CartSectionButtonSection>
                        </CartSectionAddressGrid>
                ) :
                <div style={{ display: 'flex', flexDirection: 'column' }} ref={targetRef}>
                    <CartSectionRow>
                        <CartSectionLabel>{firstName} {lastName}</CartSectionLabel>
                    </CartSectionRow>
                    {company && 
                        <CartSectionRow>
                            <CartSectionLabel>{company}</CartSectionLabel>
                        </CartSectionRow>
                    }
                    <CartSectionRow>
                        <CartSectionLabel>{addressLineOne}</CartSectionLabel>
                    </CartSectionRow>
                    {addressLineTwo && 
                        <CartSectionRow>
                            <CartSectionLabel>{addressLineTwo}</CartSectionLabel>
                        </CartSectionRow>
                    }
                    <CartSectionRow>
                        <CartSectionLabel>{city}, {province}, {postalCode}</CartSectionLabel>
                    </CartSectionRow>
                    {phone && 
                        <CartSectionRow>
                            <CartSectionLabel>{phone}</CartSectionLabel>
                        </CartSectionRow>
                    }
                    {email && 
                        <CartSectionRow>
                            <CartSectionLabel>{email}</CartSectionLabel>
                        </CartSectionRow>
                    }

                    <CartSectionButtonSection>
                        <CartSectionUpdateButton onClick={() => {setDisabled(false); setCollapse(false)}}>
                            <CartSectionButtonLabel>
                                {translate('labels.edit')}
                            </CartSectionButtonLabel>
                        </CartSectionUpdateButton>
                        {/* <CartSectionCancelButton>
                            {translate('checkout.shipping.address.default')}
                        </CartSectionCancelButton> */}
                    </CartSectionButtonSection>
                </div>
            }
            </AnimationBox> 
        </>
    );
}

export default withLocalize(CartSectionAddress);