import React from 'react';
import { lsStyled as styled } from './../../../tools/helpers/lsStyled';
import { sizing, spacing } from './../../../defaults/styles';

const Main = styled.main`
  margin-bottom: ${props => 
      props.isMobile
      ? spacing.pageLastMobileLastBlockMargin
      : spacing.pageLastBlockMarginBottom + 'px'
      };
  padding-top: ${props =>
    props.noPadding
      ? null
      : props.isMobile
      ? sizing.header.mobileTop + sizing.header.mobileBottom + 'px'
      : sizing.header.top + sizing.header.mid + sizing.header.btm + 'px'};

  ${props => (props.customStyle ? props.customStyle : null)};
`;

const UIMain = ({ children, customStyle = null, noPadding = null, isMobile, isTablet }) => {
  return (
    <Main noPadding={noPadding} customStyle={customStyle} isMobile={isMobile} isTablet={isTablet}>
      {children}
    </Main>
  );
};

export default UIMain;
