import React from 'react';
import { lsStyled as styled } from './../../../../../tools/helpers/lsStyled';


const Root = styled.div`
  display: flex;
  flex-direction: column;
  flex: ${props => (props.isMobile ? '0 0 100%' : '0 0 48%')};

  order: ${
    props => (props.isMobile && props.mobileOrder ? 2 : undefined)
  };
`;

const Column = ({ children, isMobile, mobileOrder, links }) => {
  return (
    <Root isMobile={isMobile} links={ links } mobileOrder={mobileOrder}>
      {children}
    </Root>
  );
};

export default Column;
