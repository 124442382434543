import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlayCircle } from '@fortawesome/pro-solid-svg-icons';

import PageReinforcementStatic from './../../interface/PageReinforcementStatic';
import UIContainer from './../../interface/UIContainer';
import UIGrid2cols from './../../interface/UIGrid2cols';
import UISection from './../../interface/UISection';
import UIParagraph from './../../interface/UIParagraph';
import { colors } from './../../../../defaults/styles';
import Popup from './Popup';

import { Translate, withLocalize } from 'react-localize-redux';

const offSpacing = '50%';
// == Styling == //

const DistributionCenter = ({ isMobile, isTablet, translate }) => {
  const t = (tag) => `home.DistributionCenter.${tag}`;

  const [showPopup, setShowPopup] = useState(false);

  // temp video placeholder
  const videoPlaceholder = {
    href: 'images/pages/home/ERP.png',
    alt: 'ERP',
  };

  return (
    <UIContainer
      fullWidth
      customStyle={{
        backgroundColor: colors.gray.light,
        marginTop: isMobile ? '2em' : '8em',
        marginBottom: '2em',
        paddingTop: '4em',
        paddingBottom: '4em',
      }}
    >
      <UIContainer noPadding isMobile={isMobile} isTablet={isTablet}>
        <UIGrid2cols isMobile={isMobile} isTablet={isTablet}>
          <UISection noPadding>
            <PageReinforcementStatic
              title={translate(t('title'))}
              subTitle={translate(t('subTitle'))}
              alignment={'left'}
              borderRequired={true}
              heading={'h2'}
              type={'2'}
            />
            <UIParagraph customStyle={{ marginBottom: '1em' }}>
              <Translate id={t('mainParagraph')} />
            </UIParagraph>
          </UISection>

          <UISection noPadding>
            <VideoContainer className="video-player">
              <VideoBox isMobile={isMobile} isTablet={isTablet}>
                {/* <FontAwesomeIcon
                  icon={faPlayCircle}
                  style={fontAwesomeStyling}
                /> */}
                <VideoImage
                  src={videoPlaceholder.href}
                  alt={videoPlaceholder.alt}
                />
              </VideoBox>
            </VideoContainer>
          </UISection>
        </UIGrid2cols>
      </UIContainer>
      {showPopup ? (
        <Popup showPopup={showPopup} setShowPopup={setShowPopup}>
          <ReactPlayer
            url="https://youtu.be/jssO8-5qmag"
            height="432px"
            width="768px"
          />
        </Popup>
      ) : null}
    </UIContainer>
  );
};

export default withLocalize(DistributionCenter);

// styled componenet names as Video as it was supposed to be video popup.
// leaving it as it is for now so that it can be used if video becomes available.
const VideoContainer = styled.div`
  position: relative;
`;

const VideoBox = styled.div`
  position: ${props => (props.isMobile ? 'relative' : 'absolute')};
  top: ${props => (props.isMobile ? 0 : '-' + offSpacing)};
  right: ${props => (props.isMobile ? 0 : '1em')};
  transform: ${props => (props.isMobile ? 0 : 'translateY(-50%)')};
`;

const VideoImage = styled.img`
  border-radius: 5px;
  /* cursor: pointer; */
  max-width: 450px;
  width: 100%;
  height: auto;
`;

// Please active the folloing styling when video is added.
// const fontAwesomeStyling = {
//   color: colors.white.normal,
//   cursor: 'pointer',
//   pointerEvents: 'none',
//   fontSize: 92,
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
// };
// == Styling == //
