import React, { useState, useContext } from 'react';
import UIMain from './../../interface/UIMain';
import Intro from './Intro';
import ResetPasswordForm from './ResetPasswordForm';
import { ResizeAwareContext } from '../../../smart/ResizeContext';
import { withLocalize } from 'react-localize-redux';

const ResetPasswordPage = ({ translate }) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);
  document.title = translate('resetPassword.intro.title');

  const getURL = window.location.href;
  const randomString = getURL.substring(getURL.lastIndexOf("=")).slice(1);
  const [sessionId, setSessionId] = useState(randomString);

  return (
    <UIMain isMobile={isMobile} isTablet={isTablet}>
      <Intro isMobile={isMobile} isTablet={isTablet} />
      <ResetPasswordForm isMobile={isMobile} isTablet={isTablet} sessionId={sessionId} />
    </UIMain>
  );
};

export default withLocalize(ResetPasswordPage);