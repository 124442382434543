import React from 'react'
import { spacing, fonts, colors } from '../../../../../../../defaults/styles'

const styles = {
  item: {
    fontSize: fonts.sizes.smallText,
  },
  label: {
    color: colors.gray.normal,
  },
  value: {
    color: colors.black.normal,
  },
}

const Item = ({ label, value }) => (
  <div
    style={{
      display: 'inline-block',
      backgroundColor: 'rgb(249, 232, 233)',
      padding: spacing.verySmall,
    }}
  >
    <span style={{ ...styles.item, ...styles.label }}>{label}</span>
    <span style={{ ...styles.item, ...styles.value }}>{value}</span>
  </div>
)

export default Item
