export const tFactory = uniqueLexiconPath => {
  return lexiconKey => {
    return `${uniqueLexiconPath}.${lexiconKey}`
  }
}

export const formatLexicon = (lexicon, uniqueLexiconPath) => {
  const formattedLexicon = Object.keys(lexicon).map(lexiconKey => {
    const newLexiconKey = `${uniqueLexiconPath}.${lexiconKey}`
    return { [newLexiconKey]: lexicon[lexiconKey] }
  })
  return Object.assign(...formattedLexicon)
}

export const setLexicon = (uniqueLexiconPath, incomingLexicon) => {
  const t = tFactory(uniqueLexiconPath)
  const lexicon = formatLexicon(incomingLexicon, uniqueLexiconPath)
  return { t, lexicon }
}
