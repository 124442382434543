const colors = {
  primary: {
    normal: '#EC1C24',
    dark: '#C30810',
  },
  gray: {
    normal: '#959595',
    light: '#E4E6E8',
  },
  black: {
    normal: '#333333',
  },
  white: {
    normal: '#ffffff',
  },
  green: {
    normal: '#228B22'
  }
};

export default colors;
