import React from 'react';
// import styled from 'styled-components';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { colors, fonts, spacing } from './../../../../defaults/styles';

const BoxHeight = (1024 * 300) / 1100;
const ImageSize = (1024 * 140) / 1100;

export const Banner = ({ isMobile, isTablet, content }) => {
  return (
    <BannerBoxInner>
      {/* Banner image */}
      <BannerImage src={content.url} alt={content.name + ' image'} />

      {/* Brand image if any */}
      {content.brandImageUrl && (
        <BannerLogo src={content.brandImageUrl} alt={content.name + ' logo'} />
      )}
      <TextBox>
        {/* Title of the banner */}
        <BannerTitleLabel>{content.name}</BannerTitleLabel>

        {/* Description of the banner */}
        <BannerDescription></BannerDescription>
        <span>{content.description}</span>
      </TextBox>
    </BannerBoxInner>
  );
};

const BannerBoxInner = styled.div`
  background-color: ${colors.white.normal};
  /* height: 300px;  /* width: 1100px; */
  height: ${BoxHeight}px;
  width: 1024px;
  position: relative;
`;

const BannerImage = styled.img`
  display: block;
  height: auto;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const BannerLogo = styled.img`
  height: ${ImageSize}px !important;
  width: ${ImageSize}px !important;

  position: absolute;
  top: 2px;
  right: 2px;
  z-index: 1;
`;

const TextBox = styled.div`
  color: ${colors.gray.dark};
  padding-left: ${spacing.medium}px;
  position: absolute;
  top: 10%;
  left: 0%;
  transform: translateY(-50%);
`;

const BannerTitleLabel = styled.h3`
  color: inherit;
  font-size: ${fonts.sizes.subHeading}px;
`;

const BannerDescription = styled.p`
  color: inherit;
  font-size: ${fonts.sizes.small}px;
`;
