import React, { useContext } from 'react';
import UIMain from "../../interface/UIMain";
import UISection from '../../interface/UISection';
import { withLocalize } from "react-localize-redux";
import { sizing } from '../../../../defaults/styles';
import qs from 'querystring';
import { CategoriesContainer } from '../../../smart';
import CategoriesSideBar from '../../layout/CategoriesSideBar';
import CatalogContent from './CatalogContent';
import { ResizeAwareContext } from '../../../smart/ResizeContext';
import { NewCatalogContainer as CatalogContainer } from "../../../smart";

const categoriesAsideWidth = sizing.categoriesAsideWidth;

const sectionStyle = (isMobile) => isMobile
    ? {
        maxWidth: sizing.contentWidth,
        margin: '2em auto',
        display: 'flex',
        flexDirection: 'column',
    }
    : {
        maxWidth: sizing.contentWidth,
        margin: '2.5em auto',
        display: 'grid',
        gridTemplateColumns: `${categoriesAsideWidth}px ${sizing.contentWidth -
        categoriesAsideWidth}px`,
    };

const CategoryPage = ({ history, translate, ...props }) => {
    const { isMobile, isTablet } = useContext(ResizeAwareContext);

    // Query
    const currentQuery = qs.parse(props.location.search.substr(1));

    return (
        <UIMain isMobile={isMobile} isTablet={isTablet}>
            <UISection noPadding customStyle={sectionStyle(isMobile)}>
                <CategoriesContainer>
                    <CatalogContainer history={history}>
                        <CategoriesSideBar
                            isMobile={isMobile}
                            history={history}
                            currentQuery={currentQuery}
                        />

                        <CatalogContent
                            isMobile={isMobile}
                            history={history}
                            currentQuery={currentQuery}
                        />
                    </CatalogContainer>
                </CategoriesContainer>
            </UISection>
        </UIMain>
    );
};

export default withLocalize(CategoryPage);