import React from 'react';
import FooterContactButton from './FooterContactButton';
import { lsStyled as styled } from './../../../../../tools/helpers/lsStyled';
import { colors, fonts, spacing, typography } from './../../../../../defaults/styles';
import { Translate, withLocalize } from 'react-localize-redux';

const styles = {
  ul: {
    listStyle: 'none',
    marginBottom: spacing.large,
    textAlign: 'left',
  },
  ulLastBlock: {
    listStyle: 'none',
    marginBottom: 0,
    textAlign: 'left'
  },
  li: {
    fontSize: fonts.sizes.footerItems,
    paddingBottom: spacing.small,
  },
  liTitle: {
    paddingBottom: spacing.medium,
  },
  a: {
    color: 'white',
    fontSize: fonts.sizes.medium,
    textDecoration: 'none',
  },
};

const styleMobileUl = {
  ...styles.ul,
  textAlign: 'left',
  marginBottom: spacing.small,
};

const MainFooter = ({ isMobile }) => {
  const t = (tag) => `footer.companyInfo.${tag}`;

  return (
    <>
      <ul style={isMobile ? styleMobileUl : styles.ul}>
        <FooterTitle>{<Translate id={t('name')} />}</FooterTitle>
        <li style={styles.li}>{<Translate id={t('street')} />}</li>
        <li style={styles.li}>
          {<Translate id={t('city')} />} {<Translate id={t('province')} />}
        </li>
        <li style={styles.li}>{<Translate id={t('postalCode')} />}</li>
      </ul>
      <ul style={isMobile ? styleMobileUl : styles.ulLastBlock}>
        <li style={styles.li}>
          {<Translate id={t('contactUs')} />}{' '}
          <a style={styles.a} href="tel:15146645304">
            514 664-5304
          </a>
        </li>
        <li style={styles.li}>
          {<Translate id={t('tollFree')} />}{' '}
          <a style={styles.a} href="tel:18447299970">
          1-844-729-9970
          </a>
        </li>
        <li style={styles.li}>
          {<Translate id={t('openHours')} />}
          {'\u00A0'}|{'\u00A0'}
          {<Translate id={t('openDays')} />}
        </li>
        <li style={{marginTop: '0.5em'}}>
          <FooterContactButton />
        </li>
      </ul>
    </>
  );
};

export default withLocalize(MainFooter);

const FooterTitle = styled.h4 `
  ${typography.h4};
  color: ${colors.white.normal};
  margin-bottom: ${spacing.medium}px;
`;