import * as types from './types'
import { shoppingList } from '../../tools/api'
import { beginApiCall, apiCallError } from './apiStatusActions'

const sortShoppingListsByDefault = shoppingList => {
  if(shoppingList){
    shoppingList.sort((a,b)=>
        a.defaultList?
          -Number.MAX_SAFE_INTEGER:
        b.defaultList?
          Number.MAX_SAFE_INTEGER: 
        a.id - b.id);
  }
  return shoppingList;
}

export const loadShoppingListsSuccess = shoppingLists => {
  return { type: types.LOAD_SHOPPINGLISTS_SUCCESS, shoppingLists:sortShoppingListsByDefault(shoppingLists) }
}

export const updateShoppingListsSuccess = shoppingLists => {
  return { type: types.UPDATE_SHOPPINGLISTS_SUCCESS, shoppingLists:sortShoppingListsByDefault(shoppingLists) }
}

export const downloadedExcelFileSuccess = downloadedShoppingList => {
  return { type: types.DOWNLOAD_SHOPPING_LIST_EXCEL, downloadedShoppingList }
}

export const loadShoppingLists = () => {
  return dispatch => {
    dispatch(beginApiCall())
    return shoppingList
      .getShoppingLists()
      .then(({ json: shoppingLists }) => {
        dispatch(loadShoppingListsSuccess(shoppingLists))
      })
      .catch(error => {
        dispatch(apiCallError(error))
        throw error
      })
  }
}

export const updateShoppingLists = (shoppingLists) => {
  return dispatch => {
    dispatch(updateShoppingListsSuccess(shoppingLists))
  }
}
const downloadedShoppingListExcelFile = (downloadedExcelFile) => {
    return dispatch => {
      dispatch(downloadedExcelFileSuccess(downloadedExcelFile))
    }
}

export const addProductIdEventHandler = (event, sku, shoppingLists) => {
  return dispatch => {
    let extras = {
      queryString:{sku},
    };
    //api call
    shoppingList.addProductToShoppingList(event.value, extras).then(({ json: modifiedShoppingList }) => {
      //make defaultList to be false to all shopping lists
      let newShoppingLists = shoppingLists.map(sl => (
        {
          id: sl.id,
          listName: sl.listName,
          defaultList: sl.defaultList,
          productIds: (sl.id == modifiedShoppingList.id) ? modifiedShoppingList.productIds : sl.productIds,
        }
      ));
      //update store state
      dispatch(updateShoppingListsSuccess(newShoppingLists));
    })
    .catch(error => {
      dispatch(apiCallError(error))
      throw error
    })
  }
}

export const removeProductIdEventHandler = (event, sku, shoppingLists) => {
  return dispatch => {
    //api call
    shoppingList.removeProductFromShoppingList(event.value, sku).then(({ json: modifiedShoppingList }) => {
      //make defaultList to be false to all shopping lists
      let newShoppingLists = shoppingLists.map(sl => (
        {
          id: sl.id,
          listName: sl.listName,
          defaultList: sl.defaultList,
          productIds: (sl.id == modifiedShoppingList.id) ? modifiedShoppingList.productIds : sl.productIds,
        }
      ));
      //update store state
      dispatch(updateShoppingListsSuccess(newShoppingLists));
    })
    .catch(error => {
      dispatch(apiCallError(error))
      throw error
    })
  }
}

export const modifyDefaultEventHandler = (event, shoppingLists) => {
  return dispatch => {
    let extras = {
      queryString:{defaultList:true},
    };
    //api call
    shoppingList.modifyShoppingList(event.target.value, extras).then(({ json: modifiedShoppingList }) => {
      //make defaultList to be false to all shopping lists
      let newShoppingLists = shoppingLists.map(sl => (
        {
          id: sl.id,
          listName: sl.listName,
          defaultList: (sl.id == modifiedShoppingList.id) ? true : false,
          productIds: sl.productIds
        }
      ));
      //update store state
      dispatch(updateShoppingListsSuccess(newShoppingLists));
    })
    .catch(error => {
      dispatch(apiCallError(error))
      throw error
    })
  }
}

export const modifyNameEventHandler = (listId, extras, shoppingLists) => {
  return dispatch => {
    //api call
    shoppingList.modifyShoppingList(listId, extras).then(({ json: modifiedShoppingList }) => {
      shoppingLists = shoppingLists.map(sl => (sl.id === modifiedShoppingList.id) ? modifiedShoppingList : sl)
      //update store state
      dispatch(updateShoppingListsSuccess(shoppingLists));
    })
    .catch(error => {
      dispatch(apiCallError(error))
      throw error
    })
  }
}

export const deleteShoppingListEventHandler = (event, shoppingLists) => {
  const listId = event.target.value;
  return dispatch => {
    //api call
    shoppingList.deleteShoppingList(listId).then(() => {
        //update store state
        dispatch(updateShoppingListsSuccess(shoppingLists.filter(sl => sl.id != listId)));
    })
    .catch(error => {
      dispatch(apiCallError(error))
      throw error
    })
  }
}


export const createShoppingListEventHandler = (callback, shoppingLists, listName, skus = []) => {
  return dispatch => {
    //Give a default name for the new ShoppingList
    let extras = {
        queryString:{listName, skus}
      };
    //api call
    shoppingList.createShoppingList(extras).then(({ json: newShoppingList }) => {
        //add the new shopping list and update store state
        dispatch(updateShoppingLists([...shoppingLists, newShoppingList]));
        callback();
    })
    .catch(error => {
      dispatch(apiCallError(error))
      throw error
    })
  }
}

const downloadShoppingList = (dispatcher, shoppingListId, requestId, timeBetweenPings) => {
  return new Promise((resolve)=>{
      setTimeout(()=>{
        dispatcher((dispatch) => {
          //api call
          shoppingList.downloadShoppingList(shoppingListId, {params:{requestId}})
            .then((response)=>{
              if(!response){
                return;
              }
              if(response.status === 204){
                downloadShoppingList(dispatcher, shoppingListId, requestId, timeBetweenPings).then(resolve);
              } else {
                resolve.call(null, response);
              }
            })
            .catch(error => {
              dispatch(apiCallError(error))
              throw error
            }
          );
        });
    }, timeBetweenPings);
  });
  
}

export const downloadShoppingListEventHandler = (event) => {
  return dispatch => {
    const requestId = Math.random().toString(36).slice(2);
    //api call
    shoppingList.exportShoppingList(event.target.value, {params:{requestId}})
      .then(() => {
        return downloadShoppingList(dispatch, event.target.value, requestId, 5000);
      })
      .then((downloadExcelFile)=>{
        downloadExcelFile.blob().then((file)=>
          dispatch(downloadedShoppingListExcelFile({id: event.target.value, file })
        ));
      })
      .catch(error => {
        dispatch(apiCallError(error))
        throw error
      });
  }
}