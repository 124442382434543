import React from 'react';
import Brands from './Brands';
import Features from './Features';
import PriceRange from './PriceRange';
import AvailabilityCheckbox from './AvailabilityCheckbox';
import ManageShoppingLists from './ManageShoppingLists';
import ShoppingLists from './ShoppingLists';

const CatalogFilters = ({
    currentQuery,
    isMobile,
    history,
    brands,
    corporateGiftsFeatures,
    user,
    apiCallsInProgress,
    shoppingLists
}) => {
    return (
        <section>
            <AvailabilityCheckbox
                currentQuery={currentQuery}
                history={history}
                user={user}
                apiCallsInProgress={apiCallsInProgress}
            />
            <Brands
                currentQuery={currentQuery}
                isMobile={isMobile}
                history={history}
                brands={brands}
            />
            <PriceRange
                currentQuery={currentQuery}
                history={history}
                user={user}
                apiCallsInProgress={apiCallsInProgress}
            />
            <Features
                currentQuery={currentQuery}
                history={history}
                isMobile={isMobile}
                corporateGiftsFeatures={corporateGiftsFeatures}
                apiCallsInProgress={apiCallsInProgress}
            />
            <ManageShoppingLists
                isMobile={isMobile}
                user={user}
            />
            <ShoppingLists
                currentQuery={currentQuery}
                isMobile={isMobile}
                history={history}
                user={user}
                shoppingLists={shoppingLists}
            />
        </section>
    );
}

export default CatalogFilters;
