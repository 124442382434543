import * as types from '../actions/types'
import { brands } from './initialState'

export default function brandReducer(state = brands, action) {
  switch (action.type) {
    case types.LOAD_BRANDS_SUCCESS:
      return action.brands
    default:
      return state
  }
}
