import React from 'react';
import Nav from './Nav';
import LogoArea from './LogoArea';

const DesktopMenu = ({ productsPopupOpen, openProductsPopupClickHandler, setSelectedCategory }) => {
  return (
    <>
      <LogoArea />
      <Nav
        setSelectedCategory={setSelectedCategory}
        productsPopupOpen={productsPopupOpen}
        openProductsPopupClickHandler={openProductsPopupClickHandler}
      />
    </>
  );
};

export default DesktopMenu;
