import React from 'react';
import { lsStyled as styled } from './../../../tools/helpers/lsStyled';
import { spacing } from './../../../defaults/styles';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.isMobile ? '1fr' : 'repeat(2, 1fr)'};
  grid-gap: ${props =>
    props.isMobile ? spacing.marginMobileStandard : spacing.marginStandard};
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
  grid-auto-flow: ${props =>
    props.isMobile || props.isTablet ? 'dense' : null};
  /* adds custom styles if necessary*/
  ${props => (props.customStyle ? props.customStyle : null)}
`;

const UIGrid2cols = ({
  children,
  alignItems = 'center',
  customStyle = null,
  isMobile,
  isTablet,
  mobileOrderReverse,
}) => {
  return (
    <Wrapper
      alignItems={alignItems}
      customStyle={customStyle}
      isMobile={isMobile}
      isTablet={isTablet}
      mobileOrderReverse={mobileOrderReverse}
    >
      {children}
    </Wrapper>
  );
};

export default UIGrid2cols;
