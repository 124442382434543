import React from 'react';
import { lsStyled as styled } from '../../../tools/helpers/lsStyled';

import { colors, typography } from './../../../defaults/styles';

/* ===== When you use this component, please pass the props from the original file:

-alignment (text-align, default is left)
-color (font's color, default is black #333),
-type  (heading type, please see the following types definition, default is 1),
-subTitle (text which comes after heading, default is null ),

e.g. A tag should be 'h2' but it should be h1 styling. In the case, you can type:
<PageReinforcementStatic
  title={title}
  heading={'h2'}
  type={'1'}
/>
This will create 'h2' tag but the styling looks like h1.

type 1
  color: black,
  fontSize: '44px',
  fontWeight: 'bold',

type 2
  color: black,
  fontSize: '28px',
  fontWeight: 'bold', 


type 3 
  color: black,
  fontSize: '22px',
  fontWeight: 'bold'

If you need to customize style for heading or subHeading, please use 'customStyleHeading' or 'customStyleSubHeading' 
e.g. <PageReinforcementStatic
  title={title}
  heading={'h2'}
  type={'1'}
  customStyleHeading={{ marginBottom: 0, paddingBottom: 0}}
/>

================================================================================== */

const H1 = styled.h1`
  line-height: 1.3em;
  ${props => (props.type === '1' ? typography.h1 : typography.h2)}
  color: ${props => props.color};
  margin-bottom: 5px;
  text-align: ${props => (props.isMobile ? 'center' : props.alignment)};
  ${props => (props.customStyleHeading ? props.customStyleHeading : null)};
  /* border setup */
  ::after {
    content: '${props =>
      props.borderRequired && !props.subTitleExists ? ' ' : null}';
    ${props =>
      props.borderRequired && !props.subTitleExists
        ? {
            backgroundColor: props.borderColor,
            display: 'block',
            margin:
              props.alignment === 'center' || props.isMobile
                ? '10px auto 25px auto'
                : '5px 0 25px 0',
            height: '5px',
            width:
              props.alignment !== 'center' ||
              props.type === '3' ||
              props.borderWidth === 'short'
                ? '90px'
                : '200px',
          }
        : null}
  }
`;

const H2 = styled.h2`
  line-height: 1.3em;
  ${props => (props.type === '1' ? typography.h1 : typography.h2)}
  color: ${props => props.color};
  margin-bottom: 5px;
  text-align: ${props => (props.isMobile ? 'center' : props.alignment)};
  ${props => (props.customStyleHeading ? props.customStyleHeading : null)};
  /* border setup */
  ::after {
    content: '${props =>
      props.borderRequired && !props.subTitleExists ? ' ' : null}';
    ${props =>
      props.borderRequired && !props.subTitleExists
        ? {
            backgroundColor: props.borderColor,
            display: 'block',
            margin:
              (props.alignment === 'center' && props.type === '3') ||
              props.isMobile
                ? '10px auto 15px auto'
                : props.alignment === 'center'
                ? '10px auto 25px auto'
                : '10px 0 25px 0',
            height: '5px',
            width:
              props.alignment !== 'center' ||
              props.type === '3' ||
              props.borderWidth === 'short'
                ? '90px'
                : '200px',
          }
        : null}
  }
`;

const H3 = styled.h3`
  line-height: 1.3em;
  ${props => (props.type === '3' ? typography.h3 : typography.h2)}
  color: ${props => props.color};
  margin-bottom: 5px;
  text-align: ${props => (props.isMobile ? 'center' : props.alignment)};
  ${props => (props.customStyleHeading ? props.customStyleHeading : null)};
  /* border setup */
  ::after {
    content: '${props =>
      props.borderRequired && !props.subTitleExists ? ' ' : null}';
    ${props =>
      props.borderRequired && !props.subTitleExists
        ? {
            backgroundColor: props.borderColor,
            display: 'block',
            margin:
              (props.alignment === 'center' && props.type === '3') ||
              props.isMobile
                ? '10px auto 15px auto'
                : props.alignment === 'center'
                ? '10px auto 25px auto'
                : '10px 0 25px 0',
            height: '5px',
            width:
              props.alignment !== 'center' ||
              props.type === '3' ||
              props.borderWidth === 'short'
                ? '90px'
                : '200px',
          }
        : null}
  }
`;

// <p> is for subTitle
const P = styled.p`
  color: ${props => props.color};
  font-size: ${props => (props.type === '1' ? '28px' : '18px')};
  line-height: 1.4;
  margin-bottom: 5px;
  text-align: ${props =>
    props.isMobile || props.isTablet ? 'center' : props.alignment};
  ${props =>
    props.customStyleSubHeading ? props.customStyleSubHeading : null};
  /* border setup */
  ::after {
    content: '${props =>
      props.borderRequired && !props.subTitleExists ? ' ' : null}';
    ${props =>
      props.borderRequired
        ? {
            backgroundColor: props.borderColor,
            content: ' ',
            display: 'block',
            margin:
              props.alignment === 'center' && props.type === '3'
                ? '10px auto 15px auto'
                : props.alignment === 'center'
                ? '10px auto 25px auto'
                : '10px 0 25px 0',
            height: '5px',
            width:
              props.alignment !== 'center' ||
              props.type === '3' ||
              props.borderWidth === 'short'
                ? '90px'
                : '200px',
          }
        : null}
  }
`;
// == /Styling == //

// Component
const PageReinformcementStatic = ({
  alignment = 'left',
  borderRequired = false,
  borderColor = colors.gray.normal,
  borderWidth = null,
  color = colors.black.normal,
  children,
  heading = 'h2',
  subTitle = null,
  title,
  type = '1',
  customStyleHeading = null,
  customStyleSubHeading = null,
  isMobile,
  isTablet,
}) => {
  const subTitleExists = !subTitle ? false : true;

  return (
    <>
      {heading === 'h1' ? (
        <H1
          alignment={alignment}
          borderRequired={borderRequired}
          borderColor={borderColor}
          borderWidth={borderWidth}
          color={color}
          subTitleExists={subTitleExists}
          title={title}
          type={type}
          customStyleHeading={customStyleHeading}
          isMobile={isMobile}
          isTablet={isTablet}
        >
          {title}
          {customStyleHeading}
        </H1>
      ) : heading === 'h2' ? (
        <H2
          alignment={alignment}
          borderRequired={borderRequired}
          borderColor={borderColor}
          borderWidth={borderWidth}
          color={color}
          title={title}
          type={type}
          subTitleExists={subTitleExists}
          customStyleHeading={customStyleHeading}
          isMobile={isMobile}
          isTablet={isTablet}
        >
          {title}
        </H2>
      ) : (
        <H3
          title={title}
          type={type}
          alignment={alignment}
          color={color}
          borderRequired={borderRequired}
          borderColor={borderColor}
          borderWidth={borderWidth}
          subTitleExists={subTitleExists}
          customStyleHeading={customStyleHeading}
        >
          {title}
        </H3>
      )}
      {subTitle ? (
        <P
          alignment={alignment}
          borderRequired={borderRequired}
          borderColor={borderColor}
          borderWidth={borderWidth}
          type={type}
          customStyleSubHeading={customStyleSubHeading}
          isMobile={isMobile}
          isTablet={isTablet}
        >
          {subTitle}
        </P>
      ) : null}
      {children}
    </>
  );
};
export default PageReinformcementStatic;
