import React from 'react';
import Radium from 'radium';
import styled, { keyframes } from 'styled-components';
import Brand from './Brand';
import UIContainer from './../../interface/UIContainer';
import UISection from './../../interface/UISection';
import { colors, fonts, sizing, spacing } from '../../../../defaults/styles';
import GridLoader from 'react-spinners/GridLoader';
import { withLocalize } from 'react-localize-redux';

// Create the keyframes for SlideIn effect
const slideInAnimation = keyframes`
  0%   { opacity: 0; min-height: 100vh; }
  20%  { opacity: 1; min-height: auto; }
  100% { opacity: 1; min-height: auto;}
`;

const Divider = styled.div`
  background-color: ${colors.primary.normal};
  color: ${colors.white.normal};
  font-size: ${fonts.sizes.large}px;
  font-weight: ${fonts.weights.title};
  margin-bottom: ${spacing.large}px;
  padding: ${spacing.medium}px;
  text-align: center;
  ${'' /* margin-bottom: ${spacing.large}px; */}
  position: relative;

  /* following codes are to add extra space when jump to the anchor postion due to the fixed header. */
  border-top: ${sizing.header.top +
      sizing.header.mid +
      sizing.header.btm +
      50}px
    solid transparent;
  margin-top: -${sizing.header.top + sizing.header.mid + sizing.header.btm + 50}px;
  background-clip: padding-box;
  z-index: -1;
`;

const ListOfBrands = styled.ul`
  display: grid;
  grid-gap: ${spacing.small}px;
  grid-template-columns: ${props =>
    props.isMobile ? 'repeat(2, 1fr)' : 'repeat(auto-fill, 180px)'};
  justify-content: space-around;
  list-style: none;
  margin-bottom: 5em;

  padding-right: ${spacing.small}px;
  padding-left: ${spacing.small}px;
  width: ${props => (props.isMobile ? '100%' : 'auto')};
  ${'' /* slide in effect */}
  animation: ${slideInAnimation} 5s 1;
  animation-fill-mode: forwards;
`;

const List = ({ brands, activeLanguage, isMobile }) => {
  const lang =
    activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';

  const getUrl = brand => `/${lang}/catalog?brand=${brand.id}`;

  return brands.length ? (
    <UIContainer isMobile={isMobile}>
      {brands.map((brandsByLetter, index) => (
        <UISection noPadding key={brandsByLetter[0] + index}>
          <Divider id={brandsByLetter[0] !== '#' ? brandsByLetter[0] : 'other'}>
            {brandsByLetter[0]}
          </Divider>
          <ListOfBrands isMobile={isMobile} key={'brand-' + index}>
            {brandsByLetter[1].map((brand, index) => (
              <li key={brand.id + 'brand' + index}>
                <Brand
                  brand={brand}
                  isMobile={isMobile}
                  url={getUrl(brand)}
                />
              </li>
            ))}
          </ListOfBrands>
        </UISection>
      ))}
    </UIContainer>
  ) : (
    <UIContainer>
      <GridLoader
        css={{ margin: 'auto' }}
        size={fonts.sizes.text}
        color={colors.main.normal}
      />
    </UIContainer>
  );
};

export default withLocalize(Radium(List));
