import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { loadShoppingLists} from '../../../redux/actions/shoppingListActions';

const HomeImageListContainer = ({
    user,
    loadShoppingLists,
    children
}) => {
    useEffect(() => { loadShoppingLists(); }, []);

    // Push prop to children
    const childrenWithProps = React.Children.map(children, child =>
        React.cloneElement(child, {
            user, 
        })
    );

    // Return just presentation of children
    return <>{childrenWithProps}</>;
};

function mapStateToProps({ user, }) {
    return { user, };
}

const mapDispatchToProps = {
    loadShoppingLists,
};
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
) (HomeImageListContainer);