import * as types from './types';
import { category } from '../../tools/api';
import { beginApiCall, apiCallError } from './apiStatusActions';
import { setPreviousCategoryFilters } from './previousCategoryFiltersActions';

export const loadCategoriesSuccess = categories => {
  return { type: types.LOAD_CATEGORIES_SUCCESS, categories };
};

export const loadCategories = language => {
  return dispatch => {
    dispatch(setPreviousCategoryFilters({ language }));
    dispatch(beginApiCall());
    return category
      .getCategories(language)
      .then(({ json: categories }) => {
        dispatch(loadCategoriesSuccess(categories));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
};
