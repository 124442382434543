import * as types from '../actions/types'
import { product } from './initialState'

export default function productReducer(state = product, actions) {
  switch (actions.type) {
    case types.LOAD_PRODUCT_SUCCESS:
      return actions.product
    default:
      return state
  }
}
