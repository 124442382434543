import React from 'react';
import UIPageTopTitle from '../../interface/UIPageTopTitle';
import { withLocalize } from 'react-localize-redux';

const Intro = ({isMobile, isTablet, translate}) => {
  return (
      <UIPageTopTitle  isMobile={isMobile} isTablet={isTablet}
        title={translate('services.fulfillment.title')}
        subTitle={translate('services.fulfillment.subTitle')}
      />
  );
};

export default withLocalize(Intro);
