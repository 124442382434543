module.exports = {
  tiny: 4,
  verySmall: 8,
  small: 12,
  medium: 18,
  large: 24,
  veryLarge: 32,
  sectionSpace: 32,
  // spacingStandard: '2.5em',
  // marginStandard: '2.5em',
  // paddingStandard: '2.5em',
  spacingStandard: '3em', 
  marginStandard: '3em',
  pageLastBlockMarginBottom: 32 * 2.5,
  paddingStandard: '2em',

  paddingMobileStandard: '1.5em',
  marginMobileStandard: '1.5em',
  pageLastMobileLastBlockMargin: '3em',
  productItemWidth: 180,

  // for product list in Logged In User homepage
  containerWidth: 1024,
  productColWidth: 230,
  productGridGap: 12,
  // width 1024, img 230, gap 12, 4 items in each viewarea
  sliderArrowIconWidth: 34,
  sliderOuterWidth: 230 * 4 + 12 * 3,
  sliderImageWidth: 230,
};
