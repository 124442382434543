import React from 'react';

import PageReinforcementStatic from '../../interface/PageReinforcementStatic';
import UIContainer from '../../interface/UIContainer';
import UIGrid2cols from '../../interface/UIGrid2cols';
import UISection from '../../interface/UISection';
import UIParagraph from '../../interface/UIParagraph';
import UIListItemBullet from './../../interface/UIListItemBullet';
import { colors } from '../../../../defaults/styles';
import { Translate, withLocalize } from 'react-localize-redux';
import FlippingImage from '../../interface/FlippingImage';
import { lsStyled } from '../../../../tools/helpers/lsStyled';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const RewardsCatalogue = ({ isMobile, isTablet, translate, activeLanguage }) => {
  const t = (tag) => `home.rewardsCatalogue.${tag}`;

  const lang =
  activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';

  const imgList = [
    // the following each icon is a component from fontawesome
    {
      href: 'images/pages/home/products/Alienware.png',
      alt: 'Product image 1',
    },
    {
      href: 'images/pages/home/products/Ascaso.png',
      alt: 'Product image 2',
    },
    {
      href: 'images/pages/home/products/Bose.png',
      alt: 'Product image 3',
    },
    {
      href: 'images/pages/home/products/Fossil.png',
      alt: 'Product image 4',
    },
    {
      href: 'images/pages/home/products/Makita.png',
      alt: 'Product image 5',
    },
    {
      href: 'images/pages/home/products/MichaelKors.png',
      alt: 'Product image 6',
    },
  ];

  const imgList2 = [
    // the following each icon is a component from fontawesome
    {
      href: 'images/pages/home/products/Rayban.png',
      alt: 'Product image 7',
    },
    {
      href: 'images/pages/home/products/SamsungTV.png',
      alt: 'Product image 8',
    },
    {
      href: 'images/pages/home/products/SharkNinja.png',
      alt: 'Product image 9',
    },
    {
      href: 'images/pages/home/products/Staub.png',
      alt: 'Product image 10',
    },
    {
      href: 'images/pages/home/products/Weber.png',
      alt: 'Product image 11',
    },
    {
      href:
        'images/pages/home/products/Zwilling.png',
      alt: 'Product image 12',
    },
  ];

  return (
    <UIContainer isMobile={isMobile} isTablet={isTablet}>
      <UIGrid2cols
        alignItems="start"
        isMobile={isMobile}
        isTablet={isTablet}
        customStyle={!isMobile ? { gridTemplateColumns: 'auto 1fr' } : null}
      >
        <ImageList isMobile={isMobile} isTablet={isTablet}>
          {imgList.map((item, index) => (
            <ImageBox
              key={'product-image-' + index}
              isMobile={isMobile}
              isTablet={isTablet}
            >
              <FlippingImage
                frontImageUrl={item.href}
                backImageUrl={imgList2[index].href}
                ImgComponent={Img}
                automaticIntervalMs={5000}
                initialDelay={500 * (index + 1)}
              />
            </ImageBox>
          ))}
        </ImageList>
        <UISection noPadding>
          <PageReinforcementStatic
            title={translate(t('title'))}
            alignment={'left'}
            borderRequired={true}
            heading={'h2'}
            type={'2'}
            customStyleHeading={{ marginTop: 0 }}
          />

          <UIParagraph>
            <Translate id={t('mainParagraph')} />
          </UIParagraph>

          <UIListItemBullet
            text={[
              { text: translate(t('listItems.categories')) },
              { text: translate(t('listItems.pricePoints')) },
              { text: translate(t('listItems.competitivePricing')) },
              { text: translate(t('listItems.forEveryone')) },
            ]}
          />

          <ButtonGrid>
          <Button
              to={"/" + lang + "/brands"}
              size={18}
            >
              <Translate id={t('btnViewBrands')} />
            </Button>
            <Button
              to={"/" + lang + "/catalog"}
              size={18}
            >
              <Translate id={t('btnViewProducts')} />
            </Button>
          </ButtonGrid>
        </UISection>
      </UIGrid2cols>
    </UIContainer>
  );
};

export default withLocalize(RewardsCatalogue);

// == Styling == //
const ImageList = lsStyled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1em;
  margin: ${props => (props.isMobile ? '0 auto' : null)};
`;

const ImageBox = lsStyled.div`
  border-radius: 5px;
  height: ${props => (props.isMobile || props.isTablet ? '100%' : '11em')};
  width: ${props => (props.isMobile || props.isTablet ? '100%' : '11em')};
`;

const Img = lsStyled.img`
  border-radius: 5px;
  height: auto;
  width: 100%;
`;

const ButtonGrid = lsStyled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 1em;
  grid-auto-rows: 1fr;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    grid-template-columns: auto;
  }
`;

const Button = styled(Link)`
  text-decoration: none;
  border-style: solid;
  color: ${colors.white.normal};
  cursor: pointer;
  background-color: ${colors.primary.normal};
  border-radius: 2px;
  display: inline-block;
  line-height: 1;
  transition: all 200ms ease-in-out 0s;
  border-width: 1.33333px;
  border-color: ${colors.primary.normal};
  font-size: 16px;
  padding: 10.4px 20px;
  text-align: center;

  &:hover {
    background-color: ${colors.primary.hover};
    color: ${colors.white.hover};
  }

  @media (max-width: 600px) {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
`;
