import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { loadCart, updateCartItem, addCartItem, removeFromCart, updateCartInfo, updateCartAddress, submitCart, sendConfirmation } from '../../../redux/actions/cartAction';
import { withLocalize } from 'react-localize-redux';

const CartContainer = ({
    user,
    activeLanguage,
    history,
    cart,
    loadCart,
    addCartItem,
    updateCartItem,
    updateCartInfo,
    removeFromCart,
    updateCartAddress,
    submitCart,
    sendConfirmation,
    ...props
}) => {
    useEffect(() => { 
        if (activeLanguage && user && user.loggedIn ) {
            loadCart(activeLanguage.code, cart=>({...cart, 
                    items: cart.items
                                    .filter(({statusId})=> statusId !== 4), 
                }));
        }
    }, [ props.cart, activeLanguage, user, ]);

    const childrenWithProps = React.Children.map(props.children, child =>
        React.cloneElement(child, { user, activeLanguage, history, cart, addCartItem, updateCartItem, removeFromCart, updateCartInfo, updateCartAddress, submitCart, sendConfirmation })
    );

    return <>{childrenWithProps}</>;
};

CartContainer.propTypes = {
    user: propTypes.object.isRequired,
    cart: propTypes.object.isRequired,
};

function mapStateToProps({ user, cart }) { return { user, cart }; }
const mapDispatchToProps = { loadCart, updateCartItem, addCartItem, removeFromCart, updateCartInfo, updateCartAddress, submitCart, sendConfirmation };

export default withLocalize(connect(
    mapStateToProps,
    mapDispatchToProps,
) (CartContainer));
