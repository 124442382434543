import React, { useRef, useState } from 'react';

import {
  faWarehouse,
  faBoxUsd,
  faBoxes,
  faShippingFast,
} from '@fortawesome/pro-light-svg-icons';

import UIContainer from '../../interface/UIContainer';
import UIGrid4cols from '../../interface/UIGrid4cols';
import UISection from '../../interface/UISection';
import { withLocalize } from 'react-localize-redux';
import StatCountUp from '../../interface/StatCountUp';

const Stats = ({isMobile, isTablet, activeLanguage, translate}) => {
  const t = (tag) => `home.stats.${tag}`;

  const lexiconListItems = ['facilities', 'perfectShipping', 'inventory', 'threeDayShipping'];

  const [ startCounting, setStartCounting ] = useState(false);

  // Handle scrolling for stat count
  const ref = useRef(null);
  const handleScroll = () => {
    if (ref && ref.current) {
      const top = ref.current.getBoundingClientRect().top;
      if (top < window.innerHeight) {
        setStartCounting(true);
      }
    }
  };

  if (!startCounting) {
    window.removeEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll);
  }

  // Icon array for stat
  const faIcons = [ faWarehouse, faBoxUsd, faBoxes, faShippingFast ];

  const lang = activeLanguage && activeLanguage.code === 'fr' ? 'fr' : 'en';

  return (
    <div ref={ref}>
      <UIContainer isMobile={isMobile} isTablet={isTablet}>
        <UISection>
          <UIGrid4cols
            isMobile={isMobile}
            isTablet={isTablet}
            customStyle={
              !isMobile ? {
              gridTemplateColumns: '35% 1fr 1fr 1fr',
              alignItems: 'start',
            } : null
          }
          >
            {lexiconListItems.map((lexiconItemKey, i) => (
              <StatCountUp
                key={'stats' + i}
                withBorder={i === 0}
                icon={faIcons[i]}
                startNumber={0}
                desiredNumber={Number(translate(t(`listItems.${lexiconItemKey}.num`)))}
                label={translate(t(`listItems.${lexiconItemKey}.label`), null, { renderInnerHtml: true })}
                startCounting={startCounting}
                animationTime={1000}
                suffix={i === 0 
                  ? lang === 'en' ? ' SF' : ' PC' 
                  : '%'
                }
              />
            ))}
          </UIGrid4cols>
        </UISection>
      </UIContainer>
    </div>
  );
};

export default withLocalize(Stats);
