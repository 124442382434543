import React from 'react';
import styled from 'styled-components';
import Switcher from './Switcher';
import PageReinforcementStatic from './../../interface/PageReinforcementStatic';
import UIContainer from './../../interface/UIContainer';
import UISection from './../../interface/UISection';
import UIGrid2cols from './../../interface/UIGrid2cols';
import UIListItemBullet from './../../interface/UIListItemBullet';
import { withLocalize } from 'react-localize-redux';

// == Styling == //
const ImageBox = styled.div`
  width: 100%;
`;
// == /Styling == //

const ModernMarketplace = ({ isMobile, isTablet, translate }) => {
  const t = (tag) => `services.rewards.modernMarketplace.${tag}`;

  return (
    <UIContainer isMobile={isMobile} isTablet={isTablet}>
      <UIGrid2cols isMobile={isMobile} isTablet={isTablet}>
        <UISection>
          <PageReinforcementStatic
            title={translate(t('title'))}
            subTitle={translate(t('subTitle'))}
            borderRequired={true}
            heading={'h2'}
            type={'2'}
          />
          <UIListItemBullet
            text={[
              { text: translate(t('listItems.brands')) },
              { text: translate(t('listItems.productImages')) },
              { text: translate(t('listItems.productDescriptions')) },
              { text: translate(t('listItems.catalogueUpdates')) },
              { text: translate(t('listItems.bundling')) },
              { text: translate(t('listItems.easeToUse')) },
            ]}
          />
        </UISection>

        <ImageBox>
          <img
            src="./../images/pages/services-rewards/Marketplace-Screen.png"
            alt="Marketplace-Screenshot"
            style={{ maxWidth: isMobile || isTablet ? '100%' : 500 }}
          />
        </ImageBox>
      </UIGrid2cols>
      <Switcher isMobile={isMobile} isTablet={isTablet} />
    </UIContainer>
  );
};

export default withLocalize(ModernMarketplace);
