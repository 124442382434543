import * as types from './types';
import { product } from '../../tools/api';
import { beginApiCall, apiCallError } from './apiStatusActions';
import { setCatalogRequestTime } from '../actions/catalogRequestTimeActions';
import { setPreviousFilters } from '../actions/previousFiltersActions';

export const loadProductsSuccess = products => {
  return { type: types.LOAD_PRODUCTS_SUCCESS, products };
};

export const loadProducts = params => {
  return (dispatch, getState) => {
    dispatch(setPreviousFilters(params));
    const time = new Date();
    dispatch(setCatalogRequestTime(time));
    dispatch(beginApiCall());
    return product
      .getProducts(params)
      .then(({ json: products }) => {
        if (getState().catalogRequestTime == time)
          dispatch(loadProductsSuccess(products));
        else dispatch(loadProductsSuccess(getState().products));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
};

export const loadCatalogSuccess = result => { return { type: types.LOAD_CATALOG_SUCCESS, result }; };
export const loadCatalogForCorporateGiftsSuccess = result => { return { type: types.LOAD_CATALOG_FOR_CORPORATE_GIFTS_SUCCESS, result }; };

export const resetCatalog = () => { return { type: types.RESET_CATALOG, result: [] }; };
export const resetCatalogForCorporateGifts = () => { return { type: types.RESET_CATALOG_FOR_CORPORATE_GIFTS, result: [] }; };

export const loadCatalog = (search, page) => {
  return (dispatch) => {
    if (page === 0) {
      dispatch(resetCatalog());
    }
    dispatch(beginApiCall());
    return product
      .getCatalog(search, page)
      .then(({ json: products }) => {
        dispatch(loadCatalogSuccess({ page, products }));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      })
  }
}

export const loadCatalogForCorporateGifts = (search, page) => {
  return (dispatch) => {
    if (page === 0) {
      dispatch(resetCatalogForCorporateGifts());
    }
    dispatch(beginApiCall());
    return product
      .getCatalogForCorporateGifts(search, page)
      .then(({ json: products }) => {
        dispatch(loadCatalogForCorporateGiftsSuccess({ page, products }));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      })
  }
}