import React from 'react';
import Links from './Links';
import User from './User';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

import { colors, sizing, spacing } from '../../../../../../../defaults/styles';
import { UserContainer } from '../../../../../../smart';
import { lsStyled as styled } from '../../../../../../../tools/helpers/lsStyled';

const Menu = ({
  openProductsPopupClickHandler,
  open = false,
  setMobileMenuOpen,
  setSelectedCategory,
  modifyFilters,
}) => (
  <NavMenu open={open}>
    <CloseButtonItem onClick={() => setMobileMenuOpen(false)}>
      <FontAwesomeIcon icon={faTimes} />
    </CloseButtonItem>
    <Links
      setSelectedCategory={setSelectedCategory}
      modifyFilters={modifyFilters}
      setMobileMenuOpen={setMobileMenuOpen}
      openProductsPopupClickHandler={openProductsPopupClickHandler}
    />
    <UserContainer>
      <User setMobileMenuOpen={setMobileMenuOpen} />
    </UserContainer>
  </NavMenu>
);

export default Menu;

const NavMenu = styled.nav`
    position: fixed;
    left: ${props => (props.open ? 0 : '-100%')};
    transition: left 200ms;
    /* top: ${sizing.header.top + sizing.header.container}px; */
    top: 0;
    z-index: 999;
    
    background-color: ${colors.white.normal};
    border-top: 1px solid ${colors.gray.light};
    border-right: 1px solid ${colors.gray.light};
    box-shadow: 1px 1px 5px -3px rgba(51,51,51,0.5);
    overflow-y: auto;
    /* overflow-scrolling: touch; */
    -webkit-overflow-scrolling: touch;
    height: 100vh;
    width: 100vw;
  `;

const CloseButtonItem = styled.div`
  display: block;
  color: ${colors.black.normal};
  font-size: 1.4em;
  text-decoration: none;
  padding: ${spacing.medium}px 35px;
  border-bottom: 1px solid ${colors.gray.light};
  text-align: right;
`;
