import React, { useEffect } from 'react';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { colors } from '../../ui/common';
import CartSectionAddress from './sections/CartSectionAddress';
import CartSectionInfo from './sections/CartSectionInfo';
import CartSectionBillingAddress from './sections/CartSectionBillingAddress';

const CartConfirmWrapper = styled.div`
    padding-right: ${props => !props.isMobile ? '1rem' : ''};
`;

const CartConfirmSection = styled.div`
    border: 1px solid ${colors.gray.light};
    padding: 1.5rem;
    margin-bottom: 1rem;
`;

const CartConfirm = ({ cart, updateCartInfo, updateCartAddress, isMobile }) => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        })
    }, []);
    return (
        <CartConfirmWrapper isMobile={isMobile}>
            <CartConfirmSection>
                <CartSectionBillingAddress cart={cart} updateCartInfo={updateCartInfo} isMobile={isMobile} />
            </CartConfirmSection>
            <CartConfirmSection>
                <CartSectionInfo cart={cart} updateCartInfo={updateCartInfo} isMobile={isMobile} />
            </CartConfirmSection>
            <CartConfirmSection>
                <CartSectionAddress cart={cart} updateCartAddress={updateCartAddress} isMobile={isMobile} />
            </CartConfirmSection>
        </CartConfirmWrapper>
    );
}

export default CartConfirm;