import React from 'react';
import { Link } from 'react-router-dom';
import TopLevelCategory from './TopLevelCategory';
import SecondLevelCategory from './SecondLevelCategory';
import ThirdLevelCategory from './ThirdLevelCategory';
import { Translate, withLocalize } from 'react-localize-redux';
import { colors, fonts, spacing } from '../../../../../defaults/styles';
import PulseLoader from 'react-spinners/PulseLoader';
import qs from 'querystring';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';

const ViewAllDiv = styled.div`
    font-weight: ${fonts.weights.heading};
    padding: ${spacing.medium / 2}px ${spacing.medium}px;
    cursor: pointer;
    margin-top: ${spacing.small}px;
    user-select: none;
`;

const LinkStyle = {
    color: 'inherit',
    textDecoration: 'none'
};

const Categories = ({
    categories,
    history,
    activeLanguage,
    currentQuery,
    product,
}) => {
    const t = (tag) => `catalog.${tag}`;

    let topId = currentQuery && currentQuery.group ? Number(currentQuery.group) : 0;
    let catId = currentQuery && currentQuery.category ? Number(currentQuery.category) : 0;
    let typeId = currentQuery && currentQuery.type ? Number(currentQuery.type) : 0;

    if (product && product.categoryGroupId) { topId = product.categoryGroupId; }
    if (product && product.categoryId) { catId = product.categoryId; }
    if (product && product.categorySpecificationId) { typeId = product.categorySpecificationId; }

    const separatedQuery = currentQuery ? JSON.parse(JSON.stringify(currentQuery)) : {};

    delete separatedQuery.group;
    delete separatedQuery.category;
    delete separatedQuery.type;

    const lang = activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';
    const pathname = `/${lang}/catalog`;

    const pushHistory = (param = null) => {
        if (separatedQuery) {
            delete separatedQuery.productFeatureValueId;
            history.push({
                pathname: pathname,
                search: '?' + qs.stringify({ ...separatedQuery, ...param })
            });
        } else {
            history.push({
                pathname: pathname,
                search: '?' + qs.stringify(param)
            });
        }
    }

    const getUrl = (param = {}) => {
        if (separatedQuery) {
            delete separatedQuery.productFeatureValueId;
            return pathname + '?' + qs.stringify({ ...separatedQuery, ...param });
        } else {
            return pathname + '?' + qs.stringify(param);
        }
    }

    const createTopLevelCategory = (id, name) => {
        const forwardFilter = { group: id };
        const filter = topId !== id ? forwardFilter : null;
        return (
            <TopLevelCategory
                text={name}
                selected={topId === id}
                url={getUrl(forwardFilter)}
                onClick={() => pushHistory(filter)}
            />
        );
    }

    const createSecondLevelCategory = (id, name, typeList) => {
        const backwardFilter = { group: topId };
        const forwardFilter = { ...backwardFilter, category: id };

        const filter = (catId !== id ? forwardFilter : backwardFilter);
        return (
            <SecondLevelCategory
                text={name}
                hasSubCategories={typeList.length > 0}
                selected={catId === id}
                url={getUrl(forwardFilter)}
                onClick={() => pushHistory(filter)}
            />
        );
    }

    const createThirdLevelCategory = (id, name) => {
        const filter = { group: topId, category: catId, type: id };
        return (
            <ThirdLevelCategory
                key={'type' + id}
                text={name}
                selected={typeId === id}
                url={getUrl(filter)}
                onClick={() => {
                    if (typeId !== id) {
                        pushHistory(filter);
                    }
                }}
            />
        );
    }

    return categories.length ? (
        <section>
            {/* First later */}
            {categories.map(({ name: topName, categoryList, id }) => (
                <div key={'category-' + id}>
                    {[id, 0].includes(topId) && (<>
                        {createTopLevelCategory(id, topName)}

                        {/* Second layer */}
                        {topId === id && categoryList.map(({ name: catName, typeList, id }) => (
                            <React.Fragment key={'category-list-' + id}>
                                {createSecondLevelCategory(id, catName, typeList)}

                                {/* Third layer */}
                                {catId === id && typeList.map(({ name: typeName, id }) => (
                                    createThirdLevelCategory(id, typeName)
                                ))}
                            </React.Fragment>
                        ))}
                    </>)}
                </div>
            ))}

            <ViewAllDiv onClick={() => pushHistory()}>
                <Link
                    to={getUrl()}
                    style={LinkStyle}
                    onClick={(e) => e.preventDefault()}
                >
                    <Translate id={t('viewAll')} />
                </Link>
            </ViewAllDiv>
        </section>
    ) : (
        <div style={{ textAlign: 'center' }}>
            <PulseLoader
                css={{ margin: 'auto' }}
                color={colors.gray.normal}
                size={12}
            />
        </div>
    );
};

export default withLocalize(Categories);