import * as types from '../actions/types';
import { filters } from './initialState';
import { objectEquivalent } from '../../tools/helpers';

function removeQuery(state, filters) {
  const filterToCompare = { ...filters };
  const oldStateToCompare = { ...state };
  ['page', 'size', 'sort', 'language'].forEach(key => {
    delete filterToCompare[key];
    delete oldStateToCompare[key];
  });
  
  const oldState = { ...state };

  if (!objectEquivalent(filterToCompare, oldStateToCompare) && Object.entries(filterToCompare).length > 0) {
    oldState['page'] = 0;
    delete oldState['q'];
  }
  return oldState;
}

export default function categoryReducer(state = filters, actions) {
  let oldState = null;
  switch (actions.type) {
    case types.MODIFY_FILTERS:
      oldState = removeQuery(state, actions.filters);
      return { ...oldState, ...actions.filters };
    case types.SET_FILTERS_SELECTED_CATEGORY:
      oldState = removeQuery(state, actions.filters);
      ['group', 'category', 'type', 'page'].forEach(key => {
        delete oldState[key];
      });
      return {
        ...oldState,
        ...actions.filters,
        sort: filters.sort,
        size: filters.size,
      };
    case types.RESET_FILTERS:
      return filters;
    case types.RESET_PAGE_FILTERS:
      oldState = { ...state };
      ['priceType', 'priceStart', 'priceEnd', 'display', 'displayId', 'q'].forEach(key => {
        delete oldState[key];
      });
      const { page, size, sort } = filters;
      return { ...oldState, page, size, sort };
    case types.REMOVE_FILTER:
      const newState = { ...state };
      delete newState[actions.filter];
      return newState;
    default:
      return state;
  }
}
