import * as types from './types';
import { feature } from '../../tools/api';
import { beginApiCall, apiCallError } from './apiStatusActions';

export const loadFeaturesSuccess = features => {
  return { type: types.LOAD_PRODUCT_FEATURES_SUCCESS, features };
};

export const loadCorporateGiftsFeaturesSuccess = corporateGiftsFeatures => {
  return { type: types.LOAD_CORPORATE_GIFTS_FEATURES_SUCCESS, corporateGiftsFeatures: corporateGiftsFeatures };
};

export const loadProductFeatures = language => {
  return dispatch => {
    dispatch(beginApiCall());
    return feature
      .getAllFeatures(language)
      .then(({ json: response }) => {
        dispatch(loadFeaturesSuccess(response));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
};

export const loadProductFeaturesForCorporateGifts = language => {
  return dispatch => {
    dispatch(beginApiCall());
    return feature
      .getFeaturesForCorporateGifts(language)
      .then(({ json: response }) => {
        dispatch(loadCorporateGiftsFeaturesSuccess(response));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
};

export const loadProductFeaturesByCategorical = (dto) => {
  return dispatch => {
    dispatch(beginApiCall());
    return feature
      .getAllFeaturesByCategorical(dto)
      .then(({ json: features }) => {
        features && 
        dispatch(loadFeaturesSuccess(features));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
};
