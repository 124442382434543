import React, { useContext } from 'react';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { withLocalize } from 'react-localize-redux';
import { NewsContainer } from '../../../smart/News';
import UIContainer from '../../interface/UIContainer';
import { ResizeAwareContext } from '../../../smart/ResizeContext';

/**
 * News List page, where users are able to see all News posted
 */

const headerStyle = {
    margin: "12px 0px",
    color: "white",
    "textAlign": "center"
}

const NewsEntriesPage = withLocalize(({ translate }) => {
    const { isMobile, isTablet } = useContext(ResizeAwareContext);
    return (
        <>
            <Header isMobile={isMobile}>
                <h1 style={headerStyle}>{translate("newsContainer.slogan")}</h1>
            </Header>
            <UIContainer isMobile={isMobile} isTablet={isTablet}>
                <NewsContainer />
            </UIContainer>
        </>
    )
});

export default (NewsEntriesPage);

const Header = styled.div`
    margin: 0 auto;
    width: 100%
    background-color: rgb(195,8,16);
    padding-top: 220px;
    padding-bottom: 24px;

    @media(max-width: 600px) {
        margin: 0 auto;
        width: 100%
        background-color: rgb(195,8,16);
        padding-top: 140px;
        padding-bottom: 24px;
    }
`