import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { fonts, colors, spacing } from './../../../../../../../defaults/styles';
import { withLocalize } from 'react-localize-redux';

const LinkStyles = {
  display: 'block',
  fontSize: fonts.sizes.large,
  fontWeight: 300,
  padding: `${spacing.small}px`,
  textDecoration: 'none',
  transition: 'color ease-in-out 0.1s',
};

const NavLink = ({ url, text, activeLanguage, onClick = () => {} }) => {
  const [hovering, setHovering] = useState(false);
  const lang =
    activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';

  return (
    <Link
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      to={'/' + lang + url}
      style={{
        ...LinkStyles,
        color: hovering ? colors.primary.normal : colors.black.normal,
      }}
      onClick={onClick}
    >
      {text}
    </Link>
  );
};

export default withLocalize(NavLink);
