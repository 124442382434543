import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { colors, spacing, fonts } from '../../../../defaults/styles';

const styles = {
  color: colors.main.normal,
  fontSize: fonts.small,
  marginTop: spacing.small,
  width: '100%',
};

const ErrorMessage = ({ error = false, errorRequired = false }) => {
  const t = (tag) => `accountRequest.form.${tag}`;
  if (error) {
    return (
      <p style={styles}>
        <Translate id={t('errorMsgRegular')} />
      </p>
    );
  }
  if (errorRequired) {
    return (
      <p style={styles}>
        <Translate id={t('errorMsgRequired')} />
      </p>
    );
  }
};

export default withLocalize(ErrorMessage);
