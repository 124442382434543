import React from 'react';
import PageReinforcementStatic from './../../interface/PageReinforcementStatic';
import UIContainer from './../../interface/UIContainer';
import UIParagraph from './../../interface/UIParagraph';
import UIGridIcons from './../../interface/UIGridIcons';
import UISection from './../../interface/UISection';

import { Translate, withLocalize } from 'react-localize-redux';

const Experts = ({ isMobile, isTablet, translate }) => {
  const t = (tag) => `aboutUs.experts.${tag}`;

  return (
    <UIContainer noPadding isMobile={isMobile} isTablet={isTablet}>
      <UISection noPadding>
        <PageReinforcementStatic
          title={translate(t('title'))}
          alignment={'center'}
          heading={'h2'}
          type={'1'}
          borderRequired
        />

        <UIContainer isMobile={isMobile} isTablet={isTablet} noPadding>
          <UIParagraph textAlign="center">
            <Translate id={t('mainParagraph')} />
          </UIParagraph>
        </UIContainer>

        <UIGridIcons
          isMobile={isMobile}
          isTablet={isTablet}
          customStyle={{
            marginBottom: '4em',
            gridTemplateColumns: isMobile ? '1fr' : 'repeat(3, 1fr)',
          }}
          iconsAndText={[
            {
              src: 'about/icons/rewards.png',
              alt: 'reward icon',
              text: translate(t('iconRewards'), null, {
                renderInnerHtml: true,
              }),
            },
            {
              src: 'about/icons/bulk.png',
              alt: 'wholesale icon',
              text: translate(t('iconWhoesale'), null, {
                renderInnerHtml: true,
              }),
            },
            {
              src: 'about/icons/fulfilment.png',
              alt: 'fulfillment & distribution logistics icon',
              text: translate(t('iconFulfilment'), null, {
                renderInnerHtml: true,
              }),
            },
          ]}
        />
      </UISection>
    </UIContainer>
  );
};

export default withLocalize(Experts);
