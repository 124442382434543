import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTasks,
  faHandReceiving,
  faBoxes,
} from '@fortawesome/pro-solid-svg-icons';

import PageReinforcementStatic from './../../interface/PageReinforcementStatic';
import UIContainer from './../../interface/UIContainer';
import UISection from './../../interface/UISection';
import UIParagraph from './../../interface/UIParagraph';
import { colors, fonts, spacing } from './../../../../defaults/styles';

import { Translate, withLocalize } from 'react-localize-redux';

const OurPromise = ({isMobile, isTablet, translate}) => {
  const t = (tag) => `aboutUs.ourPromise.${tag}`;

  const itemsList = [
    {
      icon: faTasks,
      lexicon: 'iconFirstContact',
    },
    {
      icon: faHandReceiving,
      lexicon: 'iconSubsitutionOrders',
    },
    {
      icon: faBoxes,
      lexicon: 'iconCSATScores',
    },
  ];

  return (
    <UIContainer isMobile={isMobile} isTablet={isTablet} noPadding customStyle={{ marginBottom: '2em' }}>
      <UISection isMobile={isMobile} isTablet={isTablet} customStyle={{ paddingTop: 0 }}>
        <PageReinforcementStatic
          title={translate(t('title'))}
          alignment={'center'}
          borderRequired={true}
          borderWidth={'short'}
          heading={'h2'}
          type={'2'}
        />
        <UIParagraph textAlign={'center'}>
          <Translate id={t('mainParagraph')} />
        </UIParagraph>

        <UL isMobile={isMobile} isTablet={isTablet}>
          {itemsList.map((item, index) => (
            <LI key={'list-' + index} isMobile={isMobile} isTablet={isTablet}>
              <FontAwesomeIcon icon={item.icon} style={iconStyles} />
              <UIParagraph
                customStyle={{
                  fontSize: `${fonts.sizes.subHeading}px`,
                  marginBottom: 0,
                  paddingRight: `${spacing.medium}px`,
                  paddingLeft: `${spacing.medium}px`,
                }}
              >
                <Translate id={t(item.lexicon)} />
              </UIParagraph>
            </LI>
          ))}
        </UL>
      </UISection>
    </UIContainer>
  );
};

export default withLocalize(OurPromise);

// == Styling == //
const UL = styled.ul `
  display: grid;
  grid-gap: 2.5em;
  grid-template-columns: ${props => props.isMobile || props.isTablet ? '1fr' : 'repeat(3, 1fr)'};
  grid-auto-rows: 1fr;
  align-items: center;
  justify-content: center;
  list-style: none;
  width: 100%;
`;

const LI = styled.li `
  border: 2px dashed ${colors.dashedBoarder.normal};
  border-radius: 5px;
  display: grid;
  grid-template-rows: auto 1fr;
  align-items: start;
  justify-content: center;

  font-size: 10px;
  margin: auto;
  padding: 2em 1em;
  text-align: center;
  height: 100%;
  width: 100%;
`;

// this is just styles for FontAwesome compoenent, not using as component
const iconStyles = {
  color: colors.primary.normal,
  fontSize: fonts.sizes.heading * 2,
  margin: 'auto',
  marginBottom: spacing.medium,
};
// == /Styling == //