import React, { useState, useRef, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import PulseLoader from 'react-spinners/PulseLoader';

import UIContainer from '../../interface/UIContainer';
import { colors, fonts } from '../../../../defaults/styles';
import './carousel.min.css';
import { withLocalize } from 'react-localize-redux';

const Intro = withLocalize(
  ({ isMobile, isTablet, landingPageBanners, activeLanguage }) => {
    const lang = activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';

    const t = (tag) => `home.intro.${tag}`;
    const [imageHeight, setImageHeight] = useState(0);
    const bgImgList = landingPageBanners && landingPageBanners.length > 0 ? landingPageBanners : null;

    const imageRefs = useRef([]);

    const updateImageHeight = () => {
      const heights = imageRefs.current.map((img) => img.offsetHeight);
      const height = Math.min(...heights);
      setImageHeight(height);
    };

    const handleImageLoad = () => {
      updateImageHeight();
    };

    useEffect(() => {
      const updateImage = () => {
        const heights = imageRefs.current.map((img) => img.offsetHeight);
        const height = Math.min(...heights);
        setImageHeight(height);
      };
      window.addEventListener('resize', updateImage);
      return function cleanup() {
        window.removeEventListener('resize', updateImage);
      };
    }, [imageHeight]);

    const goToListing = (e) => {
      if (e.linkType === 'brand') {
        window.location.href = lang + `/catalog?brand=` + e.linkId
      }
      else if (e.linkType === 'product') {
        window.location.href = lang + `/product/` + e.linkId
      }
      else if (e.linkType === 'outside') {
        if (lang === 'fr') {
          window.open(e.linkId2, '_blank');
        } else {
          window.open(e.linkId, '_blank');
        }
      }
    }

    const [isMouseOverImage, setIsMouseOverImage] = useState(false);

    const handleMouseEnter = () => {
      setIsMouseOverImage(true);
    };
  
    const handleMouseLeave = () => {
      setIsMouseOverImage(false);
    };

    return (
      <Outer imageHeight={imageHeight} isMobile={isMobile} isTablet={isTablet}>
        <BannerBox imageHeight={imageHeight}>
          {bgImgList && bgImgList.length > 0 ? (
            <Carousel
              infiniteLoop
              autoPlay
              showArrows={true}
              showStatus={true}
              showIndicators={true}
              showThumbs={false}
              stopOnHover={false}
              transitionTime={1500}
              interval={6000}
              onClickItem={event => goToListing(bgImgList[event])}
            >
              {bgImgList.map((item, i) => (
                <div key={"a" + i}>
                  <UIContainer
                    noPadding
                    isMobile={isMobile}
                    isTablet={isTablet}
                    customStyle={{ backgroundColor: 'transparent' }}
                  >
                    <IntroLabelBox imageHeight={imageHeight}>
                      <IntroLabel isMobile={isMobile} isTablet={isTablet}>
                        <>
                          <IntroLabelBold isMobile={isMobile} isTablet={isTablet}>
                            {item.name}
                          </IntroLabelBold><br />
                          {item.description}
                        </>
                      </IntroLabel>
                    </IntroLabelBox>
                  </UIContainer>
                  <MaskDiv
                    primary
                    nthBlock={i + 1}
                    imageHeight={imageHeight}
                    isMobile={isMobile}
                    isTablet={isTablet}
                  />
                  <MaskDiv
                    nthBlock={i + 1}
                    isMobile={isMobile}
                    isTablet={isTablet}
                  />
                    <BannerBoxInner
                      imageHeight={imageHeight}
                      isMobile={isMobile}
                      isTablet={isTablet}
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                      style={{ cursor: isMouseOverImage ? 'pointer' : 'default' }}
                    >
                      {/* Banner image */}
                      <BannerImage
                        ref={(el) => (imageRefs.current[i] = el)}
                        src={item.url}
                        alt={'image '+ i}
                        onLoad={handleImageLoad}
                      />
                    </BannerBoxInner>
                </div>
              ))
              }
            </Carousel>
          ) : (
            <PulseLoader size={5} color={colors.gray.light} />
          )}

        </BannerBox>
      </Outer>
    );
  });

export default withLocalize(Intro);

// == Styling == //
const Outer = styled.div`
  height: ${props => (props.imageHeight ? props.imageHeight : 0)}px;
  overflow: hidden;
  position: relative;
`;

const MaskDiv = styled.div`
  background-color: ${props =>
    props.nthBlock % 2 === 0 ? colors.black.normal : colors.primary.normal};
  height: 1200px;
  width: 100%;
  opacity: ${props =>
    props.primary && props.nthBlock === 2 ? 0.7 : props.primary ? 0.4 : 0.9};
  transform: rotate(-10deg);

  position: absolute;
  top: ${props =>
    props.isMobile ? '-100px' : 0};
  left: ${props =>
    props.primary && props.isMobile
      ? '-14%'
      : props.isMobile
        ? '-16%'
        : props.primary
          ? '-47%'
          : '-48%'};
  z-index: 1;
`;

// const BoxHeight = (spacing.containerWidth * 300) / 1100;
const BannerBox = styled.div`
  height: ${props => (props.imageHeight ? props.imageHeight : 0)}px;
  width: 100%;
  position: relative;
`;
const BannerBoxInner = styled.div`
  background-color: ${colors.white.normal};
  height: ${props => (props.imageHeight ? props.imageHeight : 0)}px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

const BannerImage = styled.img`
  display: block;
  height: auto;
  width: 100%;
  /* max-height: 600px; */
  position: absolute;
  top: 0;
  left: 0;
`;

const IntroLabelBox = styled.div`
  display: flex;
  align-items: center;
  height: ${props => (props.imageHeight ? props.imageHeight : 0)}px;
`;
const IntroLabel = styled.p`
  color: ${colors.white.normal};
  font-size: ${props =>
    props.isMobile
      ? '13px'
      : props.isTablet
        ? '15px'
        : '34px'};
  line-height: 1.3;
  text-align: left;
  width: ${props => props.isMobile ? '55%' : '40%'};
  position: relative;
  z-index: 2;
`;

const IntroLabelBold = styled.strong`
  font-size: ${props =>
    props.isMobile
      ? '14px'
      : props.isTablet
        ? fonts.sizes.medium + 'px'
        : fonts.sizes.title + 'px'};
    
  font-weight: 700;
`;
// == .Styling == //
