import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { colors, fonts, spacing } from '../../../defaults/styles';
import { withLocalize } from 'react-localize-redux';
import { lsStyled as styled } from '../../../tools/helpers/lsStyled';

// == Styling == //
const ContainerBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: ${spacing.large}px ${spacing.large}px;

    /* div with border or not */
    border: ${props =>
        props.withBorder ? `2px dashed ${colors.dashedBoarder.normal}` : null};
    border-radius: ${props => (props.withBorder ? '5px' : null)};
`;

const FaIconWrapper = styled.div`
    color: ${colors.gray.normal};
    font-size: ${fonts.sizes.large * 2}px;
    opacity: 0.2;
    position: relative;
    bottom: -13px;
    z-index: -5;
`;

const RateNum = styled.div`
    font-weight: 600;
    font-size: ${fonts.sizes.title * 1.2}px;
    margin-bottom: ${spacing.small}px;
    text-align: center;
`;

const LabelText = styled.div`
    color: ${colors.gray.normal};
    font-size: 14px;
    font-weight: 200;
    line-height: 1.2;
    text-align: center;
`;

const animationRate = 10;

const StatCountUp = ({ 
    activeLanguage,
    withBorder,
    icon,
    startNumber = 0,
    desiredNumber,
    label,
    startCounting,
    animationTime,
    suffix = '',
}) => {
    const [ currentNumber, setCurrentNumber ] = useState(null);
    const [ rate, setRate ] = useState(null);

    if (currentNumber === null && (startNumber || startNumber === 0)) {
        setCurrentNumber(startNumber);
    }

    if (!rate && desiredNumber) {
        setRate(Math.round((desiredNumber - startNumber) / (animationTime / animationRate)));
    }
    
    if (startCounting && (startNumber || startNumber === 0) && desiredNumber && currentNumber < desiredNumber) {
        setTimeout(() => {
            setCurrentNumber(currentNumber + rate);
        }, animationRate);
    }

    const lang = activeLanguage && activeLanguage.code === 'fr' ? 'fr-CA' : 'en-CA';

    return (
        <ContainerBox withBorder={withBorder}>
            {icon &&
                <FaIconWrapper>
                    <FontAwesomeIcon icon={icon} />
                </FaIconWrapper>
            }
            <RateNum>
                {Number(Math.min(currentNumber, desiredNumber)).toLocaleString(lang)}{suffix}
            </RateNum>
            <LabelText>
                {label}
            </LabelText>
        </ContainerBox>
    );
}

export default withLocalize(StatCountUp);