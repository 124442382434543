// import produce from 'immer'
import * as types from '../actions/types'
import { reportList } from './initialState'

export default function reportListReducer(state = reportList, actions) {
  switch (actions.type) {
    case types.LOAD_REPORT_LIST_SUCCESS:
      console.log({ actions })
      return { ...actions.reports }
    default:
      return state
  }
}
