import React from 'react';
import { lsStyled as styled } from './../../../tools/helpers/lsStyled';
import { spacing, typography } from './../../../defaults/styles';

const Paragraph = styled.p`
  ${typography.normal}
  margin-bottom: ${props => props.isMobile || props.isTablet ? spacing.marginMobileStandard : spacing.marginStandard};
  margin-right: ${props => props.textAlign === 'center' ? 'auto' : null};
  margin-left: ${props => props.textAlign === 'center' ? 'auto' : null};
  ${props => (props.textAlign && props.isMobile || props.textAlign && props.isTablet ? 'text-align: center' : props.textAlign ? `text-align : ${props.textAlign}` : null)};
  ${props => (props.customStyle ? props.customStyle : null)};
  ${props => (props.allowOverflow ? 'overflow-x: auto' : '')};
  max-width: ${props => (props.maxWidth ? props.maxWidth : '900px')};
`;

const UIParagraph = ({
  children,
  textAlign,
  noPadding,
  customStyle = null,
  isMobile,
  isTablet,
  overflow = false,
  maxWidth,
}) => {
  return (
    <Paragraph
      textAlign={textAlign}
      noPadding={noPadding}
      customStyle={customStyle}
      isMobile={isMobile}
      isTablet={isTablet}
      allowOverflow={overflow}
      maxWidth={maxWidth}
    >
      {children}
    </Paragraph>
  );
};

export default UIParagraph;
