import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { fonts, colors, spacing } from './../../../../../../../defaults/styles';
import { withLocalize } from 'react-localize-redux';

const LinkStyles = {
  display: colors.white.normal,
  fontSize: fonts.sizes.normal,
  fontWeight: 500,
  padding: `0 ${spacing.medium}px ${spacing.small}px ${spacing.medium}px`,
  textDecoration: 'none',
  transition: 'color ease-in-out 0.1s',
};

const NavLink = ({ url, text, activeLanguage }) => {
  const [hovering, setHovering] = useState(false);
  const lang =
    activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';

  return (
    <Link
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      to={'/' + lang + url}
      style={{
        ...LinkStyles,
        color: hovering ? colors.primary.normal : colors.white.normal,
      }}
    >
      {text}
    </Link>
  );
};

export default withLocalize(NavLink);
