import * as types from './types';
import { product } from '../../tools/api';
import { beginApiCall, apiCallError } from './apiStatusActions';
import { setCatalogRequestTime } from '../actions/catalogRequestTimeActions';

export const loadFeatureProductsSuccess = featureProducts => {
    return { type: types.LOAD_FEATURE_PRODUCTS_SUCCESS, featureProducts };
};
  
export const loadFeatureProducts = (params) => {
    return (dispatch, getState) => {
        const time = new Date();
        dispatch(setCatalogRequestTime(time));
        dispatch(beginApiCall());
        return product
            .getActiveListing(params)
            .then(({ json: featureProducts }) => {
                if (getState().catalogRequestTime == time) {
                    dispatch(loadFeatureProductsSuccess(featureProducts));
                } else {
                    dispatch(loadFeatureProductsSuccess(getState().featureProducts));
                }
            })
            .catch(error => {
                dispatch(apiCallError(error))
                throw error
            })
    }
}

export const loadListingSuccess = listing => ({ type: types.LOAD_LISTING_SUCCESS, listing });

export const loadListing = (params) => {
    return (dispatch) => {
        dispatch(beginApiCall());
        return product
            .getCurrentListingObject(params)
            .then(({ json: listing }) => {
                if (listing.length === 0) {
                    dispatch(loadListingSuccess(['N/A']));
                } else {
                    dispatch(loadListingSuccess(listing));
                }
            })
            .catch(error => {
                dispatch(apiCallError(error))
                throw error
            })
    }
}