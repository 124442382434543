
import React from 'react';
import { withLocalize } from "react-localize-redux";
import { colors } from '../ui/common';

const ItemCounter =({
        size = 1,
        count,
        marginLeft,
        activeLanguage,
        fill,
        extend = 'left',
        isDiv = true,
        style,
    }) => {
    const value = isNaN(count)?0.0:Number(count).toLocaleString(activeLanguage.code);
    const widthExpansion = (value.length*size*0.375)+0.25;
    const marginLeftReduction = extend === 'left' ? widthExpansion
                                : extend === 'right' ? 0 
                                : extend === 'center' ? widthExpansion / 2.0
                                : 0;
    const formattedStyle = {
        position: 'absolute',
        borderStyle: 'solid',
        borderWidth: 'thin',
        borderRadius: `${size*0.35+0.35}rem`,
        background: fill ? colors.primary.normal : colors.white.normal,
        borderColor: colors.primary.normal,
        color: fill ? colors.white.normal : colors.primary.normal,
        textAlign: 'center',
        fontSize: `${size*0.75}rem`,
        height: `${size*0.75+size*0.3}rem`,
        marginLeft: `${marginLeft - marginLeftReduction}rem`,
        width: `${size*0.5 + widthExpansion}rem`,
        display: 'flex',
        alignItems:'center',
        justifyContent:'center',
        //LineHeight: `${size*0.75+size*0.3}rem`,
        ...style
    };
    return isNaN(count)?<></>:
            <>{
                isDiv ?
                (<div style={formattedStyle}>
                    {value}
                </div>)
                :(<span style={formattedStyle}>
                    {value}
                </span>)}
            </>;
};

export default withLocalize(ItemCounter);