import React from 'react';
import styled from 'styled-components';

import UIContainer from './../../../../../interface/UIContainer';
import Actions from '../Actions';
import Logo from '../../Logo';
import { sizing } from './../../../../../../../defaults/styles';

import { withLocalize } from 'react-localize-redux';

//== Styles ==//
const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: 1em;
  height: ${sizing.header.mid}px;
`;

//== /Styles ==//

const LogoArea = () => {
  return (
    <UIContainer noPadding>
      <Grid>
        <Logo />
        <Actions />
      </Grid>
    </UIContainer>
  );
};

export default withLocalize(LogoArea);
