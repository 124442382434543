import React, { useContext } from 'react';
import UIMain from './../../interface/UIMain';
import Intro from './Intro';
import Experts from './Experts';
import OurValues from './OurValues';
import OurPeople from './OurPeople';
import OurPromise from './OurPromise';
import OurHistory from './OurHistory';
import { withLocalize } from 'react-localize-redux';
import { ResizeAwareContext } from '../../../smart/ResizeContext';

const AboutUsPage = ({ translate }) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);

  document.title = translate('aboutUs.page.title');

  return (
    <UIMain isMobile={isMobile} isTablet={isTablet}>
      <Intro isMobile={isMobile} isTablet={isTablet} />
      <Experts isMobile={isMobile} isTablet={isTablet} />
      <OurValues isMobile={isMobile} isTablet={isTablet} />
      <OurPeople isMobile={isMobile} isTablet={isTablet} />
      <OurPromise isMobile={isMobile} isTablet={isTablet} />
      <OurHistory isMobile={isMobile} isTablet={isTablet} />
    </UIMain>
  );
};

export default withLocalize(AboutUsPage);
