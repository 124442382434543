import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { colors, spacing } from '../../../../../../../../../defaults/styles';
import { withLocalize } from 'react-localize-redux';

const Item = ({ children, to, activeLanguage, onClick }) => {
  const [hovering, setHovering] = useState(false);
  const lang =
    activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';
  return (
    <Link
      to={'/' + lang + to}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      style={{
        color: hovering ? 'white' : colors.gray.dark,
        backgroundColor: hovering ? colors.primary.normal : 'white',
        textDecoration: 'none',
        display: 'block',
        padding: spacing.small,
        whiteSpace: 'nowrap',
      }}
      onClick={onClick}
    >
      {children}
    </Link>
  );
};

export default withLocalize(Item);
