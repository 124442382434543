import React from 'react';
import { lsStyled as styled } from './../../../tools/helpers/lsStyled';
import { spacing } from './../../../defaults/styles';

const Container = styled.div`
  margin: 0 auto;
  padding-top: ${props => (props.noPadding || props.pageTop ? null : (props.isMobile || props.isTablet) ? spacing.paddingMobileStandard : spacing.paddingStandard)};
  padding-bottom: ${props =>
     (props.noPadding ? null : (props.isMobile || props.isTablet) ? spacing.paddingMobileStandard : spacing.paddingStandard)};
  padding-right: ${props => (props.isMobile || props.isTablet) ?  spacing.paddingMobileStandard : null };
  padding-left: ${props => (props.isMobile || props.isTablet) ?  spacing.paddingMobileStandard : null };

  text-align: ${props => (props.textAlign ? props.textAlign : null)};
  width: ${props => (props.fullWidth || props.isMobile || props.isTablet ? '100%' : '1024px' )};

  ${props => (props.customStyle ? props.customStyle : null)};
  /* responsive purpose only
  padding-right: ${props => (props.isMobile || props.isTablet ? `${spacing.large}px` : null )};
  padding-left: ${props => (props.isMobile || props.isTablet ? `${spacing.large}px` : null )}; */
  /* ${props => (props.isMobile || props.isTablet) ?  ({ paddingRight: spacing.paddingMobileStandard, paddingLeft: spacing.paddingMobileStandard }) : null }; */
`;

const UIContainer = ({
  children,
  fullWidth = null,
  noPadding = null,
  textAlign,
  customStyle = null,
  pageTop = false,
  isMobile,
  isTablet,
}) => {
  return (
    <Container
      fullWidth={fullWidth}
      textAlign={textAlign}
      noPadding={noPadding}
      customStyle={customStyle}
      pageTop={pageTop}
      isMobile={isMobile}
      isTablet={isTablet}
    >
      {children}
    </Container>
  );
};

export default UIContainer;
