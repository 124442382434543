import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import propTypes from 'prop-types'
import { loadStatus } from '../../../redux/actions/userActions'

export const UserContainer = ({ apiCallsInProgress, loadStatus, ...props }) => {
  const [user, setUser] = useState()

  useEffect(() => {
    if (!props.user.checked) loadStatus()
    else setUser({ ...props.user })
  }, [props.user, loadStatus])

  const childrenWithProps = React.Children.map(props.children, child =>
    React.cloneElement(child, { user, apiCallsInProgress })
  )
  return <>{childrenWithProps}</>
}

UserContainer.propTypes = {
  user: propTypes.object.isRequired,
  loadStatus: propTypes.func.isRequired,
  apiCallsInProgress: propTypes.number.isRequired,
}

function mapStateToProps({ user, apiCallsInProgress }) {
  return {
    user,
    apiCallsInProgress,
  }
}

const mapDispatchToProps = {
  loadStatus,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserContainer)
