import React from 'react';
import Currency from 'react-currency-formatter';
import { spacing, colors } from '../../../../../../../defaults/styles';
import LoginRequired from '../LoginRequired';
import { lsStyled as styled } from '../../../../../../../tools/helpers/lsStyled';

const MainDiv = styled.div`
  display: grid;
  grid-template-columns: 125px 1fr;
  align-items: center;
  justify-content: center;
`;

const LabelDiv = styled.div`
  font-weight: bold;
  margin-right: ${spacing.verySmall}px;
`;

const ValueDiv = styled.div`
  ${'' /* margin-bottom: ${spacing.verySmall}px; */}
`;

const Item = ({ label, value, discountPercentage, logInRequired, activeLanguage, ...props }) => {
  const locale = (activeLanguage && activeLanguage.code) || 'en';
  return (
    <MainDiv>
      <LabelDiv>{label}</LabelDiv>
      <ValueDiv>
        {logInRequired ? (
          <LoginRequired />
        ) : typeof value === 'string' ? (
          <>
            <span>{value}</span>
            {!!discountPercentage && (<span> {'(' + discountPercentage + '%)'} </span>)}
          </>
        ) : (
          <>
          <Currency quantity={value} currency="CAD" locale={locale} />
          {!!discountPercentage && (<span> {'(' + discountPercentage + '%)'} </span>)}
          </>
        )}
      </ValueDiv>
    </MainDiv>
  );
};

export default Item;
