import React from 'react';
import PageSection from '../../interface/PageSection';
import spacing from './../../../../defaults/styles/spacing';
import { Translate, withLocalize } from 'react-localize-redux';

const styles = {
  ul: {
    listStyle: 'none',
    paddingLeft: spacing.small,
  },
  li: {
    paddingBottom: spacing.verySmall,
  },
};

const stylesMobile = {
  ul: {
    ...styles.ul,
    paddingLeft: spacing.veryLarge,
    textAlign: 'left',
  },
};

const Catalogue = ({ isMobile }) => {
  const t = (tag) => `services.legacy.catalog.${tag}`;

  return (
    <PageSection
      title={<Translate id={t('title')} />}
      paragraphs={[
        <Translate id={t('paragraph1')} />,
        <ul style={isMobile ? stylesMobile.ul : styles.ul}>
          <li style={styles.li}>
            - <Translate id={t('listItem1')} />
          </li>
          <li style={styles.li}>
            - <Translate id={t('listItem2')} />
          </li>
          <li style={styles.li}>
            - <Translate id={t('listItem3')} />
          </li>
          <li style={styles.li}>
            - <Translate id={t('listItem4')} />
          </li>
          <li style={styles.li}>
            - <Translate id={t('listItem5')} />
          </li>
          <li style={styles.li}>
            - <Translate id={t('listItem6')} />
          </li>
        </ul>,
        <Translate id={t('paragraph2')} />,
      ]}
      contentPosition="right"
      imgSrc="/images/pages/services/devices.png"
      imgAlt="catalog automation"
    />
  );
};

export default withLocalize(Catalogue);
