import { report } from "../../../../../tools/api";

const lexicon = {
  home: ['HOME', 'ACCUEIL'],
  aboutUs: ['OUR STORY', 'À PROPOS'],
  services: ['SERVICES', 'SERVICES'],
  brands: ['BRANDS', 'MARQUES'],
  rewards: ['REWARDS', 'RÉCOMPENSES'],
  wholesale: ['WHOLESALE', 'VENTE EN GROS'],
  fulfilment: ['FULFILLMENT', 'EXÉCUTION'],
  product: ['PRODUCTS', 'PRODUITS'],
  news: ['NEWS', 'NOUVELLES'],
  blog: ['BLOG', 'BLOG'],
  corporateGifts: ['CORPORATE GIFTS', 'CADEAUX CORPORATIFS'],
};

const links = [
  { url: '', lexiconCode: 'home' }, // Use empty for now, / will result in missing images
  // { url: '/rewards', lexiconCode: 'rewards' },
  { url: '/services-rewards', lexiconCode: 'services' },
  { url: '/brands', lexiconCode: 'brands' },
  { url: '/about-us', lexiconCode: 'aboutUs' },
  { url: '/catalog', lexiconCode: 'product' },
  { url: '/news-list', lexiconCode: 'news'},
];

const subLinks = [
  { url: '/services-rewards', lexiconCode: 'rewards' },
  { url: '/services-fulfilment', lexiconCode: 'fulfilment' },
  { url: '/services-gifting', lexiconCode: 'gifting' }
];

const subNewsLinks = [
  { url: '/news-list', lexiconCode: 'news'},
  { url: '/blog-list', lexiconCode: 'blog'}
];

const subProductLinks = [
  { url: '/catalog', lexiconCode: 'allProducts'},
  { url: '/corporate-gifts', lexiconCode: 'corporateGifts'},
]

const userLinks = [
  // { url: '/reports', lexiconCode: 'reports' },
  { url: '/info', lexiconCode: 'settings' },
  { callback: (event) => { event.preventDefault(); report.downloadCatalog(); }, lexiconCode: 'downloadCatalogue'},
  { callback: (event) => { event.preventDefault(); }, lexiconCode: 'shoppingList'},
  { callback: (event) => { event.preventDefault(); console.log("call m pre..e")}, lexiconCode: 'myPresentation'},
  { url: '/orders?completed=false&page=1&size=15&sort=creationDate%2Cdesc', lexiconCode: 'openOrders' },
  { url: '/orders?completed=true&page=1&size=15&sort=creationDate%2Cdesc', lexiconCode: 'completedOrders' },
  { url: '/logout', lexiconCode: 'logOut' },
];

export default { lexicon, links, subLinks, subNewsLinks, userLinks, subProductLinks };