import React, { useEffect, useState } from 'react';
import Title from './Title';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { lsFormatNum as formatNum } from '../../../../tools/helpers/lsFormatNum';
import { colors, fonts, spacing } from '../../../../defaults/styles';
import { withLocalize } from 'react-localize-redux';
import { loadBrands } from '../../../../redux/actions/brandActions';
import { connect } from 'react-redux';
import { convert, fixHtmlEncodings } from '../../../../tools/helpers/descriptionConverter';

const Breadcrumbs = styled.p`
  color: ${colors.gray.normal};
  font-size: ${fonts.sizes.smallText}px;
  margin-bottom: ${spacing.small}px;
`;

const Bread = styled.span`
  color: ${props =>
    props.isLast || props.isFirst ? colors.black.normal : null};
  font-weight: ${props =>
    props.isLast || props.isFirst ? fonts.weights.heading : null};
  &::after {
    content: "${props => (!props.isLast ? ' > ' : null)}";
    display: inline-block;
    padding-right: ${spacing.tiny}px;
    padding-left: ${spacing.tiny}px;
  }
  ${props => (props.isFirst ? 'text-decoration: underline;' : '')}
  ${props => (props.isFirst ? 'cursor: pointer;' : '')}
`;

const BreadCrumbTitle = ({
  categories,
  history,
  currentQuery,
  totalElements,
  translate,
  user,
  loadBrands,
  listing,
  ...props
}) => {
  const t = (tag) => `corporateGifts.${tag}`;

  const defaultTitle = translate(t('title'));
  const breadcrumbs = [defaultTitle];

  let brandImageUrl = '';
  let brandDescription = '';

  let lastLabel = defaultTitle;
  let lastImgUrl = null;

  // Check query string
  if (currentQuery.q) {
    const queryString = translate(t('search')) + `"${currentQuery.q}"`;
    breadcrumbs.push(queryString);
    lastLabel = queryString;
  }

  // Check display type
  if (currentQuery.display) {
    switch (currentQuery.display) {
      case 'NEW':
        const newTitle = translate('loggedInHome.new.title');
        breadcrumbs.push(newTitle);
        lastLabel = newTitle;
        break;
      case 'BEST_SELLER':
        const bestSellerTitle = translate('loggedInHome.hot.title');
        breadcrumbs.push(bestSellerTitle);
        lastLabel = bestSellerTitle;
        break;
      case 'CANADIAN':
        const canadianTitle = translate('loggedInHome.canadian.title');
        breadcrumbs.push(canadianTitle);
        lastLabel = canadianTitle;
        break;
      case 'LISTING':
        const matchedListing = listing.filter(o => o.id === currentQuery.displayId);
        if (matchedListing && matchedListing.length > 0) {
          const featureProductsTitle = matchedListing[0].name;
          breadcrumbs.push(featureProductsTitle);
          lastLabel = featureProductsTitle;
        } else {
          const featureProductsTitle = translate('loggedInHome.featured.title');
          breadcrumbs.push(featureProductsTitle);
          lastLabel = featureProductsTitle;
        }
        break;
    }
  }

  // Check brand
  const [brands, setBrands] = useState([]);
  const [previousLanguage, setPreviousLanguage] = useState();
  useEffect(() => {
    if (props.activeLanguage) {
      const language =
        props.activeLanguage && props.activeLanguage.code ? props.activeLanguage.code : 'en';
      // Forces reload of brands on language change
      if (previousLanguage !== language || props.brands.length === 0) {
        setPreviousLanguage(language)
        loadBrands(language);
      }
      else setBrands([...props.brands]);
    }
  },[props.brands, loadBrands, props.activeLanguage]);  

  if (currentQuery.brand) {
    const brandsList = [];
    brands.forEach(brandGroup => {
        brandGroup[1].forEach(brand => {
            brandsList[brand.id] = brand;
        });
    });

    const brand = brandsList[currentQuery.brand];
    if(brand){
      brandDescription = brand.description.toString();
      brandImageUrl = brand.imageUrl;
    }
    if (brandsList[currentQuery.brand]) {
      breadcrumbs.push(brand.name);
    }
  }

  // Check first level
  const group = categories.filter(({ id }) => id == currentQuery.group);
  if (group && group[0]) {
    breadcrumbs.push(group[0].name);
    lastLabel = group[0].name;
    lastImgUrl = group[0].imageUrl;

    // Check second level
    const category = group[0].categoryList
      ? group[0].categoryList.filter(({ id }) => id == currentQuery.category)
      : null;
    if (category && category[0]) {
      breadcrumbs.push(category[0].name);
      lastLabel = category[0].name;
      lastImgUrl = category[0].imageUrl;

      // Check third level
      const type = category[0].typeList
        ? category[0].typeList.filter(({ id }) => id == currentQuery.type)
        : null;
      if (type && type[0]) {
        breadcrumbs.push(type[0].name);
        lastLabel = type[0].name;
        lastImgUrl = type[0].imageUrl;
      }
    }
  }

  const lang =
    props.activeLanguage && props.activeLanguage.code
      ? props.activeLanguage.code
      : 'en';

  // Reset filter for entire page
  const resetFilters = () => {
    history.push({ pathname: `/${lang}/corporate-gifts` });
  };

  if(breadcrumbs && breadcrumbs.length > 0) {
    document.title =  breadcrumbs.map((bread) => bread.trim()).join(" > ") + ' - Loyalty Source';
  }
  return (
    <>
      <Breadcrumbs>
        {breadcrumbs.length > 0 &&
          breadcrumbs.map((bread, index) => (
            <Bread
              key={'catalog-breadcrumb-' + bread}
              isFirst={index === 0}
              isLast={breadcrumbs.length === index + 1}
              onClick={() => {
                if (index === 0) {
                  resetFilters();
                }
              }}
            >
              {bread.trim()}
            </Bread>
          ))}
      </Breadcrumbs>
      <Title
        text={lastLabel}
        imgSrc={lastImgUrl}
        productCounter={true}
        totalProducts={
          totalElements > 0 ? formatNum(totalElements, lang) : null
        } // total product num
        productCountsLabel={
          totalElements !== 1
            ? translate(t('productLabel_other'))
            : translate(t('productLabel_1'))
        }
        lang={lang}
        history={history}
        currentQuery={currentQuery}
        user={user}
        brandImageUrl={brandImageUrl ? brandImageUrl : null}
        brandDescription={brandDescription ? fixHtmlEncodings(convert(brandDescription)) : null}
      />
    </>
  );
};
function mapStateToProps({ brands }) {
  return {
    brands,
  };
}

const mapDispatchToProps = {
  loadBrands,
};

export default withLocalize(connect(
  mapStateToProps,
  mapDispatchToProps
)(BreadCrumbTitle));
