import React, { useRef, useState, useEffect } from 'react';
import { spacing, colors } from '../../../../defaults/styles';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { GridLoader } from 'react-spinners';
import { Translate, withLocalize } from 'react-localize-redux';
// import Filters from './Filters';
import List from '../../layout/ProductsList/List';
import BreadCrumbTitle from './BreadCrumbTitle';
import Button from '../../interface/Button';

const ResultHeader = styled.header`
  ${props => (props.isMobile ? 'display: flex' : '')};
  ${props => (props.isMobile ? 'flex-direction: column' : '')};
  ${props => (props.isMobile ? '' : 'align-items: center')};
  ${props =>
    props.isMobile ? '' : 'margin-bottom: ' + spacing.large + 'px'};
`;

const LoaderWrapper = styled.div`
  padding: ${spacing.veryLarge}px;
`;

const CatalogBlock = styled.section`
  display: block;
  text-align: center;
`;

const ContentSection = styled.section`
  padding-bottom: ${spacing.veryLarge}px;
`;

const ButtonDiv = styled.div`
  padding-bottom: 1.5em;
`;

const LoadingGrid = (
  <LoaderWrapper>
    <GridLoader css={{ margin: 'auto' }} color={colors.main.normal} size={12} />
  </LoaderWrapper>
);

const ResultWrapper = ({
  categories,
  apiCallsInProgress,
  catalogForCorporateGifts,
  loadCatalogForCorporateGifts,
  history,
  totalElements,
  totalPages,
  isMobile,
  currentQuery,
  user,
  brands,
  listing,
}) => {
  const t = (tag) => `catalog.${tag}`;

  const ContentHolder = content => (
    <ContentSection>
      <ResultHeader isMobile={isMobile}>
        <div>
          <BreadCrumbTitle
            user={user}
            categories={categories}
            totalElements={totalElements}
            history={history}
            currentQuery={currentQuery}
            brands={brands}
            listing={listing}
          />
        </div>
      </ResultHeader>
      {content}
    </ContentSection>
  );

  // Lazy loading
  const ref = useRef(null);

  const [loadingCatalog, setLoadingCatalog] = useState(true);

  useEffect(() => {
    catalogForCorporateGifts && catalogForCorporateGifts.length > 0 && setLoadingCatalog(false);
  }, [catalogForCorporateGifts])

  // Check if first results are loaded yet
  if (loadingCatalog && (catalogForCorporateGifts.length == 0 || !catalogForCorporateGifts)) {
    return ContentHolder(LoadingGrid);
  } else {
    // Dummy page section
    const ary = Array.from(Array(totalPages).keys());

    // find next page
    const nextPage = catalogForCorporateGifts.length > totalPages - 1 ? -1 : catalogForCorporateGifts.length;
    
    const loadNextPage = () => {
      setLoadingCatalog(true)
      loadCatalogForCorporateGifts(currentQuery, nextPage);
    };

    const handleScroll = () => {
      if (ref && ref.current) {
        const top = ref.current.getBoundingClientRect().top;
        if (top < window.innerHeight && !apiCallsInProgress) {
          ref.current.click();
        }
      }
    };

    window.removeEventListener('scroll', handleScroll);
    window.addEventListener('scroll', handleScroll);

    const loadedProducts = [];
    ary.forEach((index) => {
      if (catalogForCorporateGifts[index] && catalogForCorporateGifts[index].content) {
        catalogForCorporateGifts[index].content.forEach((product) => {
          loadedProducts.push(product);
        });
      }
    });

    // Loading content
    const content =
      totalElements == 0 ? (
        <Translate id={t('noProducts')} />
      ) : (
        <>
          <List
            loggedInHome={user.loggedIn} 
            products={loadedProducts} 
            isMobile={isMobile}  
            currentQuery={currentQuery} />
          {ary.map((value, index) => (
            <CatalogBlock key={'product-block-' + index}>
              {catalogForCorporateGifts[value] && catalogForCorporateGifts[value].content ? (
                <></>
              ) : index === nextPage ? (
                loadingCatalog ? (
                  LoadingGrid
                ) : (
                  <ButtonDiv>
                    <Button>
                      <span ref={ref} onClick={loadNextPage}>
                        Load more
                      </span>
                    </Button>
                  </ButtonDiv>
                )
              ) : (
                <></>
              )}
            </CatalogBlock>
          ))}
        </>
      );

    return ContentHolder(content);
  }
};

export default withLocalize(ResultWrapper);
