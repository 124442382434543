import React, { useState, useEffect } from 'react';
import { lsStyled as styled } from '../../../tools/helpers/lsStyled';

export const type = {
  horizontal: 1,
  vertical: 2,
};

const FlipBox = styled.div`
  background-color: transparent;
  width: ${props => (props.width ? props.width : '100%')};
  height: ${props => (props.height ? props.height : '100%')};
  ${props => (props.display3d ? 'perspective: 1000px' : '')};
`;

const FlipBoxInner = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 1.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform-style: preserve-3d;
  text-align: center;

  ${props =>
    props.hovered
      ? 'transform: ' +
        (props.type === type.horizontal ? 'rotateY(180deg)' : 'rotateX(180deg)')
      : ''};
`;

const FlipBoxImageFront = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  backface-visibility: hidden;
`;

const FlipBoxImageBack = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  backface-visibility: hidden;
  transform: ${props =>
    props.type === type.horizontal ? 'rotateY(180deg)' : 'rotateX(180deg)'};
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
`;

const FlippingImage = ({
  type = 1,
  frontImageUrl,
  backImageUrl,
  width,
  height,
  display3d = true,
  startWithBackSide = false,
  ImgComponent = null,
  automaticIntervalMs,
  initialDelay,
}) => {
  const [hovered, setHovered] = useState(startWithBackSide);
  const [launched, setLaunched] = useState(!initialDelay);

  useEffect(() => {
    if (launched) {
      const timer = setTimeout(() => {
        setHovered(!hovered);
      }, automaticIntervalMs);
      return () => clearTimeout(timer);
    }
  }, [ hovered ]);

  useEffect(() => {
    if (initialDelay) {
      const timer = setTimeout(() => { 
        setLaunched(true); // launched first to set the timeout
        setHovered(!hovered); // hovered after to trigger useEffect
      }, initialDelay);
      return () => clearTimeout(timer);
    }
  }, []);

  return (
    <FlipBox
      width={width}
      height={height}
      display3d={display3d}
      type={type}
      onMouseEnter={() => { setHovered(!hovered); }}
      onMouseLeave={() => { setHovered(!hovered); }}
    >
      <FlipBoxInner hovered={hovered} type={type}>
        <FlipBoxImageFront hovered={hovered} type={type}>
          {ImgComponent ? (
            <ImgComponent src={frontImageUrl} />
          ) : (
            <Img src={frontImageUrl} />
          )}
        </FlipBoxImageFront>

        <FlipBoxImageBack hovered={hovered} type={type}>
          {ImgComponent ? (
            <ImgComponent src={backImageUrl} />
          ) : (
            <Img src={backImageUrl} />
          )}
        </FlipBoxImageBack>
      </FlipBoxInner>
    </FlipBox>
  );
};

export default FlippingImage;
