import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { colors, spacing, fonts } from '../../../../defaults/styles';

const styles = {
  backgroundColor: colors.gray.light,
  fontSize: fonts.small,
  padding: spacing.small,
  width: '100%',
};

const SuccessMessage = () => {
  const t = (tag) => `accountRequest.form.${tag}`;
  return (
    <p style={styles}>
      <Translate id={t('successMsg1')} /><br />
      <Translate id={t('successMsg2')} />
    </p>
  );
};

export default withLocalize(SuccessMessage);
