import React, { useState, useContext } from 'react';
import styled from 'styled-components';
import Top from './Top';
import HeaderContainer from './HeaderContainer';
import { colors } from '../../../../defaults/styles';
import { HeadersContainer } from '../../../smart';
import { ResizeAwareContext } from '../../../smart/ResizeContext';

// const height = sizing.header.top + sizing.header.container;

const MainHeader = () => {
  const [productsPopupOpen, setProductsPopupOpen] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const openProductsPopupClickHandler = () => {
    setProductsPopupOpen(!productsPopupOpen);
  };

  const { isMobile, isTablet } = useContext(ResizeAwareContext);

  return (
    <Header>
      <FixedWrapper mobileMenuOpen={mobileMenuOpen}>
        <Top
          isMobile={isMobile}
          isTablet={isTablet}
        />
        <HeadersContainer>
          <HeaderContainer
            openProductsPopupClickHandler={openProductsPopupClickHandler}
            productsPopupOpen={productsPopupOpen}
            isMobile={isMobile}
            isTablet={isTablet}
            mobileMenuOpen = {mobileMenuOpen}
            setMobileMenuOpen = {setMobileMenuOpen}
          />
        </HeadersContainer>
        {/* <ProductsPopup
          openProductsPopupClickHandler={openProductsPopupClickHandler}
          windowWidth={windowWidth}
          open={productsPopupOpen}
          isMobile={isMobile}
          isTablet={isTablet}
        /> */}
      </FixedWrapper>
    </Header>

  );
};

export default MainHeader;

const Header = styled.header `
  background-color: ${colors.white.normal};
  position: relative;
  z-index: 999;
`;

// const styles = {
//   root: {},
//   wrapper: {
//     backgroundColor: colors.white.normal,
//     boxShadow: '0 0 5px 0 rgba(0, 0, 0, 0.25)',
//     position: 'fixed',
//     width: '100%',
//     zIndex: 999
//     // height,
//   },
// };

const FixedWrapper = styled.div `
  background-color: ${colors.white.normal};
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.25);
  position: ${props => props.mobileMenuOpen ? 'relative' : 'fixed'};
  width: 100%;
  z-index: 999;
`;