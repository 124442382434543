import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Content from './Content';
import Images from './Images';
import EstimatedShipping from './EstimatedShipping';
import RecommendedProducts from './RecommendedProducts';
import { UserContainer } from '../../../../smart';
import { spacing, colors } from '../../../../../defaults/styles';
import {
  getAccessTokenToCreateProject,
  getItAccountShortToken,
} from '../../../../../tools/api/zoomCatalog';
import Description from './Content/Description';
import DescriptionList from './Content/DescriptionList';
import { withLocalize } from 'react-localize-redux';
import { GridLoader } from 'react-spinners';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';
import { ResizeAwareContext } from '../../../../smart/ResizeContext';
import { loadShoppingLists } from '../../../../../redux/actions/shoppingListActions';
import ZoomPopup from './Content/ZoomCatalog/ZoomPopup';

const ProductWrapper = styled.section`
  padding-left: ${props => (props.isMobile ? spacing.small : spacing.medium)}px;
`;

const Product = ({ product, translate, shoppingLists, loadShoppingLists, userInfo, loadInfo }) => {
  const t = (tag) => `product.${tag}`;
  const { isMobile, isTablet } = useContext(ResizeAwareContext);

  const [viewUrl, setViewUrl] = useState("");
  const [editUrl, setEditUrl] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [zoomStudioUserToken, setZoomStudioUserToken] = useState("");
  const [zoomStudioItToken, setZoomStudioItToken] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  if (product && product.name) {
    document.title = product.name + (product.brand ? ' - ' + product.brand : '') + ' - Loyalty Source';
  }

  useEffect(() => {
    if (!shoppingLists) {
      loadShoppingLists();
    }
  }, [loadShoppingLists, shoppingLists]);

  //Add zoompopup here to make both zoomcatalog components and recommended product reuse the popup.
  const appendErrorMessage = (newMessage) => {
    setErrorMessage((prevMessage) => {
      return prevMessage + (prevMessage ? '\n' : '') + newMessage
    });
  };

  useEffect(() => {
    let isMounted = true;

    const fetchData = async () => {
      try {
        const itToken = await getItAccountShortToken();
        setZoomStudioItToken(itToken);

        if (isMounted && userInfo && Object.keys(userInfo).length > 0) {
          const data = await getAccessTokenToCreateProject(userInfo.username);
          setZoomStudioUserToken(data.user_token);
        }
      } catch (error) {
        setZoomStudioUserToken(null);
      }
    };

    if (userInfo && Object.keys(userInfo).length > 0) {
      fetchData();
    }

    // Cleanup function to cancel any ongoing tasks when component unmounts
    return () => {
      isMounted = false;
    };
  }, [userInfo])


  return product && product.name ? (
    <ProductWrapper isMobile={isMobile}>
      {/* Image / point form section */}
      <section>
        <section style={{ display: isMobile ? 'block' : 'flex' }}>
          <div style={{ flex: 1, marginRight: spacing.verySmall }}>
            <Images product={product} isMobile={isMobile} isTablet={isTablet} />
          </div>
          <div style={{ flex: 1, marginLeft: spacing.verySmall }}>
            <Content
              product={product}
              showPopup={showPopup}
              setShowPopup={setShowPopup}
              isMobile={isMobile}
              editUrl={editUrl}
              viewUrl={viewUrl}
              zoomStudioUserToken={zoomStudioUserToken}
              zoomStudioItToken={zoomStudioItToken}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              appendErrorMessage={appendErrorMessage}
            />
          </div>
        </section>

        {/* Long text section */}
        <section>
          <>
            <Description
              title={translate(t('description'))}
              content={product.description}
            />
            {product.imprintingList && (
              <DescriptionList
                title={translate(t('imprintingOptions'))}
                items={product.imprintingList}
              />
            )}
            <Description
              title={translate(t('specifications'))}
              content={product.specification}
            />
            {product.other && (
              <Description
                title={translate(t('additionalInfo'))}
                content={product.other}
              />
            )}
          </>
        </section>
        <UserContainer>
          <EstimatedShipping
            provinces={product.shippingEstimate}
            average={product.shippingAverage}
            isMobile={isMobile}
          />
        </UserContainer>
        {product &&
          product.recommendedProducts &&
          product.recommendedProducts.length > 0 && (
            <RecommendedProducts
              products={product.recommendedProducts}
              isMobile={isMobile}
              showPopup={showPopup}
              setShowPopup={setShowPopup}
              editUrl={editUrl}
              viewUrl={viewUrl}
              setEditUrl={setEditUrl}
              setViewUrl={setViewUrl}
              zoomStudioUserToken={zoomStudioUserToken}
              zoomStudioItToken={zoomStudioItToken}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              appendErrorMessage={appendErrorMessage}
            />
          )}
          <ZoomPopup
            showPopup={showPopup}
            setShowPopup={setShowPopup}
            isMobile={isMobile}
            editUrl={editUrl}
            viewUrl={viewUrl}
            zoomStudioUserToken={zoomStudioUserToken}
            zoomStudioItToken={zoomStudioItToken}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            appendErrorMessage={appendErrorMessage}
          />
      </section>
    </ProductWrapper>
  ) : (
    <ProductWrapper isMobile={isMobile} style={{ textAlign: 'center' }}>
      <GridLoader
        css={{ margin: 'auto' }}
        color={colors.main.normal}
        size={12}
      />
    </ProductWrapper>
  );
};

function mapStateToProps({ shoppingLists, userInfo }) {
  return { shoppingLists, userInfo };
}
const mapDispatchToProps = {
  loadShoppingLists
};

export default withLocalize(connect(
  mapStateToProps,
  mapDispatchToProps
)(Product));
