import React from 'react';
import Radium from 'radium';
import { fonts, spacing, sizing } from './../../../../defaults/styles';

const styles = {
  fontFamily: fonts.title,
  fontSize: fonts.sizes.title,
  fontWeight: fonts.weights.normal,
  letterSpacing: 1,
  marginBottom: spacing.large,
  ...sizing.boundToContentWidth,
};

const stylesMobile = {
  ...styles,
  fontSize: fonts.sizes.titleMobile,
  letterSpacing: 0.8,
  textAlign: 'center',
};

function Title({ text, isMobile }) {
  return <h2 style={isMobile ? stylesMobile : styles}>{text}</h2>;
}

export default Radium(Title);
