import React from 'react';
import { Link } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';

// == Styling == //
const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
const Img = styled.img`
  display: block;
  height: ${props => props.isMobile ? 'auto' : '42px'};
  width: ${props => props.isMobile ? '150px' : 'auto'};
`;

// == /Styling == //

const Logo = ({ activeLanguage, isMobile }) => {
  const lang =
    activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';
  return (
    <Wrapper>
      <Link to={'/' + lang}>
        <Img src={`/images/LSLogo${lang.toUpperCase()}.png`} alt="Loyalty Source Logo" isMobile={isMobile} />
      </Link>
    </Wrapper>
  );
};

export default withLocalize(Logo);
