import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import UIPageTopTitle from './UIPageTopTitle';

const PageTitleSimple = ({ isMobile, isTablet, translateId }) => {  
    return (
      <Translate>
        {({ translate }) => (
          <UIPageTopTitle  isMobile={isMobile} isTablet={isTablet}
            title={translate(translateId)}
          />
        )}
      </Translate>
    );
};
  
export default withLocalize(PageTitleSimple);