import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import qs from 'querystring';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';

const PageText = styled.span`
  margin-left: 15px;
  margin-right: 15px;
`;

export const OrdersPageFooter = ({
  totalPages = 0,
  currentQuery,
  history,
  lang,
}) => {
  const { page = 1 } = currentQuery;

  if (totalPages > 0) {
    const prevPage = Number(page) - 1;
    const nextPage = Number(page) + 1;

    const prevQuery = qs.stringify({
      ...currentQuery,
      page: Math.max(prevPage, 1)
    });

    const nextQuery = qs.stringify({
      ...currentQuery,
      page: Math.min(nextPage, totalPages)
    });

    const prevClick = (event) => {
      event.preventDefault();
      history.push({ pathname: `/${lang}/orders`, search: `?${prevQuery}`
      });
    };

    const nextClick = (event) => {
      event.preventDefault();
      history.push({ pathname: `/${lang}/orders`, search: `?${nextQuery}` });
    };

    return (
      <div className="row">
        <div className="col-md-12">
          <form
            className="form-inline"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            {/* Previous page */}
            {prevPage >= 1 && (
              <Link
                to={`/${lang}/orders?${prevQuery}`}
                className="btn btn-light"
                onClick={prevClick}
              >
                <FontAwesomeIcon icon={faCaretLeft} />
              </Link>
            )}

            {/* Total page text */}
            <PageText>
              {page}
              &nbsp;/&nbsp;
              {totalPages}
            </PageText>

            {/* Next page */}
            {nextPage <= totalPages && (
              <Link
                to={`/${lang}/orders?${nextQuery}`}
                className="btn btn-light"
                onClick={nextClick}
              >
                <FontAwesomeIcon icon={faCaretRight} />
              </Link>
            )}
          </form>
        </div>
      </div>
    );
  }
  // Nothing is rendered
  else {
    return <div className="row" />;
  }
};
