import React, { useContext } from 'react';
import { lsStyled as styled } from './../../../../tools/helpers/lsStyled';
import { connect } from 'react-redux';
import { loadBlogPosts } from "../../../../redux/actions/blogActions"
import { BlogsContainer } from '../../../smart';
import { withLocalize } from 'react-localize-redux';
import UIContainer from '../../interface/UIContainer';
import { ResizeAwareContext } from '../../../smart/ResizeContext';

/**
 * Blog List page, where users are able to see all blogs posted
 */

const headerStyle = {
    margin: "12px 0px",
    color: "white",
    textAlign: "center"
}

const BlogPostsPage = withLocalize(({ translate }) => {
    const { isMobile, isTablet } = useContext(ResizeAwareContext);
    return (
        <>
            <Header isMobile={isMobile}>
                <h1 style={headerStyle}>{translate("blogContainer.slogan")}</h1>
            </Header>
            <UIContainer isMobile={isMobile} isTablet={isTablet}>
                <BlogsContainer />
            </UIContainer>
        </>
    )
});

const mapStateToProps = ({ blogPosts }) => {return { blogPosts }}
const mapDispatchToProps = { loadBlogPosts };

export default connect(mapStateToProps, mapDispatchToProps,) (BlogPostsPage);

const Header = styled.div`
    margin: 0 auto;
    width: 100%
    background-color: rgb(195,8,16);
    padding-top: 220px;
    padding-bottom: 24px;

    @media(max-width: 600px) {
        margin: 0 auto;
        width: 100%
        background-color: rgb(195,8,16);
        padding-top: 140px;
        padding-bottom: 24px;
    }
`