import React from 'react';

import PageReinforcementStatic from './../../interface/PageReinforcementStatic';
import UIContainer from './../../interface/UIContainer';
import UISection from './../../interface/UISection';
import UIParagraph from './../../interface/UIParagraph';
import UIGridIcons from './../../interface/UIGridIcons';
import { Translate, withLocalize } from 'react-localize-redux';

const Experts = ({ isMobile, isTablet, translate }) => {
  const t = (tag) => `services.fulfillment.experts.${tag}`;

  return (
    <UIContainer noPadding isMobile={isMobile} isTablet={isTablet}>
      <UISection noPadding>
        <PageReinforcementStatic
          title={translate(t('title'))}
          subTitle={translate(t('subTitle'))}
          borderRequired={true}
          heading={'h2'}
          type={'1'}
          alignment={'center'}
        />
        <UIParagraph textAlign={'center'}>
          <Translate id={t('mainParagraph')} />
        </UIParagraph>
      </UISection>

      <UISection>
        <UIGridIcons
          customStyle={{
            marginTop: 0,
            gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr 1fr',
          }}
          isMobile={isMobile}
          isTablet={isTablet}
          iconsAndText={[
            {
              src: 'services-fulfilment/icons/inventory.png',
              alt: 'inventory icon',
              text: translate(t('icon1'), null, {
                renderInnerHtml: true,
              }),
            },
            {
              src: 'services-fulfilment/icons/computer.png',
              alt: 'integration icon',
              text: translate(t('icon2'), null, {
                renderInnerHtml: true,
              }),
            },
            {
              src: 'services-fulfilment/icons/track.png',
              alt: 'delivery icon',
              text: translate(t('icon3'), null, {
                renderInnerHtml: true,
              }),
            },
          ]}
        />
      </UISection>
    </UIContainer>
  );
};

export default withLocalize(Experts);
