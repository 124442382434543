import React, { useState, useEffect } from 'react';
import Button from '../../../../interface/Button';
import Inputs from './Inputs';
import { spacing, colors } from '../../../../../../defaults/styles';
import { Translate, withLocalize } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import qs from 'querystring';
import { lsStyled as styled } from '../../../../../../tools/helpers/lsStyled';

const PriceRangeOuterDiv = styled.div`
    border-top: 1px solid ${colors.gray.light};
    padding-top: ${spacing.medium}px;
    padding-left: ${spacing.verySmall}px;
    padding-right: ${spacing.verySmall}px;
    margin-top: ${spacing.large}px;
`;

const PriceRangeTitle = styled.div`
    font-weight: bold;
    margin-bottom: ${spacing.verySmall}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const RadioButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: ${spacing.verySmall}px;
    margin-bottom: ${spacing.verySmall}px;
`;

const RadioButtonGroup = styled.div`
    display: flex;
    align-items: center;
`;

const TypeLabel = styled.label`
    display: block;
    ${props => (props.left ? 'margin-left: ' : 'margin-right: ') + spacing.verySmall}px;
`;

const PriceButtonWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: ${spacing.small}px;
    margin-bottom: ${spacing.medium}px;
`;

const PriceButton = styled.button`
    width: 100%;
    padding: ${spacing.small}px ${spacing.medium}px;
    font-size: 1rem;
    cursor: pointer;
    background-color: ${colors.gray.light};
    border: 1px solid ${colors.gray.dark};

    &:hover {
        background-color: ${colors.main.normal};
        color: ${colors.white};
    }
`;

const priceRanges = [
    { start: 0, end: 50, label: '0-50' },
    { start: 50, end: 100, label: '50-100' },
    { start: 100, end: 150, label: '100-150' },
    { start: 150, end: 200, label: '150-200' },
    { start: 200, end: 300, label: '200-300' },
    { start: 300, end: null, label: '300+' },
    { start: 400, end: null, label: '400+' },
    { start: 500, end: null, label: '500+' },
];

const PriceRange = ({ currentQuery, history, user, apiCallsInProgress }) => {
    const t = (tag) => `corporateGifts.price.${tag}`;

    const [price, setPrice] = useState({
        priceStart: currentQuery.priceStart || '',
        priceEnd: currentQuery.priceEnd || '',
        priceType: currentQuery.priceType || 'msrp',
    });

    const [shouldPushHistory, setShouldPushHistory] = useState(false);

    useEffect(() => {
        setPrice({
            priceStart: currentQuery.priceStart || '',
            priceEnd: currentQuery.priceEnd || '',
            priceType: currentQuery.priceType || 'msrp',
        });
    }, [currentQuery.priceStart, currentQuery.priceEnd, currentQuery.priceType]);

    useEffect(() => {
        if (shouldPushHistory) {
            const separatedQuery = { ...currentQuery };
            delete separatedQuery.priceType;
            delete separatedQuery.priceStart;
            delete separatedQuery.priceEnd;
            
            history.push({
                pathname: history.location.pathname,
                search: '?' + qs.stringify({
                    ...separatedQuery,
                    priceStart: price.priceStart,
                    priceEnd: price.priceEnd,
                    priceType: price.priceType,
                }),
            });

            setShouldPushHistory(false);
        }
    }, [price, shouldPushHistory, currentQuery, history]);

    const handlePriceButtonClick = (start, end) => {
        setPrice((prevPrice) => ({
            ...prevPrice,
            priceStart: start,
            priceEnd: end,
        }));
        setShouldPushHistory(true);
    };

    const cancelPriceValues = () => {
        setPrice({
            priceStart: '',
            priceEnd: '',
            priceType: 'msrp',
        });
        setShouldPushHistory(true);
    }

    return user.loggedIn ? (
        <PriceRangeOuterDiv>
            <PriceRangeTitle>
                <Translate id={t('title')} />
                <Button size={12} onClick={cancelPriceValues} color={colors.gray.normal}>
                    <Translate id={t('clear')} />
                    &nbsp;
                    <FontAwesomeIcon icon={faChevronRight} />
                </Button>
            </PriceRangeTitle>

            <Inputs setPrice={setPrice} price={price} />
            <RadioButtonWrapper>
                <RadioButtonGroup>
                    <TypeLabel left={false}>
                        <input
                            type="radio"
                            name="price-type"
                            value="price"
                            onChange={() => setPrice((prevPrice) => ({
                                ...prevPrice,
                                priceType: 'price',
                            }))}
                            checked={price.priceType === 'price'}
                        />
                        &nbsp;
                        <Translate id={t('cost')} />
                    </TypeLabel>

                    <TypeLabel left={true}>
                        <input
                            type="radio"
                            name="price-type"
                            value="msrp"
                            onChange={() => setPrice((prevPrice) => ({
                                ...prevPrice,
                                priceType: 'msrp',
                            }))}
                            checked={price.priceType === 'msrp'}
                        />
                        &nbsp;
                        <Translate id={t('msrp')} />
                    </TypeLabel>
                </RadioButtonGroup>

                <Button size={12} onClick={() => setShouldPushHistory(true)}>
                    <Translate id={t('filter')} />
                    &nbsp;
                    <FontAwesomeIcon icon={faChevronRight} />
                </Button>
            </RadioButtonWrapper>
            <PriceButtonWrapper>
                {priceRanges.map(({ start, end, label }) => (
                    <PriceButton
                        key={label}
                        onClick={() => handlePriceButtonClick(start, end)}
                    >
                        {label}
                    </PriceButton>
                ))}
            </PriceButtonWrapper>
        </PriceRangeOuterDiv>
    ) : null;
};

export default withLocalize(PriceRange);
