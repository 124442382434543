import React, { useContext } from 'react';
import UIMain from './../../interface/UIMain';
import { withLocalize } from 'react-localize-redux';
import { ResizeAwareContext } from '../../../smart/ResizeContext';
import Form from './Form';

const ContactBriefPage = ({ translate }) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);
  document.title = translate('services.gifting.title');

  return (
    <UIMain isMobile={isMobile} isTablet={isTablet}>
      <Form isMobile={isMobile} isTablet={isTablet}/>
    </UIMain>
  );
};

export default withLocalize(ContactBriefPage);
