export const objectEquivalent = (objA, objB) => {
  var aProps = Object.getOwnPropertyNames(objA);
  var bProps = Object.getOwnPropertyNames(objB);

  if (aProps.length != bProps.length) return false;

  for (const prop of aProps) {
    if (objA[prop] !== objB[prop]) return false;
  }

  return true;
};
