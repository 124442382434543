import * as types from '../actions/types'
import { newsEntries } from './initialState'

export default function newsEntriesReducer(state = newsEntries, actions) {
    switch (actions.type) {
        case types.LOAD_NEWS_SUCCESS:{
            return {...state, news: actions.news}
        }
        default: 
            return state
    }
}