import * as types from '../actions/types'
import { cart } from './initialState'

export default function cartReducer(state = cart, actions) {
    switch (actions.type) {
        case types.LOAD_CART_SUCCESS:
            return actions.cart
        default:
            return state
    }
}
