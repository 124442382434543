import * as types from './types';
import { resetSelectedCategory } from '../actions/selectedCategoryActions';

export const modifyFiltersAction = filters => ({
  type: types.MODIFY_FILTERS,
  filters,
});

export const setFiltersSelectedCategory = filters => ({
  type: types.SET_FILTERS_SELECTED_CATEGORY,
  filters,
});

export const resetFilters = () => ({
  type: types.RESET_FILTERS,
});

export const resetPageFilters = () => ({
  type: types.RESET_PAGE_FILTERS,
});

export const setFiltersSelectedFeatureValues = filters => ({
  type: types.SET_SELECTED_PRODUCT_FEATURE_VALUES,
  filters,
});

// use reset to make modifications to initial state rather than current
export const modifyFilters = (filters, reset = false) => {
  return dispatch => {
    if (reset) {
      dispatch(resetFilters());
      dispatch(resetSelectedCategory());
    }
    dispatch(modifyFiltersAction(filters));
  };
};

export const removeFilter = filter => ({
  type: types.REMOVE_FILTER,
  filter,
});
