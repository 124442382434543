import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { lsFormatNum as formatNum } from '../../../../tools/helpers/lsFormatNum';
import qs from 'querystring';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { fonts, colors, spacing } from '../../../../defaults/styles';
import { withLocalize } from 'react-localize-redux';

const ColumnWrapper = styled.header`
  display: block;
  align-items: flex-end;
`;

const H2 = styled.h2`
  font-size: ${fonts.sizes.title}px;
  ${'' /* the next minus margin is a temporary solution for h2 alignment due to the font family */}
  margin-bottom: 0.2em;
  padding-top: 0.2em;
  line-height: 1.2em;
`;

const CategorySubtitle = styled.span`
  color: ${colors.gray.normal};
  flex: 1 1 0;
  margin-top: ${spacing.small}px;
  margin-left: 0;
  display: block;
`;

const SubTitleWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: end;
`;

const InputContainer = styled.div`
  border: 1px solid;
  position: relative;
  overflow: hidden;
  width: ${props => props.isMobile ? '7em' : '15em'};
  z-index: 10;
  width: 100%;
  cursor: text;

  border-color: ${props => props.typing ? colors.main.normal : colors.gray.light };
  ${props => props.typing ? `box-shadow: 0 0 0 1px ${colors.main.normal}` : '' };
`;

const Input = styled.input`
  width: 100%;
  font-size: ${fonts.sizes.medium}px;
  line-height: 1;
  padding: 0;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${colors.gray.normal};
  }
  :-ms-input-placeholder {
    color: ${colors.gray.normal};
  }
  ::placeholder {
    color: ${colors.gray.normal};
  }

  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
`;

const styles = {
  option: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    flexDirection: 'row-reverse',
    marginLeft: spacing.medium,
    marginTop: spacing.verySmall
  },
  optionLabel: {
    whiteSpace: 'nowrap',
    fontSize: fonts.sizes.text,
  },
  select: {
    backgroundColor: colors.gray.light,
    fontSize: fonts.sizes.text,
  },
};

const stylesMobile = {
  option: {
    ...styles.option,
    flexDirection: 'initial',
    marginLeft: 0,
  },
  optionLabel: {},
  select: {},
};

const FilterWrapper = styled.section`
    display: flex;
    flex: 0 0 auto;
    ${props => (props.isMobile ? 'flex-direction: column;' : '')}
    margin-bottom: ${props =>
      (props.isMobile ? spacing.medium : '')}px;
    ${props => (!props.isMobile ? 'margin-left: auto;' : '')}
`;

export const OrdersPageHeader = withLocalize(({
  totalElements,
  currentQuery,
  history,
  lang,
  isMobile,
  translate
}) => {
  const t = (tag) => `orders.${tag}`;

  const { completed, q } = currentQuery;

  const [searchQ, setSearchQ] = useState(q);
  const [typing, setTyping] = useState(false);
  const sortingOptions = getSortingOptions(completed === 'true');
  const search = value => {
    history.push({
      pathname: `/${lang}/orders`,
      search: `?${qs.stringify({
        ...currentQuery,
        q: value
      })}`
    });
  };

  const changeAction = changedValue => {
    history.push({
      pathname: history.location.pathname,
      search: '?' + qs.stringify({ ...currentQuery, ...changedValue }),
    });
  };

  const totalCount = formatNum(totalElements, lang);
  const title = completed === 'false' 
    ? translate(t('title.open'))
    : translate(t('title.completed'));

  const pageOptions = [15, 30, 50, 100];

  return (
    <>
      <div className="row" style={isMobile ? {} : { marginBottom: spacing.large }}>
        <div className="col-lg-6" style={isMobile ? { marginBottom: spacing.large } : {}}>
          <ColumnWrapper>
            <H2>{title}</H2>
            {totalCount ? (
              <SubTitleWrapper>
                <CategorySubtitle>
                  {totalCount} {totalElements > 1 
                    ? translate(t('count.postfix_other')) 
                    : translate(t('count.postfix_1'))}
                </CategorySubtitle>
              </SubTitleWrapper>
            ) : null}
          </ColumnWrapper>
        </div>

        <div className={`col-lg-6`} style={{ display: 'flex', alignItems: 'flex-end' }}>
          <FilterWrapper isMobile={isMobile}>
            <ColumnWrapper>
              {/* Page size options */}
              <div style={isMobile ? stylesMobile.option : styles.option}>
                <strong style={styles.optionLabel}>
                  {translate(t('page_size_label'))}
                  <select
                    style={styles.select}
                    onChange={({ target: { value } }) => changeAction({ size: value })}
                    value={currentQuery.size}
                  >
                    {pageOptions.map((numPages, index) => (
                      <option key={index} value={numPages}>
                        {`${numPages} ${translate(t('filters.per_page'))}`}
                      </option>
                    ))}
                  </select>
                </strong>
              </div>

              {/* Sorting */}
              <div style={isMobile ? stylesMobile.option : styles.option}>
                <strong style={styles.optionLabel}>
                  {translate(t('sort_label'))}
                  <select
                    style={styles.select}
                    onChange={({ target: { value } }) => changeAction({ sort: value })}
                    value={currentQuery.sort}
                  >
                    {sortingOptions.map(({ value, lexicon }, index) => (
                      <option key={index} value={value}>
                        {translate(t(`filters.${lexicon}`))}
                      </option>
                    ))}
                  </select>
                </strong>
              </div>
            </ColumnWrapper>
          </FilterWrapper>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-12">
          <label id="order-search-bar" style={{ position: 'relative' }}>
            <InputContainer isMobile={isMobile} typing={typing}>
              <FontAwesomeIcon
                icon={faSearch}
                style={{
                  opacity: 0.25,
                  position: 'absolute',
                  left: '12px',
                  top: '50%',
                  transform: 'translateY(-50%)',
                  zIndex: 10,
                }}
              />

              <Input
                type="search"
                placeholder={translate(t(`search_title.${completed ? 'completed' : 'open'}`))}
                onFocus={() => { if (!typing) { setTyping(true); } }}
                onBlur={({ target: { value } }) => {
                  setTyping(false);
                  if (value.length > 0 && value !== q) search(value);
                }}
                onKeyDown={({ key, target: { value } }) => { if (key === 'Enter') search(value); }}
                onChange={({ target: { value } }) => setSearchQ(value)}
                value={searchQ}
                isMobile={isMobile}
              />
            </InputContainer>
          </label>
        </div>
      </div>
    </>
  );
});

const getSortingOptions = completed => {
  const baseOptions = [
    { value: 'creationDate,desc', lexicon: 'creation_date_desc' },
    { value: 'creationDate,asc', lexicon: 'creation_date_asc' },
    { value: 'shippedDate,desc', lexicon: 'shipped_date_desc' },
    { value: 'shippedDate,asc', lexicon: 'shipped_date_asc' },
    { value: 'poNumber,desc', lexicon: 'order_number_desc' },
    { value: 'poNumber,asc', lexicon: 'order_number_asc' },
  ];

  if (completed) {
    return [
      ...baseOptions,
      { value: 'invoicedDate,desc', lexicon: 'invoiced_date_desc' },
      { value: 'invoicedDate,asc', lexicon: 'invoiced_date_asc' },
      { value: 'cancelledDate,desc', lexicon: 'canceled_date_desc' },
      { value: 'cancelledDate,asc', lexicon: 'canceled_date_asc' },
    ];
  }

  return baseOptions;
};