import React, {useEffect, useCallback} from 'react';
import styled from 'styled-components';
import { withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import { colors, spacing } from './../../../../../defaults/styles';

// == STYLING == //
const LangSwitcher = styled.div`
  color: ${colors.white.normal};
`;

const LangItem = styled.span`
  cursor: ${props => (props.selected ? 'default' : 'pointer')};
  display: inline-block;
  font-weight: ${props => (!props.selected ? 'inherit' : 'bold')};

  transition: color ease-in-out 0.1s;

  &:hover {
    color: ${props =>
      props.selected ? colors.white.normal : colors.primary.normal};
  }

`;
// == /STYLING == //

const LanguageSelector = ({
  activeLanguage,
  setActiveLanguage,
  history,
  location,
  isMobile,
}) => {

  const switchLanguage = useCallback((lang) => {
    localStorage.setItem('locale', lang);
    setActiveLanguage(lang);
    history.replace(getLangUrl(location.pathname, lang, location.search));
  }, [setActiveLanguage, history, location.pathname, location.search]);

  useEffect(() => {
    const langFromURL = location.pathname.split('/')[1];
    if (!activeLanguage && langFromURL && ['en', 'fr'].indexOf(langFromURL) >= 0) {
      switchLanguage(langFromURL);
    }
  }, [activeLanguage, location.pathname, switchLanguage]);
  
  return (
  <LangSwitcher>
    <LangItem 
      isMobile={isMobile}
      selected={activeLanguage && activeLanguage.code === 'en'}
      onClick={() => { switchLanguage('en'); }}
    >
      EN
    </LangItem>
    ｜
    <LangItem
      selected={activeLanguage && activeLanguage.code === 'fr'}
      onClick={() => { switchLanguage('fr'); }}
    >
      FR
    </LangItem>
  </LangSwitcher>);
}

const getLangUrl = (url, lang, query) => {
  const pathArr = url.split('/');
  pathArr[1] = lang;
  return pathArr.join('/') + query;
};
export default withRouter(withLocalize(LanguageSelector));
