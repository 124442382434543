import React, { useState, useEffect, useRef } from 'react';
import { withLocalize } from 'react-localize-redux';
import Input from '../../../interface/Input';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';
import { CartSectionCancelButton, CartSectionUpdateButton, CartSectionErrorMessage } from '../CartSummary';
import AnimationBox from './../AnimationBox';

const SectionTitle = styled.h2 `
    margin-bottom: 1.25rem;
`;

const CartSectionInfoWrapper = styled.div`
    ${props => !props.isMobile 
        ? 
            `display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 0.25rem 1rem;`
        : ''
    }
`;

const CartSectionButtonSection = styled.div`
    padding-top: 0.5rem;
    display: flex;
    flex-direction: row-reverse;
`;

const CartSectionRow = styled.div`
    margin-bottom: 0.75rem;
`;

const CartSectionLabel = styled.span`
    font-weight: 700;
`;

const CartSectionButtonLabel = styled.span `
    font-size: 1.2em;
`;

const clearError ={            
    // submitter: false,
    submitterPhoneNumber: false,
    productOrderNumber: false,
    // submitterEmail: false
}

const CartSectionInfo = ({ activeLanguage, translate, cart, updateCartInfo, isMobile }) => {
    // const [ disabled, setDisabled ] = useState(cart.submitter && cart.submitterPhoneNumber && cart.submitterEmail && cart.productOrderNumber);
    const [ disabled, setDisabled ] = useState(cart.productOrderNumber);
    const [ info, setInfo ] = useState({});
    const [ collapse, setCollapse ] = useState(true)
    const [ error, setError ] = useState(clearError);

    // const enableCancelButton = cart.submitter && cart.submitterPhoneNumber && cart.submitterEmail && cart.productOrderNumber;
    const enableCancelButton = cart.productOrderNumber;
    useEffect(() => {
        if(!productOrderNumber){
            const { submitterPhoneNumber, notes, productOrderNumber } = cart;
            setInfo({ submitterPhoneNumber, notes, productOrderNumber });
        }
    }, [ cart ]);

    const { submitterPhoneNumber, productOrderNumber, notes } = info;

    const validateEmail = (email) => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regex.test(String(email).toLowerCase());
    }

    const updateInfo = () => {
        //validate all fields of the form
        let validateResult = {
            // submitter: !submitter,
            // submitterPhoneNumber: !submitterPhoneNumber,
            productOrderNumber: !productOrderNumber,
            // submitterEmail: !validateEmail(submitterEmail)
        };

        if (Object.values(validateResult).every(i => i === false)) {
            const cartToUpdate = {
                ...cart,
                ...info
            };
    
            updateCartInfo(cartToUpdate, activeLanguage.code);
            setDisabled(true);
            setError({
                // submitter: false,
                // submitterPhoneNumber: false,
                productOrderNumber: false,
                // submitterEmail: false
            });
        } else {
            setError(validateResult);
        }
    }

    const clickCancelButton = () => {
        setInfo({
            productOrderNumber: cart.productOrderNumber,
            // submitter: cart.submitter,
            submitterPhoneNumber: cart.submitterPhoneNumber,
            notes: notes
            // submitterEmail: cart.submitterEmail
        });
        setDisabled(true);
        setCollapse(true);
        setError(clearError);
    };

    // for dynamic height
    const [targetHeight, setTargetHeight] = useState(0);
    const targetRef = useRef(null);
    useEffect(() => {
        if(targetRef.current) {
            const timer = setTimeout(()=>{
                setTargetHeight(targetRef.current.clientHeight);
            }, 200);
            return () => clearTimeout(timer);
        }
    });
    
    return (
        <>
            <SectionTitle>
                {translate('checkout.info.title')}
            </SectionTitle>
            <AnimationBox collapse={collapse} infoSection={true} isMobile={isMobile} targetHeight={targetHeight}>
            <div ref={targetRef}>
            {!disabled 
            ? <CartSectionInfoWrapper isMobile={isMobile}> 
                <Input
                    label={translate('checkout.info.po')}
                    value={productOrderNumber ? productOrderNumber : ''}
                    onChange={(e) => setInfo({ ...info, productOrderNumber: e.target.value })}
                    required={true}
                    error={error.productOrderNumber}
                    disabled={disabled}
                />
                {/* <Input
                    label={translate('checkout.info.submitBy')}
                    value={submitter ? submitter : ''}
                    onChange={(e) => setInfo({ ...info, submitter: e.target.value })}
                    required={true}
                    error={error.submitter}
                    disabled={disabled}
                /> */}
                <Input
                    area
                    value={notes}
                    label={translate('checkout.notes.label')}
                    required={false}
                    onChange={(e) => setInfo({ ...info, notes: e.target.value })}
                    error={error.notes}
                    disabled={disabled}
                />
                <Input
                    label={translate('checkout.info.phoneInput')}
                    value={submitterPhoneNumber ? submitterPhoneNumber : ''}
                    onChange={(e) => setInfo({ ...info, submitterPhoneNumber: e.target.value })}
                    required={false}
                    error={error.submitterPhoneNumber}
                    disabled={disabled}
                />
                {/* <Input
                    label={translate('checkout.info.email')}
                    value={submitterEmail ? submitterEmail : ''}
                    onChange={(e) => setInfo({ ...info, submitterEmail: e.target.value })}
                    required={true}
                    error={error.submitterEmail}
                    disabled={disabled}
                /> */}
                {Object.values(error).some(i => i === true) &&
                    <CartSectionErrorMessage>{translate('labels.form.required.error')}</CartSectionErrorMessage>
                }
            </CartSectionInfoWrapper>
            :
            <>
                <CartSectionRow>
                    <CartSectionLabel>{translate('checkout.info.po')}: </CartSectionLabel>
                    {productOrderNumber}
                </CartSectionRow>
                {/* <CartSectionRow>
                    <CartSectionLabel>{translate('checkout.info.submitBy')}: </CartSectionLabel>
                    {submitter}
                </CartSectionRow> */}
                <CartSectionRow>
                    <CartSectionLabel>{translate('checkout.info.phone')}: </CartSectionLabel>
                    {submitterPhoneNumber}
                </CartSectionRow>
                {/* <CartSectionRow>
                    <CartSectionLabel>{translate('checkout.info.email')}: </CartSectionLabel>
                    {submitterEmail}
                </CartSectionRow> */}
                <CartSectionRow>
                    <CartSectionLabel>{translate('checkout.notes.title')}: </CartSectionLabel>
                    {notes}
                </CartSectionRow>
            </>
            }
            {!disabled 
            ? 
                <CartSectionButtonSection>
                    {enableCancelButton && 
                        <CartSectionCancelButton onClick={clickCancelButton}>
                            <CartSectionButtonLabel>
                                {translate('labels.cancel')}
                            </CartSectionButtonLabel>
                        </CartSectionCancelButton>
                    }
                    <CartSectionUpdateButton onClick={() => {updateInfo(); setCollapse(true);}}>
                        <CartSectionButtonLabel>
                        {translate('labels.update')}
                        </CartSectionButtonLabel>
                    </CartSectionUpdateButton>
                </CartSectionButtonSection>
            :
                <CartSectionButtonSection>
                    <CartSectionUpdateButton onClick={() => {setDisabled(false); setCollapse(false);}}>
                        <CartSectionButtonLabel>
                        {translate('labels.edit')}
                        </CartSectionButtonLabel>
                    </CartSectionUpdateButton>
                </CartSectionButtonSection>
            }
            </div>
            </AnimationBox>
        </>
    )
}

export default withLocalize(CartSectionInfo);