import React, { useState } from 'react';
import styled from 'styled-components';

import NavLink from './NavLink';
import SubNavs from './SubNavs';
import SubNewsNavs from './SubNewsNavs';
import SubProductNavs from './SubProductNavs';
import ProductsPopupButton from './ProductsPopupButton';
import { UIContainer } from './../../../../../ui/views';
import { Translate, withLocalize } from 'react-localize-redux';
import content from '../../content';

import {
  colors,
  spacing,
  sizing,
} from './../../../../../../../defaults/styles';

// == Styles == //
const NavMain = styled.nav`
  background-color: ${colors.gray.light};
  display: flex;
  flex: 0 0 auto;
  height: ${sizing.header.btm}px;
`;

// additional styling in UIContainer
const moreStyle = {
  display: 'grid',
  gridTemplateColumns: 'repeat(6, 1fr)',
  gridGap: '1em',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
};

const NavLinkContainer = styled.div`
  position: relative;
`;

const SubNavLinkContainer = styled.div`
  position: absolute;
  top: 2.5em;
  left: 25%;
  z-index: 11;
  width: 100%;

  background-color: ${colors.black.normal};
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  padding-top: ${spacing.medium}px;
  padding-bottom: ${spacing.verySmall}px;
  text-align: left;
  transition: opacity 0.1s ease-in-out, transform 0.2s ease-in-out;
  /* when hovered */
  opacity: ${props => (props.isHovered ? 1 : 0)};
  pointer-events: ${props => (props.isHovered ? 'auto' : 'none')};
  transform: ${props =>
    props.isHovered ? 'translateY(0)' : 'translateY(2px)'};

  &::after {
    background-color: ${colors.black.normal};
    content: ' ';
    display: block;
    height: 10px;
    width: 10px;
    position: absolute;
    top: -2px;
    left: 10px;
    transform: rotate(45deg);
  }
`;

// == /Styles == //

const Nav = ({
  productsPopupOpen,
  openProductsPopupClickHandler,
  setSelectedCategory,
  ...props
}) => {
  const t = (tag) => `header.menu.${tag}`;
  
  const [hoverEffect, setHoverEffect] = useState(false);
  const [hoverEffect2, setHoverEffect2] = useState(false);
  const [hoverEffect3, setHoverEffect3] = useState(false);
  const linkToCatalog = (lexiconCode) => {
    if (lexiconCode === 'product') {
      setSelectedCategory({ topId: 0, catId: 0, typeId: 0 }, true);
    }
  }

  return (
    <NavMain>
      <UIContainer style={moreStyle} noPadding>
        {content.links.map(({ url, lexiconCode }, index) =>
          <React.Fragment key={'nav' + index}>
            {lexiconCode === 'services'
              ? (
              <NavLinkContainer
                onMouseEnter={() => setHoverEffect(true)}
                onMouseLeave={() => setHoverEffect(false)}
              >
                <NavLink
                  url={url}
                  text={<Translate id={t(lexiconCode)} />}
                />
                <SubNavLinkContainer isHovered={hoverEffect}>
                  <SubNavs />
                </SubNavLinkContainer>
              </NavLinkContainer>
              )
              : lexiconCode === 'news' 
                ? (
                  <NavLinkContainer
                  onMouseEnter={() => setHoverEffect2(true)}
                  onMouseLeave={() => setHoverEffect2(false)}
                  >
                  <NavLink
                    url={url}
                    text={<Translate id={t(lexiconCode)} />}
                  />
                  <SubNavLinkContainer isHovered={hoverEffect2}>
                    <SubNewsNavs />
                  </SubNavLinkContainer>
                </NavLinkContainer>
                )
                : lexiconCode === 'product'
                  ? (
                    <NavLinkContainer
                      onMouseEnter={() => setHoverEffect3(true)}
                      onMouseLeave={() => setHoverEffect3(false)}
                    >
                      <NavLink
                        url={url}
                        text={<Translate id={t(lexiconCode)} />}
                      />
                      <SubNavLinkContainer isHovered={hoverEffect3}>
                        <SubProductNavs />
                      </SubNavLinkContainer>
                    </NavLinkContainer>
                  )
                  :
                  (
                  <NavLink
                    url={url}
                    text={ <Translate id={t(lexiconCode)} />}
                    onClick={() => linkToCatalog(lexiconCode)}
                  />
                ) 
            }
          </React.Fragment>
        )}
        {/* <ProductsPopupButton
          productsPopupOpen={productsPopupOpen}
          openProductsPopupClickHandler={openProductsPopupClickHandler}
        /> */}
      </UIContainer>
    </NavMain>
  );
};

export default withLocalize(Nav);
