import React from 'react';

import SubNavLink from './SubNavLink';
import { Translate, withLocalize } from 'react-localize-redux';
import content from '../../content';

import { colors } from '../../../../../../../defaults/styles';

const SubNewsNavs = () => {
  const t = (tag) => `header.menu.${tag}`;

  return (
    <>
      {content.subNewsLinks.map(({ url, lexiconCode }, index) => (
        <SubNavLink
          url={url}
          key={'sub-nav-' + index}
          text={<Translate id={t(lexiconCode)} />}
          style={{ color: colors.white.normal }}
        />
      ))}
    </>
  );
};

export default withLocalize(SubNewsNavs);