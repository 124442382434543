import * as types from './types'
import { news } from '../../tools/api'
import { beginApiCall, apiCallError } from './apiStatusActions'

export const loadNewsSuccess = news => {
    return { type: types.LOAD_NEWS_SUCCESS, news}
}

export const loadNewsEntries = () => {
    return dispatch => {
        dispatch(beginApiCall());
        return news
        .getAllNews()
        .then(({ json: news }) => {
            dispatch(loadNewsSuccess(news))
        })
        .catch(error => {
            dispatch(apiCallError(error))
            throw error
        })
    }
}