import React from 'react';
import { Link } from 'react-router-dom';

const Logo = () => (
  <Link to={'/'} style={{ textAlign: "center" }}>
    <img
      style={{
        display: 'block',
      }}
      src={'/images/logo.svg'}
      alt="Logo of Loyalty Source"
    />
  </Link>
);

export default Logo;
