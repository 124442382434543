import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import { colors, spacing, fonts } from '../../../../../../defaults/styles';
import { lsStyled as styled } from '../../../../../../tools/helpers/lsStyled';
import Product from '../../../../layout/ProductsList/List/Product';

const ListItem = styled.li`
  position: relative;

  /* logo image gets out of the position due to the other product page strucutre.
  In order to solve the issue, added css to make it block here for direct child - <a> tag */
  & > a {
    display: block;
  }
`;

const RecommendedSection = styled.section`
  border-top: 1px solid ${colors.gray.light};
  padding-top: ${spacing.medium}px;
  margin-top: ${spacing.small}px;
  ${props => props.isMobile ? 'text-align: center' : ''};
`;

const RecommendedHeader = styled.header`
  margin-bottom: ${spacing.medium}px;
  text-align: left;
`;

const RecommendedHeaderLabel = styled.h3`
  font-family: ${fonts.title};
`;

const RecommendedProductList = styled.ul`
  list-style: none;
  margin-bottom: ${spacing.medium}px;
  display: ${props => props.isMobile ? 'inline-block' : 'grid'};
  grid-template-columns: repeat(auto-fill, 220px);
  grid-gap: ${spacing.small}px;
  justify-content: space-between;
  align-items: start;
`;

const RecommendedProducts = ({ products, isMobile, shoppingLists, cart,
  showPopup,
  setShowPopup,
  editUrl,
  viewUrl,
  zoomStudioUserToken,
  zoomStudioItToken,
  errorMessage,
  setErrorMessage,
  appendErrorMessage,
  setEditUrl,
  setViewUrl
 }) => {
  const t = (tag) => `product.${tag}`;
  return (
    <RecommendedSection isMobile={isMobile}>
      <RecommendedHeader>
        <RecommendedHeaderLabel>
          <Translate id={t('recommended')} />
        </RecommendedHeaderLabel>
      </RecommendedHeader>
      <RecommendedProductList isMobile={isMobile}>
        {products.map(product => (
          <ListItem key={product.sku}>
            <Product 
              product={product}
              isMobile={isMobile}
              showPopup={showPopup}
              setShowPopup={setShowPopup}
              editUrl={editUrl}
              viewUrl={viewUrl}
              setEditUrl={setEditUrl}
              setViewUrl={setViewUrl}
              zoomStudioUserToken={zoomStudioUserToken}
              zoomStudioItToken={zoomStudioItToken}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              appendErrorMessage={appendErrorMessage}
            />
          </ListItem>
        ))}
      </RecommendedProductList>
    </RecommendedSection>
  );
};

export default withLocalize(RecommendedProducts);
