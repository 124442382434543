import React from 'react';
import { spacing, fonts, colors } from '../../../../../../defaults/styles';

const DescriptionList = ({ title, items }) => (
  <section
    style={{
      borderTop: `1px solid ${colors.gray.light}`,
      marginTop: spacing.small,
      marginBottom: spacing.large,
      paddingTop: spacing.medium,
    }}
  >
    <h3
      style={{
        fontSize: fonts.sizes.largeText,
        fontFamily: fonts.title,
        color: colors.gray.dark,
        marginBottom: spacing.medium,
      }}
    >
      {title}
    </h3>
    <div>
      {items.map((item, index) => (
        <div
          key={index}
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: spacing.small,
            backgroundColor: index % 2 === 0 ? colors.white : colors.gray.light,
          }}
        >
          <div style={{ flex: 1 }}>{item.name}</div>
          <div style={{ marginLeft: spacing.small }}>{item.price}</div>
        </div>
      ))}
    </div>
  </section>
);

export default DescriptionList;
