import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { Brand } from './Brand';

const BoxHeight = 200;
const BrandBox = styled.div`
  width: 100%;
  height: ${BoxHeight}px;
  position: relative;
`;

export const BrandSlideShow = ({ isMobile, isTablet, list, activeLanguage, history }) => {
  var numberOfSlides = isMobile ? 1 : isTablet ? 3 : 4;
  const lang = activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';
  // Turns the original brand list, which is an array of alphabetical arrays (all the A's, then all the B's, etc), into one array
  const flatList = list.flatMap(item => item[1]);
  const filteredList = flatList.filter(item => item.feature_on_cg_page);

const goToListing = (e) => {
  history.push({
      pathname: `/${lang}/catalog`,
      search:
          (e ? `&brand=${e.id}` : ''),
  });
};
 
  if (list && list.length > 0) {
    return (
      <BrandBox>
        <Carousel
          centerMode
          centerSlidePercentage={100 / numberOfSlides}
          infiniteLoop
          autoPlay
          showThumbs={false}
          showArrows={true}
          stopOnHover={false}
          transitionTime={1000}
          showStatus={false}
          onClickItem = {event => goToListing(filteredList[event])}
        >
          {filteredList.map(item => (
            <div key={item.id} style={{ background: "white" }}>
              <Brand 
               isMobile={isMobile}
               isTablet={isTablet}
               content={item}
               />
            </div>
          ))}
        </Carousel>
      </BrandBox>
    );
  } else {
    return <></>;
  }
};
