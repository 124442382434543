  // Sanitizes description and converts html entity names for (among them french) special characters to actual characters
export const fixHtmlEncodings = (string) => {

    string = string.replace(/&amp;/mg, '&');
    string = string.replace(/&quot;/mg, '"');
    string = string.replace(/&quote;/mg, '"');
    string = string.replace(/&#95;/mg, '_');
    string = string.replace(/&ndash;/mg, '-');
    string = string.replace(/&mdash;/mg, '—');
    string = string.replace(/&#39;/mg, "'");
    string = string.replace(/&rsquo;/mg, "‘");
    string = string.replace(/&lsquo;/mg, "‘");
    string = string.replace(/&#34;/mg, '"');
    string = string.replace(/&ldquo;/mg, '“');
    string = string.replace(/&rdquo;/mg, '”');
    string = string.replace(/&#62;/mg, '>');
    string = string.replace(/&#60;/mg, '<');
    string = string.replace(/&nbsp;/mg, ' ');
    string = string.replace(/&eacute;/mg, 'é');
    string = string.replace(/&Eacute;/mg, 'É');
    string = string.replace(/&laquo;/mg, '«');
    string = string.replace(/&raquo;/mg, '»');
    string = string.replace(/&egrave;/mg, 'è');
    string = string.replace(/&Egrave;/mg, 'È');
    string = string.replace(/&ecirc;/mg, 'ê');
    string = string.replace(/&Ecirc;/mg, 'Ê');
    string = string.replace(/&euml;/mg, 'ë');
    string = string.replace(/&Euml;/mg, 'Ë');
    string = string.replace(/&icirc;/mg, 'î');
    string = string.replace(/&Icirc;/mg, 'Î');
    string = string.replace(/&iuml;/mg, 'ï');
    string = string.replace(/&Iuml;/mg, 'Ï');
    string = string.replace(/&ccedil;/mg, 'ç');
    string = string.replace(/&Ccedil;/mg, 'Ç');
    string = string.replace(/&acirc;/mg, 'â');
    string = string.replace(/&Acirc;/mg, 'Â');
    string = string.replace(/&agrave;/mg, 'à');
    string = string.replace(/&Agrave;/mg, 'À');
    string = string.replace(/&ocirc;/mg, 'ô');
    string = string.replace(/&Ocirc;/mg, 'Ô');
    string = string.replace(/&ucirc;/mg, 'û');
    string = string.replace(/&Ucirc;/mg, 'Û');
    string = string.replace(/&ugrave;/mg, 'ù');
    string = string.replace(/&Ugrave;/mg, 'Ù');
    string = string.replace(/&uuml;/mg, 'ü');
    string = string.replace(/&Uuml;/mg, 'Ü');
    string = string.replace(/&yuml;/mg, 'ÿ');
    string = string.replace(/&Yuml;/mg, 'Ÿ');
    string = string.replace(/&aelig;/mg, 'æ');
    string = string.replace(/&AElig;/mg, 'Æ');
    string = string.replace(/&oelig;/mg, 'œ');
    string = string.replace(/&OElig;/mg, 'Œ');
    string = string.replace(/&auml;/mg, 'ä');
    string = string.replace(/&Auml;/mg, 'Ä');
    string = string.replace(/&ouml;/mg, 'ö');
    string = string.replace(/&Ouml;/mg, 'Ö');

    return string;
  }

  export const convert = (description) => {
    return description ? typeof(description) === 'string' 
    ? description.replace(/<[^>]+>/g, '') 
    : description.toString().replace(/<[^>]+>/g, '') 
      : 'To Be Continued';
  }