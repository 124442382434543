import { combineReducers } from 'redux';
import apiCallsInProgress from './apiStatusReducer';
import brands from './brandReducer';
import categories from './categoryReducer';
import features from './featureReducer';
import products from './catalogReducer';
import catalog from './catalogReducer2';
import featureProducts from './featureProductsReducer';
import product from './productReducer';
import user from './userReducer';
import userInfo from './userInfoReducer';
import userParentInfo from './userParentInfoReducer';
import reportList from './reportListReducer';
import orders from './orderReducer';
import selectedCategory from './selectedCategoryReducer';
import filters from './filtersReducer';
import selectedBrand from './selectedBrandReducer';
import previousCategoryFilters from './previousCategoryFiltersReducer';
import catalogRequestTime from './catalogRequestTimeReducer';
import previousFilters from './previousFiltersReducer';
import listing from './listingReducer';
import cart from './cartReducer';
import blogPosts from './blogReducer';
import newsEntries from './newsReducer';
import landingPageBanners from './landingPageReducer'
import shoppingLists, {shoppingListsDownloadReducer as downloadedShoppingList} from './shoppingListsReducer';
import catalogForCorporateGifts from './catalogForCorporateGiftsReducer';
import corporateGiftsFeatures from './corporateGiftsFeatureReducer';
import vendorAgreement from './vendorAgreementReducer';

const rootReducer = combineReducers({
  brands,
  categories,
  features,
  apiCallsInProgress,
  products,
  product,
  user,
  reportList,
  orders,
  selectedCategory,
  filters,
  selectedBrand,
  previousCategoryFilters,
  catalogRequestTime,
  previousFilters,
  featureProducts,
  userInfo,
  userParentInfo,
  catalog,
  listing,
  cart,
  shoppingLists,
  downloadedShoppingList,
  blogPosts,
  newsEntries,
  landingPageBanners,
  catalogForCorporateGifts,
  corporateGiftsFeatures,
  vendorAgreement,
});

export default rootReducer;
