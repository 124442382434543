import React, { useEffect, useContext } from 'react'
import { withLocalize } from "react-localize-redux";
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { useParams } from 'react-router';
import { findBlogById } from '../../../../redux/actions/blogActions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { colors } from '../../../../defaults/styles';
import { ResizeAwareContext } from '../../../smart/ResizeContext';

/**
 * This is the individual blog page, where users can read the blog content.
 * We pull the paramter out of the url and then send the param (id) to the server to fetch the single blog
 **/

const headerStyle = {
    margin: "auto",
    textAlign: 'center',
    paddingLeft: "20px",
    paddingRight: '20px',
}

const linkStyle = {
    textDecoration: "none",
    color: 'black',
}

const divStyle = {
    maxWidth: '1024px',
    margin: "auto",
}

export const BlogPage = ({ blogPosts, findBlogById, translate }) => {
    const { isMobile } = useContext(ResizeAwareContext);
    const blogs = blogPosts.blogs;
    useEffect(() =>  {
        findBlogById(id);

        const imgStyle = document.createElement('style');
        imgStyle.innerHTML = `img { max-width: 100%; }`;
        document.head.appendChild(imgStyle);

        const pStyle = document.createElement('style');
        pStyle.innerHTML = `p { text-align: justify; }`;
        document.head.appendChild(pStyle);
    }, []);

    useEffect(() =>  {
        if (blogs){
        //The OG attributes below are Open Graph meta tags - snippets of code that control how URLs are displayed when shared on social media.
        
        //Remove old tags in case blogs is refreshed
        document.head.querySelectorAll('[property^="og:"]').forEach(tag => tag.remove());

        const ogTitle = document.createElement('meta');
        ogTitle.setAttribute('name', 'title');
        ogTitle.setAttribute('property', 'og:title');
        ogTitle.content = blogs.blogTitle;
        document.head.appendChild(ogTitle);

        const ogDescription = document.createElement('meta');
        ogDescription.setAttribute('name', 'description');
        ogDescription.setAttribute('property', 'og:description');
        ogDescription.content = blogs.blogHeadline;
        document.head.appendChild(ogDescription);

        const ogUrl = document.createElement('meta');
        ogUrl.setAttribute('name', 'url');
        ogUrl.setAttribute('property', 'og:url');
        ogUrl.content = window.location.href; 
        document.head.appendChild(ogUrl);

        const ogImage = document.createElement('meta');
        ogImage.setAttribute('name', 'image');
        ogImage.setAttribute('property', 'og:image');
        ogImage.content = blogs.imageUrl;
        document.head.appendChild(ogImage);
        }
    }, [blogs]);

    const { id } = useParams();
    
    return (
        <div style={divStyle}>
                <Link to="/blog-list" style={linkStyle}>
                        <LinkToBlogs isMobile={isMobile}> {'< ' + translate("blogContainer.returnToBlog")}</LinkToBlogs>
                </Link>
                
                        {blogs != null ?
                        <> 
                            <h1 style={headerStyle}>{blogs.blogTitle}</h1> 
                            <h3 style={headerStyle}>{blogs.blogHeadline}</h3> 
                            <Section>
                                <BlogCard isMobile={isMobile}>
                                    <div dangerouslySetInnerHTML={{__html: blogs.blogPost}}></div>
                                </BlogCard>
                            </Section>
                        </>
                        : "..."}
        </div>
    )
}

const Section = styled.div`
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    `
const BlogCard = styled.div`
    font-size: 14pt;
    line-height: 1.5;
    width: 100%;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 40px;
`

const LinkToBlogs = styled.div`
    margin-left: 1rem;
    padding-top: ${props => props.isMobile ? '150px' : '220px'}
    padding-bottom:20px;
    color: ${props => props.primary ? 'white' : colors.gray.normal};
    cursor: pointer;
    font-size: 28px;
    font-weight: 400;
    &:hover{
        color: rgb(195,8,16);
        cursor: pointer;
    }
`
const mapStateToProps = ({ blogPosts }) => {return { blogPosts }}
const mapDispatchToProps = { findBlogById };

export default connect(mapStateToProps, mapDispatchToProps) (withLocalize(BlogPage));