import React, { useState, useEffect } from 'react';
import Button from '../../../../interface/Button';
import Inputs from './Inputs';
import { spacing, colors } from '../../../../../../defaults/styles';
import { Translate, withLocalize } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import qs from 'querystring';
import { lsStyled as styled } from '../../../../../../tools/helpers/lsStyled';

const PriceRangeOuterDiv = styled.div`
    border-top: 1px solid ${colors.gray.light};
    padding-top: ${spacing.medium}px;
    padding-left: ${spacing.verySmall}px;
    padding-right: ${spacing.verySmall}px;
    margin-top: ${spacing.large}px;
`;

const PriceRangeTitle = styled.div`
    font-weight: bold;
    margin-bottom: ${spacing.verySmall}px;
`;

const RadioButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-top: ${spacing.verySmall}px;
`;

const TypeLabel = styled.label`
    display: block; 
    ${props => (props.left ? 'margin-left: ' : 'margin-right: ') + spacing.verySmall}px;
`;

const FilterButtonWrapper = styled.div`
    margin-top: ${spacing.verySmall}px;
    text-align: right;
`;

const PriceRange = ({
    currentQuery,
    history,
    user,
    apiCallsInProgress,
}) => {
    const t = (tag) => `catalog.price.${tag}`;

    const defaultPriceType = currentQuery && currentQuery.priceType ? currentQuery.priceType : 'price';
    const defaultPriceStart = currentQuery && currentQuery.priceStart ? currentQuery.priceStart : '';
    const defaultPriceEnd = currentQuery && currentQuery.priceEnd ? currentQuery.priceEnd : ''

    // Setting up state of component
    const [price, setPrice] = useState({});
    useEffect(() => {
        setPrice({
            priceStart: defaultPriceStart,
            priceEnd: defaultPriceEnd,
            priceType: defaultPriceType
        });
    }, [ setPrice ]);

    if (apiCallsInProgress) {
        if (!defaultPriceStart && !defaultPriceEnd && price.priceStart && price.priceEnd) {
            setPrice({
                priceStart: defaultPriceStart,
                priceEnd: defaultPriceEnd,
                priceType: defaultPriceType
            });
        }
    }

    // Building query
    const loggedIn = user && user.loggedIn;

    const separatedQuery = currentQuery ? JSON.parse(JSON.stringify(currentQuery)) : {};

    delete separatedQuery.priceType;
    delete separatedQuery.priceStart;
    delete separatedQuery.priceEnd;

    // Applying filter
    const pushHistory = () => {
        if (price.priceType && (price.priceStart || price.priceEnd)) {
            if (separatedQuery) {
                history.push({
                    pathname: history.location.pathname,
                    search: '?' + qs.stringify({ 
                        ...separatedQuery, 
                        priceStart: price.priceStart, 
                        priceEnd: price.priceEnd, 
                        priceType: price.priceType 
                    })
                });
            } else {
                history.push({
                    pathname: history.location.pathname,
                    search: '?' + qs.stringify({ 
                        priceStart: price.priceStart, 
                        priceEnd: price.priceEnd, 
                        priceType: price.priceType 
                    })
                });
            }
        } else {
            history.push({
                pathname: history.location.pathname,
                search: '?' + qs.stringify(separatedQuery)
            })
        }
    }

    return loggedIn 
        ? (
        <PriceRangeOuterDiv>
            <PriceRangeTitle>
                <Translate id={t('title')} />
            </PriceRangeTitle>

            {/* min-max input boxes */}
            <Inputs 
                setPrice={setPrice} 
                price={price}
            />

            {/* Type selecting */}
            <RadioButtonWrapper>
                <div>
                    <TypeLabel left={false}>
                        <input
                            type="radio"
                            name="price-type"
                            value="price"
                            onChange={() => setPrice({
                                ...price,
                                priceType: 'price'
                            })}
                            checked={price.priceType === 'price'}
                        />
                        &nbsp;
                        <Translate id={t('cost')} />
                    </TypeLabel>
                </div>

                <div>
                    <TypeLabel left={true}>
                        <input
                            type="radio"
                            name="price-type"
                            value="msrp"
                            onChange={() => setPrice({
                                ...price,
                                priceType: 'msrp'
                            })}
                            checked={price.priceType === 'msrp'}
                        />
                        &nbsp;
                        <Translate id={t('msrp')} />
                    </TypeLabel>
                </div>
            </RadioButtonWrapper>

            {/* Filter button */}
            <FilterButtonWrapper>
                <Button size={12} onClick={() => pushHistory()}>
                    <Translate id={t('filter')} />
                    &nbsp;
                    <FontAwesomeIcon icon={faChevronRight} />
                </Button>
            </FilterButtonWrapper>
        </PriceRangeOuterDiv>
        ) 
        : null;
};

export default withLocalize(PriceRange);
