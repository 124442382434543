import * as types from '../actions/types'
import { featureProducts } from './initialState'

export default function featureProductReducer(state = featureProducts, actions) {
  switch (actions.type) {
    case types.LOAD_FEATURE_PRODUCTS_SUCCESS:
      return actions.featureProducts;
    default:
      return state;
  }
}
