import React from 'react';
import GridLoader from 'react-spinners/GridLoader';
import { colors } from '../../../../defaults/styles';
import { withRouter } from 'react-router-dom';
import UIMain from '../../interface/UIMain';
import UISection from '../../interface/UISection';
import { UIContainer } from '../../ui/views';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';

const GridLoaderWrapper = styled.div`
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: middle;
`;

const Logout = ({ onLogout, history, match }) => {
  onLogout(
    // success
    () => {
      window.location.href = '/';
    },
    // failure
    () => {
      // console.log('logout failure');
    }
  );
  return (
    <UIMain>
      <UIContainer>
        <UISection>
          <GridLoaderWrapper>
            <GridLoader
              css={{ margin: 'auto' }}
              color={colors.main.normal}
              size={12}
            />
          </GridLoaderWrapper>
        </UISection>
      </UIContainer>
    </UIMain>
  );
};

export default withRouter(Logout);
