import React, { useState, useEffect } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { spacing, colors } from '../../../../../../../defaults/styles';
import GridLoader from 'react-spinners/GridLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as fasHeart } from '@fortawesome/pro-solid-svg-icons';
import { faHeart as falHeart } from '@fortawesome/pro-light-svg-icons';
import { lsStyled as styled } from '../../../../../../../tools/helpers/lsStyled';

const AddToShoppingList = ({ 
  sku, 
  user, 
  activeLanguage,
  shoppingLists,
  addProductIdEventHandler,
  removeProductIdEventHandler,
  isMobile,
}) => {
  const t = (tag) => `product.info.${tag}`;

  const logInRequired = user && !user.loggedIn;
  const lang = activeLanguage && activeLanguage.code === 'fr' ? 'fr' : 'en';

  const [ hovered, setHovered ] = useState(false);
  const [ defaultList, setDefaultList ] = useState(null);
  const [ isInShoppingList, setIsInShoppingList ] = useState(false);


  useEffect(() => {
    if(shoppingLists) {
      const newDefaultList = shoppingLists.find(({defaultList}) => defaultList);
      setDefaultList(newDefaultList);
      setIsInShoppingList(newDefaultList.productIds && newDefaultList.productIds.includes(sku));
    }
  }, [shoppingLists]);


  const onClick = () => {
    if(shoppingLists) {
      if(isInShoppingList){
        removeProductIdEventHandler({value: defaultList.id}, sku, shoppingLists);
        setIsInShoppingList(false);
      } else {
        addProductIdEventHandler({value: defaultList.id}, sku, shoppingLists);
        setIsInShoppingList(true);
      }
    }
  }

  return logInRequired?(<></>):(
    <Button 
      onMouseEnter={()=>setHovered(true)}
      onMouseLeave={()=>setHovered(false)}
      hovered={hovered}
      isMobile={isMobile}
    >
      {!shoppingLists ? (
        <GridLoader
          css={{ margin: 'auto' }}
          color={colors.main.normal}
          size={12}
        />
      ):(
      <Translate>
          {({ translate }) => (
            <TextWrapper
              onClick={onClick}
            >
              <IconBox hovered={hovered} isInShoppingList={isInShoppingList}>
                <div className="animation">
                  <FontAwesomeIcon color={colors.primary.normal} icon={fasHeart} />
                </div>
                <FontAwesomeIcon color={colors.primary.normal} icon={falHeart} />
              </IconBox>
              {defaultList && translate(t(isInShoppingList?'removeFromShoppingList':'addToShoppingList'))}
              {defaultList && defaultList.listName}

            </TextWrapper>
          )}
        </Translate>
      )}
    </Button>
  );
};
const Button = styled.div`
  color: ${colors.primary.normal};
  cursor: pointer;
  max-width: ${props => props.isMobile ? '95%' : '250px'};
  padding-top: ${spacing.small}px;
  padding-bottom: ${spacing.small}px;
  text-decoration: underline;
  text-decoration-color: ${props => props.hovered ? 'inherit' : 'transparent'};
  transition: all 250ms ease-in-out 0s;
`;

const TextWrapper = styled.div `
  display: grid;
  font-size: 0.8rem;
  grid-template-columns: repeat(3, auto);
  align-items: center;
  justify-content: center;
  grid-gap: 0.25rem;
  
`;

const IconBox = styled.div `
  position: relative;
  height: 0.8rem;
  width: 0.8rem;

  > * {
    position: absolute;
    top: 0;
    left: 0;
  }

  .animation {
    opacity: ${props => props.hovered && props.isInShoppingList 
      ? 0 
      : props.hovered && !props.isInShoppingList
        ? 1
        : props.isInShoppingList
          ? 1
          : 0  
      };
    transition: opacity 250ms;
  }
`;
export default withLocalize(AddToShoppingList);

