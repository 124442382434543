import React, { useContext } from 'react';
import { OrdersContainer } from '../../../smart';
import { parse } from 'querystring';
import { bootstrap, sizing } from '../../../../defaults/styles';
import './orderlisting.css';

import { OrdersPagePresentation } from './OrdersPagePresentation';
import UIMain from '../../interface/UIMain';
import { ResizeAwareContext } from '../../../smart/ResizeContext';
import UISection from '../../interface/UISection';

const sectionStyle = (isMobile) => isMobile
    ? {
        maxWidth: sizing.contentWidth,
        margin: 'auto',
        display: 'flex',
        flexDirection: 'column',
    }
    : {
        maxWidth: sizing.contentWidth,
        margin: '2.5em auto',
        display: 'grid',
        gridTemplateColumns: `${sizing.contentWidth}px`,
    };

const OrdersPage = ({ history, ...props }) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);

  const filters = props.match.params;
  const { '?q': q = '' } = parse(props.location.search);
  filters.q = q;

  return (
    <UIMain isMobile={isMobile} isTablet={isTablet}>
      <UISection noPadding customStyle={sectionStyle(isMobile)}>
        {/* Using bootstrap for now */}
        <style>{bootstrap}</style> 
        <div className="has-bootstrap">
          <OrdersContainer history={history} filters={filters}>
            <OrdersPagePresentation 
              isMobile={isMobile}
              isTablet={isTablet}
              history={history}
            />
          </OrdersContainer>
        </div>
      </UISection>
    </UIMain>
  );
};

export default OrdersPage;
