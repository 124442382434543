import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loadBrands } from '../../../redux/actions/brandActions';
import { modifyFilters } from '../../../redux/actions/filtersActions';
import {
  setSelectedBrand,
  removeSelectedBrand,
} from '../../../redux/actions/selectedBrandActions';
import { setSelectedCategory } from '../../../redux/actions/selectedCategoryActions';
import { withLocalize } from 'react-localize-redux';

export const BrandsContainer = ({
  activeLanguage,
  loadBrands,
  selectedBrand,
  setSelectedBrand,
  setSelectedCategory,
  modifyFilters,
  removeSelectedBrand,
  ...props
}) => {
  const [brands, setBrands] = useState([]);
  const [previousLanguage, setPreviousLanguage] = useState();
  useEffect(() => {
    if (activeLanguage) {
      const language =
        activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';
      // Forces reload of brands on language change
      if (previousLanguage !== language || props.brands.length === 0) {
        setPreviousLanguage(language)
        loadBrands(language);
      }
      else setBrands([...props.brands]);
    }
  },[props.brands, loadBrands, activeLanguage, previousLanguage]);    

  const childrenWithProps = React.Children.map(props.children, child =>
    React.cloneElement(child, {
      activeLanguage,
      brands,
      selectedBrand,
      setSelectedBrand,
      removeSelectedBrand,
      setSelectedCategory,
      modifyFilters,
    })
  );
  return <>{childrenWithProps}</>;
};

BrandsContainer.propTypes = {
  loadBrands: PropTypes.func.isRequired,
  brands: PropTypes.array.isRequired,
  setSelectedBrand: PropTypes.func.isRequired,
  setSelectedCategory: PropTypes.func.isRequired,
  modifyFilters: PropTypes.func.isRequired,
  removeSelectedBrand: PropTypes.func.isRequired,
};

function mapStateToProps({ brands, selectedBrand }) {
  return {
    brands,
    selectedBrand,
  };
}

const mapDispatchToProps = {
  loadBrands,
  setSelectedBrand,
  removeSelectedBrand,
  setSelectedCategory,
  modifyFilters,
};

export default withLocalize(connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandsContainer));
