import React, { useContext } from 'react';
import PageTitle from './../../interface/PageTitle';
import Intro from './Intro';
import WhitegloveService from './WhitegloveServiceSection';
import PlWarehousing from './PlWarehousingSection';
import CustomizableLabelling from './CustomizableLabellingSection';
import Catalogue from './CatalogueSection';
import { Translate, withLocalize } from 'react-localize-redux';
import { sizing } from './../../../../defaults/styles';
import { ResizeAwareContext } from '../../../smart/ResizeContext';

const ServicesPage = ({ translate }) => {
  const { isMobile } = useContext(ResizeAwareContext);
  document.title = translate('services.legacy.title');

  return (
    <main style={{ marginBottom: sizing.paddingEq }}>
      <PageTitle text={<Translate id={translate('services.legacy.title')} />} />
      <Intro />
      <WhitegloveService />
      <PlWarehousing />
      <CustomizableLabelling />
      <Catalogue isMobile={isMobile} />
    </main>
  );
};

export default withLocalize(ServicesPage);
