import React from 'react';
import PageSection from '../../interface/PageSection';
import { Translate, withLocalize } from 'react-localize-redux';

const PlWarehousing = () => {
  const t = (tag) => `services.legacy.warehouse.${tag}`;
  return (
    <PageSection
      title={<Translate id={t('title')} />}
      paragraphs={[<Translate id={t('paragraph1')} />]}
      contentPosition="right"
      imgSrc="/images/pages/services/warehousing.png"
      imgAlt="3PL Warehousing"
    />
  );
};

export default withLocalize(PlWarehousing);
