import React, { useState } from 'react';
import { colors, spacing } from './../../../defaults/styles';
import { Link } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';

// Styles
const styles = {
  root: {
    textDecoration: 'none',
    borderStyle: 'solid',
    color: 'white',
    cursor: 'pointer',
  },
};

// Style Formatter
const formatStyle = (
  fill,
  borderRadius,
  color,
  size,
  fullWidth,
  lineHeight,
  isMobile,
  isTablet,
  isHomeTop,
  isHeader,
  order,
  hovering
) => {

  const style = {};
  if (fill) {
    style.color = !hovering ? '#fff' : hovering && isHeader ? '#fff' : color;
    style.backgroundColor = !hovering ? color : hovering && isHeader ? colors.primary.dark : 'transparent';
  } else {
    style.color = !hovering ? color : color === 'white' ? colors.primary.dark : '#fff';
    style.backgroundColor = !hovering ? 'transparent' : color;
  }
  if (borderRadius) {
    style.borderRadius = borderRadius;
  }
  if (fullWidth) {
    style.display = 'block';
    style.textAlign = 'center';
    style.width = '100%';
  } else {
    style.display = 'inline-block';
  }
  if (lineHeight) {
    style.lineHeight = lineHeight;
  }
  if (!isMobile || !isTablet) {
    style.transition = 'all 200ms ease-in-out';
  }
  if (isMobile) {
    style.display = 'block';
    style.textAlign = 'center';
    style.marginBottom = spacing.medium;
    style.paddingTop = spacing.large;
    style.paddingBottom = spacing.large;
  }
  if ((isMobile && isHomeTop) || (isTablet && isHomeTop)) {
    style.display = 'block';
    style.textAlign = 'center';
    style.margin = `0 auto ${spacing.medium}px auto`;
  }
  if (isTablet && order === 'left') {
    style.marginLeft = 12;
  }
  style.borderWidth = size / 12;
  style.borderColor = color;
  style.fontSize = size;
  style.padding = `${size * 0.65}px ${size * 1.25}px`;
  return { ...styles.root, ...style };
};

// Component
/**
 * @typedef {object} props
 * @property {string}  url       href of the button (Required)
 * @property {string}  color     The color used for the button (text, border, background)
 * @property {string}  borderRadius The borderRadius used for the button corner
 * @property {boolean} fill      True: background is colored; False: background is transparent, but has border
 * @property {boolean} fullWidth Makes the button into a block instead of inline-block
 * @property {number}  size      The font-size of the text. Also affects padding and border-width.
 * @property {boolean} isMobile  True: when window width is less than sizing.mobileWidthThreshold
 * @property {boolean} isTablet  True: when window width is less than sizing.tabletWidthThreshold
 * @property {string}  order     left or right
 * @property {number}  lineHeight line-height which allows button to increase height
 *
 * Generic button
 * @param {props} props
 */
const Button = ({
  children,
  url,
  fullWidth = false,
  fill = true,
  color = colors.primary.normal,
  borderRadius = 0,
  size = 12,
  lineHeight = 1,
  cta = false,
  onClick = null,
  activeLanguage,
  isMobile = false,
  isTablet = false,
  isHomeTop = false,
  isHeader = false,
  order,
  value = null,
  type = null,
  id = null,
}) => {
  const lang =
    activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';
  
  const [hovering, setHovering] = useState(false);

  return url ? (
    <Link
      id={id}
      to={'/' + lang + url}
      onClick={(event) => (onClick ? onClick(event) : null)}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      style={
        !cta
          ? formatStyle(
              fill,
              borderRadius,
              color,
              size,
              fullWidth,
              lineHeight,
              isMobile,
              isTablet,
              isHomeTop,
              isHeader,
              order,
              hovering
            )
          : formatStyle(
              fill,
              borderRadius,
              color,
              16,
              fullWidth,
              lineHeight,
              isMobile,
              isTablet,
              isHomeTop,
              isHeader,
              order,
              hovering
            )
      }
    >
      {children}
    </Link>
  ) : (
    <button
      id={id}
      onClick={(event) => (onClick ? onClick(event) : null)}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      value={value}
      type={type}
      style={
        !cta
          ? formatStyle(
              fill,
              borderRadius,
              color,
              size,
              fullWidth,
              lineHeight,
              isMobile,
              isTablet,
              isHomeTop,
              order
            )
          : formatStyle(
              fill,
              borderRadius,
              color,
              16,
              fullWidth,
              lineHeight,
              isMobile,
              isTablet,
              isHomeTop,
              order
            )
      }
    >
      {children}
    </button>
  );
};

export default withLocalize(Button);
