import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { lsStyled as styled } from './../../../../../tools/helpers/lsStyled';
import { fonts } from '../../../../../defaults/styles';

const currentYear = new Date().getFullYear();

const LegalInfo = ({ isMobile }) => {
  const t = (tag) => `footer.legal.${tag}`;

  return (
    <RootP isMobile={isMobile}>
      <Translate id={t("copyright")} />
      &nbsp;
      {currentYear}
      &nbsp;
      <Translate id={t("klf")} />
      .&nbsp;
      <Translate id={t("rights")} />
    </RootP>
  );
};
export default withLocalize(LegalInfo);

// == Styles == //
const RootP = styled.p `
  font-size: ${fonts.sizes.footerItems}px;
`;
