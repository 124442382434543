import React, { useContext } from 'react';
import UIMain from './../../interface/UIMain';
import UIContainer from './../../interface/UIContainer';
import UISection from './../../interface/UISection';
import Intro from './Intro';
import ContactInfoSection from './ContactInfoSection';
import { spacing } from '../../../../defaults/styles';
import { ResizeAwareContext } from '../../../smart/ResizeContext';
import { withLocalize } from 'react-localize-redux';
import HubspotContactForm from './HubspotContactForm';

const ContactUsPage = ({ translate, activeLanguage }) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);
  const language =
  activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';
  document.title = translate('contactUs.title');

  return (
    <UIMain isMobile={isMobile} isTablet={isTablet}>
      <Intro isMobile={isMobile} isTablet={isTablet} />
      <UIContainer
        isMobile={isMobile}
        isTablet={isTablet}
        noPadding
        customStyle={{ marginBottom: spacing.veryLarge }}
      >
        <UISection
          isMobile={isMobile}
          isTablet={isTablet}
          alignItems={'start'}
          customStyle={{ gridGap: '2em' }}
        >
          <HubspotContactForm language={language}/>
        </UISection>
      </UIContainer>
      <UIContainer
        isMobile={isMobile}
        isTablet={isTablet} 
        customStyle={{ marginBottom: '2em' }}
      >
        <UISection
          isMobile={isMobile}
          isTablet={isTablet}
          noPadding
        >
          <ContactInfoSection 
            isMobile={isMobile}
            isTablet={isTablet} 
          />
        </UISection>
      </UIContainer>
    </UIMain>
  );
};

export default withLocalize(ContactUsPage);
