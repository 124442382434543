import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import { spacing, colors } from '../../../../../defaults/styles';
import { selectThemeOverride } from '../../../../../tools/helpers/styling';
import Select from 'react-select';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';
import qs from 'querystring';

const ShoppingListDiv = styled.div`
    border-top: 1px solid ${colors.gray.light};
    padding-top: ${spacing.medium}px;
    ${props => props.isMobile ? 'padding-right: ' + spacing.verySmall + 'px' : ''};
    padding-left: ${spacing.verySmall}px;
    ${props => props.isMobile ? 'margin-bottom:' + spacing.large + 'px' : ''};
    margin-top: ${spacing.large}px;
`;

const ShoppingListTitleWrapper = styled.div`
    font-weight: bold;
    margin-bottom: ${spacing.verySmall}px;
`;

const ShoppingList = ({    
    shoppingLists,
    currentQuery,
    history,
    isMobile,
    translate,
    user,
}) => {

    const t = (tag) => `catalog.shoppingList.${tag}`;

    const loggedIn = user && user.loggedIn;

    const options = [];
    let selectedShoppingList; //should be an object
    //get all options for select and the selected shopping list
    if(shoppingLists) {
        shoppingLists.forEach(sl => {

            options.push ({
                value: sl.id,
                label: sl.listName
            });

            if(sl.id == currentQuery.shoppingList ) {
                selectedShoppingList = {
                    id: sl.id,
                    listName: sl.listName
                };
            }
        });
    }

    const separatedQuery = currentQuery ? JSON.parse(JSON.stringify(currentQuery)) : {};

    delete separatedQuery.shoppingList;

    const pushHistory = (shoppingList) => {
        if (shoppingList) {
            if (separatedQuery) {
                history.push({
                    pathname: history.location.pathname,
                    search: '?' + qs.stringify({ ...separatedQuery, shoppingList: shoppingList.value })
                });
            } else {
                history.push({
                    pathname: history.location.pathname,
                    search: '?' + qs.stringify({ shoppingList: shoppingList.value })
                });
            }
        } else {
            history.push({
                pathname: history.location.pathname,
                search: '?' + qs.stringify(separatedQuery)
            });
        }
    }

    return (
        <>
        {loggedIn && (
            <ShoppingListDiv isMobile={isMobile}>
                <ShoppingListTitleWrapper>
                    <Translate id={t('title')} />
                </ShoppingListTitleWrapper>
                <div>
                    <Select
                        value={
                            selectedShoppingList
                            ? {
                                value: selectedShoppingList.id,
                                label: selectedShoppingList.listName,
                            }
                            : null
                        }
                        isClearable={true}
                        options={options}
                        placeholder={translate(t('selectPlaceholder'))}
                        theme={theme => selectThemeOverride(theme)}
                        noOptionsMessage={() => translate(t('loading'))}
                        onChange={pushHistory}
                    />
                </div>
            </ShoppingListDiv>
        )}
        </>
    );
};

export default withRouter(withLocalize(ShoppingList));