import React, { useState, useEffect, useRef } from 'react';
import { withLocalize } from 'react-localize-redux';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';

const SectionTitle = styled.h2`
    margin-bottom: 1.25rem;
`;

const CartSectionRow = styled.div`
    margin-bottom: 0.75rem;
`;

const CartSectionLabel = styled.span`
    font-weight: 400;
    font-weight: bold;
`;

const requiredFields = {
    firstName: true, 
    lastName: true, 
    company: false,
    addressLineOne: true,
    addressLineTwo: false,
    city: true, 
    provinceId: true,
    province: true,
    postalCode: true,
    phone: false,
    email: false,
}

const CartSectionBillingAddress = ({ activeLanguage, translate, cart, isMobile }) => {
    const [ address, setAddress ] = useState({});

    const options = [];
    //get all options for select and the selected province
    const provincesList = cart.provinces
    if(provincesList) {
        provincesList.forEach(p => {
            options.push ({
                value: p.id,
                label: p.code
            });
        });
    }

    useEffect(() => {
        const newAddress = {};
        if (cart.billingAddress) {
            Object.entries(requiredFields).forEach(([ key, _ ]) => { newAddress[key] = cart.billingAddress[key] });
        }
        setAddress(newAddress);
    }, [ cart.billingAddress ]);

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        })
    }, []);

    const {
        firstName, lastName, company, addressLineOne,
        addressLineTwo, city, province, provinceId, postalCode, phone, email
    } = address;

    return <>
                <SectionTitle>{translate('checkout.billingAddress.title')}</SectionTitle>
                <div style={{ display: 'flex', flexDirection: 'column' }}>               
                    <CartSectionRow>
                        <CartSectionLabel>{translate('checkout.billingAddress.name')}: </CartSectionLabel>{firstName} {lastName}
                    </CartSectionRow>
                    {company && 
                        <CartSectionRow>
                            <CartSectionLabel>{translate('checkout.billingAddress.companyName')}: </CartSectionLabel>{company}
                        </CartSectionRow>
                    }
                    <CartSectionRow>
                        <CartSectionLabel>{translate('checkout.billingAddress.address')}: </CartSectionLabel>
                    </CartSectionRow>
                    <CartSectionRow>  {addressLineOne}</CartSectionRow>
                    {addressLineTwo && 
                        <CartSectionRow>
                        {addressLineTwo}
                        </CartSectionRow>
                    }
                    <CartSectionRow>
                            {city && `${city} `}
                            {province && `${province} `}
                            {postalCode && postalCode}
                    </CartSectionRow>
                    {phone && 
                        <CartSectionRow>
                            <CartSectionLabel>{phone}</CartSectionLabel>
                        </CartSectionRow>
                    }
                    {email && 
                        <CartSectionRow>
                            <CartSectionLabel>{email}</CartSectionLabel>
                        </CartSectionRow>
                    }
                </div>
            </>
};

export default withLocalize(CartSectionBillingAddress);