import React from 'react';
import Data from './Data';
import { lsStyled as styled } from '../../../../../../../tools/helpers/lsStyled';
import { Translate, withLocalize } from 'react-localize-redux';
import { spacing } from '../../../../../../../defaults/styles';

const Wrapper = styled.div`
  display: grid;
  grid-gap: ${spacing.large}px;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: start;
`;
const formatSize = (width, height, length) => {
  return `${width} × ${length} × ${height}`;
};

const Info = ({
  activeLanguage,
  product: {
    brand,
    brandId,
    warranty,
    weight,
    width,
    height,
    length,
    itemWeight,
    itemWidth,
    itemHeight,
    itemLength,
  },
  translate
}) => {
  const t = (tag) => `product.info.${tag}`;

  const lang = activeLanguage && activeLanguage.code === 'fr' ? 'fr' : 'en';

  return (
    <section
      style={{
        marginBottom: spacing.veryLarge,
      }}
    >
      <Wrapper>
        {/* <img
          src={brandImageUrl}
          alt={brand}
          style={{ border: '1px solid #eee', width: '100%' }}
        /> */}
        <div>
          <Data heading={<Translate id={t('brand')} />} data={brand} href={`/${lang}/catalog?brand=${brandId}`} />

          <Data heading={<Translate id={t('warranty')} />} data={warranty} />
          <Data
            heading={<Translate id={t('itemSize')} />}
            data={`${formatSize(itemWidth, itemHeight, itemLength)} ${translate(t('inches'))}`}
            position="right"
          />
          <Data
            heading={<Translate id={t('itemWeight')} />}
            data={`${itemWeight} ${translate(t('pounds'))}`}
            position="right"
          />
          <Data
            heading={<Translate id={t('size')} />}
            data={`${formatSize(width, height, length)} ${translate(t('inches'))}`}
            position="right"
          />
          <Data
            heading={<Translate id={t('weight')} />}
            data={`${weight} ${translate(t('pounds'))}`}
            position="right"
          />
        </div>
      </Wrapper>
    </section>
  );
};

export default withLocalize(Info);
