import * as types from '../actions/types';
import { corporateGiftsFeatures } from './initialState';

export default function corporateGiftsFeatureReducer(state = corporateGiftsFeatures, actions) {
  switch (actions.type) {
    case types.LOAD_CORPORATE_GIFTS_FEATURES_SUCCESS:
      return actions.corporateGiftsFeatures;
    default:
      return state;
  }
}