import React from 'react';
import UIMain from './../../../interface/UIMain';

import Form from './Form';
import { LoginContainer } from '../../../../smart';

const LoginForm = ({ history, isMobile, isTablet, sample, triggerMfa, msrp }) => {

  // Call this function when the login is successful
  const onLoginSuccess = (username, isClientMfaBypassEnabled, mfaBypassPeriod, randomGeneratedId) => {
    sessionStorage.setItem("username", username);
    sessionStorage.setItem("isClientMfaBypassEnabled", isClientMfaBypassEnabled);
    sessionStorage.setItem("mfaBypassPeriod", mfaBypassPeriod);
    sessionStorage.setItem("randomGeneratedId", randomGeneratedId);
  };

  return (
    <UIMain noPadding isMobile={isMobile} isTablet={isTablet}>
      <LoginContainer history={history} sample={sample} triggerMfa={triggerMfa} msrp={msrp} onLoginSuccess={onLoginSuccess}>
        <Form history={history} sample={sample} isMobile={isMobile} triggerMfa={triggerMfa} msrp={msrp} onLoginSuccess={onLoginSuccess}/>
      </LoginContainer>
    </UIMain>
  );
};

export default LoginForm;