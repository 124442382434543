import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import PageReinforcementStatic from './../../interface/PageReinforcementStatic';
import UIContainer from './../../interface/UIContainer';
import UISection from './../../interface/UISection';
import { spacing } from './../../../../defaults/styles';
import { withLocalize } from 'react-localize-redux';

const Intro = ({ isMobile, isTablet, translate }) => {
  const t = (tag) => `aboutUs.intro.${tag}`;

  const { height, width } = useWindowDimensions();
  // image - 1920(w) x 500(h)
  const imgContainerHeight = 500 * (width > 1024 ? 1024 : width) / 1920;

  return (
    <UIContainer noPadding isMobile={isMobile} isTablet={isTablet}>
      <UISection>
        <PageReinforcementStatic
          title={translate(t('title'))}
          subTitle={translate(t('subTitle'))}
          alignment={'center'}
          borderRequired
          heading={'h1'}
          isMobile={isMobile}
          isTablet={isTablet}
        />
        <ImageBoxContainer isMobile={isMobile} height={imgContainerHeight}>
          <ImageBox>
            <LSImage
              src="../images/pages/about/Team-Pic-1.png"
              alt="LoyaltySource Group Photo"
              order={1}
              isMobile={isMobile}
            />
            <LSImage
              src="../images/pages/about/Team-Pic-2.png"
              alt="LoyaltySource Group Photo"
              order={0}
              isMobile={isMobile}
            />
          </ImageBox>
        </ImageBoxContainer>
        {/* <Image
          src="/images/pages/home/loyalty-source-banner3.jpg"
          alt="LoyaltySource"
        /> */}
        
      </UISection>
    </UIContainer>
  );
};

export default withLocalize(Intro);

function getWindowDimensions() {
  const {
    innerWidth: width,
    innerHeight: height
  } = window;
  return {
    width,
    height
  };
}

// the following is used in order to add height to the top image container
function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

const ImageBoxContainer = styled.div`
  height: ${props => props.height}px;
  margin-top: ${spacing.marginStandard};
  margin-bottom: ${spacing.marginStandard};
  position: relative;
  width: 100%;
`;

// // Create the keyframes
const slideAnimation = keyframes`
  0%   { opacity: 0; z-index: 0; }
  12%  { opacity: 1; }
  24%  { opacity: 1; }
  36%  { opacity: 1; }
  60%  { opacity: 1; }
  72%  { opacity: 0; z-index: 5; }
  100% { opacity: 0; }
`;

const ImageBox = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -5;
  overflow: hidden;
`;

const LSImage = styled.img`
  display: block;
  height: auto;
  width: ${props => (props.isMobile ? '120%' : '100%')};
  opacity: ${props => (props.order === 0 ? 1 : 0)};
  /* total 2 images, 4s per image - adjust the next 2 lines if it is too quick or too slow.   */
  animation: ${slideAnimation} 4s 0s infinite;
  animation-delay: ${props => (props.order === 1 ? '2s' : '0s')};

  position: absolute;
  top: 0;
  left: ${props => (props.isMobile ? '-10%' : 0)};
`;

// == .Styling == /
