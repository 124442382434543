import React, { useContext } from 'react';
import UIMain from './../../interface/UIMain';
import UIContainer from './../../interface/UIContainer';
import { spacing, colors } from '../../../../defaults/styles';
import { withLocalize } from 'react-localize-redux';
import { BrandSlideShow } from './BrandSlideShow';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { GridLoader } from 'react-spinners';
import { ResizeAwareContext } from '../../../smart/ResizeContext';

const LoaderWrapper = styled.div`
  padding: ${spacing.veryLarge}px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100%;
`;

const LoadingGrid = (
    <LoaderWrapper>
        <GridLoader css={{ margin: 'auto' }} color={colors.main.normal} size={12} />
    </LoaderWrapper>
);

export const BrandCarousel = withLocalize(
    ({ activeLanguage, history, brands }) => {
        const { isMobile, isTablet } = useContext(ResizeAwareContext);

        if (brands) {
            return (
                <UIContainer noPadding isMobile={isMobile} isTablet={isTablet}>
                    <BrandSlideShow
                        isMobile={isMobile}
                        isTablet={isTablet}
                        list={brands}
                        activeLanguage={activeLanguage}
                        history={history}
                    />
                </UIContainer>
            );
        } else {
            return (
                <UIMain isMobile={isMobile} isTablet={isTablet}>
                    {LoadingGrid}
                </UIMain>
            );
        }
    }
);