import React from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { setSelectedCategory } from '../../../redux/actions/selectedCategoryActions';

export const HeadersContainer = ({
    setSelectedCategory,
    ...props
}) => {
    const childrenWithProps = React.Children.map(props.children, child => React.cloneElement(child, { setSelectedCategory }) );
    return <>{childrenWithProps}</>;
};

HeadersContainer.propTypes = { setSelectedCategory: propTypes.func.isRequired };

const mapDispatchToProps = { setSelectedCategory };

export default connect(null, mapDispatchToProps) (HeadersContainer);
