import React from 'react';
import styled from 'styled-components';
import PageReinforcementStatic from '../../interface/PageReinforcementStatic';
import UIContainer from '../../interface/UIContainer';
import UISection from '../../interface/UISection';
import { withLocalize } from 'react-localize-redux';
import UIGrid2cols from '../../interface/UIGrid2cols';

const ImageBox = styled.div`
  width: 100%;
  order: ${props => (props.isMobile || props.isTablet ? 2 : null)};
  overflow: hidden
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const ShowCatalog = ({ isMobile, isTablet, translate, activeLanguage }) => {
  const t = (tag) => `services.gifting.showCatalog.${tag}`;
  const lang = activeLanguage ? activeLanguage.code : 'en';

  if (lang == "fr") {
    return (
      <UIContainer noPadding isMobile={isMobile} isTablet={isTablet}>
        <UISection>
          <PageReinforcementStatic
            subTitle={translate(t('paragraph'))}
            borderRequired={false}
            heading={'h2'}
            type={'1'}
            alignment={'center'}
          />
          <UIGrid2cols
            isMobile={isMobile}
            isTablet={isTablet}
            alignItems={'center'}
          >
            <ImageBox isMobile={isMobile} isTablet={isTablet} >
              <iframe width="90%" height="730px" src="//canvas.zoomcats.com/e45981b6/summer-catalog"></iframe>
            </ImageBox>
            <ImageBox isMobile={isMobile} isTablet={isTablet} >
              <iframe width="90%" height="730px" src="//www.zoomcats.com/flyer/loyalty-source-a-0"></iframe>
            </ImageBox>
            <ImageBox isMobile={isMobile} isTablet={isTablet} >
              <iframe width="90%" height="730px" src="//www.zoomcats.com/flyer/loyalty-source-b-0"></iframe>
            </ImageBox>
            <ImageBox isMobile={isMobile} isTablet={isTablet} >
              <iframe width="90%" height="730px" src="//www.zoomcats.com/flyer/loyalty-source-c-0"></iframe>
            </ImageBox>
            <ImageBox isMobile={isMobile} isTablet={isTablet} >
              <iframe width="90%" height="730px" src="//www.zoomcats.com/flyer/loyalty-source-d-1"></iframe>
            </ImageBox>
            <ImageBox isMobile={isMobile} isTablet={isTablet} >
              <iframe width="90%" height="730px" src="//www.zoomcats.com/flyer/loyalty-source-e-0"></iframe>
            </ImageBox>

          </UIGrid2cols>
        </UISection>
      </UIContainer>
    );
  } else {
    return (
      <UIContainer noPadding isMobile={isMobile} isTablet={isTablet}>
        <UISection>
          <PageReinforcementStatic
            subTitle={translate(t('paragraph'))}
            borderRequired={false}
            heading={'h2'}
            type={'1'}
            alignment={'center'}
          />
          <UIGrid2cols
            isMobile={isMobile}
            isTablet={isTablet}
            alignItems={'center'}
          >
            <ImageBox isMobile={isMobile} isTablet={isTablet} >
              <iframe width="90%" height="730px" src="//canvas.zoomcats.com/e45981b6/summer-catalog"></iframe>
            </ImageBox>
            <ImageBox isMobile={isMobile} isTablet={isTablet} >
              <iframe width="90%" height="730px" src="//www.zoomcats.com/flyer/loyalty-source-a"></iframe>
            </ImageBox>
            <ImageBox isMobile={isMobile} isTablet={isTablet} >
              <iframe width="90%" height="730px" src="//www.zoomcats.com/flyer/loyalty-source-b"></iframe>
            </ImageBox>
            {/* That's how to deactivate what is inside an iframe. Waiting on feedback to find out if we use it or not.
             <ImageBox isMobile={isMobile} isTablet={isTablet} >
              <iframe width="90%" height="730px" src="//www.zoomcats.com/flyer/loyalty-source-b" style={{pointerEvents: "none"}}></iframe>
            </ImageBox> */}
            <ImageBox isMobile={isMobile} isTablet={isTablet} >
              <iframe width="90%" height="730px" src="//www.zoomcats.com/flyer/loyalty-source-c"></iframe>
            </ImageBox>
            <ImageBox isMobile={isMobile} isTablet={isTablet} >
              <iframe width="90%" height="730px" src="//www.zoomcats.com/flyer/loyalty-source-d"></iframe>
            </ImageBox>
            <ImageBox isMobile={isMobile} isTablet={isTablet} >
              <iframe width="90%" height="730px" src="//www.zoomcats.com/flyer/loyalty-source-e"></iframe>
            </ImageBox>
          </UIGrid2cols>
        </UISection>
      </UIContainer>
    );
  }
};

export default withLocalize(ShowCatalog);
