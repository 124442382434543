import * as types from './types'
import { report } from '../../tools/api'
import { beginApiCall, apiCallError } from './apiStatusActions'

export const loadReportListSuccess = reports => {
  return { type: types.LOAD_REPORT_LIST_SUCCESS, reports }
}

export const loadReportList = reportParams => {
  return dispatch => {
    dispatch(beginApiCall())
    return report
      .getList(reportParams)
      .then(({ json: reports, status }) => {
        if (status > 299 || status < 200) return dispatch(apiCallError())
        dispatch(loadReportListSuccess(reports))
      })
      .catch(error => {
        dispatch(apiCallError(error))
        throw error
      })
  }
}
