import React, { useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import { spacing, colors, fonts } from '../../../../../defaults/styles';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';
import { ShoppingListContainer } from '../../../../smart/ShoppingList';
import ShoppingListPopup from '../../../pages/CatalogPage/ShoppingList/ShoppingListPopup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks } from '@fortawesome/pro-regular-svg-icons';
const ManageShoppingLists = ({    
    isMobile,
    user,
}) => {
    const [showPopup, setShowPopup] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const t = (tag) => `shoppingListContainer.${tag}`;
    const loggedIn = user && user.loggedIn;
    return (
        <>
        {loggedIn && (
            <Outer 
                isMobile={isMobile}
                isHovered={isHovered}
                onMouseEnter={() => setIsHovered(true) }
                onMouseLeave={() => setIsHovered(false) }
            >
                <TitleWrapper onClick={()=>{ setShowPopup(!showPopup)}}>
                    <Translate id={t('sideBarTitle')} />
                    <FontAwesomeIcon
                        icon={faTasks}
                    />
                </TitleWrapper>
                <ShoppingListContainer>
                    <ShoppingListPopup showPopup={showPopup} setShowPopup={setShowPopup} isMobile={isMobile} />
                </ShoppingListContainer>
            </Outer>
        )}
        </>
    );
}

const Outer = styled.div`
    border-top: 1px solid ${colors.gray.light};
    padding-top: ${spacing.medium}px;
    ${props => props.isMobile ? 'padding-right: ' + spacing.verySmall + 'px' : ''};
    padding-left: ${spacing.verySmall}px;
    ${props => props.isMobile ? 'margin-bottom:' + spacing.large + 'px' : ''};
    margin-top: ${spacing.large}px;
`;

const TitleWrapper = styled.div`
    background-color: ${colors.gray.normal};
    color: ${colors.white.normal};
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${spacing.small}px;
    padding: ${spacing.small}px;
    user-select: none;
    transition: all 250ms;

    :hover {
        background-color: ${colors.gray.light};
        color: ${colors.black.normal};

    }
`;

export default withRouter(withLocalize(ManageShoppingLists));