import React from 'react';
import UIPageTopTitle from '../../interface/UIPageTopTitle';
import { withLocalize } from 'react-localize-redux';

const Intro = ({ isMobile, isTablet, translate }) => {
  const t = (tag) => `forgetPassword.intro.${tag}`;

  return (
    <UIPageTopTitle
      title={translate(t('title'))}
      isMobile={isMobile}
      isTablet={isTablet}
    />
  );
};

export default withLocalize(Intro);
