import React from 'react';
import UIContainer from './UIContainer';
import UISection from './UISection';
import { lsStyled as styled } from './../../../tools/helpers/lsStyled';
import { colors, typography } from './../../../defaults/styles';

/* Please use this component for main content top section where background is in red and white text of Title (H1) and Sub-title */
const Heading = styled.h1`
  /* using h3 styling here for design but the tag is H1 as main title. */
  ${typography.h3};
  color: ${colors.white.normal};
  line-height: 1em;
  margin-top: 0.25em;
  ${props => (props.customStyle ? props.customStyle : null)};
`;
const Paragraph = styled.p`
  ${typography.normal}
  color: ${colors.white.normal};
  /*line-height: 1em;*/
  opacity: 0.5;
  ${props => (props.customStyle ? props.customStyle : null)};
`;

const UIPageTopTitle = ({
  children,
  title,
  subTitle,
  textAlign = 'left',
  customStyle = null,
  isMobile,
  isTablet,
}) => {
  return (
    <UIContainer
      fullWidth
      customStyle={{
        backgroundColor: colors.primary.dark,
        paddingTop: 0,
        paddingBottom: 0,
      }}
    >
      <UIContainer isMobile={isMobile} isTablet={isTablet}>
        <UISection
          noPadding
          customStyle={{
            display: 'flex',
            flexDirection: 'column-reverse',
          }}
          isMobile={isMobile} 
          isTablet={isTablet}
        >
          <Heading customStyle={customStyle}>{title}</Heading>
          <Paragraph textAlign={textAlign} customStyle={customStyle}>
            {subTitle}
          </Paragraph>
          {children}
        </UISection>
      </UIContainer>
    </UIContainer>
  );
};

export default UIPageTopTitle;
