import React from 'react';

import { Translate, withLocalize } from 'react-localize-redux';
import { lsStyled as styled } from '../../../../../../tools/helpers/lsStyled';
import { spacing, colors } from '../../../../../../defaults/styles';

const LoginRequiredBtn = styled.span`
        background-color: ${colors.gray.light};
        padding: 0.5em;
        cursor: pointer;
        margin-bottom: ${spacing.small}px;
    `;
const LoginRequired = () => {
    const t = (tag) => `product.${tag}`;
    return (
        <LoginRequiredBtn onClick={(e) => { e.preventDefault(); window.location.href = '/login'; } }>
            <Translate id={t('logInRequired')} />
        </LoginRequiredBtn>
    );
}

export default withLocalize(LoginRequired);