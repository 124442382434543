import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';

import { fonts, spacing, colors } from '../../../../../defaults/styles';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';

const TopLevelWrapper = styled.div`
    background-color: ${props => props.selected ? colors.primary.normal : colors.gray.light };
    color: ${props => props.selected ? colors.white.normal : colors.black.normal };
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    font-size: ${fonts.sizes.largeText}px;
    font-weight: ${fonts.weights.title};
    margin-bottom: ${spacing.small}px;
    padding: ${spacing.small}px;
    text-transform: uppercase;
    user-select: none;
`;

const LinkStyle = {
    color: 'inherit',
    textDecoration: 'none'
};

const TopLevelCategory = ({ 
    text,
    selected = false, 
    onClick,
    url
}) => (
    <TopLevelWrapper selected={selected} onClick={onClick}>
        <Link 
            to={url}
            style={LinkStyle} 
            onClick={(e) => e.preventDefault()}
        >
            {text}
        </Link>
        <FontAwesomeIcon
            icon={faChevronDown}
            style={{
                transform: selected ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'all 0.2s ease-in-out',
            }}
        />
    </TopLevelWrapper>
);


export default TopLevelCategory;
