import React from 'react';
import { withLocalize } from 'react-localize-redux';

export const OrderItemFooter = withLocalize(({ order, locale, isMobile, translate }) => {
  const t = (tag) => `orders.${tag}`;

  const getTrackingUrl = trackingNumber => {
    const { shippingCompany } = order;
    // Custom url to the carrier website for tracking purposes
    if (shippingCompany) {
      if (shippingCompany.toUpperCase() === 'CANADA POST') {
        return (`https://www.canadapost.ca/trackweb/en#/search?searchFor=${trackingNumber}`);
      } else if (shippingCompany.toUpperCase() === 'FEDEX') {
        return (`https://www.fedex.com/apps/fedextrack/?action=track&locale=${locale === 'fr' ? 'fr_CA' : 'en_US'}&trackingnumber=${trackingNumber}`);
      } else if (shippingCompany.toUpperCase() === 'PUROLATOR') {
        return (`https://www.purolator.com/${locale}/ship-track/tracking-details.page?pin=${trackingNumber}`);
      }
    }
    return null;
  };

  if (order.trackingNumber && order.trackingNumber.length > 0) {
    return (
      <div className="card-footer" style={{ fontSize: '15px' }}>
        {/* Loop through each tracking number, there can be multiple */}
        {order.trackingNumber.map(trackingNumber => (
          <div key={trackingNumber} className="row">
            {/* Left side content, the detail of tracking */}
            <div className={`${isMobile ? 'col-lg-12' : 'col-lg-9' } text-left`}>
              <b>
                {locale === 'en' 
                  ? order.shippingCompany + ' ' + translate(t('label.trackingNumber')).toLowerCase() + ' : '
                  : translate(t('label.trackingNumber')) + ' ' + order.shippingCompany + ' : '
                }
              </b>
              {trackingNumber}
            </div>

            {/* Right side content, the url to carrier website */}
            <div className={isMobile ? `col-lg-12 text-left` : `col-lg-3 text-right`}>
              {[getTrackingUrl(trackingNumber)].map(url =>
                url ? (
                  <a
                    key={trackingNumber}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {translate(t('label.view'))}&nbsp;
                    <span className="fas fa-caret-right" />
                  </a>
                ) : (
                  ''
                )
              )}
            </div>
          </div>
        ))}
      </div>
    );
  } else {
    return <></>;
  }
});
