
import React, { useEffect, useState, } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';
import { downloadShoppingListEventHandler} from '../../../../../redux/actions/shoppingListActions';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';
import { colors, } from '../../../../../defaults/styles';
// import Button from '../../presentation/interface/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload } from '@fortawesome/pro-regular-svg-icons';
import GridLoader from 'react-spinners/GridLoader';
// import { getTranslate } from "react-localize-redux"
import moment from 'moment';

const DownloadShoppingListButton = ({
    user,
    translate,
    id,
    shoppingLists,
    downloadedShoppingList,
    downloadShoppingListEventHandler,
}) => {
    const t = (tag) => `shoppingListContainer.${tag}`;

    const [ shoppingListName, setShoppingListName ] = useState(null);
    const [ shoppingListLength, setShoppingListLength ] = useState(null);
    const [ isDownloading, setIsDownloading ] = useState(false);

    useEffect(()=>{
        if (isDownloading
            && downloadedShoppingList
            && downloadedShoppingList.id === id
        ) {
            setIsDownloading(false);
            const fileName = `${shoppingListName} - ${moment().format('YYYY-MM-DD')}.xls`;
            if (window.navigator.msSaveOrOpenBlob){
                window.navigator.msSaveBlob(downloadedShoppingList.file, fileName);
            }
            else {
                let a = window.document.createElement("a");
                a.href = window.URL.createObjectURL(downloadedShoppingList.file);
                a.download = fileName;
                a.click();
            }
        }
    }, [ downloadedShoppingList ]);

    useEffect(()=>{
        if (shoppingLists && shoppingLists.length > 0) {
            const shoppingList = shoppingLists.find((sl) => sl.id === id);
            if (shoppingList) {
                setShoppingListName(shoppingList.listName);
                setShoppingListLength(shoppingList.productIds.length);
            } else {
                setShoppingListName(null);
                setShoppingListLength(null);
            }
        }
    }, [ shoppingLists ]);

    const loggedIn = user && user.loggedIn;

    if (!loggedIn) { return <></> };
    
    return !shoppingListName || isDownloading 
        ? (
            <div style={{textAlign: 'center'}}>
                <GridLoader
                    css={{ margin: 'auto', height: '1rem', overflowY:'hidden' }}
                    size={12}
                    color={colors.main.normal}
                />
            </div>
        )
        : (
            <ButtonIcon 
                value={id}
                shoppingListLength={shoppingListLength}
                disabled={shoppingListLength === 0}
                onClick={()=>{
                    if (shoppingListLength > 0) {
                        downloadShoppingListEventHandler({target:{value:id}});
                        setIsDownloading(true);
                    }
                }}
                title={shoppingListLength > 0 
                    ? translate(t('download'))
                    : translate(t('cannotDownload')).replace('{}', shoppingListName)}
            >
                <FontAwesomeIcon icon={faFileDownload} size="lg" />
                {/* {shoppingListLength > 0 && translate(t('download'))} */}
            </ButtonIcon>
        )
}

const ButtonIcon = styled.button`
    background-color: transparent;
    cursor: pointer;
    cursor: ${props => props.shoppingListLength > 0 ? 'pointer' : 'not-allowed'};
`;

function mapStateToProps({ shoppingLists, user, downloadedShoppingList }) {
    return { shoppingLists, user, downloadedShoppingList };
}

const mapDispatchToProps = {
    downloadShoppingListEventHandler,
};
  
export default connect(
    mapStateToProps,
    mapDispatchToProps
) (withLocalize(DownloadShoppingListButton));