import * as types from '../actions/types';
import { selectedBrand } from './initialState';

export default function selectedBrandReducer(state = selectedBrand, actions) {
  switch (actions.type) {
    case types.SET_SELECTED_BRAND:
      return { ...actions.selectedBrand };
    case types.REMOVE_SELECTED_BRAND:
      return selectedBrand;
    default:
      return state;
  }
}
