import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { loadReportList } from '../../../redux/actions/reportActions';

const ReportListContainer = ({ loadReportList, ...props }) => {
  const [reportList, setReportList] = useState({});
  const [reportParams, setReportParams] = useState({});

  useEffect(() => {
    if (reportParams.size) loadReportList(reportParams);
  }, [loadReportList, reportParams]);

  useEffect(() => {
    if (props.reportList.content.length > 0)
      setReportList({ ...props.reportList });
  }, [props.reportList]);

  const { content: reports, ...restOfList } = reportList;

  const childrenWithProps = React.Children.map(props.children, child =>
    React.cloneElement(child, { reports, ...restOfList, setReportParams })
  );

  return <>{childrenWithProps}</>;
};

ReportListContainer.propTypes = {
  reportList: propTypes.array.isRequired,
  loadReportList: propTypes.func.isRequired,
};

const mapStateToProps = ({ reportList }) => ({ reportList });

const mapDispatchToProps = { loadReportList };

const connector = connect(
  mapStateToProps,
  mapDispatchToProps
);

export default connector(ReportListContainer);
