import React, { useState, useRef, useEffect, useCallback } from 'react';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { withLocalize } from 'react-localize-redux';
import { colors } from '../../ui/common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faTimes } from '@fortawesome/free-solid-svg-icons';

const ProductLine = styled.div`
    border-top: 1px solid ${colors.gray.light};
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 1.5rem;
    align-items: start;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
`;

const ProductImage = styled.img`
    border: 1px solid rgba(149, 149, 149, 0.1); 
    max-width: ${props => props.isMobile ? '100px' : '160px'};
    height: auto;
    cursor: pointer;
    :hover {
        border-color: ${colors.primary.normal};
    }
`;

const ProductContentWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const ProductName = styled.h3`
    font-size: 1.2rem;
    font-Weight: 400;
    line-height: 1.5rem;
    margin-bottom: 0.5rem;
    cursor: pointer;
    :hover {
        color: ${colors.primary.normal};
    }
`;

const ProductSku = styled.span`
    font-size: 0.8rem;
    color: ${colors.gray.normal};
    margin-bottom: 1.5rem;
    cursor: pointer;
    :hover {
        color: ${colors.primary.normal};
    }
`;

const ProductQuantityWrapper = styled.div`
    margin-bottom: 1.5rem;
    display: grid;
    grid-template-columns: auto 65px auto;
    grid-auto-flow: column;
    justify-content: start;
    text-align: center;
`;

const ProductQuantityButton = styled.button`
    font-size: 0.8rem;
    width: 1rem;
    height: 1rem;
    color: ${colors.white.normal};
    /* rgb 149, 149, 149 = colors.gray */
    background-color: rgba(149, 149, 149, 0.5);
    border-radius: 2px;
    font-weight: 700;
    padding: 0.1rem;
    cursor: pointer;

    :hover {
        background-color: ${colors.primary.normal};
    }
`;

const ProductQuantityLabel = styled.div`
    margin: 0 0.5rem 0 0.5rem;
    font-size: 1rem;
    font-weight: 700;

    position: relative;
`;

const ProductQuantityInput = styled.input`
    border-bottom: 2px solid transparent;
    font-size: 1rem;
    font-weight: 500;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;

    :focus {
        border-color: ${colors.primary.normal};
        transition: 1s border-color ease-in-out;
    }
`;

const ProductRemoveButton = styled.a`
    color: ${colors.gray.normal};
    border-bottom: 1px solid ${colors.gray.normal};
    font-size: 0.8rem;
    cursor: pointer;

    :hover {
        color: ${colors.primary.normal};
        border-bottom: 1px solid ${colors.primary.normal};
    }
`;

const CartListItem = ({ activeLanguage, translate, item, updateCartItem, removeFromCart, isMobile, history }) => {
    const { id, imageUrl, name, sku, quantity } = item;
    const [inputNum, setInputNum] = useState(quantity);
    const [typing, setTyping] = useState(false);
    const [animationDone, setAnimationDone] = useState(false);

    useEffect(() => {
        setTimeout(() => setAnimationDone(typing), 0);
    }, [typing, inputNum]);

    const textInput = useRef();
    const focusTextInput = () => {
        textInput.current.focus();
    }
    const inputOnChange = useCallback((e) => {
        const reg = /^[0-9\b]+$/;
        const newQty = e.target.value;
        if (newQty !== '') {
            if (reg.test(newQty) 
                && newQty !== inputNum) {
                updateCartItem(sku, Number(newQty), activeLanguage.code);
                setInputNum(newQty);
            } else {
                setInputNum(inputNum);
            }
        } else {
            setInputNum('');
        }
    }, [activeLanguage.code, inputNum, sku, updateCartItem]);

    const proceedToProduct = () => history.push(`/${activeLanguage.code}/product/${sku}`);

    return (
        <ProductLine isMobile={isMobile}>
            <ProductImage src={imageUrl} alt={ 'product - '+ name} isMobile={isMobile} onClick={proceedToProduct}/>
            <ProductContentWrapper>
                <ProductName onClick={proceedToProduct}>{name}</ProductName>
                <ProductSku onClick={proceedToProduct}>{translate('checkout.list.sku')}: {sku}</ProductSku>
                <ProductQuantityWrapper>
                    <ProductQuantityButton onClick={() => {
                        const newQty = Number(inputNum) - 1 <= 0 ? 1 : Number(inputNum) - 1;
                        setInputNum(newQty);
                        updateCartItem(sku, newQty,  activeLanguage.code);
                    }}>
                        <FontAwesomeIcon icon={faMinus} />
                    </ProductQuantityButton>
                    <ProductQuantityLabel>
                        <ProductQuantityInput 
                            ref={textInput}
                            type={"text"} 
                            value={inputNum} 
                            maxLength={"5"}
                            onClick={focusTextInput}
                            onChange={inputOnChange}
                            onFocus={() => { if (!typing) { setTyping(true); } }}
                            onBlur={() => { if (typing) setTyping(false); }}
                        />
                    </ProductQuantityLabel>
                    <ProductQuantityButton onClick={() => {
                        const newQty = Number(inputNum) + 1 >= 100000 ? 99999 : Number(inputNum) + 1;
                        if(newQty !== inputNum) {
                            setInputNum(newQty);
                            updateCartItem(sku, newQty, activeLanguage.code);
                        }
                    }}>
                        <FontAwesomeIcon icon={faPlus} />
                    </ProductQuantityButton>
                </ProductQuantityWrapper>

                
                <div>
                    <ProductRemoveButton onClick={() => removeFromCart(sku, activeLanguage.code)}>
                        <FontAwesomeIcon icon={faTimes} /> {translate('labels.remove')}
                    </ProductRemoveButton>
                </div>
            </ProductContentWrapper>
        </ProductLine>
    );
}

export default withLocalize(CartListItem);