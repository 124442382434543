import * as types from '../actions/types'
import { catalogForCorporateGifts } from './initialState'

export default function catalogForCorporateGiftsReducer(state = catalogForCorporateGifts, actions) {
    switch (actions.type) {
        case types.LOAD_CATALOG_FOR_CORPORATE_GIFTS_SUCCESS:
            const cloneCatalog = JSON.parse(JSON.stringify(state));
            cloneCatalog[actions.result.page] = actions.result.products;
            return cloneCatalog;
        case types.RESET_CATALOG_FOR_CORPORATE_GIFTS:
            return actions.result;
        default:
            return state;
    }
}