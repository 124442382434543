import React, { useContext } from 'react';
import PageTitleSimple from '../../interface/PageTitleSimple';
import PrivacySection from './PrivacySection';
import { withLocalize } from 'react-localize-redux';
import { sizing } from './../../../../defaults/styles';
import UIMain from '../../interface/UIMain';
import { ResizeAwareContext } from '../../../smart/ResizeContext';

const PrivacyPage = ({ translate }) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);
  document.title = translate('privacyTitle');

  return (
    <main style={{ marginBottom: sizing.paddingEq }}>
      <UIMain isMobile={isMobile} isTablet={isTablet}>
        <PageTitleSimple translateId={'privacyTitle'} isMobile={isMobile} isTablet={isTablet} />
        <PrivacySection isMobile={isMobile} isTablet={isTablet} />
      </UIMain>
    </main>
  );
};

export default withLocalize(PrivacyPage);
