import React, { useContext } from 'react';
import TermsAndConditionsSection from './TermsAndConditionsSection';
import UIMain from '../../interface/UIMain';

import { withLocalize } from 'react-localize-redux';
import { sizing } from './../../../../defaults/styles';
import PageTitleSimple from '../../interface/PageTitleSimple';
import { ResizeAwareContext } from '../../../smart/ResizeContext';

const TermsAndConditionsPage = ({ translate }) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);
  document.title = translate('termsAndConditionTitle');

  return (
    <main style={{ marginBottom: sizing.paddingEq }}>
      <UIMain isMobile={isMobile} isTablet={isTablet}>
        <PageTitleSimple translateId={'termsAndConditionTitle'} isMobile={isMobile} isTablet={isTablet} />
        <TermsAndConditionsSection isMobile={isMobile} isTablet={isTablet} />
      </UIMain>
    </main>
  );
};

export default withLocalize(TermsAndConditionsPage);
