import React from 'react';

import PageReinforcementStatic from './../../interface/PageReinforcementStatic';
import UIContainer from './../../interface/UIContainer';
import { withLocalize } from 'react-localize-redux';
import HubspotContactForm from './HubspotContactForm';


const RewardNetwork = ({ isMobile, isTablet, translate, activeLanguage }) => {
  const t = (tag) => `footer.services.rewardNetwork.${tag}`;
  const language =
    activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';
  return (
    <UIContainer noPadding isMobile={isMobile} isTablet={isTablet}>
      <PageReinforcementStatic
        title={translate(t('title'))}
        subTitle={translate(t('subTitle'))}
        alignment={'center'}
        borderRequired={true}
        heading={'h2'}
      />
      <HubspotContactForm language={language} />
    </UIContainer>
  );
};

export default withLocalize(RewardNetwork);