import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import MainHeader from '../components/presentation/layout/MainHeader';
import MainFooter from '../components/presentation/layout/MainFooter';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import {
  HomePage,
  AboutUsPage,
  ServicesWholesalePage,
  // RewardsPage,
  // ServicesPage,
  ServicesRewardsPage,
  ServicesFulfilmentPage,
  ServicesGiftingPage,
  OrdersPage,
  EnvironmentPage,
  CheckoutPage,
  ProductPage,
  BrandsPage,
  LoginPage,
  ContactUsPage,
  ContactBriefPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  PrivacyPage,
  TermsAndConditionsPage,
  LogoutPage,
  LoggedInHomePage,
  UserInfoPage,
  CatalogPage,
  BlogPostsPage,
  NewsEntriesPage,
  AccountRequestPage,
  BrandPartnersPage,
  ThankYouPage,
  CorporateGiftsPage,
  VendorAgreementPage,
  VendorAgreementSuccessPage,
} from '../components/presentation/pages';
import { renderToStaticMarkup } from 'react-dom/server';
import { withLocalize } from 'react-localize-redux';
import ErrorPage from '../components/presentation/pages/ErrorPage';
import ResizeAwareContainer from '../components/smart/ResizeContext';
import BlogPost from '../components/presentation/pages/BlogPostsPage/BlogPostPage'
import globalLexicon from './global-lexicon';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import { lsStyled as styled } from './../tools/helpers/lsStyled';

const GlobalStyle = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700,900&display=swap');
    body {
        font-family: 'Roboto', sans-serif;
    }
`;

class App extends React.Component {
  constructor(props) {
    super(props);
    props.initialize({
      languages: [
        { name: 'English', code: 'en' },
        { name: 'Français', code: 'fr' },
      ],
      translation: globalLexicon,
      options: { renderToStaticMarkup },
    });
  }

  render() {
    const { user: { loggedIn }, location} = this.props;

    const isLoginPage = window.location.href.includes('login');

    return (
      <ResizeAwareContainer>
        <GlobalStyle />
        {!isLoginPage && <MainHeader />}

        <AnimationWrapper>
            <TransitionGroup>
                <CSSTransition
                  key={location.key}
                  timeout={{ enter: 300, exit: 150 }}
                  classNames={'fade'}
                >
                  
                    <Switch location={location}>
                      <Route
                        exact
                        path='/earthfriendly'
                      >
                        <Redirect to='/en/environment' />
                      </Route>
                      <Route
                        exact
                        path='/amidelaplanete'
                      >
                        <Redirect to='/fr/environment' />
                      </Route>
                      <Route
                        exact
                        path={['/en', '/fr']}
                        component={loggedIn ? LoggedInHomePage : HomePage}
                      />
                      <Route path={['/en/*', '/fr/*']}>
                        <Route path="/:lang/*" component={DefaultLanguage} />
                        <Route path="/:lang/info" component={loggedIn ? UserInfoPage : HomePage} />
                        <Route path="/:lang/about-us" component={AboutUsPage} />
                        <Route
                          path="/:lang/services-rewards"
                          component={ServicesRewardsPage}
                        />
                        <Route
                          path="/:lang/services-fulfilment"
                          component={ServicesFulfilmentPage}
                        />
                        <Route
                          path="/:lang/services-gifting"
                          component={ServicesGiftingPage}
                        />
                        <Route path="/:lang/brands" component={BrandsPage} />
                        <Route path="/:lang/catalog" component={CatalogPage} />
                        <Route path="/:lang/product/:sku" component={ProductPage} />
                        <Route path="/:lang/blog-list" component={BlogPostsPage} />
                        <Route path="/:lang/news-list" component={NewsEntriesPage} />
                        <Route path="/:lang/blog/:id" component={BlogPost} />
                        <Route path="/:lang/orders" component={loggedIn ? OrdersPage : LoginPage} />
                        <Route path="/:lang/checkout" component={loggedIn? CheckoutPage : LoginPage} />
                        <Route path="/:lang/environment" component={EnvironmentPage} />
                        <Route path="/:lang/login" component={loggedIn ? LoggedInHomePage : LoginPage} />
                        <Route path="/:lang/login/sample" element={loggedIn ? <LoggedInHomePage /> : <LoginPage />} />
                        <Route path="/:lang/login/msrp" element={loggedIn ? <LoggedInHomePage /> : <LoginPage />} />
                        <Route path="/:lang/login/token" element={loggedIn ? <LoggedInHomePage /> : <LoginPage />} />
                        <Route path="/:lang/logout" component={loggedIn ? LogoutPage : HomePage} />
                        <Route path="/:lang/contact-us" component={ContactUsPage} />
                        <Route path="/:lang/contact-brief" component={ContactBriefPage} />
                        <Route path="/:lang/account-request" component={AccountRequestPage} />
                        <Route path="/:lang/brand-partners" component={BrandPartnersPage} />
                        <Route path="/:lang/thank-you" component={ThankYouPage} />
                        <Route path="/:lang/corporate-gifts" component={CorporateGiftsPage} />
                        <Route path="/:lang/vendor-agreement/:token" component={VendorAgreementPage} />
                        <Route path="/:lang/vendor-agreement-success" component={VendorAgreementSuccessPage} />
                        <Route
                          path="/:lang/forgot-password"
                          component={ForgotPasswordPage}
                        />
                        <Route
                          path="/:lang/reset-password"
                          component={ResetPasswordPage}
                        />
                        <Route path="/:lang/privacy-policy" component={PrivacyPage} />
                        <Route
                          path="/:lang/terms-and-conditions"
                          component={TermsAndConditionsPage}
                        />
                        <Route
                          path="/:lang/error"
                          component={ErrorPage}
                        />
                      </Route>
                      <Route
                        path="*"
                        render={props =>
                          langRedirect({
                            setActiveLanguage: this.props.setActiveLanguage,
                            activeLanguage: this.props.activeLanguage,
                            ...props,
                          })
                        }
                      />
                    </Switch>

                </CSSTransition>
            </TransitionGroup>
        </AnimationWrapper>


        {!isLoginPage && <MainFooter />}
        <ToastContainer autoClose={3000} hideProgressBar />
      </ResizeAwareContainer>
    );
  }
}

const DefaultLanguage = withLocalize(
  ({ activeLanguage, setActiveLanguage, ...props }) => {
    useEffect(() => {
      if (!activeLanguage || activeLanguage.code !== props.match.params.lang) {
        setActiveLanguage(props.match.params.lang);
      }
    }, []);
    return <></>;
  }
);

const AnimationWrapper = styled.div`
.fade-appear,
.fade-enter {
    opacity: 0;
    z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
    opacity: 1;
    transition: opacity 300ms linear;
}
.fade-exit {
    opacity: 1;
}  
.fade-exit.fade-exit-active {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 150ms linear;
}
`;

// add lang to url if it's not present
function langRedirect({ history, activeLanguage, setActiveLanguage }) {
  const { pathname } = history.location;

  
  //check if lang exists
  if(pathname.substring(0,3) != "/en" && pathname.substring(0,3) != "/fr") {

    let storageLocale = localStorage.getItem('locale');
    if (['en', 'fr'].indexOf(storageLocale) < 0) {
      storageLocale = 'en';
    }
  
    const defaultLang = activeLanguage && activeLanguage.code ? activeLanguage.code : storageLocale;
    localStorage.setItem('locale', defaultLang);
    setActiveLanguage(defaultLang);
    const redirectPath =
      pathname && pathname !== '/' ? `/${defaultLang}${pathname}` : defaultLang;
    history.replace({ pathname: redirectPath, });

  }
}

function mapStateToProps({ user }) { return { user, } };

export default connect(mapStateToProps, null) (withLocalize(withRouter(App)));
