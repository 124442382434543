import React from 'react';
import styled from 'styled-components';

import { Translate, withLocalize } from 'react-localize-redux';
import { colors, spacing, fonts } from '../../../../defaults/styles';
import Button from './../../../presentation/interface/Button';

const Paragraph = styled.p`
  background-color: ${colors.gray.light};
  font-size: ${fonts.small};
  padding: ${spacing.small}px;
  width: 100%;
`;

const buttonStyle = {
  display: "flex",
  marginTop: `${spacing.medium}px`,
  justifyContent: "center"
}

const SuccessMessage = () => {
  const t = (tag) => `resetPassword.success.${tag}`;

  return (
    <>
    <Paragraph>
      <Translate id={t('message')} />
    </Paragraph>
    <div style={buttonStyle}>
      <Button url={'/login'} size={16} fill={"true"} isHeader={"true"}>
      {<Translate id={t('login')} />}
      </Button>
    </div>
    </>
  );
};

export default withLocalize(SuccessMessage);
