import React, { useContext } from 'react';
import UIMain from './../../interface/UIMain';
import { ResizeAwareContext } from '../../../smart/ResizeContext';
import { withLocalize } from 'react-localize-redux';
import Intro from './Intro';

const ThankYouPage = ({ translate, activeLanguage }) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);
  const language =
  activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';
  document.title = translate('thankYou.title');

  return (
    <UIMain isMobile={isMobile} isTablet={isTablet}>
      <Intro isMobile={isMobile} isTablet={isTablet} />
    </UIMain>
  );
};

export default withLocalize(ThankYouPage);