import React, { useContext } from 'react';
import Name from './Name';
import Info from './Info';
import Numbers from './Numbers';
import Pricing from './Pricing';
import AddToShoppingList from './AddToShoppingList';
import AddToCart from './AddToCart';
import ZoomCatalog from './ZoomCatalog';
import { ResizeAwareContext } from '../../../../../smart/ResizeContext';
import { UserContainer, ShoppingListContainer } from '../../../../../smart';
import CartContainer from '../../../../../smart/Cart/CartContainer';

const Content = ({ product }) => {
  const { isMobile } = useContext(ResizeAwareContext);

  return (
    <section>
      <Name name={product.name} />
      <Numbers sku={product.sku} modelNumber={product.modelNumber} />
      <Info product={product} />
      <UserContainer>
        <Pricing product={product} />
        <CartContainer>
          <AddToCart sku={product.sku} statusId={product.statusId} isMobile={isMobile} />
        </CartContainer>
        <ShoppingListContainer>
          <AddToShoppingList sku={product.sku} isMobile={isMobile} />
          <ZoomCatalog
            sku={product.sku}
            statusId={product.statusId}
            isMobile={isMobile} />
        </ShoppingListContainer>
      </UserContainer>
    </section>
  );
};

export default Content;
