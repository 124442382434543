import React from 'react';
import FooterLink from './FooterLink';
import { Translate, withLocalize } from 'react-localize-redux';
import { lsStyled as styled } from './../../../../../../tools/helpers/lsStyled';
import {
  colors,
  spacing,
  typography,
} from './../../../../../../defaults/styles';

// == Styling == //
const FooterTitle = styled.h4`
  ${typography.h4};
  color: ${colors.white.normal};
  margin-bottom: ${spacing.medium}px;
`;
// == /Styling == //

const FooterLinksLeft = props => {
  const t = (tag) => `footer.company.${tag}`;

  return (
    <>
      <FooterTitle>{<Translate id={t('title')} />}</FooterTitle>
      <FooterLink url="/about-us" text={<Translate id={t('aboutUs')} />} />
      <FooterLink
        url="/privacy-policy"
        text={<Translate id={t('privacy')} />}
      />
      <FooterLink
        url="/terms-and-conditions"
        text={<Translate id={t('terms')} />}
      />
      <FooterLink url="/environment" text={<Translate id={t('global')} />} />
      {/* <FooterLink url="https://press.klfgroup.ca/" text={<Translate id={t('presses')} />} /> */}
      <FooterLink url="/account-request" text={<Translate id={t('accountRequest')} />} />
      <FooterLink url="https://www.incentiveconcepts.com/" text={<Translate id={t('usPartner')} />} />
    </>
  );
};

export default withLocalize(FooterLinksLeft);
