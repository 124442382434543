import React, { useContext } from 'react';
// import Radium from 'radium';
import { fonts, sizing, spacing } from './../../../defaults/styles';
import { ResizeAwareContext } from '../../smart/ResizeContext';

// Styles
const styles = {
  root: {
    textAlign: 'center',
    paddingTop: sizing.paddingEq,
    paddingBottom: sizing.paddingEq / 2,
    ...sizing.boundToContentWidth,
  },
  title: {
    fontFamily: fonts.title,
    fontSize: fonts.sizes.title,
    fontWeight: fonts.weights.normal,
    marginBottom: spacing.large,
  },
  p: {
    fontFamily: fonts.content,
    fontSize: fonts.sizes.large,
  },
};

const mobileLineHeight = 1.2;
const stylesMobile = {
  root: {
    ...styles.root,
    paddingTop: sizing.paddingEq / 4,
    paddingBottom: sizing.paddingEq / 4,
    paddingRight: spacing.small,
    paddingLeft: spacing.small,
  },
  title: {
    ...styles.title,
    fontSize: fonts.sizes.titleMobile,
    lineHeight: mobileLineHeight,
  },
  p: {
    ...styles.p,
    lineHeight: mobileLineHeight,
  },
};

// Component
const PageReinformcement = ({ title, body }) => {
  const { isMobile } = useContext(ResizeAwareContext);
  return (
    <section style={isMobile ? stylesMobile.root : styles.root}>
      <h2 style={isMobile ? stylesMobile.title : styles.title}>
        {typeof title === 'string'
          ? title
          : title.map((line, index) => (
              <span key={index} style={{ all: 'inherit', marginBottom: 0 }}>
                {line}
              </span>
            ))}
      </h2>
      <p style={isMobile ? stylesMobile.p : styles.p}>{body}</p>
    </section>
  );
};
// export default Radium(PageReinformcement);
export default PageReinformcement;
