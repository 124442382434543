import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Translate, withLocalize } from 'react-localize-redux';
import { colors, spacing, fonts } from '../../../../../defaults/styles';

const fadeIn = keyframes`
  0% { opacity: 0; }
  25% {opacity: 0.9;}
  30% {opacity: 1;}
  100% {opacity: 1;}
`;

const MessageParagraph = styled.p`
  color: ${colors.primary.normal};
  font-size: ${fonts.small};
  text-align: center;
  width: 100%;
  opacity: 1;
  margin-bottom: ${spacing.large}px;
  left: 0;
  animation: ${fadeIn} 2s 1;
`;

const ErrorMessage = (errorKey) => {
  const t = (tag) => `login.errors.${tag}`;

  return (
    <MessageParagraph>
      <Translate id={t(errorKey.errorKey)} />
    </MessageParagraph>
  );
};

export default withLocalize(ErrorMessage);
