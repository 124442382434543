// Because the mobile and desktop headers are drastically different,
// rather than having a bunch of if statements everywhere,
// There are two completely different components for them.
// They share almost all the same data (stored in content.js)

import React from 'react';
import DesktopMenu from './DesktopMenu';
import MobileMenu from './MobileMenu';

const HeaderContainer = ({
  productsPopupOpen,
  openProductsPopupClickHandler,
  setSelectedCategory,
  mobileMenuOpen,
  setMobileMenuOpen,
  isMobile,
  isTablet
}) => {
  return !isMobile ? (
    <DesktopMenu
      productsPopupOpen={productsPopupOpen}
      openProductsPopupClickHandler={openProductsPopupClickHandler}
      setSelectedCategory={setSelectedCategory}
    />
  ) : (
    <MobileMenu
      productsPopupOpen={productsPopupOpen}
      openProductsPopupClickHandler={openProductsPopupClickHandler}
      setSelectedCategory={setSelectedCategory}
      mobileMenuOpen={mobileMenuOpen}
      setMobileMenuOpen={setMobileMenuOpen}
      isMobile={isMobile}
      isTablet={isTablet}
    />
  );
}

export default HeaderContainer;
