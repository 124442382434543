import { LSFetch } from '../helpers';

export const contactUs = params => {
  return LSFetch.post('/user/request', {
    params,
    headers: { 'Content-type': 'application/json' },
  })
};

  export const accountRequest = params => {
    return LSFetch.post('/accountRequest', {
      params,
      headers: { 'Content-type': 'application/json' },
  })
};
