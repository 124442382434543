import React from 'react';
import PropTypes from 'prop-types';
import Button from './Button';

const defaultStyle = {
  display: 'inline-block',
};

const ZachButton = ({ children, style = {}, onClick, ...passProps }) => (
  <div style={{ ...defaultStyle, ...style }} onClick={onClick}>
    <Button {...passProps}>{children}</Button>
  </div>
);

ZachButton.propTypes = {
  style: PropTypes.object,
  onClick: PropTypes.func,
};

export default ZachButton;
