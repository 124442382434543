import React from 'react';
import styled from 'styled-components';

import UIContainer from '../../interface/UIContainer';
import UISection from '../../interface/UISection';
import { withLocalize } from 'react-localize-redux';



const ImageBox = styled.div`
  order: ${props => (props.isMobile || props.isTablet ? 2 : null)};
`;

const Form = ({ isMobile, isTablet, activeLanguage }) => {
  const t = (tag) => `services.gifting.catalog.${tag}`;
  const lang = activeLanguage ? activeLanguage.code : 'en';
  var contactUrl = '';
  if (lang == "fr") {
    contactUrl = 'https://share.hsforms.com/1JoO_NcfrTn-6Uv-G75foegduh8y'
  } else {
    contactUrl = 'https://share.hsforms.com/1zHFM36o9Sdqdc-Efc9fRkwduh8y'
  }

  return (
    <UIContainer noPadding isMobile={isMobile} isTablet={isTablet}>
      <UISection>
        <ImageBox isMobile={isMobile} isTablet={isTablet}>
          <iframe width="100%" height="800px" src={contactUrl}></iframe>
        </ImageBox>
      </UISection>
    </UIContainer>
  );
};

export default withLocalize(Form);
