import React from 'react';
import { vendorPrefix } from '../../../tools/helpers/styling';

// Styles
const styles = {
  root: {
    backgroundColor: 'white',
    height: 80,
    position: 'relative',
    width: '100vw',
  },
  top: {
    ...vendorPrefix(
      'clipPath',
      'polygon(25% 5%, 100% 50%, 100% 0, 0 0, 0 50%)'
    ),
    top: 75,
  },
  bottom: {
    ...vendorPrefix(
      'clipPath',
      'polygon(60% 50%, 100% 20%, 100% 100%, 0px 100%, 0px 20%)'
    ),
    height: 150,
    top: -80,
  },
  secondaryTop: {
    ...vendorPrefix(
      'clipPath',
      'polygon(75% 5%, 100% 50%, 100% 0, 0 0, 0 50%)'
    ),
    top: 75,
  },
  // secondaryBottom: {
  //   ...vendorPrefix(
  //     'clipPath',
  //     'polygon(25% 95%, 100% 50%, 100% 100%, 0 100%, 0 50%)'
  //   ),
  //   top: 1,
  // },
  tertiaryTop: {
    ...vendorPrefix(
      'clipPath',
      'polygon(75% 5%, 100% 50%, 100% 0, 0 0, 0 50%)'
    ),
    top: 75,
  },
  tertiaryBottom: {
    ...vendorPrefix(
      'clipPath',
      'polygon(25% 95%, 100% 50%, 100% 100%, 0 100%, 0 50%)'
    ),
    top: -75,
  },
  aboutUsTop: {
    ...vendorPrefix(
      'clipPath',
      'polygon(20% 5%, 100% 50%, 100% 0, 0 0, 0 50%)'
    ),
    top: 75,
  },
  footer: {
    ...vendorPrefix(
      'clipPath',
      'polygon(35% 5%, 100% 50%, 100% 0, 0 0, 0 50%)'
    ),
    top: -1,
  },
};

// Component
const BgPolygon = ({
  polygonTop = false,
  polygonBottom = false,
  polygonSecondaryTop = false,
  // polygonSecondaryBottom = false,
  polygonTertiaryTop = false,
  polygonTertiaryBottom = false,
  polygonAboutUsTop = false,
  // polygonFooter = false,
  isEnvironmentPageFooter = false,
}) => (
  <div
    style={
      polygonTop
        ? { ...styles.root, ...styles.top }
        : polygonBottom
        ? { ...styles.root, ...styles.bottom }
        : polygonSecondaryTop
        ? { ...styles.root, ...styles.secondaryTop }
        : // : polygonSecondaryBottom
        // ? { ...styles.root, ...styles.secondaryBottom }
        polygonTertiaryTop
        ? { ...styles.root, ...styles.tertiaryTop }
        : polygonAboutUsTop
        ? { ...styles.root, ...styles.aboutUsTop }
        : polygonTertiaryBottom
        ? { ...styles.root, ...styles.tertiaryBottom }
        : isEnvironmentPageFooter 
        ? { ...styles.root, ...styles.footer, backgroundColor: '#c1dfa9', } 
        : { ...styles.root, ...styles.footer }
    }
  />
);

export default BgPolygon;
