import * as types from './types'
import { banner } from '../../tools/api'
import { beginApiCall, apiCallError } from './apiStatusActions'

export const loadLandingPageBannersSuccess = banners => ({ type: types.LOAD_LANDING_PAGE_BANNER_SUCCESS, banners });

export const loadLandingPageBanners = (params) => {
    return (dispatch) => {
        dispatch(beginApiCall());
        return banner
            .getLandingPageBanners(params)
            .then(({ json: banners }) => {
                if (banners.length === 0) {
                    dispatch(loadLandingPageBannersSuccess(['N/A']));
                } else {
                    dispatch(loadLandingPageBannersSuccess(banners));
                }
            })
            .catch(error => {
                dispatch(apiCallError(error))
                throw error
            })
    }
}