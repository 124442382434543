import React, { useContext } from 'react';
import UIMain from './../../interface/UIMain';
import Intro from './Intro';
import AffordableServices from './AffordableServices';
import Experts from './Experts';
import LastMile from './LastMile';
import { withLocalize } from 'react-localize-redux';
import { ResizeAwareContext } from '../../../smart/ResizeContext';

const ServicesFulfilmentPage = ({ translate }) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);
  document.title = translate('services.fulfillment.title');

  return (
    <UIMain isMobile={isMobile} isTablet={isTablet}>
      <Intro isMobile={isMobile} isTablet={isTablet} />
      <AffordableServices isMobile={isMobile} isTablet={isTablet} />
      <Experts isMobile={isMobile} isTablet={isTablet} />
      <LastMile isMobile={isMobile} isTablet={isTablet} />
    </UIMain>
  );
};

export default withLocalize(ServicesFulfilmentPage);
