import React, { useState, useContext } from 'react';
import { withRouter } from 'react-router-dom';
import Top from './Top';
import Bar from './Bar';
import { lsStyled as styled } from './../../../../tools/helpers/lsStyled';
import { colors } from './../../../../defaults/styles';
import { ResizeAwareContext } from '../../../smart/ResizeContext';

// == Styling == //
const Footer = styled.footer`
  background-color: ${colors.primary.dark};
  color: ${colors.white.normal};
`;

// == /Styling == //

// const stylesMobile = {
//   ...styles.root,
//   marginTop: spacing.veryLarge,
// };

const MainFooter = ({ history }) => {
  const [isEnvironmentPageFooter, setIsEnvironmentPageFooter] = useState(
    window.location.href.indexOf('environment') !== -1
  );

  history.listen(location => {
    if (location.pathname.indexOf('environment') !== -1) {
      setIsEnvironmentPageFooter(true);
    } else {
      setIsEnvironmentPageFooter(false);
    }
  });

  const { isMobile, isTablet } = useContext(ResizeAwareContext);

  return (
    <Footer>
      <Top isMobile={isMobile} isTablet={isTablet} />
      <Bar isMobile={isMobile} isTablet={isTablet} />
    </Footer>
  );
};

export default withRouter(MainFooter);
