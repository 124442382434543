import React from 'react';
import styled from 'styled-components';
import { fonts, colors, spacing } from '../../../../../defaults/styles';
import { Translate, withLocalize } from 'react-localize-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/pro-solid-svg-icons';

const InputContainer = styled.div`
  position: relative;
  margin-bottom: ${props =>
    props.lastChild
      ? `${spacing.veryLarge}px`
      : `${spacing.large}px`};

  /* for checkbox */
  display: ${props => (props.isCheckbox ? 'grid' : null)};
  grid-gap: ${props => (props.isCheckbox ? `${spacing.small + 'px'}` : null)};
  grid-template-columns: ${props => (props.isCheckbox ? 'auto 1fr' : null)};
  align-items: ${props => (props.isCheckbox ? 'center' : null)};
  justify-content: ${props => (props.isCheckbox ? 'center' : null)};
`;

const Label = styled.label`
  color: ${colors.gray.normal};
  font-size: ${props => props.isCheckbox ? '12px' : fonts.sizes.text + 'px'};
  line-height: ${props => props.isCheckbox ? 1.2 : 'inherit'};
`;

const Input = styled.input`
  border: 1px solid ${colors.gray.light};
  border-radius: 3px;
  line-height: ${props => props.isCheckbox ? 1.2 : 'inherit'};
  margin-top: ${spacing.small}px;
  padding-top: ${spacing.small}px;
  padding-right: ${spacing.small}px;
  padding-bottom: ${spacing.small}px;
  padding-left: ${props => props.noIcon ? spacing.small + 'px' : spacing.veryLarge + 'px'};
  width: ${props => (props.isCheckBox ? '15px' : '100%')};

  &:focus {
    border: 1px solid ${colors.main.normal};
    box-shadow: 0 0 0 1px ${colors.main.normal};
  }
`;
const IconPlaceholder = styled.span`
  cursor: ${props => (props.alignRight ? 'pointer' : 'default')};
  opacity: ${props => (props.alignRight ? '0.8' : '0.1')};
  position: absolute;
  top: 50%;
  margin-top: 0.4em;
  left: ${props => (props.alignRight ? null : spacing.small + 'px')};
  right: ${props => (props.alignRight ? spacing.small + 'px' : null)};
  transform: translateY(-50%);
`;

export const InputField = withLocalize(
  ({
    id,
    lexiconId,
    onChange,
    onTogglePassword,
    required = true,
    autoFocus = false,
    type = 'text',
    faIcon = null,
    form,
    lastChild = false,
    styleLoginReg = false,
    mfaBypassPeriod,
    maxLength,
  }) => {
    return (
      <>
        {type !== 'checkbox' && (
          <Label htmlFor={id} styleLoginReg={styleLoginReg}>
            <Translate id={lexiconId} />
          </Label>
        )}
        <InputContainer
          lastChild={lastChild}
          styleLoginReg={styleLoginReg}
          isCheckbox={type === 'checkbox'}
        >
          {faIcon && (
            <IconPlaceholder>
              <FontAwesomeIcon icon={faIcon} />
            </IconPlaceholder>
          )}
          <Input
            type={type}
            id={id}
            name={id}
            value={form[id]}
            onChange={onChange}
            required={required}
            autoFocus={autoFocus}
            isCheckBox={type === 'checkbox'}
            noIcon={faIcon === null}
            maxLength={maxLength}
          />

          {(onTogglePassword) && (
            <IconPlaceholder alignRight onClick={onTogglePassword}>
              {form.showPassword ? (
                <FontAwesomeIcon icon={faEyeSlash} />
                ) : (
                  <FontAwesomeIcon icon={faEye} />
                )}
              </IconPlaceholder>
            )}
          {type === 'checkbox' && (
            <Translate>
              {({ translate }) => {
                  let translatedText = translate(lexiconId, null, { renderInnerHtml: true });

                  if(id === 'isMfaBypassEnabled') {
                    translatedText = translate(lexiconId).replace("*", mfaBypassPeriod);
                  }

                return (
                  <Label 
                    for={id} 
                    isCheckbox={type === 'checkbox'}
                  >
                    {translatedText}
                  </Label>
                );
              }}
            </Translate>
          )}
        </InputContainer>
      </>
    );
  }
);
