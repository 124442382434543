import React from 'react';
import Currency from 'react-currency-formatter';
import { withLocalize, Translate } from 'react-localize-redux';
import { colors, spacing, fonts } from '../../../../../../defaults/styles';
import LoginRequired from '../Content/LoginRequired';

const styles = {
  item: {
    display: 'flex',
    marginBottom: spacing.verySmall,
    borderBottom: `1px dashed ${colors.gray.light}`,
    paddingBottom: spacing.verySmall,
  },
  label: {
    marginRight: spacing.verySmall,
    flex: '1 0 auto',
    paddingLeft: spacing.small,
  },
  value: { flex: '0 0 auto', paddingRight: spacing.small },
};

const EstimatedShipping = ({
  user,
  provinces,
  average,
  activeLanguage,
  isMobile,
  translate
}) => {
  const t = (tag) => `product.shipping.${tag}`;
  const tp = (tag) => `provinces.canada.${tag}`;

  const loggedIn = user && user.loggedIn;
  const locale = (activeLanguage && activeLanguage.code) || 'en';

  const currency = value => {
    if (parseInt(value)) {
      return <Currency quantity={value} currency="CAD" locale={locale} />;
    }

    return <>N/A</>;
  };

  return (
    <section
      style={{
        borderTopWidth: 1,
        borderTopStyle: 'solid',
        borderTopColor: colors.gray.light,
        paddingTop: spacing.medium,
        marginTop: spacing.small,
      }}
    >
      <header style={{ marginBottom: spacing.medium }}>
        <h3 style={{ fontFamily: fonts.title }}>
          <Translate id={t('title')} />
        </h3>
      </header>
      <section style={{ marginBottom: spacing.medium }}>
        {loggedIn && average && provinces ? (
          <ul style={{ columns: isMobile ? 1 : 2 }}>
            <li style={styles.item}>
              <div style={styles.label}>
                <Translate id={t('averageShipping')} />
              </div>
              <div style={styles.value}>{currency(average)}</div>
            </li>
            {provinces.map((province, index) => (
              <li key={index} style={styles.item}>
                <div style={styles.label}>{translate(tp(index))}</div>
                <div style={styles.value}>{currency(province.cost)}</div>
              </li>
            ))}
          </ul>
        ) : (
          <LoginRequired />
        )}
      </section>
      {loggedIn && (
        <footer>
          <p
            style={{
              color: colors.gray.normal,
              fontSize: fonts.sizes.smallText,
            }}
          >
            <Translate id={t('disclaimer')} />
          </p>
        </footer>
      )}
    </section>
  );
};

export default withLocalize(EstimatedShipping);
