
import React, { useEffect, useState, useRef, forwardRef, } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart as fasHeart } from '@fortawesome/pro-solid-svg-icons';
import { faHeart as falHeart } from '@fortawesome/pro-light-svg-icons';
import { addProductIdEventHandler, removeProductIdEventHandler, createShoppingListEventHandler } from '../../../../../redux/actions/shoppingListActions';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';
import { colors } from '../../../ui/common';

const AddToDefaultButton = styled.div`
    cursor: pointer;
    font-size: 1.5rem;
    bottom: 0.5rem;
    right: 0.5rem;
    z-index: 100;
`;

const IconBox = styled.div`
    color: ${colors.primary.normal};
    display: absolute;
    bottom: 0;
    right: 0;
    height: 0.8rem;
    width: 0.8rem;

    > * {
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .animation {
        opacity: ${props => props.hovered
        ? 0.5 
        : props.listContainsSku
            ? 1
            : 0  
        };
        transition: opacity 250ms;
    }
`;

export const AddToShoppingListButton = ({
    user,
    apiCallsInProgress,
    activeLanguage,
    sku, 
    isMobile,
    shoppingLists,
    currentQuery,
    addProductIdEventHandler,
    removeProductIdEventHandler,
    translate,
}) => {
    const t = (tag) => `catalog.shoppingList.${tag}`;
    
    const [ selectedListId, setSelectedListId ] = useState(-1);
    const [ listContainsSku, setListContainsSku ] = useState(false);
    const [ hovered, setHovered ] = useState(false);
    useEffect(() => {
        if(!shoppingLists){
            setSelectedListId(-1);
            setListContainsSku(null);
            return;
        }
        const newSelectedList = currentQuery && currentQuery.shoppingList ?
                    shoppingLists.find(({id})=>Number(currentQuery.shoppingList) === id)
                    : shoppingLists.find(({defaultList})=>defaultList);
        if(newSelectedList){
            setSelectedListId(newSelectedList.id);
            setListContainsSku(newSelectedList.productIds.includes(sku));
        } else {
            setSelectedListId(-1);
            setListContainsSku(null);
        }
    }, [ shoppingLists ]);

    const onClickDefaultButton = ()=>{
        if(shoppingLists){
            if (!listContainsSku) {
                addProductIdEventHandler({value: selectedListId}, sku, shoppingLists);
                setListContainsSku(true);
            } else {
                removeProductIdEventHandler({value: selectedListId}, sku, shoppingLists);
                setListContainsSku(false);
            }
        }
    }

    return <> { user && user.loggedIn && 
            (<AddToDefaultButton 
                    isMobile={isMobile}
                    onMouseEnter={() => setHovered(true) }
                    onMouseLeave={() => setHovered(false) }
                    onClick={onClickDefaultButton}
                    title = {
                        !shoppingLists || shoppingLists.length === 0 ?
                        translate(t('noShoppingList')) :
                            listContainsSku ?
                            translate(t('removeFromList')) :
                            translate(t('addToList'))
                    }
                >
                <IconBox isMobile={isMobile} hovered={hovered} listContainsSku={listContainsSku}>
                    <div className="animation">
                        <FontAwesomeIcon icon={ fasHeart }/>
                    </div>
                    <FontAwesomeIcon icon={ falHeart }/>
                </IconBox>
            </AddToDefaultButton> )
        }
    </>;
}

function mapStateToProps({ shoppingLists, user }) {
    return { shoppingLists, user };
}
const mapDispatchToProps = {
    addProductIdEventHandler,
    removeProductIdEventHandler,
    createShoppingListEventHandler,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
) (withLocalize(AddToShoppingListButton));