import * as types from '../actions/types';
import { previousCategoryFilters } from './initialState';

export default function previousCategoryFiltersReducer(
  state = previousCategoryFilters,
  actions
) {
  switch (actions.type) {
    case types.SET_PREVIOUS_CATEGORY_FILTERS:
      return { ...actions.previousCategoryFilters };
    default:
      return state;
  }
}
