import { LSFetch } from "../helpers";

export const getShoppingLists = () => {
  return LSFetch.get("/product/shoppinglist");
};

export const createShoppingList = (params) => {
  return LSFetch.post("/product/shoppinglist", params);
};

export const deleteShoppingList = (listId) => {
  return LSFetch.del("/product/shoppinglist/" + listId);
};

export const modifyShoppingList = (listId, extras) => {
  return LSFetch.put("/product/shoppinglist/" + listId, extras);
};

export const addProductToShoppingList = (listId, extras) => {
  return LSFetch.post("/product/shoppinglist/" + listId, extras);
};

export const removeProductFromShoppingList = (listId, sku) => {
  return LSFetch.del("/product/shoppinglist/" + listId + "/" + sku);
};

export const exportShoppingList = (listId, params) => {
  return LSFetch.get("/product/shoppinglist/" + listId + "/export", params);
};

export const downloadShoppingList = (listId, params) => {
  return LSFetch.getRaw("/product/shoppinglist/" + listId + "/download", params);
};