import React from 'react';
import Search from '../../Search';
import ContactButton from './ContactButton';
import UserSection from './UserSection';
import { lsStyled as styled } from './../../../../../../../tools/helpers/lsStyled';
import { FiltersContainer, UserContainer } from '../../../../../../smart';
import NavigateToCheckoutButton from '../../../../Cart/NatigateToCartButton';

// == Styling == //
const Section = styled.section`
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 1em;
  align-items: center;
  justify-content: space-between;
`;

function Actions() {
  return (
    <Section>
      <FiltersContainer>
        <Search />
      </FiltersContainer>
      <NavigateToCheckoutButton />
      <ContactButton />
      <UserContainer>
        <UserSection />
      </UserContainer>
    </Section>
  );
}

export default Actions;
