import React, { useState, useEffect } from 'react';
import Button from './../../interface/Button';
import Radium from 'radium';
import ErrorMessage from './ErrorMessage';
import SuccessMessage from './SuccessMessage';
import { vendorPrefix } from '../../../../tools/helpers/styling';
import { fonts, colors, spacing } from '../../../../defaults/styles';
import { Translate, withLocalize } from 'react-localize-redux';
import { accountRequest } from '../../../../tools/api/misc';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import ReCAPTCHA from 'react-google-recaptcha';
import { getCAPTCHAKey } from '../../../../tools/helpers/recaptcha';
import Paragraph from '../../interface/PageSection/Paragraph';
import UIGrid2cols from '../../interface/UIGrid2cols';
import UISection from '../../interface/UISection';
import UIParagraph from '../../interface/UIParagraph';
import UIContainer from '../../interface/UIContainer';
import GridLoader from 'react-spinners/GridLoader';

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: `0 auto ${spacing.marginStandard} auto`,
    maxWidth: 720,
    // flex is defined in parent(index) page.
    // flexBasis: '40%',
  },
  title: {
    fontSize: fonts.sizes.subHeading,
    fontWeight: fonts.weights.title,
    marginBottom: spacing.large,
  },
  inputContainer: {
    marginTop: spacing.medium,
    marginBottom: spacing.medium,
  },
  select: {
    // reset styling
    ...vendorPrefix('appearance', 'none'),
    backgroundColor: 'white',
    border: `1px solid ${colors.gray.light}`,
    borderRadius: 0,
    color: colors.gray.normal,
    fontSize: fonts.sizes.text,
    marginBottom: spacing.small,
    padding: spacing.verySmall,
    cursor: 'pointer',
    width: '100%',
  },
  twoCol: {
    display: 'flex',
  },
};
  
const RequestForm = ({ isMobile, activeLanguage, translate, ...props }) => {
  const t = (tag) => `accountRequest.form.${tag}`;
  // language
  const lang = activeLanguage && activeLanguage.code === 'fr' ? 'fr-CA' : 'en';
  // state for the form
  const [form, setValues] = useState({
    // when any error appears from smart component
    error: false,
    // success
    success: false,
  });

  const [captchaFlashing, setCaptchaFlashing] = useState(false);
  const [dropdownValidation, setDropdownValidation] = useState({
    country: '',
    select_language: '',
    businessType: '',
  });
  const [showLoader, setShowLoader] = useState(false);

  function checkValValidity(targetName) {
    // onBlur
    if (targetName) {
      if (!form[targetName] || form[targetName].length < 3) {
        return false;
      } else {
        return true;
      }
    }
    // onSubmit
    if (!targetName) {
      if (
        !form.select_language ||
        !form.country ||
        !form.businessType ||
        !form.captcha ||
        form.captcha.length < 3
      ) {
        return false
      } else {
        return true
      }
    }
  }

  const validateDropdowns = () => {
    const newErrors = {
      country: form.country ? '' : 'Country is required',
      select_language: form.select_language ? '' : 'Language is required',
      businessType: form.businessType ? '' : 'Business Type is required',
    };

    setDropdownValidation(newErrors);

    // Return true if there are no errors, false otherwise
    return Object.values(newErrors).every((error) => !error);
  };

  const handleSubmit = e => {
    e.preventDefault();
    setShowLoader(true);
    let errors = false;
    let valLengthValidity = checkValValidity();
    errors = valLengthValidity ? false : true;
    if (!validateDropdowns()){
      setShowLoader(false);
      window.scroll(0,150);
    };
    if(!form.captcha){
      setCaptchaFlashing(true);
    }
    if (!errors) {
      const {
        company,
        address,
        city,
        province,
        country,
        zip,
        website,
        officePhone,
        businessType,
        presidentOwner,

        select_language,

        firstname,
        lastname,
        title,
        email,
        phone,
        phoneExtension,

        accountsPayableFirstName,
        accountsPayableLastName,
        accountsPayableTitle,
        accountsPayablePhone,
        accountsPayablePhoneExtension,
        accountsPayableEmail,
    
        coordinatorFirstName,
        coordinatorLastName,
        coordinatorTitle,
        coordinatorPhone,
        coordinatorPhoneExtension,
        coordinatorEmail,
    
        purchaserFirstName,
        purchaserLastName,
        purchaserTitle,
        purchaserPhone,
        purchaserPhoneExtension,
        purchaserEmail,

        rewardsRecognition,
        yearsService,
        corporateGifting,
        eventsGifting,
        safetyTraining,

        captcha,
      } = form;
      
      // required params
      // Some of the form field names are different than our DTO field names, to conform to what Hubspot expects
      const params = {
        companyName : company,
        address,
        city,
        province,
        country,
        postalCode : zip,
        url: website,
        officePhone,
        businessType,
        presidentOwner,

        preferredLanguage : select_language,
    
        keyContactName : firstname+" "+lastname,
        keyContactTitle: title,
        keyContactEmail: email,
        keyContactPhone: phone + (phoneExtension ? "#" + phoneExtension : ''),

        accountsPayableName :accountsPayableFirstName+" "+accountsPayableLastName,
        accountsPayableTitle,
        accountsPayablePhone : accountsPayablePhone + (accountsPayablePhoneExtension ? "#" + accountsPayablePhoneExtension : ''),
        accountsPayableEmail,

        captcha,
      };
      // optional params
      params.rewardsRecognition = rewardsRecognition ? rewardsRecognition : false;
      params.yearsService = yearsService ? yearsService : false;
      params.corporateGifting = corporateGifting ? corporateGifting : false;
      params.eventsGifting = eventsGifting ? eventsGifting : false;
      params.safetyTraining = safetyTraining ? safetyTraining : false;

      //For the hubspot integration, we needed the key contact to have separate fields
      // for first and last name, even though we store name as a single field in our database.
      //Upon request, we used two separate fields for the other names in the form to maintain visual consistency.
      //Since the name fields below aren't required, this condition ensures concatenation doesn't store "undefined"s in the DB
      if (coordinatorFirstName || coordinatorLastName){
        params.coordinatorName = (coordinatorFirstName ? coordinatorFirstName + " " : "") +
        (coordinatorLastName ? coordinatorLastName : "");
      } 
      params.coordinatorTitle = coordinatorTitle;
      if (coordinatorPhone){
        params.coordinatorPhone = coordinatorPhone + (coordinatorPhoneExtension ? "#"+coordinatorPhoneExtension : '');
      } 
      params.coordinatorEmail = coordinatorEmail;
    
      if (purchaserFirstName || purchaserLastName){
        params.purchaserName = (purchaserFirstName ? purchaserFirstName + " " : "") +
        (purchaserLastName ? purchaserLastName : "");
      } 
      params.purchaserTitle = purchaserTitle;
      if (purchaserPhone){
        params.purchaserPhone = purchaserPhone + (purchaserPhoneExtension ? "#"+purchaserPhoneExtension : '');
      }
      params.purchaserEmail = purchaserEmail;
      window.scroll(0, 0);
      accountRequest(params)
        .then(({ status }) => {
          setShowLoader(false);
          if (status < 200 || status > 202) errors = true;
          handleSubmitErrors(errors);
        })
        .catch(res => {
          setShowLoader(false);
          console.log(res.error)
          handleSubmitErrors(res.error);
        });
      return;
    } else {
      setShowLoader(false);
    }

    handleSubmitErrors(errors);
  };

  const handleSubmitErrors = errors => {
    if (errors)
      return setValues({
        ...form,
        errorRequired: true,
      });
     
    // if no error
    return setValues({
      company: '',
      address: '',
      city: '',
      province: '',
      country: '',
      zip: '',
      website: '',
      officePhone: '',
      businessType: '',
      presidentOwner: '',

      select_language: '',
  
      firstname: '',
      lastname: '',
      title: '',
      email: '',
      phone: '',
      phoneExtension: '',

      accountsPayableFirstName: '',
      accountsPayableLastName: '',
      accountsPayableTitle: '',
      accountsPayablePhone: '',
      accountsPayablePhoneExtension: '',
      accountsPayableEmail: '',
  
      coordinatorFirstName: '',
      coordinatorLastName: '',
      coordinatorTitle: '',
      coordinatorPhone: '',
      coordinatorPhoneExtension: '',
      coordinatorEmail: '',
  
      purchaserFirstName: '',
      purchaserLastName: '',
      purchaserTitle: '',
      purchaserPhone: '',
      purchaserPhoneExtension: '',
      purchaserEmail: '',

      rewardsRecognition: false,
      yearsService: false,
      corporateGifting: false,
      eventsGifting: false,
      safetyTraining: false,

      success: true,
      error: false,
    });
  };

  const handleBlur = e => {
    const re = /^[a-zA-Z0-9_\-\s,.àâäçèéêëîïôœùûüÿÀÂÄÇÈÉÊËÎÏÔŒÙÛÜŸ\']+$/;
    if ( (e.target.type === 'text' || e.target.type === 'tel') && !re.test(e.target.value) ) {
      setValues({
        ...form,
        [e.target.name]: '',
      });
      return
    }
    const re2 = /^[0-9\b]+$/;
    if ( (e.target.type === 'tel') && !re2.test(e.target.value) ) {
      setValues({
        ...form,
        [e.target.name]: '',
      });
      return
    }
    const emailRegex = /^[\w!#$%&’*+/=?`{|}~^-]+(?:\.[\w!#$%&’*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}$/;
    if ( (e.target.type === 'email') && !emailRegex.test(e.target.value) ) {
      setValues({
        ...form,
        [e.target.name]: '',
      });
      return
    }
  };

  const handleUpdate = e => {
    sessionStorage.setItem("accountData", JSON.stringify(form));
    // restrict to type only numbers in input[type=tel] fields
    if (e.target.type === 'tel') {
      const re = /^[0-9\b]+$/;
      if (e.target.value === '' || re.test(e.target.value)) {
        setValues({
          ...form,
          [e.target.name]: e.target.value,
        });
      }
      return;
    }

    // adding 'http' string for website field to pass html validation
    if (e.target.type === 'url') {
      let str = e.target.value;
      if (!~str.indexOf('http')) {
        str = 'http://' + str;
      }
      setValues({
        ...form,
        [e.target.name]: str,
      });
      return;
    }

    if (e.target.type === 'checkbox') {
      setValues({
        ...form,
        [e.target.name]: e.target.checked,
      });
      return;
    } 
    
    if (e.target.type === 'integer'){
      setValues({
        ...form,
        [e.target.name]: parseInt(e.target.value, 10),
      });
      return;
    }

    if (e.target.type === 'email'){
      setValues({
        ...form,
        [e.target.name]: e.target.value,
      });
      return;
    }

    const re = /^[a-zA-Z0-9_\-\s,.àâäçèéêëîïôœùûüÿÀÂÄÇÈÉÊËÎÏÔŒÙÛÜŸ\']+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
      setValues({
        ...form,
        [e.target.name]: e.target.value,
      });
    }
    return
  };

  const onReCAPTCHAChange = (value) => {
    setValues({
      ...form,
      captcha: value,
    });
  }

  useEffect(() => {
    if (captchaFlashing) {
      const intervalId = setInterval(() => {
        setCaptchaFlashing((prev) => !prev);
      }, 5000); // Adjust the interval duration as needed

      // Clear the interval after a certain duration or on component unmount
      setTimeout(() => {
        clearInterval(intervalId);
        setCaptchaFlashing(false);
      }, 500); // Adjust the duration of the flashing effect
    }
  }, [captchaFlashing]);

  useEffect(() => {
    const savedFormData = sessionStorage.getItem("accountData");
    if (savedFormData !== null) {
      const formDataObject = JSON.parse(savedFormData);
      setValues(prevValues => ({ ...prevValues, ...formDataObject, captcha: '' }));
    }
  }, []);

  return (
    <>
     { showLoader
    ?
     <UIContainer>
        <GridLoader
          css={{ margin: 'auto' }}
          size={fonts.sizes.text}
          color={colors.main.normal}
        />
      </UIContainer>
    :
     !form.success &&
      <form style={styles.form} onSubmit={handleSubmit} id={'ProspectAccount'}>
        <UIParagraph customStyle={{margin:'auto'}} textAlign="center" >{translate(t('subTitle'))}</UIParagraph>
        <div style={styles.inputContainer}>
          <Input
            type={'text'}
            id={'company'}
            name={'company'}
            maxLength={50}
            placeholder={'*'+translate(t('companyName'))}
            value={form.company || ''}
            onChange={handleUpdate}
            onBlur={handleBlur}
            required
            autoFocus
            disabled={form.success ? 'disabled' : ''}
          />
          <Input
            type={'text'}
            id={'address'}
            name={'address'}
            maxLength={50}
            placeholder={'*'+translate(t('address'))}
            value={form.address || ''}
            onChange={handleUpdate}
            onBlur={handleBlur}
            required
            style={{ marginRight: '0px' }}
            disabled={form.success ? 'disabled' : ''}
          />
          <div style={props.isMobile ? {} : styles.twoCol}>
            <Input
              type={'text'}
              id={'city'}
              name={'city'}
              maxLength={50}
              placeholder={'*'+translate(t('city'))}
              value={form.city || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              required
              disabled={form.success ? 'disabled' : ''}
            />
            <Input
              type={'text'}
              id={'province'}
              name={'province'}
              maxLength={50}
              placeholder={'*'+translate(t('province'))}
              value={form.province || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              required
              disabled={form.success ? 'disabled' : ''}
            />
          </div>
          <div style={props.isMobile ? {} : styles.twoCol}>
          <Input
              type={'text'}
              id={'zip'}
              name={'zip'}
              maxLength={10}
              placeholder={'*'+translate(t('postalCode'))}
              value={form.zip || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              required
              disabled={form.success ? 'disabled' : ''}
            />
            <Select
              id={'country'}
              name={'country'}
              value={form.country}
              onChange={handleUpdate}
              required
              disabled={form.success ? 'disabled' : ''}
              type={'integer'}
              style={dropdownValidation.country ? {border: '2px solid red'} : {border: '1px solid grey'}}
            >
              <option default>{'*'+translate(t('country'))}</option>
              <option value="1">{translate(t('canada'))}</option>
              <option value="2">{translate(t('usa'))}</option>
              <option value="3">{translate(t('otherCountry'))}</option>
            </Select>
          </div>
          <Input
            type={'url'}
            id={'website'}
            name={'website'}
            maxLength={500}
            placeholder={'*'+translate(t('companyWebsite'))}
            value={form.website || ''}
            onChange={handleUpdate}
            onBlur={handleBlur}
            required
            disabled={form.success ? 'disabled' : ''}
          />
          <div style={props.isMobile ? {} : styles.twoCol}>
            <Input
              type={'tel'}
              id={'officePhone'}
              name={'officePhone'}
              maxLength={12}
              placeholder={'*'+translate(t('officePhone'))}
              value={form.officePhone || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              required
              disabled={form.success ? 'disabled' : ''}
            />
            <Select
              id={'businessType'}
              name={'businessType'}
              value={form.businessType}
              onChange={handleUpdate}
              required
              disabled={form.success ? 'disabled' : ''}
              style={dropdownValidation.businessType ? {border: '2px solid red'} : {border: '1px solid grey'}}
            >
              <option default>{'*'+translate(t('businessType'))}</option>
              <option value="Distributor">{translate(t('distributor'))}</option>
              <option value="Corporation">{translate(t('corporation'))}</option>
            </Select>
          </div>
          <div style={props.isMobile ? {} : styles.twoCol}>
            <Input
              type={'text'}
              id={'presidentOwner'}
              name={'presidentOwner'}
              maxLength={50}
              placeholder={'*'+translate(t('presidentOwner'))}
              value={form.presidentOwner || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              required
              disabled={form.success ? 'disabled' : ''}
            />
            <Select
              id={'select_language'}
              name={'select_language'}
              value={form.select_language}
              onChange={handleUpdate}
              required
              disabled={form.success ? 'disabled' : ''}
              type={'integer'}
              style={dropdownValidation.select_language ? {border: '2px solid red'} : {border: '1px solid grey'}}
            >
              <option default>{'*'+translate(t('preferredLanguage'))}</option>
              <option value="1">{translate(t('english'))}</option>
              <option value="2">{translate(t('french'))}</option>
            </Select>
          </div>

          <UIGrid2cols isMobile={isMobile} alignItems='start'>
            <UISection>
              <UIParagraph customStyle={{margin:15}} textAlign="center">
                <Translate id={t('keyContactInformation')}/>
              </UIParagraph>
              <Input
              type={'text'}
              id={'firstname'}
              name={'firstname'}
              maxLength={50}
              placeholder={'*'+translate(t('keyContactFirstName'))}
              value={form.firstname || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              required
              disabled={form.success ? 'disabled' : ''}
              />
              <Input
              type={'text'}
              id={'lastname'}
              name={'lastname'}
              maxLength={50}
              placeholder={'*'+translate(t('keyContactLastName'))}
              value={form.lastname || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              required
              disabled={form.success ? 'disabled' : ''}
              />
              <Input
              type={'text'}
              id={'title'}
              name={'title'}
              maxLength={50}
              placeholder={'*'+translate(t('keyContactTitle'))}
              value={form.title || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              required
              disabled={form.success ? 'disabled' : ''}
              />
              <Input
              type={'email'}
              id={'email'}
              name={'email'}
              maxLength={50}
              placeholder={'*'+translate(t('keyContactEmail'))}
              value={form.email || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              required
              disabled={form.success ? 'disabled' : ''}
              />
              <Input
              style={{width: "223px"}}
              type={'tel'}
              id={'phone'}
              name={'phone'}
              maxLength={12}
              placeholder={'*'+translate(t('keyContactPhone'))}
              value={form.phone || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              required
              disabled={form.success ? 'disabled' : ''}
              />
              <Input
              style={{width: "100px"}}
              type={'tel'}
              id={'phoneExtension'}
              name={'phoneExtension'}
              maxLength={6}
              placeholder={'ext'}
              value={form.phoneExtension || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              disabled={form.success ? 'disabled' : ''}
              />
            </UISection>
            <UISection>
              <UIParagraph customStyle={{margin:15}} textAlign="center">
                <Translate id={t('accountsPayableInformation')}/>
              </UIParagraph>
              <Input
              type={'text'}
              id={'accountsPayableFirstName'}
              name={'accountsPayableFirstName'}
              maxLength={50}
              placeholder={'*'+translate(t('accountsPayableFirstName'))}
              value={form.accountsPayableFirstName || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              required
              disabled={form.success ? 'disabled' : ''}
              />
              <Input
              error={form.accountsPayableLastNameErrorRequired}
              type={'text'}
              id={'accountsPayableLastName'}
              name={'accountsPayableLastName'}
              maxLength={50}
              placeholder={'*'+translate(t('accountsPayableLastName'))}
              value={form.accountsPayableLastName || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              required
              disabled={form.success ? 'disabled' : ''}
              />
              <Input
              type={'text'}
              id={'accountsPayableTitle'}
              name={'accountsPayableTitle'}
              maxLength={50}
              placeholder={'*'+translate(t('accountsPayableTitle'))}
              value={form.accountsPayableTitle || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              required
              disabled={form.success ? 'disabled' : ''}
              />
              <Input
              type={'email'}
              id={'accountsPayableEmail'}
              name={'accountsPayableEmail'}
              maxLength={50}
              placeholder={'*'+translate(t('accountsPayableEmail'))}
              value={form.accountsPayableEmail || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              required
              disabled={form.success ? 'disabled' : ''}
              />
              <Input
              style={{width: "223px"}}
              type={'tel'}
              id={'accountsPayablePhone'}
              name={'accountsPayablePhone'}
              maxLength={12}
              placeholder={'*'+translate(t('accountsPayablePhone'))}
              value={form.accountsPayablePhone || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              required
              disabled={form.success ? 'disabled' : ''}
              />
              <Input
              style={{width: "100px"}}
              type={'tel'}
              id={'accountsPayablePhoneExtension'}
              name={'accountsPayablePhoneExtension'}
              maxLength={6}
              placeholder={'ext'}
              value={form.accountsPayablePhoneExtension || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              disabled={form.success ? 'disabled' : ''}
              />
            </UISection>
          </UIGrid2cols>

          <UIGrid2cols isMobile={isMobile}>
            <UISection>
              <UIParagraph customStyle={{margin:15}} textAlign="center">
                <Translate id={t('coordinatorInformation')}/>
              </UIParagraph>
              <Input
              type={'text'}
              id={'coordinatorFirstName'}
              name={'coordinatorFirstName'}
              maxLength={50}
              placeholder={translate(t('coordinatorFirstName'))}
              value={form.coordinatorFirstName || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              // required
              disabled={form.success ? 'disabled' : ''}
              />
              <Input
              type={'text'}
              id={'coordinatorLastName'}
              name={'coordinatorLastName'}
              maxLength={50}
              placeholder={translate(t('coordinatorLastName'))}
              value={form.coordinatorLastName || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              // required
              disabled={form.success ? 'disabled' : ''}
              />
              <Input
              type={'text'}
              id={'coordinatorTitle'}
              name={'coordinatorTitle'}
              maxLength={50}
              placeholder={translate(t('coordinatorTitle'))}
              value={form.coordinatorTitle || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              // required
              disabled={form.success ? 'disabled' : ''}
              />
              <Input
              type={'email'}
              id={'coordinatorEmail'}
              name={'coordinatorEmail'}
              maxLength={50}
              placeholder={translate(t('coordinatorEmail'))}
              value={form.coordinatorEmail || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              // required
              disabled={form.success ? 'disabled' : ''}
              />
              <Input
              style={{width: "223px"}}
              type={'tel'}
              id={'coordinatorPhone'}
              name={'coordinatorPhone'}
              maxLength={12}
              placeholder={translate(t('coordinatorPhone'))}
              value={form.coordinatorPhone || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              // required
              disabled={form.success ? 'disabled' : ''}
              />
              <Input
              style={{width: "100px"}}
              type={'tel'}
              id={'coordinatorPhoneExtension'}
              name={'coordinatorPhoneExtension'}
              maxLength={6}
              placeholder={'ext'}
              value={form.coordinatorPhoneExtension || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              disabled={form.success ? 'disabled' : ''}
              />
            </UISection>
            <UISection>
              <UIParagraph customStyle={{margin:15}} textAlign="center">
                <Translate id={t('purchaserInformation')}/>
              </UIParagraph>
              <Input
              type={'text'}
              id={'purchaserFirstName'}
              name={'purchaserFirstName'}
              maxLength={50}
              placeholder={translate(t('purchaserFirstName'))}
              value={form.purchaserFirstName || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              // required
              disabled={form.success ? 'disabled' : ''}
              />
              <Input
              type={'text'}
              id={'purchaserLastName'}
              name={'purchaserLastName'}
              maxLength={50}
              placeholder={translate(t('purchaserLastName'))}
              value={form.purchaserLastName || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              // required
              disabled={form.success ? 'disabled' : ''}
              />
              <Input
              type={'text'}
              id={'purchaserTitle'}
              name={'purchaserTitle'}
              maxLength={50}
              placeholder={translate(t('purchaserTitle'))}
              value={form.purchaserTitle || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              // required
              disabled={form.success ? 'disabled' : ''}
              />
              <Input
              type={'email'}
              id={'purchaserEmail'}
              name={'purchaserEmail'}
              maxLength={50}
              placeholder={translate(t('purchaserEmail'))}
              value={form.purchaserEmail || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              // required
              disabled={form.success ? 'disabled' : ''}
              />
              <Input
              style={{width: "223px"}}
              type={'tel'}
              id={'purchaserPhone'}
              name={'purchaserPhone'}
              maxLength={12}
              placeholder={translate(t('purchaserPhone'))}
              value={form.purchaserPhone || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              // required
              disabled={form.success ? 'disabled' : ''}
              />
              <Input
              style={{width: "100px"}}
              type={'tel'}
              id={'purchaserPhoneExtension'}
              name={'purchaserPhoneExtension'}
              maxLength={6}
              placeholder={'ext'}
              value={form.purchaserPhoneExtension || ''}
              onChange={handleUpdate}
              onBlur={handleBlur}
              disabled={form.success ? 'disabled' : ''}
              />
            </UISection>
          </UIGrid2cols>

          <UIParagraph customStyle={{margin:10}} textAlign="center">
            <Translate id={t('serviceTypes')}/>
          </UIParagraph>
          <UIGrid2cols isMobile={isMobile} alignItems='start'>
            <div>
              <Input
                id={'rewardsRecognition'}
                type={'checkbox'}
                name={'rewardsRecognition'}
                checked={form.rewardsRecognition || false}
                onChange={handleUpdate}
                isCheckBox={true}
              />
              <CheckboxLabel htmlFor={'rewardsRecognition'}>
                {translate(t('rewardsRecognition'))} 
              </CheckboxLabel>
              <br/>
              <Input
                id={'yearsService'}
                type={'checkbox'}
                name={'yearsService'}
                checked={form.yearsService || false}
                onChange={handleUpdate}
                isCheckBox={true}
              />
              <CheckboxLabel htmlFor={'yearsService'}>
                {translate(t('yearsService'))}
              </CheckboxLabel>
              <br/>
              <Input
                id={'corporateGifting'}
                type={'checkbox'}
                name={'corporateGifting'}
                checked={form.corporateGifting || false}
                onChange={handleUpdate}
                isCheckBox={true}
              />
              <CheckboxLabel htmlFor={'corporateGifting'}>
                {translate(t('corporateGifting'))}
              </CheckboxLabel>
            </div>

            <div>
              <Input
                id={'eventsGifting'}
                type={'checkbox'}
                name={'eventsGifting'}
                checked={form.eventsGifting || false}
                onChange={handleUpdate}
                isCheckBox={true}
              />
              <CheckboxLabel htmlFor={'eventsGifting'}>
                {translate(t('eventsGifting'))}
              </CheckboxLabel>
              <br/>
              <Input
                id={'safetyTraining'}
                type={'checkbox'}
                name={'safetyTraining'}
                checked={form.safetyTraining || false}
                onChange={handleUpdate}
                isCheckBox={true}
              />
              <CheckboxLabel htmlFor={'safetyTraining'}>
                {translate(t('safetyTraining'))}
              </CheckboxLabel>
            </div>
          </UIGrid2cols>
          <br/><br/>
          <Input
            id={'acknowledge'}
            type={'checkbox'}
            name={'acknowledge'}
            checked={form.acknowledge || false}
            onChange={handleUpdate}
            isCheckBox={true}
            required
          />
          <CheckboxLabel htmlFor={'acknowledge'}>
            {translate(t('acknowledge'), null, { renderInnerHtml: true })}
          </CheckboxLabel>
        </div>

        {!form.success && (
          <>
            <ReCAPTCHAWrapper>
              <ReCAPTCHA
                sitekey={getCAPTCHAKey()}
                onChange={onReCAPTCHAChange}
                hl={lang}
                style={captchaFlashing ? {border: '2px solid red'} : {border: '2px solid white'}}
              />
            </ReCAPTCHAWrapper>
            <Button
              children={translate(t('submitButton'))}
              fill={true}
              size={16}
              lineHeight={1.5}
            />
          </>
        )}
        {form.error && <ErrorMessage error="true" />}
        {form.errorRequired && <ErrorMessage errorRequired="true" />}
       
      </form>
    }
    {form.success && <SuccessMessage />}
    </>
  );
};

export default Radium(withLocalize(RequestForm));

const CheckboxLabel = styled.label `
  color: ${colors.black.normal};
  font-size: 13px;
  line-height: 1.2;
`;

const Input = styled.input `
  border-radius: 2px;
  border: 1px solid ${colors.gray.normal};
  color: ${colors.gray.normal};
  cursor: pointer;
  font-size: ${fonts.sizes.text}px;
  margin-bottom: ${spacing.small}px;
  margin-right: ${spacing.small}px;
  padding: ${spacing.verySmall}px;
  width: ${props => (props.isCheckBox ? '15px' : '100%')};
  &:last-child {margin-right: 0px;}
  ${props => props.error ? 'border-color: ' + colors.main.normal : ''};

  :focus {
    border-color: ${colors.main.normal};
    box-shadow: 0 0 0 1px ${colors.main.normal};
  }
`;

const Select = styled.select `
  appearance: none;
  background-color: white;
  border: 1px solid ${colors.gray.normal};
  border-radius: 2px;
  color: ${colors.gray.normal};
  cursor: pointer;
  font-size: ${fonts.sizes.text}px;
  margin-bottom: ${spacing.small}px;
  padding: ${spacing.verySmall}px;
  width: 100%;
  &:last-child {margin-right: 0px;}

  :focus {
    border-color: ${colors.main.normal};
    box-shadow: 0 0 0 1px ${colors.main.normal};
  }
`;

const ReCAPTCHAWrapper = styled.div `
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: ${spacing.medium}px;
`;
