import * as types from './types';

import { setFiltersSelectedCategory, resetFilters } from './filtersActions';
import { removeSelectedBrand } from './selectedBrandActions';

const setSelectedCategoryAction = selectedCategory => ({
  type: types.SET_SELECTED_CATEGORY,
  selectedCategory,
});

export const setSelectedCategory = (selectedCategory, reset = false) => {
  return dispatch => {
    if (reset) {
      dispatch(resetFilters());
      dispatch(removeSelectedBrand());
    }
    dispatch(setSelectedCategoryAction(selectedCategory));
    dispatch(
      setFiltersSelectedCategory(filterizeSelectedCategory(selectedCategory))
    );
  };
};

export const resetSelectedCategory = () => ({
  type: types.RESET_SELECTED_CATEGORY,
});

// map the keys to become filters that the api expects
const filterizeSelectedCategory = ({ topId, catId, typeId }) => {
  const filters = {};
  if (topId) filters.group = topId;
  if (catId) filters.category = catId;
  if (typeId) filters.type = typeId;
  return filters;
};
