import React from 'react';
import Radium from 'radium';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { colors, fonts, spacing, sizing } from '../../../../defaults/styles';
import { Link as ScrollLink } from 'react-scroll'
// import { vendorPrefix } from '../../../../tools/helpers/styling';

const Navigator = ({
  brands,
  letterSelected,
  setLetterSelected,
  isSticky,
  setActiveNav,
  activeNav,
  isMobile,
}) => {
  return (
    <NavList isSticky={isSticky} isMobile={isMobile}>
      {brands.map(brand => (
        <NavListItem 
          key={`brand-${brand}`}
          isMobile={isMobile}
          letterSelected={letterSelected === brand}
          activeNav={activeNav === `#${brand}`}
        >
            <ScrollLink 
              to={brand === "#"? "other" : brand}
              spy={true}
              smooth={true}
              onClick={(e) => {
                e.preventDefault();
                setLetterSelected(brand);
                setActiveNav(`#${brand}`);
              }}
            >
              {brand}
            </ScrollLink>
        </NavListItem>
      ))}
    </NavList>
  );
};

export default Radium(Navigator);

const NavList = styled.ul`
  background-color: ${colors.white.normal};
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: ${props => props.isMobile ? 'center' : 'space-between'};
  line-height: 1.3em;
  list-style: none;
  /* sticky brands nav */
  border-bottom: ${props => (props.isSticky ? '1px solid #eee' : 0)};
  max-width: ${props =>
    props.isSticky ? sizing.contentWidth + 'px' : 'inherit'};
  margin: 0 auto;
  position: ${props => (props.isSticky ? 'fixed' : 'relative')};
  top: ${props =>
    props.isSticky
      ? 
        !props.isMobile
          ? sizing.header.top + sizing.header.mid + sizing.header.btm + 'px'
          : sizing.header.mobileTop + sizing.header.mobileBottom + 'px'
      : 0};
  left: 0;
  right: 0;
  z-index: ${props => (props.isSticky ? 5 : null)};
`;

const NavListItem = styled.li`
  ${props => props.isMobile ? 'margin-bottom:' + spacing.verySmall + 'px' : ''};
  color: ${props =>
    props.activeNav ? colors.primary.normal : colors.gray.normal};
  font-size: ${fonts.sizes.subHeading}px;
  font-weight: ${props =>
    props.activeNav ? fonts.weights.heading : fonts.weights.thin};
  padding: ${props => props.isMobile ? spacing.verySmall : spacing.small}px;
  text-decoration: none;
  :hover {
    color: ${colors.main.normal};
  }
`;
