import React from 'react';
import { lsStyled as styled } from './../../../tools/helpers/lsStyled';
import { spacing } from './../../../defaults/styles';

const Section = styled.section`
  padding-top: ${props => (props.noPadding ? 0 : (props.isMobile || props.isTablet) ? spacing.paddingMobileStandard : spacing.paddingStandard)};
  ${props => (props.customStyle ? props.customStyle : null)}
  
  ${props => (props.isMobile || props.isTablet) ? ({
    width: '100%',
  }) : null};

`;

const UISection = ({ children, noPadding = null, customStyle = null, isMobile, isTablet }) => {
  return (
    <Section noPadding={noPadding} customStyle={customStyle} isMobile={isMobile} isTablet={isTablet}>
      {children}
    </Section>
  );
};

export default UISection;
