import React, { useEffect, useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { spacing, colors } from '../../../../../../defaults/styles';
import { lsStyled as styled } from '../../../../../../tools/helpers/lsStyled';
import { loadProductFeaturesForCorporateGifts } from '../../../../../../redux/actions/featureActions'
import FeatureValues from './FeatureValues'; import { connect } from 'react-redux';
import Button from '../../../../interface/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import qs from 'querystring';

const FeatureDiv = styled.div`
    border-top: 1px solid ${colors.gray.dark};
    ${props => props.isMobile ? 'padding-right: ' + spacing.verySmall + 'px' : ''};
    padding-left: ${spacing.verySmall}px;
    padding-right: ${spacing.verySmall}px;
    ${props => props.isMobile ? 'margin-bottom:' + spacing.large + 'px' : ''};
    margin-top: ${spacing.medium}px;
    padding-top: ${spacing.small}px;
`;

const FeatureTitleWrapper = styled.div`
    font-weight: bold;
    margin-bottom: ${spacing.verySmall}px;
    webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    justify-content: space-between;
    display: flex;
    align-items: center;
`;

const ConfirmButtonWrapper = styled.div`
    margin-top: ${spacing.verySmall}px;
    margin-bottom: ${spacing.verySmall}px;
    text-align: right;
`;

const Features = ({
    currentQuery,
    history,
    isMobile,
    translate,
    corporateGiftsFeatures,
    activeLanguage,
    loadProductFeaturesForCorporateGifts,
}) => {
    const t = (tag) => `corporateGifts.feature.${tag}`;

    const [selectedOptions, setSelectedOptions] = useState([]);
    const selectedOptionsRef = React.useRef([]);

    const param = qs.parse(history.location.search.substr(1));
    param.language = activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';

    useEffect(() => {
        loadProductFeaturesForCorporateGifts(activeLanguage);
    }, []);

    // Load query
    const separatedQuery = currentQuery ? JSON.parse(JSON.stringify(currentQuery)) : {};


    // // Applying filter, supposed to use `productFeatureValueId` as the array's name
    const cancelFeatureValues = () => {
        if (separatedQuery.productFeatureValueId) {
            delete separatedQuery.productFeatureValueId;
            history.push({
                pathname: history.location.pathname,
                search: '?' + qs.stringify({ ...separatedQuery })
            });
        }
    }

    return corporateGiftsFeatures && corporateGiftsFeatures.length > 0 ? (
        <FeatureDiv isMobile={isMobile}>
            <FeatureTitleWrapper>
                <Translate id={t('title')} />
                <ConfirmButtonWrapper>
                    <Button size={12}
                        onClick={cancelFeatureValues}
                        color={colors.gray.normal}>
                        <Translate id={t('clear')} />
                        &nbsp;
                        <FontAwesomeIcon icon={faChevronRight} />
                    </Button>
                </ConfirmButtonWrapper>
            </FeatureTitleWrapper>
            {
                corporateGiftsFeatures.map((feature, index) =>
                    feature.productFeatureValueList.length > 0 ?
                        <FeatureValues
                            key={index.toString() + feature.id}
                            featureValues={
                                feature.productFeatureValueList
                            }
                            featureNameEn={feature.nameEn}
                            featureNameFr={feature.nameFr}
                            corporateGiftsFeatures={corporateGiftsFeatures}
                            currentQuery={currentQuery}
                            activeLanguage={activeLanguage}
                            history={history}
                            translate={translate}
                            selfIndex={index}
                            selectedOptions={selectedOptions}
                            selectedOptionsRef={selectedOptionsRef}
                            setSelectedOptions={setSelectedOptions}
                        /> : <div key={index.toString() + feature.id } />
                )
            }
        </FeatureDiv>
    ) : (
        <div />
    );
};

const mapStateToProps = ({ corporateGiftsFeatures }) => { return { corporateGiftsFeatures } }
const mapDispatchToProps = { loadProductFeaturesForCorporateGifts };
export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(Features));