import { LSFetch } from '../helpers';

export const login = (username, password, languageUsed) => {
  const encodedDetails = Buffer.from(`${username.toLowerCase()}:${password}`).toString('base64');
  const auth = `Basic ${encodedDetails}`;

  return LSFetch.post('/user/authenticate', {
    queryString: { encodedDetails, languageUsed }
  }, false);
};

export const loginBypassMfa = (username, password, languageUsed) => {
  const combined = Buffer.from(`${username.toLowerCase()}:${password}`).toString('base64');
  const auth = `Basic ${combined}`;

  return LSFetch.post('/user/authenticateBypassMfa', {
    headers: { Authorization: auth },
    queryString: { languageUsed }
  }, false);
}

export const loginWithMfa = (username, token, isClientMfaBypassEnabled, randomGeneratedId) => {
  const combined = Buffer.from(`${username.toLowerCase()}:${token}`).toString('base64');
  const auth = `Basic ${combined}`;

  return LSFetch.post('/user/authenticateWithMfa', {
    headers: { Authorization: auth },
    queryString: { combined, isClientMfaBypassEnabled, randomGeneratedId }
  }, false);
}

export const loginSample = (username, password, firstName, lastName, company, email, phone, phoneExt, captcha) => {
  const combined = btoa(`${username}:${password}`);
  const auth = `Basic ${combined}`;

  return LSFetch.post('/user/authenticate', {
    headers: {
      Authorization: auth,
      'Access-Read-Only-First-Name': firstName ? firstName : '',
      'Access-Read-Only-Last-Name': lastName ? lastName : '',
      'Access-Read-Only-Company': company ? company : '',
      'Access-Read-Only-Email': email ? email : '',
      'Access-Read-Only-Phone': phone ? phone : '',
      'Access-Read-Only-Phone-Ext': phoneExt ? phoneExt : ''
    },
    queryString: { captcha }
  }, false);
}

export const loginMsrp = (username, password, captcha) => {
  const combined = btoa(`${username}:${password}`);
  const auth = `Basic ${combined}`;

  return LSFetch.post('/user/authenticate', {
    headers: { Authorization: auth },
    queryString: { captcha }
  }, false);
}

export const logout = () => {
  return LSFetch.del('/user/authenticate');
};

export const getStatus = () => {
  return LSFetch.get('/statistics/status').then(({ stream }) => {
    const owner = stream.headers.get('owner');
    const checked = { checked: true };
    return typeof owner === 'string'
      ? { ...checked, name: owner, loggedIn: true }
      : checked;
  });
};

export const forgotPassword = (inputEmail, captcha, activeLanguage) => {
  const email = inputEmail.toLowerCase();
  return LSFetch.post('/user/password', { queryString: { email, captcha, activeLanguage } });
};

export const info = () => {
  return LSFetch.get('/user');
};

export const parentInfo = () => {
  return LSFetch.get('/user/parentInfo');
};

export const resetPassword = (inputClientEmail, newPassword, captcha, token) => {
  const clientEmail = inputClientEmail.toLowerCase();
  return LSFetch.put('/user/resetPassword', { queryString: { clientEmail, newPassword, captcha, token } });
};

export const verifyPasswordResetToken = (token) => {
  return LSFetch.post('/user/verifyToken', { queryString: { token } });
};

export const vendorAgreement = (token) => {
  return LSFetch.get('/accountRequest/vendorAgreement', { params: { token } });
};