import * as types from './types';
import {
  modifyFilters,
  removeFilter,
  resetPageFilters,
} from './filtersActions';

const setSelectedBrandAction = selectedBrand => ({
  type: types.SET_SELECTED_BRAND,
  selectedBrand,
});

export const removeSelectedBrandAction = () => ({
  type: types.REMOVE_SELECTED_BRAND,
});

export const setSelectedBrand = (selectedBrand, reset = false) => {
  return dispatch => {
    dispatch(setSelectedBrandAction(selectedBrand));
    if (reset) dispatch(resetPageFilters());
    dispatch(modifyFilters({ brand: selectedBrand.id }));
  };
};

// reset to reset filters except categories
export const removeSelectedBrand = (reset = false) => {
  return dispatch => {
    dispatch(removeSelectedBrandAction());
    dispatch(removeFilter('brand'));
    if (reset) dispatch(resetPageFilters());
  };
};
