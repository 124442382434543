import { LSFetch } from '../helpers'

export const getProduct = (sku, params) => {
  return LSFetch.get(`/product/${sku}`, { params })
}

/**
 * @typedef {object} productFilters
 * @property {int} brand Brand ID
 * @property {int} category Category ID
 * @property {array[int]} productFeatureValueId productFeatureValues ID array
 * @property {string} language Language Code
 * @property {int} page page number
 * @property {number} priceEnd End price for filer
 * @property {number} priceStart Start price for filter
 * @property {string} priceType Values can be price or msrp
 * @property {string} display display type
 * @property {string} displayId unique display id
 * @property {string} q Search param
 * @property {int} size Number of records on the page
 * @property {array[string]} sort Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
 * @property {int} type type
 * @property {boolean} availability flag to indicate that show available only
 *
 * @param {productFilters} params
 */
export const getProducts = params => {
  if (params.availability) {
    params.status = 'AVAILABLE';
  }
  return LSFetch.get('/product', { params })
}

export const getActiveListing = params => {
  return LSFetch.get('/product/home', { params });
}

export const getCatalog = (query, page) => {
  const params = {
    page,
    size: 15,
  };

  if (query.availability) { params.status = 'AVAILABLE'; }

  return LSFetch.get('/product', { params: { ...query, ...params  } });
}

export const getCatalogForCorporateGifts = (query, page) => {
  const params = {
    page,
    size: 15,
  };

  if (query.availability) { params.status = 'AVAILABLE'; }

  return LSFetch.get('/product/corporategifts', { params: { ...query, ...params  } });
}

export const getCurrentListingObject = params => {
  return LSFetch.get('/product/listing', { params });
}