import React, { useEffect, useState, useRef } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';
import { colors, fonts } from '../../../../../defaults/styles';
import Popup from './Popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt, faPlusCircle, faEye, faShareAlt } from '@fortawesome/pro-regular-svg-icons';
import DownloadShoppingListButton from './DownloadShoppingListButton';
import GridLoader from 'react-spinners/GridLoader';
import ItemCounter from '../../../interface/ItemCounter';
import { loadInfo } from '../../../../../redux/actions/userActions';
import { connect } from 'react-redux';
import {
    getAccessTokenToCreateProject,
    createBasicFourZoomProject,
    getAutoLoginRedirectUrl,
    getItAccountShortToken,
    ZOOM_REFERER
}
    from '../../../../../tools/api/zoomCatalog';

const ShoppingListPopup = ({
    user,
    loadInfo,
    userInfo,
    shoppingLists = [],
    modifyDefaultEventHandler,
    modifyNameEventHandler,
    deleteShoppingListEventHandler,
    createShoppingListEventHandler,
    translate,
    showPopup,
    setShowPopup,
    isMobile,
}) => {
    const t = (tag) => `shoppingListContainer.${tag}`;
    const loggedIn = user && user.loggedIn;

    const [showForm, setShowForm] = useState(null);

    const [viewUrl, setViewUrl] = useState("");
    const [editUrl, setEditUrl] = useState("");
    const [showZoomButton, setShowZoomButton] = useState(false);
    const [zoomStudioUserToken, setZoomStudioUserToken] = useState("");
    const [zoomStudioItToken, setZoomStudioItToken] = useState("");
    const [disableZoomButton, setDisableZoomButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const listEndRef = useRef(null);

    useEffect(() => setShowForm(null), [shoppingLists]);

    useEffect(() => {
        if (loggedIn) {
            loadInfo();
        }
    }, [loadInfo, loggedIn]);

    const fixEditUrl = (editUrl) => {
        try {
            // Check if the URL contains "localhost:3000"
            if (editUrl.includes('localhost:3000')) {
                // Replace "localhost:3000-quickcanvas" with "loyaltysource-quickcanvas"
                return editUrl.replace('localhost:3000-quickcanvas', ZOOM_REFERER);
            }
            // Check if the URL contains "preprod.loyaltysource.com"
            if (editUrl.includes('preprod.loyaltysource.com')) {
                // Replace "preprod.loyaltysource.com-quickcanvas" with "loyaltysource-quickcanvas"
                return editUrl.replace('preprod.loyaltysource.com-quickcanvas', ZOOM_REFERER);
            }
            // Also change in production environment
            if (editUrl.includes('www.loyaltysource.com')) {
                // Replace "loyaltysource.com-quickcanvas" with "loyaltysource-quickcanvas"
                return editUrl.replace('www.loyaltysource.com-quickcanvas', ZOOM_REFERER);
            }
            return editUrl;
        } catch (error) {
            console.error('Error while fixing edit_url:', error.message);
            return editUrl;
        }
    }

    useEffect(() => {
        let isMounted = true;

        const fetchData = async () => {
          try {
            const itToken = await getItAccountShortToken();
            setZoomStudioItToken(itToken);
    
            if (isMounted && userInfo && Object.keys(userInfo).length > 0) {
              const data = await getAccessTokenToCreateProject(userInfo.username);
              setZoomStudioUserToken(data.user_token);
            }
          } catch (error) {
            setZoomStudioUserToken(null);
          }
        };
    
        if (userInfo && Object.keys(userInfo).length > 0) {
          fetchData();
        }
    
        // Cleanup function to cancel any ongoing tasks when component unmounts
        return () => {
          isMounted = false;
        };
    }, [userInfo])

    if (!loggedIn) { return <></>; }

    const setDefaultList = (event) => modifyDefaultEventHandler(event, shoppingLists);

    const scrollToBottom = () => {
        listEndRef.current.scrollIntoView({ behavior: "smooth" });
    }

    const generateZoomStudio = (shortToken, shoppingItemList, name) => {
        setLoading(true);
        createBasicFourZoomProject(shortToken, shoppingItemList, name)
            .then(data => {
                const fixedEditUrl = fixEditUrl(data.edit_url);
                setEditUrl(fixedEditUrl);
                setViewUrl(data.viewer_url);
                setShowZoomButton(true);
            })
            .catch(error => {
                setErrorMessage('Error while generating zoom project:' + error);
                console.error('Error while generating zoom project:', error);
            });
        setDisableZoomButton(true);
        setLoading(false);
    }

    const onViewClick = () => {
        try {
            window.open(viewUrl);
        } catch {
            setErrorMessage('Error while jumping to view uri: ');
        }
    }

    const onEditClick = () => {
        // Use short user_token
        const autologinRedirectUrl = getAutoLoginRedirectUrl(zoomStudioUserToken, editUrl);
        try {
            window.open(autologinRedirectUrl);
        } catch (error) {
            console.error('Error while jumping auto login edit uri: ', error);
            setErrorMessage('Error while jumping auto login edit uri: ', error);
        }
    }

    return <Popup showPopup={showPopup} setShowPopup={setShowPopup} isMobile={isMobile}>
        {!shoppingLists ?
            (<GridLoader
                css={{ margin: 'auto' }}
                color={colors.main.normal}
                size={12}
            />)
            : (<ContentSectionWrapper style={{ position: 'relative' }}>
                <ContentSectionHeader>
                    <Translate id={t('title')} />
                </ContentSectionHeader>

                <DownloadExcelDisclaimer>{translate(t('explainText'))}</DownloadExcelDisclaimer>
                
                <ShoppingListContent>
                    {shoppingLists.length > 0 ? shoppingLists.map((sl) =>
                        // Shopping list has content then map them
                        <div key={sl.id}>
                            <><ListItem key={sl.id} isMobile={isMobile}>
                                {!showForm || showForm !== sl.id
                                    ?
                                    <div>
                                        <GridOuter>
                                            <ItemCounter
                                                count={sl.productIds ? sl.productIds.length : 0}
                                                size={1.25}
                                                fill={true}
                                                extend={'right'}
                                                marginLeft={0}
                                                style={{ position: 'flex', }}
                                            />
                                            <div>
                                                <ListNameLabel defaultList={sl.defaultList}>
                                                    {sl.listName}
                                                </ListNameLabel>

                                                {/* Make the list default list */}
                                                <SetDefaultListSection>{sl.defaultList
                                                    ? <SetDefaultLabel>
                                                        <Translate id={t('default')} />
                                                    </SetDefaultLabel>
                                                    : <SetDefaultButton value={sl.id} onClick={setDefaultList}>
                                                        <Translate id={t('setDefault')} />
                                                    </SetDefaultButton>
                                                }
                                                </SetDefaultListSection>
                                            </div>

                                            <DownloadShoppingListButton id={sl.id} />

                                            <ButtonIcon
                                                title={translate(t('zoomCatalog'))}
                                                onClick={() => {
                                                    generateZoomStudio(
                                                        zoomStudioUserToken ? zoomStudioUserToken : zoomStudioItToken,
                                                        sl.productIds,
                                                        sl.listName);
                                                }}
                                                disabled={disableZoomButton}>
                                                <FontAwesomeIcon icon={faShareAlt} size="lg" />
                                            </ButtonIcon>

                                            <ButtonIcon
                                                onClick={() => setShowForm(sl.id)}
                                                title={translate(t('edit'))}
                                            >
                                                <FontAwesomeIcon icon={faEdit} size="lg" />
                                            </ButtonIcon>

                                            <ButtonIcon
                                                value={sl.id}
                                                title={sl.defaultList ? translate(t('cannotDelete')) : translate(t('delete'))}
                                                onClick={() => deleteShoppingListEventHandler({ target: { value: sl.id } }, shoppingLists)}
                                                disabled={sl.defaultList}
                                            >
                                                <FontAwesomeIcon icon={faTrashAlt} size="lg" />
                                            </ButtonIcon>
                                        </GridOuter>

                                        {loading ? (
                                            <GridLoader
                                                css={{ margin: 'auto' }}
                                                color={colors.main.normal}
                                                size={12}
                                            />)
                                            : 
                                            errorMessage ? (
                                                <ZoomHeader style={{ color: 'red' }}>
                                                    {errorMessage}
                                                </ZoomHeader>
                                            )
                                            :
                                            (showZoomButton ? 
                                            <ZoomButtonSectionWrapper style={{ position: 'relative' }}>
                                                <ZoomHeader>
                                                    {translate(t('createdZoomCatalog'))}
                                                </ZoomHeader>

                                                <ZoomButtons>
                                                    <ZoomButton
                                                        onClick={onViewClick}
                                                    >
                                                        <ZoomIconBox>
                                                            <FontAwesomeIcon
                                                                icon={faEye}
                                                            />
                                                        </ZoomIconBox>
                                                        <ButtonText>
                                                            {translate(t('viewZoomCatalog'))}
                                                        </ButtonText>
                                                    </ZoomButton>

                                                    <ZoomButton
                                                        onClick={onEditClick}
                                                        disabled={!zoomStudioUserToken}
                                                    >
                                                        <ZoomIconBox>
                                                            <FontAwesomeIcon
                                                                icon={faEdit}
                                                            />
                                                        </ZoomIconBox>
                                                        <ButtonText
                                                            title={!zoomStudioUserToken ? translate(t('zoomLoginRequired')) : ''}
                                                        >
                                                            {translate(t('editZoomCatalog'))}
                                                        </ButtonText>
                                                    </ZoomButton>

                                                </ZoomButtons>

                                            </ZoomButtonSectionWrapper>
                                                : <></>)
                                        }
                                    </div>
                                    : <ShoppingListNameModificationForm
                                        id={sl.id}
                                        modifyNameEventHandler={modifyNameEventHandler}
                                        shoppingLists={shoppingLists}
                                        setShowForm={setShowForm}
                                        listName={sl.listName}
                                    />
                                }
                            </ListItem>
                                {/* This is a dummy div for scrollToBottom when creating new list */}
                                <div ref={listEndRef}></div>
                            </>
                        </div>)
                        // Shopping list doesn't have content
                        : <Translate id={t('message')} />
                    }
                </ShoppingListContent>
                <DownloadExcelDisclaimer>{translate(t('downloadDisclaimer'))}</DownloadExcelDisclaimer>
                <CreateButton onClick={
                    () => createShoppingListEventHandler(scrollToBottom, shoppingLists, translate(t('defaultName')))
                }>
                    <IconBox>
                        <FontAwesomeIcon icon={faPlusCircle} size="1x" />
                    </IconBox>
                    <Translate id={t('create')} />
                </CreateButton>
            </ContentSectionWrapper>)}
        </Popup>;

}

const ShoppingListNameModificationForm = ({ modifyNameEventHandler, shoppingLists, id, setShowForm, listName }) => {
    const [inputValue, setInputValue] = useState(listName);
    const inputRef = useRef(null);
  
    useEffect(() => {
        inputRef.current.focus();
    }, []);

    const [hideError, setHideError] = useState('');
    useEffect(() => {
        setHideError('hidden');
    }, [shoppingLists]);

    const modifyName = (event, shoppingLists) => {
        event.preventDefault();
        const listName = event.target.elements["listName"].value.trim();
        const listId = event.target.elements["listId"].value;
        const extras = {
            queryString: { listName: listName },
        };
        if (!listName) {
            setHideError('');
        } else {
            modifyNameEventHandler(listId, extras, shoppingLists);
        }
    }

    return (
        <form onSubmit={(event) => modifyName(event, shoppingLists)}>
            <div style={{ width: '100%' }}>
                <input type="hidden" name="listId" value={id} />
                <Input ref={inputRef} type="text" name="listName" maxLength="255" value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
            </div>
            <ButtonWrapper>
                <Button primary type="submit">
                    {/* <Translate id="shoppingListContainer.edit" /> */}
                    <Translate id="shoppingListContainer.save" />
                </Button>
                <Button onClick={() => { setShowForm(null) }}>
                    <Translate id="shoppingListContainer.cancel" />
                </Button>
            </ButtonWrapper>
            <p hidden={hideError}><Translate id="shoppingListContainer.errorMessage" /></p>
        </form>
    );
}
const ListItem = styled.li`
    border-bottom: 1px solid rgba(149, 149, 149, 0.3);
    margin-right: ${props => props.isMobile ? '0' : '2rem'};
    margin-left: ${props => props.isMobile ? '0' : '2rem'};
    padding-top: 2rem;
    padding-bottom: 2rem;
`;

const SetDefaultButton = styled.button`
    background-color: transparent;
    color: ${colors.gray.normal};
    cursor: pointer;
    opacity: 0.5;
    transition: all 200ms;
    :hover {
        opacity: 1;
    }
`;

const DownloadExcelDisclaimer = styled.div`
    margin: 1rem 2rem;
    background-color: transparent;
    color: ${colors.gray.normal};
    font-size: 0.8rem;
    line-height: 1.4;
`;

const Input = styled.input`
    border-radius: 2px;
    border: 1px solid rgba(149, 149, 149, 0.5);
    font-size: ${fonts.sizes.text}px;
    line-height: 1;
    padding: 10px 15px;
    width: 100%;
    ${props => props.error ? 'border-color: ' + colors.main.normal : ''};
    :focus {
        border: 2px solid ${colors.main.normal};
    }
`;

const ContentSectionWrapper = styled.section`
    position: relative;
`;

const ContentSectionHeader = styled.h2`
    margin: 1rem 2rem;
`;

const ShoppingListContent = styled.ul`
    list-style: none; 
    max-height: 410px; 
    overflow-y: scroll;
`;

const ListNameLabel = styled.span`
    font-weight: ${props => props.defaultList ? 600 : 400};
    position: relative;
`;

const SetDefaultListSection = styled.div``;

const SetDefaultLabel = styled.span`
    font-size: 0.8rem;
`;

const GridOuter = styled.div`
    display: grid;
    grid-template-columns: auto 1fr auto auto auto auto;
    grid-gap: 1rem;
    align-items: start;
`;

const ButtonIcon = styled.button`
    background-color: transparent;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    opacity: ${props => props.disabled ? 0.5 : 0.8};
`;

const ButtonWrapper = styled.div`
    display: grid;
    direction: rtl;
    grid-template-columns: repeat(2, minmax(100px, 150px));
    justify-content: end;
    grid-auto-flow: column;
    gap: 0.5em;
    margin-top: 0.5em;
`;

const Button = styled.button`
    background-color: ${props => props.primary ? colors.primary.normal : "white"};
    border-width: 1px;
    border-style: solid;
    border-color: ${props => props.primary ? colors.primary.normal : 'rgba(149, 149, 149, 0.5)'};
    color: ${props => props.primary ? 'white' : colors.gray.normal};
    cursor: pointer;
    padding: 10px 15px;
    font-size: 14px;
    font-weight: 400;
`;

const CreateButton = styled.div`
    color: ${colors.primary.normal};
    cursor: pointer;
    margin: 2rem;
`;

const IconBox = styled.span`
    margin-right: 5px;
`;

const ZoomButtonSectionWrapper = styled.section`
    position: relative;
    display: flex;
    justify-content: center; 
    align-items: center; 
    flex-direction: column;
`;

const ZoomHeader = styled.div`
    background-color: transparent;
    color: ${colors.black.normal};
    cursor: pointer;
    opacity: 0.5;
    margin-top: 1rem;
    margin-bottom: 1rem;
    transition: all 200ms;
    :hover {
        opacity: 1;
    }
`;

const ZoomButton = styled.div`
    border-radius: 0.2rem;
    background-color: ${props => props.disabled ? '#CCCCCC' : colors.primary.normal};
    color: ${props => props.disabled ? '#666666' : colors.white.normal};
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    font-size: 0.7rem;
    margin-right: 2rem;
    padding: 1em;
    min-height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};

    :hover {
        background-color: ${props => props.disabled ? '#CCCCCC' : colors.primary.hover};
        border-color: ${colors.primary.dark};
        cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    }
`;

const ZoomButtons = styled.div`
    display: flex;
    justify-content: space-around; 
    align-items: center; 
    flex-direction: row;
    `;

const ZoomIconBox = styled.div`
    margin-right: 3px;
`;

const ButtonText = styled.div`
    text-transform: uppercase;
`;
  
function mapStateToProps({ userInfo }) {
    return { userInfo };
}
const mapDispatchToProps = {
    loadInfo
};
export default withLocalize(connect(
    mapStateToProps,
    mapDispatchToProps
)(ShoppingListPopup));