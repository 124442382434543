const colors = {
  // red
  primary: {
    normal: 'rgb(236,28,36)',
    dark: 'rgb(195,8,16)',
    hover: 'rgb(163,30,40)',
  },

  // red - to remove
  main: {
    light: 'rgb(251, 233, 234)',
    normal: 'rgb(201, 48, 51)',
    dark: 'rgb(183, 35, 38)',
  },

  black: {
    normal: 'rgb(51, 51, 51)',
  },
  blue: {
    normal: 'rgb(40, 114, 187)',
  },
  gray: {
    light: 'rgb(244,246,248)',
    normal: 'rgb(149,149,149)',
    dark: 'rgb(51, 51, 51)',
  },
  white: {
    normal: 'rgb(255,255,255)',
    hover: `rgb(244,246,248)`,
  },
  dashedBoarder: {
    normal: 'rgba(149, 149, 149, 0.4)',
  },
  zoomStudio: {
    normal: '#F4326A',
    
  }
};

colors.darkBackground = colors.gray.dark;

module.exports = colors;
