import React, { useEffect } from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import CartListItem from './CartListItem';
import { GridLoader } from 'react-spinners';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { colors, fonts } from '../../../../defaults/styles';

const CartListWrapper = styled.div`
`;

const CartListTitle = styled.h2`
    font-size: 1.8rem;
    margin-bottom: 1.5rem;
`;

const ExplainText = styled.div`
    font-size:1.2rem;
    line-height:1.6rem;
`;

const CartList = ({ translate, cart, updateCartItem, removeFromCart, isMobile, history }) => {
    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        })
    }, []);
    const { items } = cart;
    return (
        <CartListWrapper>
            <CartListTitle>{translate('checkout.list.title')}</CartListTitle>
            {items
                ? 
                <>
                    {items.length > 0 
                        ? <>
                            <ExplainText>{translate('checkout.list.explainText')}<br /> <br /> <br /></ExplainText>
                            {items
                            .map((item, index) => 
                            <CartListItem 
                                key={`cart-list-item-${item.sku}`}
                                item={item} 
                                updateCartItem={updateCartItem}
                                removeFromCart={removeFromCart} 
                                isMobile={isMobile}
                                history={history}
                            />)}
                        </>
                        : <ExplainText>{translate('checkout.list.noResult') }</ExplainText>
                    }
                </>
                :
                <GridLoader
                    css={{ margin: 'auto' }}
                    size={fonts.sizes.text}
                    color={colors.main.normal} 
                />
            }
        </CartListWrapper>
    )
}

export default withLocalize(CartList);