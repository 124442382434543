import React from 'react';
import Radium from 'radium';
import { fonts, spacing } from './../../../../defaults/styles';

const styles = {
  fontFamily: fonts.title,
  fontSize: fonts.sizes.subtitle,
  fontWeight: fonts.weights.normal,
  marginBottom: spacing.medium,
};
const stylesMobile = {
  ...styles,
  fontSize: fonts.sizes.subtitleMobile,
  textAlign: 'center',
};
function SubTitle({ text, isMobile }) {
  return <h3 style={isMobile ? stylesMobile : styles}>{text}</h3>;
}

export default Radium(SubTitle);
