import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { spacing, colors } from '../../../../../defaults/styles';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';

const SecondLevelWrapper = styled.div`
    padding: ${spacing.medium / 2}px ${spacing.medium}px;
    display: flex;
    cursor: pointer;
    color: ${props => props.selected ? colors.main.normal : colors.black.normal};
    user-select: none;
`;

const SecondLevelLabel = styled.span`
    flex: 1 1 auto;
`;

const LinkStyle = {
    color: 'inherit',
    textDecoration: 'none'
};

const SecondLevelCateogry = ({
    text,
    hasSubCategories,
    selected = false,
    onClick,
    url,
}) => (
    <SecondLevelWrapper selected={selected} onClick={onClick}>
        <SecondLevelLabel>
            <Link 
                to={url} 
                style={LinkStyle} 
                onClick={(e) => e.preventDefault()}
            >
                {text}
            </Link>
        </SecondLevelLabel>
        {hasSubCategories && (
            <FontAwesomeIcon
                icon={faChevronDown}
                style={{
                    transform: selected ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'all 0.2s ease-in-out',
                }}
            />
        )}
    </SecondLevelWrapper>
);

export default SecondLevelCateogry;
