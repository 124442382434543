import React, { useContext } from 'react';
import UIMain from './../../interface/UIMain';

import Intro from './Intro';
import BrandsAndRewards from './BrandsAndRewards';
import ModernMarketplace from './ModernMarketplace';
import SeamlessIntegration from './SeamlessIntegration';
import LoveLoyaltySource from './LoveLoyaltySource';
import { ResizeAwareContext } from '../../../smart/ResizeContext';
import { withLocalize } from 'react-localize-redux';

const ServicesRewardsPage = ({ translate }) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);
  document.title = translate('services.rewards.title');

  return (
    <UIMain isMobile={isMobile} isTablet={isTablet}>
      <Intro isMobile={isMobile} isTablet={isTablet} />
      <BrandsAndRewards isMobile={isMobile} isTablet={isTablet} />
      <ModernMarketplace isMobile={isMobile} isTablet={isTablet} />
      <SeamlessIntegration isMobile={isMobile} isTablet={isTablet} />
      <LoveLoyaltySource isMobile={isMobile} isTablet={isTablet} />
    </UIMain>
  );
};

export default withLocalize(ServicesRewardsPage);
