import React, { useContext } from 'react';
import LoginHeader from './LoginHeader';
import LoginForm from './LoginForm';
import LoginFooter from './LoginFooter';
import CustomerServiceLink from './CustomerServiceLink';
import { withLocalize } from 'react-localize-redux';
import { colors, spacing } from './../../../../defaults/styles';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { ResizeAwareContext } from '../../../smart/ResizeContext';

const Outer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${props => props.isSample ? 'auto' : '100vh'};

  &::after {
    content: '';
    background: rgba(0, 0, 0, 0.7) url('/images/loginPlaceholder.jpg') center
      center;
    filter: brightness(25%);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    z-index: -1;
  }
`;

const Inner = styled.div`
  background-color: ${colors.white.normal};
  border-radius: 3px;
  padding: ${spacing.veryLarge * 2}px;
  width: ${props => (props.isSample ? props.isMobile ? '100%' : '80%' : '420px')};
  max-width: ${props => (props.isSample ? '580px' : null)};
`;

const LoginPage = ({ Login, history, translate, ...props }) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);
  document.title = translate('login.title');

  const sample = props.location.pathname.indexOf('/sample') >= 0;
  const msrp = props.location.pathname.indexOf('/msrp') >= 0;
  const triggerMfa = props.location.pathname.indexOf('/token') >= 0;

  return (
    <Outer isSample={sample} isTriggerMfa={triggerMfa} isMsrp={msrp}>
      <Inner isSample={sample} isMobile={isMobile}>
        <LoginHeader history={history} />
        <LoginForm history={history} sample={sample} triggerMfa={triggerMfa} msrp={msrp} isMobile={isMobile} isTablet={isTablet} />
        <LoginFooter isSample={sample} />
      </Inner>
      <CustomerServiceLink isSample={sample} />
    </Outer>
  );
};

export default withLocalize(LoginPage);