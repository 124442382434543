import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { CartContainer } from '../../../smart';
import CartContent from './CartContent';

const CheckoutPage = ({ activeLanguage, translate, history }) => {
    document.title = translate('checkout.title');
    const locale =
        activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';
    return (
        <CartContainer history={history}>
            <CartContent locale={locale} />  
        </CartContainer>
    );
};

export default withLocalize(CheckoutPage);