import React from 'react';
import { Link } from 'react-router-dom';
import { Translate, withLocalize } from 'react-localize-redux';
import { spacing, colors } from '../../../../../../../defaults/styles';
import content from '../../content';
import { lsStyled as styled } from '../../../../../../../tools/helpers/lsStyled';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const styles = {
  ulList: {
    // borderTop: `1px solid ${colors.gray.normal}`,
    listStyle: 'none',
  },
  link: {
    borderBottom: `1px solid ${colors.gray.light}`,
    color: colors.black.normal,
    display: 'flex',
    flex: 1,
    textDecoration: 'none',
    paddingTop: spacing.large,
    paddingRight: 35,
    paddingBottom: spacing.large,
    paddingLeft: 35,
  },
  linkSecondLevel: {
    borderBottom: `1px solid ${colors.gray.light}`,
    color: colors.black.normal,
    display: 'block',
    textDecoration: 'none',
    paddingTop: spacing.large,
    paddingRight: spacing.large,
    paddingBottom: spacing.large,
    paddingLeft: spacing.veryLarge * 1.5,
    textTransform: 'uppercase',
  },
};

const User = ({ setMobileMenuOpen, user, activeLanguage }) => {
  const t = (tag) => `header.menu.${tag}`;

  const loggedIn = user && user.loggedIn;
  const lang =
    activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';

  const loginUrl = `/${lang}/login`;

  return (
    <ul style={styles.ulList}>
      {loggedIn ? (
        <>
          <React.Fragment key={'mobile-link-' + 'user'}>
            <li onClick={() => setMobileMenuOpen()}>
                <Link to={'/' + lang + '/info'} style={styles.link}>
                    {user.name.toUpperCase()}
                    <IconBox>
                      <FontAwesomeIcon icon={faChevronRight} />
                    </IconBox>
                </Link>
            </li>
            {content.userLinks.map(({  url = '/', lexiconCode, callback = () => {}  }, index) => (
                <li
                  key={'mobile-sub-link-user' + index}
                  onClick={event => {
                    setMobileMenuOpen();
                    callback(event);
                  }}
                >
                  <Link to={'/' + lang + url} style={styles.linkSecondLevel}>
                    <Translate id={t(lexiconCode)} />
                  </Link>
                </li>
              ))}
          </React.Fragment>
        </>
      ) : (

        <ul style={styles.ulList}>
            <React.Fragment key={'mobile-link-' + 'user'}>
              <li onClick={() => setMobileMenuOpen()}>
                  <Link
                    to={loginUrl}
                    onClick={e => {
                      e.preventDefault();
                      window.location.href = loginUrl;
                    }}
                    style={styles.link}
                  >
                      <Translate id={t('logIn')} />
                      <IconBox>
                        <FontAwesomeIcon icon={faChevronRight} />
                      </IconBox>
                  </Link>
              </li>
           </React.Fragment>
        </ul>
      )}
    </ul>
  );
};

export default withLocalize(User);

const IconBox = styled.span`
  display: inline-block;
  margin-left: auto;
  text-align: center;
  width: 2.5em;
`;
