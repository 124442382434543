import React from 'react';
import Radium from 'radium';
import { fonts, spacing, sizing } from './../../../../defaults/styles';

const styles = {
  fontFamily: fonts.content,
  fontSize: fonts.sizes.text,
  lineHeight: 1.5,
  marginBottom: spacing.large,
  ...sizing.boundToContentWidth,
};
const stylesMobile = {
  ...styles,
  textAlign: 'center',
};

function Paragraph({ text, isMobile }) {
  return <p style={isMobile ? stylesMobile : styles}>{text}</p>;
}

export default Radium(Paragraph);
