import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { fonts, spacing } from '../../../../defaults/styles';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';

const Section = styled.section``;

const SectionHeading = styled.h3`
  font-size: ${fonts.sizes.large}px;
  font-weight: ${fonts.weights.normal}px;
  margin-top: ${spacing.veryLarge}px;
  margin-bottom: ${spacing.small}px;
  line-height: 1.3;
`;

const SectionBody = styled.div``;

const Paragraph = styled.p`
    line-height: 1.5em;
    margin-bottom: ${spacing.small}px;
`;

const List = styled.ol`
    margin-top: ${spacing.large}px;
    margin-right: auto;
    margin-bottom: ${spacing.large}px;
    margin-left: auto;
    padding-left: ${spacing.large}px;
    width: 90%;
`;

const ListItem = styled.li`
    white-space: pre-wrap;
    list-style: decimal outside;
    line-height: 1.2em;
    margin-bottom: ${spacing.small}px;
`;

/* 
 In order to use this component, translation has to follow format of
 1. Title of the section will start with subtitle, for example, subtitle1, etc.
 2. Content will start with paragraph, for example, paragraph1, paragraph1a, etc.
 3. List will start with paragraph and has the word list after the number, for example, paragraph1list, paragraph2list, etc.
 4. List item will be inside the object from number 3 above and go in alphabetical order, for example, paragraph1list.a, paragraph1list.b, etc.
 5. The translation will display in the same order that's written in the languageData from top to bottom.
*/
const LegalSectionContent = ({ t, languageData, section }) => {
    const header = [];
    const body = [];
    const regex = /\d+/g;

    for (let key in languageData) {
        // check next character
        const matches = key.match(regex);
        if (matches.length > 0 && matches[0] == section) {
            // Check if is header
            if (key.indexOf(`subtitle`) >= 0) {
                header.push(
                    <React.Fragment key={key}>{section}. <Translate id={t(key)} /></React.Fragment>
                );
            } 
            // Check if is paragraph
            else if (key.indexOf(`paragraph`) >= 0 && key.indexOf(`list`) < 0) {
                body.push(
                    <Paragraph key={key}><Translate id={t(key)} /></Paragraph>
                );
            }
            // Check if is list
            else if (key.indexOf(`paragraph`) >= 0 && key.indexOf(`list`) >= 0) {
                // Generate item
                const listItems = [];
                for (let itemKey in languageData[key]) {
                    listItems.push(
                        <ListItem key={itemKey}>
                            <Translate id={t(`${key}.${itemKey}`)} />
                        </ListItem>
                    );
                }

                // Push the whole list
                body.push(
                    <List key={key}>
                        {listItems}
                    </List>
                );
            }
            // Do nothing
            else { }
        }
    }

    return (
        <Section>
            <SectionHeading>
                {header}
            </SectionHeading>
            <SectionBody>
                {body}
            </SectionBody>
        </Section>
    );
};

export default withLocalize(LegalSectionContent);