import React from 'react';
import styled from 'styled-components';

import PageReinforcementStatic from './../../interface/PageReinforcementStatic';
import UIContainer from './../../interface/UIContainer';
import UISection from './../../interface/UISection';
import UIGrid2cols from './../../interface/UIGrid2cols';
import UIParagraph from '../../interface/UIParagraph';
import { Translate, withLocalize } from 'react-localize-redux';

// == Styling == //
const ImageBox = styled.div`
  display: flex;
  align-items: center;
  height: ${props => (props.isMobile || props.isTablet ? 'auto' : '30em')};
  width: ${props => (props.isMobile || props.isTablet ? 'auto' : '30em')};
`;
// == /Styling == //

const OurPeople = ({ isMobile, isTablet, translate }) => {
  const t = (tag) => `aboutUs.ourPeople.${tag}`;

  return (
    <UIContainer isMobile={isMobile} isTablet={isTablet}>
      <UIGrid2cols isMobile={isMobile} isTablet={isTablet}>
        <ImageBox isMobile={isMobile} isTablet={isTablet}>
          <img
            src={'/images/pages/about/OurPeople.jpg'}
            alt={'Loyalty Source'}
            style={{ maxWidth: !isMobile ? 450 : '100%' }}
          />
        </ImageBox>
        <UISection isMobile={isMobile} isTablet={isTablet}>
          <PageReinforcementStatic
            title={translate(t('title'))}
            alignment={'center'}
            borderRequired={true}
            heading="h2"
            type="2"
            isMobile={isMobile}
            isTablet={isTablet}
          />
          <UIParagraph textAlign={'center'}>
            <Translate id={t('mainParagraph')} />
          </UIParagraph>
        </UISection>
      </UIGrid2cols>
    </UIContainer>
  );
};

export default withLocalize(OurPeople);
