import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { colors } from './../../../../defaults/styles';

// == Styling == //
const PopupOuter = styled.div`
  height: ${props => (props.showPopup ? '100vh' : 0)};
  width: ${props => (props.showPopup ? '100vw' : 0)};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: all 0.5s ease-in-out;
`;

const PopupInner = styled.div`
  background-color: ${colors.white.normal};
  border-radius: 3px;
  height: 432px;
  width: 768px;
  ${'' /* overflow: hidden; */}
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1100;

  /* for contents inside */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Mask = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
`;

const CloseButton = styled.button`
  background-color: transparent;
  color: ${colors.white.normal};
  cursor: pointer;
  position: absolute;
  top: -5px;
  right: -40px;
`;

const Contents = styled.div``;
// == /Styling == //

// function to close modal when outside of the video is clicked
function useOuterClickNotifier(onOuterClick, innerRef) {
  useEffect(() => {
    if (innerRef.current) {
      document.addEventListener('click', handleClick);
    }
    return () => document.removeEventListener('click', handleClick);

    function handleClick(e) {
      innerRef.current &&
        !innerRef.current.contains(e.target) &&
        onOuterClick(e);
    }
  }, [onOuterClick, innerRef]);
}

const Popup = ({ children, showPopup, setShowPopup }) => {
  const innerRef = useRef(null);
  useOuterClickNotifier(e => setShowPopup(false), innerRef);
  return (
    <PopupOuter showPopup={showPopup}>
      <Mask />
      <PopupInner ref={innerRef}>
        <Contents>{children}</Contents>
        <CloseButton onClick={() => setShowPopup(false)}>
          <FontAwesomeIcon icon={faTimes} size="3x" />
        </CloseButton>
      </PopupInner>
    </PopupOuter>
  );
};
export default Popup;
