import * as types from '../actions/types';
import { userInfo } from './initialState';

export default function userInfoReducer(state = userInfo, actions) {
  switch (actions.type) {
    case types.USER_INFO_SUCCESS:
      return { ...actions.userInfo };
    default:
      return state;
  }
}
