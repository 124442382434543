import React from 'react';
import { lsStyled as styled } from './../../../tools/helpers/lsStyled';
import { colors, spacing } from '../../../defaults/styles';

const StyledUl = styled.ul`
  display: grid;
  grid-gap: 1.5em;
  align-items: center;
  justify-content: start;
  list-style: none;
  margin-bottom: ${spacing.marginStandard};
  padding-right: ${spacing.large*1.5}px;
  padding-left: ${spacing.large*1.5}px;
  width: 100%;
  ${props => (props.ulCustomStyle ? props.ulCustomStyle : null)};
`;

const StyledLi = styled.li`
  height: 100%;
  position: relative;
  width: 100%;
  ${props => (props.liCustomStyle ? props.liCustomStyle : null)};
`;

const Text = styled.p``;

const Circle = styled.div`
  height: 2px;
  width: 2px;
  padding: 2px;
  text-align: center;
  border-radius: 50%;
  display: inline-block;
  color: ${colors.white.normal};
  font-size: 1em;
  font-weight: 500;
  background-color: transparent;
  border: 3px solid ${colors.primary.normal};

  position: absolute;
  top: 2px;
  left: -20px;
`;

const UIBulletListItems = ({
  children,
  text = [],
  ulCustomStyle = null,
  liCustomStyle = null,
}) => {
  const items = text.map((item, index) => (
    <StyledLi liCustomStyle={liCustomStyle} key={index}>
      <Circle></Circle>
      <Text>{item.text}</Text>
    </StyledLi>
  ));

  return (
    <StyledUl ulCustomStyle={ulCustomStyle}>
      {items}
      {children}
    </StyledUl>
  );
};
export default UIBulletListItems;
