import React from 'react';

import PageReinforcementStatic from './../../interface/PageReinforcementStatic';
import UIContainer from './../../interface/UIContainer';
import UISection from './../../interface/UISection';
import UIParagraph from './../../interface/UIParagraph';
import UIGrid2cols from './../../interface/UIGrid2cols';
import UIListItemBullet from './../../interface/UIListItemBullet';
import { withLocalize } from 'react-localize-redux';

const PartnersAndSuppliers = ({ isMobile, isTablet, translate }) => {
  const t = (tag) => `footer.services.partnersAndSuppliers.${tag}`;

  return (
    <UIContainer isMobile={isMobile} isTablet={isTablet}>
      <UIGrid2cols isMobile={isMobile} isTablet={isTablet} mobileOrderReverse>
        <UISection noPadding>
          <img
            src={'/images/pages/services-wholesale/Services-Bulk-02.jpg'}
            alt={'Loyalty Source Second Bulk'}
            style={{ maxWidth: !isMobile ? 450 : '100%' }}
          />
        </UISection>
        <UISection noPadding>
          <PageReinforcementStatic
            title={translate(t('title'))}
            borderRequired={true}
            heading={'h3'}
            type={'2'}
          >
            <UIParagraph>
              {translate(t('mainParagraph'))}
            </UIParagraph>
          </PageReinforcementStatic>

          <UIListItemBullet
            text={[
              { text: translate(t('listItems.fullControl')) },
              { text: translate(t('listItems.liquidate')) },
              { text: translate(t('listItems.open')) },
            ]}
          />
        </UISection>
      </UIGrid2cols>
    </UIContainer>
  );
};

export default withLocalize(PartnersAndSuppliers);