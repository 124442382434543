import * as types from '../actions/types'
import { landingPageBanners } from './initialState'

export default function landingPageReducer(state = landingPageBanners, actions) {
    switch (actions.type) {
        case types.LOAD_LANDING_PAGE_BANNER_SUCCESS:
            return actions.banners
        default:
            return state
    }
}