import colors from './colors';

/* HEADING TYPO MEMO */
// H1: Roboto Black, 44px
// H2: Roboto Black, 28 px (or Roboto Regular if subtext to an H1)
// H3: Roboto Bold, 22px (or Roboto Regular if subtext to H2)
// General Text: Roboto Regular, 18px, line height 1.4em

// Please set font-size: 10px in parent element.
const fontBaseSize = '1em';
const fonts = {
  h1: {
    color: colors.black.normal,
    fontSize: fontBaseSize * 4.4,
    // fontWeight: 'bold',
  },
  h2: {
    color: colors.black.normal,
    fontSize: fontBaseSize * 2.8,
    // fontWeight: 'bold',
  },
  h3: {
    color: colors.black.normal,
    fontSize: fontBaseSize * 2.2,
    fontWeight: 'bold',
  },
  h4: {
    color: colors.black.normal,
    fontSize: fontBaseSize * 1.8,
    fontWeight: 'normal',
  },
  h5: {
    color: colors.black.normal,
    fontSize: fontBaseSize * 1,
    fontWeight: 'normal',
  },
  h6: {
    color: colors.black.normal,
    fontSize: fontBaseSize * 0.8,
    fontWeight: 'normal',
  },
  normal: {
    fontSize: fontBaseSize * 1.8,
    lineHeight: '1.4em',
  },
};

export default fonts;
