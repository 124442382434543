import React from 'react';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';

const AnimationContainer = styled.div`
    height: ${props => props.targetHeight}px;
    transition: height 0.5s ease-in-out;
    overflow: hidden;
`; 

const AnimationBox = ({children, collapse, infoSection, isMobile, targetHeight}) => {
    return ( 
        <AnimationContainer collapse={collapse} infoSection={infoSection} isMobile={isMobile} targetHeight={targetHeight}>
            {children}
        </AnimationContainer>
    );
}

export default AnimationBox;

