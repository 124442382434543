import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import {
  sizing,
  fonts,
  spacing,
  colors,
} from '../../../../../../defaults/styles';
import { vendorPrefix } from '../../../../../../tools/helpers/styling';

const MenuButton = ({ open = false }) => (
  <div
    style={{
      width: sizing.header.container,
      height: sizing.header.container,
      textAlign: 'center',
      marginRight: spacing.small,
      color: colors.gray.normal,
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      ...vendorPrefix('userSelect', 'none'),
    }}
  >
    <FontAwesomeIcon
      icon={open ? faTimes : faBars}
      style={{ fontSize: fonts.sizes.heading }}
    />
  </div>
);

export default MenuButton;
