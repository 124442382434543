import * as types from './types'
import { product } from '../../tools/api'
import { beginApiCall, apiCallError } from './apiStatusActions'

export const loadProductSuccess = product => {
  return { type: types.LOAD_PRODUCT_SUCCESS, product }
}

export const loadProduct = (sku, params) => {
  return dispatch => {
    dispatch(beginApiCall())
    return product
      .getProduct(sku, params)
      .then(({ json: product }) => {
        dispatch(loadProductSuccess(product))
      })
      .catch(error => {
        dispatch(apiCallError(error))
        throw error
      })
  }
}
