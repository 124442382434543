import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { withLocalize } from 'react-localize-redux';
import { loadFeatureProducts } from '../../../redux/actions/featureProductsActions';
import { modifyFilters } from '../../../redux/actions/filtersActions';
import { loadLandingPageBanners } from '../../../redux/actions/landingPageActions';

export const LoggedInHomeContainer = ({
    loadLandingPageBanners,
    loadFeatureProducts,
    activeLanguage,
    filters,
    modifyFilters,
    history,
    apiCallsInProgress,
    ...props
}) => {
    const [ featureProducts, setFeatureProducts ] = useState({ ...props.featureProducts });
    const [ landingPageBanners, setLandingPageBanners ] = useState({ ...props.landingPageBanners });

    // Load feature products
    useEffect(() => { 
        const language = activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';
        loadFeatureProducts({
            language: language
        }, true); 
        loadLandingPageBanners({
            language: language
        })
    }, [loadFeatureProducts, activeLanguage, loadLandingPageBanners]);

    // Set it after it's loaded
    useEffect(() => { setFeatureProducts({...props.featureProducts}); }, [props.featureProducts]);
    useEffect(() => { setLandingPageBanners([...props.landingPageBanners]); }, [props.landingPageBanners]);

    // Push prop to children
    const childrenWithProps = React.Children.map(props.children, child =>
        React.cloneElement(child, { featureProducts, filters, modifyFilters, apiCallsInProgress, landingPageBanners })
    );

    // Return just presentation of children
    return <>{childrenWithProps}</>;
};

LoggedInHomeContainer.propTypes = {
    loadFeatureProducts: propTypes.func.isRequired,
    featureProducts: propTypes.object.isRequired,
    loadLandingPageBanners: propTypes.func.isRequired
};

function mapStateToProps({ featureProducts, filters, apiCallsInProgress, landingPageBanners }) {
    return { featureProducts, filters, apiCallsInProgress, landingPageBanners };
}

const mapDispatchToProps = {
    loadFeatureProducts, modifyFilters, loadLandingPageBanners
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
) (withLocalize(LoggedInHomeContainer));