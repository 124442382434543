import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import UIContainer from './../../interface/UIContainer';
import Button from './../../interface/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { fonts, colors, spacing } from '../../../../defaults/styles';
import { Translate, withLocalize } from 'react-localize-redux';
import UIParagraph from '../../interface/UIParagraph';
import { LSFetch } from '../../../../tools/helpers';

const Form = styled.form`
  width: 50%;
  min-width: 300px;
  display: block;
  margin: ${spacing.small * 2}px auto;
  padding: ${spacing.medium}px ${spacing.medium}px 0 ${spacing.medium}px;
`;

const Paragraph = styled.p`
  font-size: ${fonts.sizes.subHeading}px;
  font-weight: ${fonts.weights.title};
  margin-bottom: ${spacing.large}px;
  text-align: center;
`;

const InputContainer = styled.div`
  margin-bottom: ${spacing.medium}px;
  position: relative;
`;

const Input = styled.input`
  border: 1px solid ${colors.gray.dark};
  border-radius: 2px;
  font-size: ${fonts.sizes.text};
  margin-bottom: ${spacing.small};
  padding: ${spacing.small}px ${spacing.small}px ${spacing.small}px ${spacing.veryLarge}px;
  width: 100%;
  line-height: 1.5;
  height: auto;

  :focus {
    border-color: ${colors.main.normal};
    box-shadow: 0 0 0 1px ${colors.main.normal};
  }
`;

const CheckboxContainer = styled.div`
  margin-bottom: ${spacing.medium}px;
  display: flex;
  align-items: center;
`;

const Checkbox = styled.input`
  margin-right: ${spacing.small}px;
`;

const IconBox = styled.span`
  opacity: 0.2;
  position: absolute;
  top: 50%;
  left: 0.5rem;
  transform: translateY(-50%);
`;

const ContainerWrapper = styled.div`
  width: 1200px;
  margin: 0 auto;
`;

const CanvasContainer = styled.div`
  border-radius: 2px;
  margin-bottom: ${spacing.medium}px;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
`;

const Canvas = styled.canvas`
  border: 1px solid ${colors.gray.dark};
  width: 100%;
  height: 200px;
`;

const ClearButton = styled.button`
  margin-left: ${spacing.small}px;
  background-color: ${colors.gray.light};
  border: 1px solid ${colors.gray.dark};
  color: ${colors.gray.dark};
  height: 40px;
  border-radius: 4px;
  cursor: pointer;
  padding: 0 ${spacing.medium}px;
`;

const InstructionText = styled.p`
  text-align: center;
  font-size: ${fonts.sizes.text}px;
  color: ${colors.gray.dark};
`;

const AcceptAgreement = ({ isMobile, isTablet, translate, vendorAgreement, activeLanguage, history }) => {
    const t = (tag) => `vendorAgreement.accept.${tag}`;

    const [name, setName] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const canvasRef = useRef(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [context, setContext] = useState(null);

    const handleNameChange = (e) => {
        setName(e.target.value);
    };

    // const handleCheckboxChange = (e) => {
    //     setIsChecked(e.target.checked);
    // };

    useEffect(() => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        setContext(context);

        // Handle canvas resizing
        const handleResize = () => {
            canvas.width = canvas.parentElement.clientWidth;
            canvas.height = canvas.parentElement.clientHeight;
            redraw(); // Redraw content when resizing
        };

        const redraw = () => {
            // Optionally redraw previous content if needed
        };

        handleResize();
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const startDrawing = (e) => {
        const rect = canvasRef.current.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        const offsetY = e.clientY - rect.top;
        context.beginPath();
        context.moveTo(offsetX, offsetY);
        setIsDrawing(true);
    };

    const draw = (e) => {
        if (!isDrawing) return;
        const rect = canvasRef.current.getBoundingClientRect();
        const offsetX = e.clientX - rect.left;
        const offsetY = e.clientY - rect.top;
        context.lineTo(offsetX, offsetY);
        context.stroke();
    };

    const endDrawing = () => {
        context.closePath();
        setIsDrawing(false);
    };

    const clearCanvas = () => {
        context.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    };

    const isCanvasEmpty = () => {
        const canvas = canvasRef.current;
        const context = canvas.getContext('2d');
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
        const data = imageData.data;
        
        for (let i = 0; i < data.length; i += 4) {
            // Check if any pixel is not fully transparent
            if (data[i + 3] > 0) {
                return false; // Canvas is not empty
            }
        }
        return true; // Canvas is empty
    };    

    const handleConfirmAndSendSignature = async (e) => {
        e.preventDefault();
        if (name.length > 0) {
            if (isCanvasEmpty()) {
                alert('Please draw your signature before submitting.');
                return;
            }
    
            // Get the signature image from the canvas as a JPEG
            const signatureImage = canvasRef.current.toDataURL('image/png');
    
            // Convert base64 to Blob
            const base64ToBlob = (base64) => {
                const byteString = atob(base64.split(',')[1]);
                const mimeString = base64.split(',')[0].split(':')[1].split(';')[0];
                const arrayBuffer = new ArrayBuffer(byteString.length);
                const intArray = new Uint8Array(arrayBuffer);
    
                for (let i = 0; i < byteString.length; i++) {
                    intArray[i] = byteString.charCodeAt(i);
                }
    
                return new Blob([arrayBuffer], { type: mimeString });
            };
    
            const signatureBlob = base64ToBlob(signatureImage);
    
            // Prepare the data for the API request
            const formData = new FormData();
            formData.append('signature', signatureBlob, 'signature.png'); // Attach as file with filename
            formData.append('userAccountId', vendorAgreement.userAccountId);
            formData.append('name', name);
    
            // Send the data to the API using LSFetch
            try {
                await LSFetch.post('/accountRequest/vendorAgreement', {
                    body: formData,
                    headers: {
                        // No need to set 'Content-Type' for FormData
                    }
                });
                history.push('/vendor-agreement-success');
            } catch (error) {
                console.error('Error submitting signature:', error);
                alert('Failed to submit signature. Please try again.');
            }
        } else {
            alert('Please enter your name.');
        }
    };

    let validToken;
    if (!vendorAgreement || vendorAgreement.invalidToken || vendorAgreement.expiredToken) {
        validToken = false;
    } else {
        validToken = true;
    }

    if (!vendorAgreement) {
        return null;
    }

    return (
        validToken ? (
            <UIContainer isMobile={isMobile} isTablet={isTablet}>
                <UIParagraph
                    customStyle={{ marginBottom: 10 }}
                    textAlign={'left'}
                    borderRequired
                    heading={'h2'}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    maxWidth={'1200'}
                >
                    {translate(t('acknowledge'))}
                </UIParagraph>
                <Form onSubmit={handleConfirmAndSendSignature}>
                    <Paragraph>{translate(t('pleaseEnterNameAndAgree'))}</Paragraph>
                    <InputContainer>
                        <IconBox>
                            <FontAwesomeIcon icon={faUser} />
                        </IconBox>
                        <Input
                            type="text"
                            value={name}
                            onChange={handleNameChange}
                            placeholder={translate(t('enterYourName'))}
                            required
                        />
                    </InputContainer>
                    <InstructionText>{translate(t('signature'))}</InstructionText>
                    <CanvasContainer>
                        <Canvas
                            ref={canvasRef}
                            onMouseDown={startDrawing}
                            onMouseMove={draw}
                            onMouseUp={endDrawing}
                            onMouseLeave={endDrawing}
                        />
                        <ClearButton type="button" onClick={clearCanvas}>Clear</ClearButton>
                    </CanvasContainer>
                    {/* <CheckboxContainer>
                        <Checkbox
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                            required
                        />
                        <span>{translate(t('agreeToTerms'))}</span>
                    </CheckboxContainer> */}
                    <Button
                        children={translate(t('confirmAndSubmitAgreement'))}
                        borderRadius={3}
                        fill={true}
                        size={16}
                        fullWidth={true}
                        type="submit"
                    />
                </Form>
            </UIContainer>
        ) : null
    );
};

export default withLocalize(AcceptAgreement);