import React from 'react';
import PageReinforcement from './../../interface/PageReinforcement';
import { withLocalize } from 'react-localize-redux';

const Intro = ({ translate }) => {
  const t = (tag) => `services.legacy.intro.${tag}`;
  return (
    <PageReinforcement
      title={translate(t('title'))}
      body={translate(t('paragraph'))}
    />
  );
};

export default withLocalize(Intro);
