import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import UIContainer from './../../interface/UIContainer';
import Button from './../../interface/Button';
import ErrorMessage from './ErrorMessage';
import SuccessMessage from './SuccessMessage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { fonts, colors, spacing } from '../../../../defaults/styles';

import { Translate, withLocalize } from 'react-localize-redux';

import { forgotPassword } from '../../../../tools/api/authenticate';
import { getCAPTCHAKey } from '../../../../tools/helpers/recaptcha';
import ReCAPTCHA from 'react-google-recaptcha';

const Form = styled.form`
  width: 50%;
  min-width: 300px;
  display: block;
  margin: ${spacing.small * 2}px auto;
  padding: ${spacing.medium}px ${spacing.medium}px 0 ${spacing.medium}px;
`;

const Paragraph = styled.p`
  font-size: ${fonts.sizes.subHeading}px;
  font-weight: ${fonts.weights.title};
  margin-bottom: ${spacing.large}px;
  text-align: 'center';
`;

const InputContainer = styled.div`
  margin-bottom: ${spacing.medium}px;
  position: relative;
`;
const Input = styled.input`
  border: 1px solid ${colors.gray.light};
  border-radius: 2px;
  font-size: ${fonts.sizes.text};
  margin-bottom: ${spacing.small};
  padding: ${spacing.small}px ${spacing.small}px ${spacing.small}px
    ${spacing.veryLarge}px;
  width: 100%;

  :focus {
    border-color: ${colors.main.normal};
    box-shadow: 0 0 0 1px ${colors.main.normal};
  }
`;

const IconBox = styled.span`
  opacity: 0.2;
  position: absolute;
  top: 50%;
  left: 0.5rem;
  transform: translateY(-50%);
`;

const ReCAPTCHAWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: ${spacing.medium}px;
`;

const RequestForm = ({ activeLanguage, isMobile, isTablet, translate }) => {
  const t = (tag) => `forgetPassword.main.${tag}`;

  const lang = activeLanguage && activeLanguage.code === 'fr' ? 'fr-CA' : 'en';

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if(isFormSubmitted) {
        resetIsFormSubmitted();
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isFormSubmitted]);

  const resetIsFormSubmitted = () => {
    setIsFormSubmitted(false);
  };

  // state for the form
  const [form, setValues] = useState({
    username: '',
    error: false,
    success: false,
  });

  const handleSubmit = e => {
    e.preventDefault();

    if (!form.username || !form.captcha) {
      return setValues({ ...form, error: true });
    };

    forgotPassword(form.username, form.captcha, activeLanguage.code)
      .then(({ status }) => {
        if (status < 200 || status > 202)
          return setValues({ ...form, error: true });

        setIsFormSubmitted(true);
        setValues({ ...form, error: false, success: true });
      })
      .catch(error => {
        setValues({ ...form, error: true });
      });
  };

  const handleUpdate = e => {
    setValues({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const onReCAPTCHAChange = (value) => {
    setValues({
      ...form,
      captcha: value
    });
  }

  return (
    <UIContainer isMobile={isMobile} isTablet={isTablet}>
      <Form onSubmit={handleSubmit} id={'ForgotPassword'} data-hs-do-not-collect="true">
        {!isFormSubmitted && 
            <InputContainer>
            <IconBox>
              <FontAwesomeIcon icon={faUser} />
            </IconBox>
            <Input
              type={'text'}
              name={'username'}
              placeholder={translate(t('username'))}
              value={form.username}
              onChange={handleUpdate}
              required
              autoFocus
            />
          </InputContainer>
        }

        {!form.success && (
          <>
            <ReCAPTCHAWrapper>
              <ReCAPTCHA
                sitekey={getCAPTCHAKey()}
                onChange={onReCAPTCHAChange}
                hl={lang}
              />
            </ReCAPTCHAWrapper>
            <Button
              children={translate(t('submitButton'))}
              borderRadius={3}
              fill={true}
              size={16}
              fullWidth={true}
            />
          </>
        )}
        {form.error && <ErrorMessage error={true} />}
        {form.success && <SuccessMessage />}
      </Form>
    </UIContainer>
  );
};

export default withLocalize(RequestForm);
