import React from 'react';
import Item from './Item';
import { Translate, withLocalize } from 'react-localize-redux';
import { lsStyled as styled } from '../../../../../../../tools/helpers/lsStyled';
import { lsFormatNum as formatNum } from '../../../../../../../tools/helpers/lsFormatNum'
import { spacing, colors } from '../../../../../../../defaults/styles';

const PriceItem = styled.li`
  margin-bottom: ${spacing.verySmall}px;
`;
const Pricing = ({ product, user, activeLanguage }) => {
  const t = (tag) => `product.info.${tag}`;

  const logInRequired = user && !user.loggedIn;
  const lang = activeLanguage && activeLanguage.code === 'fr' ? 'fr' : 'en';

  const discount = product.msrp ? product.msrp - product.price : null;
  const getDiscountPercentage = (discount) => {
    const percentage = Math.ceil(discount / ((typeof product.msrp === 'string') ? Number(product.msrp) : product.msrp) * 100);
    return percentage ? formatNum(percentage, lang) : null;
  }

  return (
    <section
      style={{
        paddingTop: spacing.veryLarge,
        paddingBottom: spacing.large - spacing.verySmall,
        borderTop: `1px solid ${colors.gray.light}`,
      }}
    >
      <ul style={{ listStyle: 'none' }}>
        <Translate>
          {({ translate }) => (
            <>
              <PriceItem>
                <Item
                  logInRequired={logInRequired}
                  label={translate(t('msrp'))}
                  value={logInRequired ? null : product.msrp}
                />
              </PriceItem>
              {!logInRequired && (
                <>
                  <PriceItem>
                    <Item
                      label={translate(t('ourPrice'))}
                      value={product.price}
                    />
                  </PriceItem>
                  {discount > 0 && (
                    <PriceItem>
                      <Item
                        label={translate(t('discount'))}
                        value={product.msrp - product.price}
                        discountPercentage={getDiscountPercentage(discount)}
                      />

                    </PriceItem>
                  )}
                  <PriceItem>
                    <Item
                      label={translate(t('status'))}
                      value={product.status}
                    />
                  </PriceItem>
                </>
              )}
            </>
          )}
        </Translate>
      </ul>
    </section>
  );
};

export default withLocalize(Pricing);

