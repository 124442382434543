import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { fonts, spacing, colors } from '../../../../../../defaults/styles';
import { lsStyled as styled } from '../../../../../../tools/helpers/lsStyled';

const InputsDiv = styled.div`
    display: flex;
    align-items: center;
`;

const NumberInput = styled.input`
    flex: 1;
    display: block;
    border: 1px solid;
    padding: ${spacing.verySmall}px;
    font-size: ${fonts.sizes.smallText}px;
    text-align: right;
    border-color: ${colors.gray.normal};
    min-width: 0;

    :focus {
        border-color: ${colors.main.normal};
        box-shadow: 0 0 0 1px ${colors.main.normal};
    }
`;

const ConnectingTextWrapper = styled.div`
    padding-left: ${spacing.verySmall}px;
    padding-right: ${spacing.verySmall}px;
`;

const Inputs = ({ 
    setPrice,
    price,
    translate
}) => {
    const t = (tag) => `catalog.price.${tag}`;

    return (
        <InputsDiv>
            <NumberInput
                type="number"
                placeholder={translate(t('min'))}
                min="0" max="99999"
                value={price.priceStart}
                onChange={({ target: { value } }) => setPrice({
                    ...price,
                    priceStart: value
                })}
            />

            <ConnectingTextWrapper>
                <Translate id={t('to')} />
            </ConnectingTextWrapper>

            <NumberInput
                type="number"
                placeholder={translate(t('max'))}
                min="0" max="99999"
                value={price.priceEnd}
                onChange={({ target: { value } }) => setPrice({
                    ...price,
                    priceEnd: value
                })}
            />
        </InputsDiv>
    );
};

export default withLocalize(Inputs);
