
import React from 'react';
import { withLocalize } from 'react-localize-redux';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShareAlt } from '@fortawesome/pro-light-svg-icons';
import { lsStyled as styled } from '../../../../../../../tools/helpers/lsStyled';
import { colors } from '../../../../../../../defaults/styles';

const ZoomShareButton = ({
  user,
  sku,
  handleShareOnClick,
  translate
}) => {
  const t = (tag) => `product.info.${tag}`;

  return <> {user && user.loggedIn &&
    (<ShareDiv
      title={translate(t(`productPresentationHover`))}
      >
      <FontAwesomeIcon
        icon={faShareAlt}
        size="lg"
        style={{
          color: colors.primary.normal,
          bottom: '0.5rem',
          right: '0.5rem',
          zIndex: 100
        }}
        onClick={() => handleShareOnClick(sku)}
      />
    </ShareDiv>)
  }
  </>;
}

const ShareDiv = styled.div`
  margin-right: 0.5rem;
  z-index: 5;
  &:hover {
    svg {
      color: ${colors.main.light} !important;
      transition: color 0.3s ease;
    }
  }
`;

function mapStateToProps({ shoppingLists, user }) {
  return { shoppingLists, user };
}
const mapDispatchToProps = {
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(ZoomShareButton));