import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons';
import { Translate, withLocalize } from 'react-localize-redux';
import { lsStyled as styled } from '../../../../../../../tools/helpers/lsStyled';
import content from '../../content';
import { colors, spacing } from '../../../../../../../defaults/styles';

const styles = {
  ulList: {
    // borderTop: `1px solid ${colors.gray.normal}`,
    listStyle: 'none',
  },
  link: {
    borderBottom: `1px solid ${colors.gray.light}`,
    color: colors.black.normal,
    display: 'flex',
    flex: 1,
    textDecoration: 'none',
    paddingTop: spacing.large,
    paddingRight: 35,
    paddingBottom: spacing.large,
    paddingLeft: 35,
  },
  linkSecondLevel: {
    borderBottom: `1px solid ${colors.gray.light}`,
    color: colors.black.normal,
    display: 'block',
    textDecoration: 'none',
    paddingTop: spacing.large,
    paddingRight: spacing.large,
    paddingBottom: spacing.large,
    paddingLeft: spacing.veryLarge * 1.5,
  },
};

const Links = ({
  openProductsPopupClickHandler,
  setMobileMenuOpen,
  activeLanguage,
}) => {
  const t = (tag) => `header.menu.${tag}`;

  const lang =
    activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';
  return (
    <ul style={styles.ulList}>
      {content.links.map(({ url, lexiconCode }, index) => (
        <React.Fragment key={'mobile-link-' + index}>
          <li onClick={() => setMobileMenuOpen()}>
            <Link to={'/' + lang + url} style={styles.link}>
              <Translate id={t(lexiconCode)} />
              <IconBox>
                <FontAwesomeIcon icon={faChevronRight} />
              </IconBox>
            </Link>
          </li>
          {url.indexOf('service') >= 0 &&
            content.subLinks.map(({ url, lexiconCode }, index) => (
              <li
                key={'mobile-sub-link-' + index}
                onClick={() => setMobileMenuOpen()}
              >
                <Link to={'/' + lang + url} style={styles.linkSecondLevel}>
                  <Translate id={t(lexiconCode)} />
                </Link>
              </li>
            ))}
          {url.indexOf('news') >= 0 &&
            content.subNewsLinks.map(({ url, lexiconCode }, index) => (
              <li
                key={'mobile-sub-link-' + index}
                onClick={() => setMobileMenuOpen()}
              >
                <Link to={'/' + lang + url} style={styles.linkSecondLevel}>
                  <Translate id={t(lexiconCode)} />
                </Link>
              </li>
            ))}
          {url.indexOf('catalog') >= 0 &&
            content.subProductLinks.map(({ url, lexiconCode }, index) => (
              <li
                key={'mobile-sub-link-' + index}
                onClick={() => setMobileMenuOpen()}
              >
                <Link to={'/' + lang + url} style={styles.linkSecondLevel}>
                  <Translate id={t(lexiconCode)} />
                </Link>
              </li>
            ))}
        </React.Fragment>
      ))}
      <li onClick={() => setMobileMenuOpen()}>
        <Link style={styles.link} to={`/${lang}/contact-us`}>
          <Translate id={t('contact')} />
          <IconBox>
            <FontAwesomeIcon icon={faChevronRight} />
          </IconBox>
        </Link>
      </li>
    </ul>
  );
};

export default withLocalize(Links);

const IconBox = styled.span`
  display: inline-block;
  margin-left: auto;
  text-align: center;
  width: 2.5em;
`;
