import React from 'react';
import HubspotForm from 'react-hubspot-form';
import GridLoader from 'react-spinners/GridLoader';
import { fonts, colors } from '../../../../defaults/styles';

const HubspotContactForm = (param) => {
  return (
    param.language && param.language === 'fr' ?
    <HubspotForm
        region= "na1"
        portalId= "23257042"
        formId= "1981a055-e55a-435f-8e98-2c4d8215715b"
        loading={<GridLoader
          css={{ margin: 'auto' }}
          size={fonts.sizes.text}
          color={colors.main.normal}
        />}
    />
    :
    <HubspotForm
        region= "na1"
        portalId= "23257042"
        formId= "50a4e676-b669-43bc-9a13-fd14029505e2"
        loading={
          <GridLoader
            css={{ margin: 'auto' }}
            size={fonts.sizes.text}
            color={colors.main.normal}
          />
        }
    />
  );
};

export default HubspotContactForm;