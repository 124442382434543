import React from 'react';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';
import { withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../../ui/common';

const Aside = styled.aside`
    position: absolute;
    top: 0px;
    left: 0;

    ${props => props.isMobile ? `
        top: 15px;
        padding-right: 1.5em;
        padding-left: 1.5em;
    ` : ''};
`;

const BreadcrumbsList = styled.ul`
    list-style: none;
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
    line-height: 0.8em;
`;

const BreadItem = styled.li`
    border-bottom: 1px solid transparent;
    opacity: ${props => props.active ? '1' : '0.4'};
    transition: 200ms opacity;
    ${props => props.isClickable ? `
        cursor: pointer;
        :hover {
            border-color: ${colors.gray.normal};
        }
    ` : ''}

    ${props => props.isDisabled ? `
        cursor: default;
        user-select: none;
        :hover {
            border-color: transparent;
        }
    ` : ''}
`;

const IconWrapper = styled.span`
    display: block;
    opacity: ${props => props.active ? '1' : '0.4'};
`;

export const STAGES = Object.freeze({
    MY_CART: 0,
    SHIPPING_INFO: 1,
    CONFIRMATION: 2
})

const Breadcrumbs = ({  
    breadIndex, 
    setBreadIndex,
    cart, 
    setCart,
    confirm,
    setConfirm,
    submit, 
    setSubmit,
    submitCart,
    isMobile, 
    translate 
}) => {

    const items = [
        {
            stage: 'myCart',
            isClickable: breadIndex > STAGES.MY_CART,
            isDisabled: false,
            onClick: () => {
                if (breadIndex === STAGES.SHIPPING_INFO) {
                    setConfirm(false);
                    setBreadIndex(0);
                }
                else if(breadIndex === STAGES.CONFIRMATION){
                    setConfirm(false);
                    setSubmit(false);
                    setBreadIndex(0);
                }
            }
        },
        {
            stage: 'shippingInfo',
            isClickable: breadIndex !== STAGES.SHIPPING_INFO,
            isDisabled: breadIndex === STAGES.CONFIRMATION,
            onClick: () => {
                if(breadIndex === STAGES.MY_CART){
                    setConfirm(true);
                    setBreadIndex(STAGES.SHIPPING_INFO);
                }
            }
        },
        {
            stage: 'confirmation',
            isClickable: false,
            isDisabled: breadIndex === STAGES.MY_CART,
            onClick: () => {}
        }
    ]
    const cartItems = cart && cart.items;
    return (
        <Aside isMobile={isMobile}>
            <BreadcrumbsList>
            
                { items.map( ( item, index )=> {
                    return (
                        <>
                        <BreadItem 
                            key={item.stage} 
                            active={breadIndex === index} 
                            isClickable={
                                item.isClickable
                            } 
                            isDisabled={
                                item.isDisabled ||
                                !cartItems || !cart ||
                                cartItems.length === 0
                            }
                            onClick={()=>{
                                if(cart && cartItems && cartItems.length > 0){
                                    item.onClick();
                                }
                            }}
                        >
                            { translate(`checkout.breadcrumbs.${item.stage}`) }
                        </BreadItem>
                        { index < items.length - 1 
                            ? 
                            (
                                <IconWrapper key={'icon' + index} active={index === breadIndex}>
                                    <FontAwesomeIcon icon={faCaretRight} />
                                </IconWrapper>
                            )
                            : null

                        }
                        </>
                    );
                })}
            </BreadcrumbsList>
        </Aside>
    );
}

export default withRouter(withLocalize(Breadcrumbs));