import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faSpinner } from '@fortawesome/pro-solid-svg-icons';

import { withLocalize, Translate } from 'react-localize-redux';
import { report } from '../../../../tools/api';

import { colors, fonts, spacing } from './../../../../defaults/styles';

const DLSectionLabel = styled.div`
  font-weight: ${fonts.weights.title};
  padding-top: ${spacing.small}px;
`;

const DLContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: min-content;

  grid-gap: ${spacing.small}px;
  margin-top: ${spacing.large}px;
`;

const DLSectionBox = styled.div`
  white-space: pre;
`;

const DLButton = styled.button`
  background-color: transparent;
  border: 1px solid ${colors.gray.light};
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  font-size: ${fonts.sizes.medium}px;
  padding: ${spacing.verySmall}px ${spacing.small}px;
  position: relative;
  transition: all 0.1s ease-in-out;
  &:hover {
    border-color: ${colors.primary.normal};
  }
`;

const FileLabel = styled.span`
  color: ${colors.gray.normal};
  display: block;
  font-size: ${fonts.sizes.smallText}px;
  font-weight: ${fonts.weights.heading};
  letter-spacing: 0.3px;
  margin-top: ${spacing.verySmall}px;
  text-align: center;
`;

// following is not from styled-component, regular css.
const iconSytle = {
  color: colors.primary.normal,
  marginRight: spacing.verySmall,
};

const iconLoadingStyle = {
  color: colors.primary.normal,
  position: 'absolute',
  right: -20,
  transform: 'translateY(-50%)',
};

const DownloadFileSection = ({ userInfo }) => {
  const [isLoading, setIsLoading] = useState(false);
  const t = (tag) => `userInfo.downloadFile.${tag}`;

  const downloadCatalog = async () => {
    setIsLoading(true);
    report.downloadCatalog(() => { setIsLoading(false); });
  };

  return (
    <>
      {userInfo.accessToCatalog && (
        <DLContainer>
          {/* label */}
          <DLSectionLabel>
            <Translate id={t('downloadTitle')} />
          </DLSectionLabel>
          {/* file dl buttons */}
          <DLSectionBox>
            <DLButton onClick={downloadCatalog}>
              <FontAwesomeIcon icon={faFileDownload} style={iconSytle} />
              <Translate id={t('rewardCatalogueBtnLabel')} />
              {isLoading ? (
                <FontAwesomeIcon
                  icon={faSpinner}
                  style={iconLoadingStyle}
                  spin
                />
              ) : null}
            </DLButton>
            <FileLabel>
              <Translate id={t('rewardCatalogueBtnDescription')} />
            </FileLabel>
          </DLSectionBox>
        </DLContainer>
      )}
    </>
  );
};

export default withLocalize(DownloadFileSection);
