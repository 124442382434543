import React, { useState, useEffect } from 'react';
import Button from '../../../interface/Button';
import ErrorMessage from './ErrorMessage';
import { useLocation } from 'react-router-dom';
import { getCAPTCHAKey } from '../../../../../tools/helpers/recaptcha';
import ReCAPTCHA from 'react-google-recaptcha';

import { colors, spacing } from '../../../../../defaults/styles';
import {
  faUser,
  faKey,
  faEnvelope,
  faPhone,
  faBuilding,
  faFileSignature
} from '@fortawesome/pro-solid-svg-icons';

import { Translate, withLocalize } from 'react-localize-redux';
import { InputField } from './InputField';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';
import GridLoader from 'react-spinners/GridLoader';

const FormElem = styled.form`
  display: flex;
  flex-direction: column;
  position: relative;
`;

const GridItem = styled.div`
  display: grid;
  grid-gap: ${spacing.tiny}px;
  grid-template-columns: ${props => props.col2 ? '1fr 1fr' : '1fr'};
  grid-template-rows: ${props => props.col2 ? 'repeat(2, auto)' : null};
  grid-auto-flow: ${props => props.col2 ? 'column' : null};
`;

const ReCAPTCHAWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: ${spacing.medium}px;
`;

const Form = ({ onLogin, history, activeLanguage, sample, triggerMfa, msrp, isMobile, translate, loading }) => {

  const t = (tag) => `login.main.${tag}`;

  const [mfaError, setMfaError] = useState(false);
  const [expiredPasswordError, setExpiredPasswordError] = useState(false);

  const isClientMfaBypassEnabled = sessionStorage.getItem("isClientMfaBypassEnabled");
  const mfaBypassPeriod = sessionStorage.getItem("mfaBypassPeriod");
  const randomGeneratedId = sessionStorage.getItem("randomGeneratedId");

  useEffect(() => {
    const handleBeforeUnload = () => {
      if(mfaError) {
        resetError();
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [mfaError]);

  const resetError = () => {
    setMfaError(false);
    const currentUrl = history.location.pathname;
    history.push(`${currentUrl}`, { mfaError: false });
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      if(expiredPasswordError) {
        resetExpPasswordError();
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [expiredPasswordError]);

  const resetExpPasswordError = () => {
    setExpiredPasswordError(false);
    const currentUrl = history.location.pathname;
    history.push(`${currentUrl}`, { expiredPasswordError: false });
  };

  const location = useLocation();
  const locationState = location.state;

  useEffect(() => {
    if (locationState && locationState.mfaError) {
        setMfaError(true);
    } else {
        setMfaError(false);
    }
  }, [location]);

  useEffect(() => {
    if (locationState && locationState.expiredPasswordError) {
        setExpiredPasswordError(true);
    } else {
        setExpiredPasswordError(false);
    }
  }, [location]);

  // state for the form
  const [form, setValues] = useState(() => ({
    username: '',
    password: '',
    token: '',
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    phone: '',
    phoneExt: '',
    errors: false,
    validForm: false,
    showPassword: false,
    captcha: '',
    isMfaBypassEnabled: false,
    isClientMfaBypassEnabled: false,
    mfaBypassPeriod: '',
    randomGeneratedId: '',
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Mfa login check
    if (triggerMfa) {
      onLogin(
        sessionStorage.getItem("username"),
        '',
        form[randomGeneratedId],
        () => window.location.href = `/${lang}`,
        () => setValues({ ...form, errors: true }),
        form.isMfaBypassEnabled,
        randomGeneratedId
      );
    }
    // Regular login check
    else if (!sample && !triggerMfa && !msrp) {
        onLogin(
          form.username,
          form.password,
          '',
          () => window.location.href = `/${lang}`,
          () => setValues({ ...form, errors: true })
        );
    }
    // Sample or msrp account check
    else {
      if (!form.captcha) {
        return setValues({ ...form, errors: true });
      };
     if(sample) {
        onLogin(
          form.username,
          form.password,
          '',
          () => window.location.href = `/${lang}`,
          () => setValues({ ...form, errors: true }),
          '',
          '',
          form.firstName,
          form.lastName,
          form.company,
          form.email,
          form.phone,
          form.phoneExt,
          form.captcha
        );
      } else if(msrp) {
        onLogin(
          form.username,
          form.password,
          '',
          () => window.location.href = `/${lang}`,
          () => setValues({ ...form, errors: true }),
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          '',
          form.captcha
        );
      }

    }
  };

  const handleUpdate = e => {
    if (e.target.type === 'checkbox') {
      setValues({
        ...form,
        [e.target.name]: e.target.checked,
      });
    } else {
      setValues({
        ...form,
        [e.target.name]: e.target.value,
      });
    }
  };

  const toggleShowPassword = () => {
    setValues({
      ...form,
      showPassword: !form.showPassword,
    });
  };

  const lang =
    activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';

  const onReCAPTCHAChange = (value) => {
    setValues({
      ...form,
      captcha: value
    });
  }

  return (
    loading? <GridLoader css={{ margin: 'auto' }} color={colors.main.normal} size={12} />: 
    <FormElem onSubmit={handleSubmit} data-hs-do-not-collect="true">
      {/* Username & Password login form */}
      {sample === false && msrp === false && triggerMfa === false && (
        <>
          <InputField
            id={'username'}
            form={form}
            autoFocus={true}
            faIcon={faUser}
            lexiconId={t('username')}
            onChange={handleUpdate}
            styleLoginReg={true}
          />
          <InputField
            id={'password'}
            form={form}
            faIcon={faKey}
            lexiconId={t('password')}
            onChange={handleUpdate}
            onTogglePassword={toggleShowPassword}
            type={form.showPassword ? 'text' : 'password'}
            styleLoginReg={true}
          />
        </>
      )}

      {/* MFA form */}
      {triggerMfa === true && sample === false && msrp === false && (
        <>
          <InputField
            id={randomGeneratedId}
            form={form}
            autoFocus={true}
            faIcon={faKey}
            lexiconId={t('token')}
            onChange={handleUpdate}
            maxLength={6}
            styleLoginReg={true}
          />
          {isClientMfaBypassEnabled === "true" && (
            <InputField
              id={'isMfaBypassEnabled'}
              form={form}
              mfaBypassPeriod={mfaBypassPeriod}
              lexiconId={t('bypassMfa')}
              onChange={handleUpdate}
              type={'checkbox'}
              required={false}
            />
          )}
        </>
      )}

      {/* Sample account form */}
      {sample === true && (
        <>
          <GridItem>
            <InputField
              id={'username'}
              form={form}
              autoFocus={true}
              faIcon={faUser}
              lexiconId={t('login')}
              onChange={handleUpdate}
            />
          </GridItem>
          <GridItem>
            <InputField
              id={'password'}
              form={form}
              faIcon={faKey}
              lexiconId={t('password')}
              onChange={handleUpdate}
              onTogglePassword={toggleShowPassword}
              type={form.showPassword ? 'text' : 'password'}
            />
          </GridItem>
          {isMobile 
            ?
            <>
              <GridItem>
                <InputField
                  id={'firstName'}
                  form={form}
                  faIcon={faFileSignature}
                  lexiconId={t('firstName')}
                  onChange={handleUpdate}
                />
              </GridItem>
              <GridItem>
                <InputField
                  id={'lastName'}
                  form={form}
                  faIcon={faFileSignature}
                  lexiconId={t('lastName')}
                  onChange={handleUpdate}
                />
              </GridItem>
            </>
            : 
            <GridItem col2>  
              <InputField
                id={'firstName'}
                form={form}
                faIcon={faFileSignature}
                lexiconId={t('firstName')}
                onChange={handleUpdate}
              />

              <InputField
                id={'lastName'}
                form={form}
                lexiconId={t('lastName')}
                onChange={handleUpdate}
              />
            </GridItem>
          }
          <GridItem>
            <InputField
              id={'company'}
              form={form}
              faIcon={faBuilding}
              lexiconId={t('company')}
              onChange={handleUpdate}
            />
          </GridItem>
          <GridItem>
            <InputField
              id={'email'}
              form={form}
              faIcon={faEnvelope}
              lexiconId={t('email')}
              onChange={handleUpdate}
            />
          </GridItem>
          <GridItem col2>
            <InputField
              id={'phone'}
              form={form}
              faIcon={faPhone}
              lexiconId={t('phone')}
              onChange={handleUpdate}
              required={false}
            />
            <InputField
              id={'phoneExt'}
              form={form}
              lexiconId={t('phoneExt')}
              onChange={handleUpdate}
              required={false}
            />
          </GridItem>
          <InputField
            id={'acknowledge'}
            form={form}
            lexiconId={t('acknowledge')}
            onChange={handleUpdate}
            type={'checkbox'}
          />
          <ReCAPTCHAWrapper>
            <ReCAPTCHA
              sitekey={getCAPTCHAKey()}
              onChange={onReCAPTCHAChange}
              hl={lang}
            />
          </ReCAPTCHAWrapper>
        </>
      )}

      {/* Msrp account form */}
      {msrp === true && (
        <>
          <GridItem>
            <InputField
              id={'username'}
              form={form}
              autoFocus={true}
              faIcon={faUser}
              lexiconId={t('login')}
              onChange={handleUpdate}
            />
          </GridItem>
          <GridItem>
            <InputField
              id={'password'}
              form={form}
              faIcon={faKey}
              lexiconId={t('password')}
              onChange={handleUpdate}
              onTogglePassword={toggleShowPassword}
              type={form.showPassword ? 'text' : 'password'}
            />
          </GridItem>
          <ReCAPTCHAWrapper>
            <ReCAPTCHA
              sitekey={getCAPTCHAKey()}
              onChange={onReCAPTCHAChange}
              hl={lang}
            />
          </ReCAPTCHAWrapper>
        </>
      )}

      {form.errors && <ErrorMessage errorKey="errorMsg"/>}
      {mfaError && <ErrorMessage errorKey="errorMsgMFA"/>}
      {expiredPasswordError && <ErrorMessage errorKey="errorMsgExpiredPassword"/>}

      <Button
        children={translate(t('submitButton'))}
        borderRadius={2}
        color={colors.blue.normal}
        fill={true}
        size={16}
        style={{ marginTop: 50}}
      />
    </FormElem>
  );
};

export default withLocalize(Form);
