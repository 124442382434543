import React from 'react';
import styled from 'styled-components';
// import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import UISection from './../../interface/UISection';
import PageReinforcementStatic from './../../interface/PageReinforcementStatic';
import { spacing, colors } from '../../../../defaults/styles';

import { Translate, withLocalize } from 'react-localize-redux';

const styles = {
  icon: {
    marginRight: spacing.small,
  },
};

// const stylesMobile = {
//   root: {
//     marginBottom: spacing.large,
//   },
//   title: {},
//   ul: {
//     ...styles.ul,
//     backgroundColor: colors.gray.light,
//     padding: spacing.small,
//   },
//   liReset: {
//     // using this only for the ul li last-child on mobile
//     paddingBottom: 0,
//   },
//   link: {},
//   linkHover: {},
//   icon: {},
// };

const ContactInfo = ({ activeLanguage, isMobile, isTablet, translate }) => {
  // live chat is temporarily deactivated since there is no cs service for it.
  // const [liveChatLinkHover, setLiveChatLinkHover] = useState(false);

  const t = (tag) => `contactUs.contactInfo.${tag}`;

  return (
    <UISection noPadding>
      <Translate>
        {({ translate }) => (
          <PageReinforcementStatic
            title={translate(t('title'))}
            heading={'h2'}
            type={2}
            alignment={'center'}
            borderRequired
          />
        )}
      </Translate>
      <UL isMobile={isMobile} isTablet={isTablet}>
        <LI isMobile={isMobile} isTablet={isTablet}>
          <LinkHref href={'tel:+15146645304'} key="phone-link">
            <FontAwesomeIcon icon={faPhone} style={styles.icon} />
            514-664-5304
          </LinkHref>
        </LI>
        <LI isMobile={isMobile} isTablet={isTablet}>
          <LinkHref href={'tel:+18447299970'} key="phone-link">
            <FontAwesomeIcon icon={faPhone} style={styles.icon} />
            <Translate id={t('tollFree')} />
            1-844-729-9970
          </LinkHref>
        </LI>
        <LI isMobile={isMobile} isTablet={isTablet}>
          <LinkHref
            href={'mailto:info@loyaltysource.com'}
            key="email-link"
            isMobile={isMobile}
            isTablet={isTablet}
          >
            <FontAwesomeIcon icon={faEnvelope} style={styles.icon} />
            info@loyaltysource.com
          </LinkHref>
        </LI>
        {/* <LI stylesMobile.liReset : styles.li}> */}
        {/* POPUP LINK HERE FOR CALENDLY */}
        {/* <Link
            onMouseEnter={() => setLiveChatLinkHover(true)}
            onMouseLeave={() => setLiveChatLinkHover(false)}
            key="live-chat-link"
            style={
              liveChatLinkHover
                ? { ...styles.link, ...styles.linkHover }
                : styles.link
            }
            to={'/' + lang}
          >
            <FontAwesomeIcon icon={faComments} style={styles.icon} />
            <Translate id={t('liveChat')} />
          </Link> */}
        {/* </li> */}
      </UL>
    </UISection>
  );
};

export default withLocalize(ContactInfo);

const LinkHref = styled.a`
  color: ${colors.black.normal};
  display: block;
  text-decoration: none;
  transition: color 0.1s ease-in-out;
  &:hover {
    color: ${colors.primary.normal};
  }
`;

const UL = styled.ul`
  background-color: ${props =>
    props.isMobile || props.isTablet ? colors.gray.light : null};
  list-style: none;
  margin-top: ${spacing.large}px;
  ${'' /* padding: ${props => (props.isMobile ? spacing.small : null)}; */}
`;

const LI = styled.li`
  text-align: center;
  padding: ${props =>
    props.isMobile || props.isTablet ? spacing.small + 'px' : null};

  &:last-child:hover {
    color: ${colors.primary.normal};
  }
    padding-bottom: ${props =>
    props.isMobile || props.isTablet ? 0 : spacing.small + 'px'};

`;