import React from 'react';
import styled from 'styled-components';
import Button from '../../interface/Button';

const BoxHeight = 200
const ImageSize = 200;

export const Brand = ({ content }) => {
  return (
    <BrandBoxInner>
      <Button fill = {false} color = {'white'} size = {4}>
        <BrandImage src={content.secondaryImageUrl} alt={content.name + ' image'} />
      </Button>
    </BrandBoxInner>
  );
};

const BrandBoxInner = styled.div`
  height: ${BoxHeight}px;
  width: ${BoxHeight}px;
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const BrandImage = styled.img`
  display: block;
  height: auto;
  width: ${ImageSize}px;
  position: relative;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: auto;
`;
