import React, { useState } from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBoxFull,
  faGiftCard,
  faRunning,
} from '@fortawesome/pro-solid-svg-icons';
import { colors, fonts, spacing } from './../../../../defaults/styles';
import { Translate, withLocalize } from 'react-localize-redux';

const borderRadius = '3px';

const Switcher = ({ isMobile, isTablet, translate }) => {
  const t = (tag) => `services.rewards.switcher.${tag}`;

  const [selectedSwitch, setSelectedSwitch] = useState('products');
  const selectedImages = {
    // ===== IMG DATA ====== //
    products: [
      {
        imgSrc: 'Amazon.jpg',
        desc: 'products.amazon',
        id: 'amazon',
      },
      {
        imgSrc: 'Apple.jpg',
        desc: 'products.apple',
        id: 'apple',
      },
      { imgSrc: 'Breville.jpg', id: 'breville', desc: 'products.breville' },
      {
        imgSrc: 'NorthFace.jpg',
        desc: 'products.northFace',
        id: 'northFace',
      },
      {
        imgSrc: 'Taylormade.jpg',
        desc: 'products.taylormade',
        id: 'taylormade',
      },
    ],
    giftCards: [
      {
        imgSrc: 'canadian-tire.jpg',
        desc: 'giftCards.canadianTire',
        id: 'canadianTire',
      },
      {
        imgSrc: 'cineplex.jpg',
        desc: 'giftCards.cineplex',
        id: 'cineplex',
      },
      {
        imgSrc: 'esso.jpg',
        desc: 'giftCards.esso',
        id: 'esso',
      },
      {
        imgSrc: 'sephora.jpg',
        desc: 'giftCards.sephora',
        id: 'sephora',
      },
      {
        imgSrc: 'winners.jpg',
        desc: 'giftCards.winners',
        id: 'winners',
      },
    ],
    experiences: [
      {
        imgSrc: 'Hilton.jpg',
        desc: 'experiences.hilton',
        id: 'hilton',
      },
      {
        imgSrc: 'SeaDoo.jpg',
        desc: 'experiences.seadoo',
        id: 'seadoo',
      },
      {
        imgSrc: 'Starbucks.jpg',
        desc: 'experiences.starbucks',
        id: 'starbucks',
      },
      {
        imgSrc: 'theKeg.jpg',
        desc: 'experiences.keg',
        id: 'keg',
      },
      {
        imgSrc: 'WaySpa.jpg',
        desc: 'experiences.waySpa',
        id: 'waySpa',
      },
    ],
  };

  return (
    <>
      <SwitchGrid isMobile={isMobile} isTablet={isTablet}>
        <SwitchItem
          onClick={() => setSelectedSwitch('products')}
          isSelected={selectedSwitch === 'products'}
          isMobile={isMobile}
        >
          <FontAwesomeIcon
            icon={faBoxFull}
            style={{
              color:
                selectedSwitch === 'products'
                  ? colors.white.normal
                  : colors.primary.normal,
              marginRight: '0.5em',
            }}
          />
          <Translate id={t('ctaProducts')} />
        </SwitchItem>
        <SwitchItem
          onClick={() => setSelectedSwitch('giftCards')}
          isSelected={selectedSwitch === 'giftCards'}
          isMobile={isMobile}
        >
          <FontAwesomeIcon
            icon={faGiftCard}
            style={{
              color:
                selectedSwitch === 'giftCards'
                  ? colors.white.normal
                  : colors.primary.normal,
              marginRight: '0.5em',
            }}
          />
          <Translate id={t('ctaGiftCards')} />
        </SwitchItem>
        <SwitchItem
          onClick={() => setSelectedSwitch('experiences')}
          isSelected={selectedSwitch === 'experiences'}
          isMobile={isMobile}
        >
          <FontAwesomeIcon
            icon={faRunning}
            style={{
              color:
                selectedSwitch === 'experiences'
                  ? colors.white.normal
                  : colors.primary.normal,
              marginRight: '0.5em',
            }}
          />
          <Translate id={t('ctaExperiences')} />
        </SwitchItem>
      </SwitchGrid>

      <ImageFlex>
        {selectedImages[selectedSwitch].map((image, index) => (
          <ImageLink
            key={image.imgSrc + index}
            isMobile={isMobile}
            isTablet={isTablet}
          >
            <ImageItem
              src={
                '../images/pages/services-rewards/' +
                selectedSwitch +
                '/' +
                image.imgSrc
              }
              alt={selectedSwitch}
              key={image.id}
              isMobile={isMobile}
              isTablet={isTablet}
            />
            <ImageDesc>
                {translate(t(image.desc), null, {
                  renderInnerHtml: true,
                })}
            </ImageDesc>
          </ImageLink>
        ))}
      </ImageFlex>
    </>
  );
};

export default withLocalize(Switcher);

// == Styling == //
const SwitchGrid = styled.ul`
  display: grid;
  justify-content: space-between;
  grid-template-columns: ${props =>
    props.isMobile ? '1fr' : 'repeat(auto-fill, minmax(150px, 1fr))'};
  grid-gap: ${props => (props.isMobile ? spacing.small + 'px' : null)};
  list-style: none;
  /* adding border-radius in this as well since the child elems look no border radius.  */
  border-radius: ${borderRadius};
  margin-top: ${props => (props.isMobile || props.isTablet ? '3em' : null)};
  margin-bottom: ${props => (props.isMobile || props.isTablet ? '1.5' : null)};
  overflow: hidden;
`;
// grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
const SwitchItem = styled.li`
  border-top: 1px solid ${colors.gray.light};
  border-right: 1px solid ${colors.gray.light};
  border-bottom: 1px solid ${colors.gray.light};
  cursor: ${props => (!props.isMobile ? 'pointer' : null)};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1em;
  text-align: center;
  transition: all 200ms ease-in-out;

  &:first-child {
    border-top-left-radius: ${borderRadius};
    border-right: ${props =>
      !props.isMobile ? `1px solid ${colors.gray.normal}` : null};
    border-bottom-left-radius: ${borderRadius};
    border-left: 1px solid
      ${props =>
        props.isSelected ? colors.primary.normal : colors.gray.normal};
  }
  &:nth-child(2) {
    border-right: ${props =>
      !props.isMobile ? `1px solid ${colors.gray.normal}` : null};
  }
  &:last-child {
    border-top-right-radius: ${borderRadius};
    border-bottom-right-radius: ${borderRadius};
  }

  &:hover {
    background-color: ${props =>
      props.isSelected ? colors.primary.normal : colors.gray.light};
    cursor: ${props => (props.isSelected ? 'default' : 'cursor')};
  }

  /* for Mobile */
  border-radius: ${props => (props.isMobile ? borderRadius : null)};
  border: ${props =>
    props.isMobile ? `1px solid ${colors.gray.normal}` : null};

  /* when selected */
  background-color: ${props =>
    props.isSelected ? colors.primary.normal : colors.white.normal};
  border-color: ${props =>
    props.isSelected ? colors.primary.normal : colors.gray.normal};
  color: ${props =>
    props.isSelected ? colors.white.normal : colors.gray.dark};
`;

const ImageFlex = styled.div`
  display: flex;
  flex: 0 0 100%;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  margin-top: 2em;
  /* margin-bottom: 2em; */
`;
const ImageLink = styled.a`
  cursor: pointer;
  display: block;
  font-size: ${fonts.sizes.smallText}px;
  height: auto;
  line-height: 1.2;
  margin-bottom: ${props => (props.isMobile || props.isTablet ? '1em' : null)};
  width: ${props => (props.isMobile || props.isTablet ? '49%' : '180px')};
  opacity: 0.9;
  text-align: center;
  transition: opacity 200ms ease-in-out;

  &:hover {
    opacity: 1;
  }
`;
const ImageItem = styled.img`
  display: block;
  height: auto;
  margin: 0 auto 0.5em auto;
  width: ${props => (props.isMobile ? '100%' : '180px')};
`;
const ImageDesc = styled.span`
  display: inline-block;
  font-weight: 400;
  margin-top: 5px;

  strong {
    display: inline-block;
    margin-bottom: 5px;
  }
`;
// == /Styling == //
