import React from 'react';
import PageReinforcementStatic from './../../interface/PageReinforcementStatic';
import UIContainer from './../../interface/UIContainer';
import UISection from './../../interface/UISection';
import UIParagraph from '../../interface/UIParagraph';
import { colors } from '../../../../defaults/styles';
import { Translate, withLocalize } from 'react-localize-redux';
import { lsStyled } from '../../../../tools/helpers/lsStyled';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const RewardsMarketplace = ({ isMobile, isTablet, translate, activeLanguage }) => {
  const t = (tag) => `home.rewardsMarketplace.${tag}`;

  const lang =
    activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';

  return (
    <UIContainer isMobile={isMobile} isTablet={isTablet} pageTop>
      <UISection>
        <PageReinforcementStatic
          title={translate(t('title'))}
          subTitle={translate(t('subTitle'))}
          alignment={'center'}
          borderRequired={false}
          heading={'h1'}
        />
        <UIParagraph />
        <PageReinforcementStatic
          subTitle={translate(t('partner'))}
          borderRequired={false}
          heading={'h2'}
          type={'1'}
          alignment={'center'}
        />
        <UIParagraph textAlign={'center'}>
            <Translate id={t('mainParagraph')} />
        </UIParagraph>
        <ButtonGrid>
          <Button
              to={"/" + lang + "/services-rewards"}
              size={18}
            >
              <Translate id={t('btnRewards')} />
            </Button>
            <Button
              to={"/" + lang + "/services-gifting"}
              size={18}
            >
              <Translate id={t('btnGifting')} />
            </Button>
          </ButtonGrid>
      </UISection>
    </UIContainer>
  );
};

export default withLocalize(RewardsMarketplace);

const ButtonGrid = lsStyled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-gap: 1em;
  grid-auto-rows: 1fr;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    grid-template-columns: auto;
  }
`;

const Button = styled(Link)`
  text-decoration: none;
  border-style: solid;
  color: ${colors.white.normal};
  cursor: pointer;
  background-color: ${colors.primary.normal};
  border-radius: 2px;
  display: inline-block;
  line-height: 1;
  transition: all 200ms ease-in-out 0s;
  border-width: 1.33333px;
  border-color: ${colors.primary.normal};
  font-size: 16px;
  padding: 10.4px 20px;
  text-align: center;

  &:hover {
    background-color: ${colors.primary.hover};
    color: ${colors.white.hover};
  }

  @media (max-width: 600px) {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }
`;
