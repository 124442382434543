import React from 'react';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { fonts, spacing } from '../../../../defaults/styles';

import { Translate, withLocalize } from 'react-localize-redux';

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${spacing.veryLarge}px;
  position: relative;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;
const H1 = styled.div`
  font-size: ${fonts.sizes.subHeading}px;
  font-weight: ${fonts.weights.heading};
  text-align: center;
`;
const Image = styled.img`
  height: auto;
  margin-bottom: ${spacing.medium}px;
  width: 250px;
  cursor: pointer;
`;

const LoginHeader = ({ Login, history, activeLanguage }) => {
  const t = (tag) => `login.header.${tag}`;

  const goToHome = () => history.push('/' + lang);

  const lang =
    activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';

  return (
    <Header>
      <LogoContainer>
        <H1>
          <Translate id={t('login')} />
        </H1>
        <Image src={`/images/LSLogo${lang.toUpperCase()}.png`} alt="Loyalty Source Logo" onClick={goToHome} />
      </LogoContainer>
    </Header>
  );
};

export default withLocalize(LoginHeader);
