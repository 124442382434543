import React, { useEffect, useRef, useState } from 'react';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

const Popup = ({ children, callback, showPopup, setShowPopup, isMobile }) => {
    const clickRef = useRef();
    const handleClick = (e) => {
        if (clickRef.current && !clickRef.current.contains(e.target)) {
            // outsideClick
            setShowPopup && setShowPopup(false);
            return;
        }
        // inside click
        return; 
    };
    
    useEffect(() => {
        document.addEventListener("mousedown", handleClick);
        return () => {
            document.removeEventListener("mousedown", handleClick);
        };
    }, []);

  return (
    <PopupWrapper showPopup={showPopup} setShowPopup={setShowPopup}>
        {showPopup && (
            <style>{`
                html, body {
                    overflow: hidden;
                    -webkit-overflow-scrolling: touch;
                }
            `}</style>
        )}
        <BGMask />
        <ContentContainer>
            <Content ref={clickRef} isMobile={isMobile}>
                <CloseButton onClick={()=>{setShowPopup(false)}}>
                    <FontAwesomeIcon icon={faTimes} size="1x" />
                </CloseButton>
                {children}
            </Content>
        </ContentContainer>
    </PopupWrapper>
  );
};

const PopupWrapper = styled.div`
    display: ${props => props.showPopup ? '' : 'none' };
    transition: opacity 500ms;
`;
const BGMask = styled.div`
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100vw;
    
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
`;

const ContentContainer = styled.div`
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    height: 100vh;
    width: 100vw;

    position: fixed;
    top: 1.5rem;
    left: 0;
    z-index: 1000;
`;

const Content = styled.div`
    background-color: #fff;
    border-radius: 3px;
    height: auto;
    width: ${props => props.isMobile ? '100%' : '500px'};
    padding: 1.5rem;
    position: relative;
`;


const CloseButton = styled.div`
    font-size: 1.4rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0.5rem;
    cursor: pointer;
    z-index: 1001;
`;


export default Popup;
