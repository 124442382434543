import React from 'react';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { spacing } from '../../../../defaults/styles';

const ListItem = styled.li`
    list-style-position: inside;
    margin-top: ${spacing.small}px;
    margin-bottom: ${spacing.small}px;
`;

const Link = styled.a`
    color: inherit;
`;

const LegalSectionTableOfContent = ({ onClick, text }) => {
    return (
        <ListItem>
            <Link href="#" onClick={onClick}>{text}</Link>
        </ListItem>
    )
}

export default LegalSectionTableOfContent;