import * as types from '../actions/types';
import { features } from './initialState';

export default function featureReducer(state = features, actions) {
  switch (actions.type) {
    case types.LOAD_PRODUCT_FEATURES_SUCCESS:
      return actions.features;
    default:
      return state;
  }
}