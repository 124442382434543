import React from 'react';
import Radium from 'radium';
import { colors, sizing, spacing, fonts } from './../../../defaults/styles';
import { vendorPrefix } from '../../../tools/helpers/styling';
import PulseLoader from 'react-spinners/PulseLoader';
import { withLocalize, Translate } from 'react-localize-redux';
import { setLexicon } from '../../../tools/helpers/lexiconFormatter';

const { t, lexicon } = setLexicon('interface.page-title', {
  catalog: ['Catalog', 'Catalogue'],
});
const PageTitle = ({
  text,
  categories,
  selectedBrand,
  selectedCategory,
  selectedFeature,
  ...props
}) => {
  props.addTranslation(lexicon);
  // priority of titles
  // if TopCategory -> TopCategory
  // if only brand & no category -> Brand
  // if text prop -> text prop
  // if no brand & no category & no text prop -> Default text/img Catalog
  const getTitle = () => {
    if (categories && categories.length && selectedCategory.topId) {
      const category = categories.find(
        category => category.id === selectedCategory.topId
      );
      return {
        title: category.name,
        imgSrc: category.imageUrl,
      };
    }

    if (selectedBrand && selectedBrand.name) {
      return {
        title: selectedBrand.name,
        imgSrc: '',
      };
    }

    return {
      title: text ? text : <Translate id={t('catalog')} />,
      imgSrc: '',
    };
  };

  const { title, imgSrc } = getTitle();
  return (
    <header
      style={{
        color: 'white',
        position: 'relative',
        height: 162,
        marginBottom: spacing.veryLarge,
      }}
    >
      <div
        style={{
          backgroundColor: colors.gray.light,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          ...vendorPrefix(
            'clipPath',
            `polygon(
            100% 0,
            0 0,
            0% 100%,
            68% 57%,
            100% 91%
          )`
          ),
        }}
      />
      <div
        style={{
          backgroundColor: colors.main.normal,
          ...vendorPrefix(
            'clipPath',
            `polygon(
            100% 0,
            0 0,
            0% 58%,
            60% 90%,
            100% 58%
          )`
          ),
        }}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            height: 144,
            ...sizing.boundToContentWidth,
          }}
        >
          <h1
            style={{
              flex: 2,
              fontSize: fonts.sizes.subtitle,
              marginBottom: 54,
              fontFamily: fonts.title,
              fontWeight: fonts.weights.normal,
              letterSpacing: 1,
            }}
          >
            {title ? title : <PulseLoader color="white" size={8} />}
          </h1>
          {imgSrc && (
            <div
              style={{
                flex: 5,
                position: 'relative',
                backgroundColor: '#ddd',
                display: 'block',
                width: '50%',
                height: '100%',
                backgroundImage: `url(${imgSrc})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                ...vendorPrefix(
                  'clipPath',
                  'polygon(5% 0%, 0% 100%, 100% 100%, 100% 0%)'
                ),
              }}
            />
          )}
        </div>
      </div>
    </header>
  );
};

export default withLocalize(Radium(PageTitle));
