import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { Translate, withLocalize } from 'react-localize-redux';
import { colors, fonts, spacing } from '../../../../../defaults/styles';

// == Styling == //
const InputContainer = styled.div`
  border: 1px solid;
  position: relative;
  padding: ${props => props.isMobile ? `${spacing.small}px ${spacing.medium}px ${spacing.small}px ${spacing.small}px` : `${spacing.small}px ${spacing.medium}px` };
  overflow: hidden;
  width: ${props => props.isMobile ? '100%' : '15em'};
  z-index: 10;

  border-color: ${props => props.typing ? colors.main.normal : colors.gray.light };
  ${props => props.typing ? `box-shadow: 0 0 0 1px ${colors.main.normal}` : '' };
`;

const Input = styled.input`
  background-color: transparent;
  font-size: ${fonts.sizes.medium}px;
  line-height: 1;
  padding: 0 1em 0 0;
  width: 100%;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${colors.gray.normal};
  }
  :-ms-input-placeholder {
    color: ${colors.gray.normal};
  }
  ::placeholder {
    color: ${colors.gray.normal};
  }

  ::-webkit-search-decoration,
  ::-webkit-search-cancel-button,
  ::-webkit-search-results-button,
  ::-webkit-search-results-decoration {
    -webkit-appearance: none;
  }
`;

// == .Styling == //

const Search = ({ history, activeLanguage, isMobile, translate }) => {
  const t = (tag) => `header.${tag}`;

  const [typedValue, setTypedValue] = useState('');
  const [typing, setTyping] = useState(false);
  const [animationDone, setAnimationDone] = useState(false);

  useEffect(() => {
    setTimeout(() => setAnimationDone(typing), 0);
  }, [typing]);

  const lang =
    activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';

  const handleSearch = searchValue => {
    setTypedValue('');
    history.push({
      pathname: `/${lang}/catalog`,
      search: `?q=${searchValue}`
    });
  }

  return (
    <InputContainer isMobile={isMobile} typing={typing}>
      <FontAwesomeIcon
        icon={faSearch}
        style={{
          cursor: 'pointer',
          opacity: 0.25,
          position: 'absolute',
          right: '15px',
          top: '50%',
          transform: 'translateY(-50%)',
          zIndex: 10,
        }}
        onClick={() => handleSearch(typedValue)}
      />
      <Input
        type="search"
        autoFocus
        placeholder={translate(t('search'))}
        onFocus={() => { if (!typing) { setTyping(true); } }}
        onBlur={() => { if (typing) setTyping(false); }}
        onKeyDown={({ key }) => {
          if (key === 'Enter') {
            handleSearch(typedValue)
          }
        }}
        onChange={({ target : { value } }) => {
          setTypedValue(value);
        }}
        value={typedValue}
        isMobile={isMobile}
      />
    </InputContainer>
  );
};

export default withRouter(withLocalize(Search));
