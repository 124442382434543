import React, { useContext } from 'react';
import { withLocalize } from 'react-localize-redux';

import { UserInfoContainer } from '../../../smart/Users';
import UIMain from '../../interface/UIMain';
import UISection from '../../interface/UISection';
import UIContainer from '../../interface/UIContainer';
import UserInformationSection from './UserInformationSection';
import DownloadFileSection from './DownloadFileSection';
import { ResizeAwareContext } from '../../../smart/ResizeContext';

const UserInfoPage = ({ translate }) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);
  document.title = translate('userInfo.title');

  return (
    <UIMain isMobile={isMobile} isTablet={isTablet}>
      <UIContainer isMobile={isMobile} isTablet={isTablet}>
        <UISection noPadding isMobile={isMobile} isTablet={isTablet}>
          <UserInfoContainer>
            <UserInformationSection isMobile={isMobile} />
            <DownloadFileSection />
          </UserInfoContainer>
        </UISection>
      </UIContainer>
    </UIMain>
  );
};

export default withLocalize(UserInfoPage);
