import React from 'react';
import HubspotForm from 'react-hubspot-form';
import GridLoader from 'react-spinners/GridLoader';
import { fonts, colors } from '../../../../defaults/styles';

const HubspotContactForm = (param) => {
  return (
    param.language && param.language === 'fr' ?
    <HubspotForm
        region= "na1"
        portalId= "23257042"
        formId= "a8295d9c-79ee-44b5-be97-d23c28fe2c14"
        loading={
          <GridLoader
            css={{ margin: 'auto' }}
            size={fonts.sizes.text}
            color={colors.main.normal}
          />
        }
        redirectUrl={`/${param.language}/thank-you`}
    />
    :
    <HubspotForm
        region= "na1"
        portalId= "23257042"
        formId= "ea2d5856-2660-4674-a09f-7158a2bc8e11"
        loading={
          <GridLoader
            css={{ margin: 'auto' }}
            size={fonts.sizes.text}
            color={colors.main.normal}
          />
        }
        redirectUrl={`/${param.language}/thank-you`}
    />
  );
};

export default HubspotContactForm;