import { colors } from '../../defaults/styles';

export const vendorPrefix = (key, value) => {
  const formattedKey = key.charAt(0).toUpperCase() + key.slice(1);
  const vendorPrefixes = ['Moz', 'Ms', 'Webkit'];
  const styleWithVendorPrefixes = Object.assign(
    ...vendorPrefixes.map(prefix => ({
      [prefix + formattedKey]: value,
    }))
  );
  return { ...styleWithVendorPrefixes, [key]: value };
};

export const selectThemeOverride = theme => {
  return {
    ...theme,
    borderRadius: 0,
    colors: {
      ...theme.colors,
      primary25: colors.main.light,
      primary: colors.main.normal,
    },
  };
};
