import * as types from '../actions/types';
import { catalogRequestTime } from './initialState';

export default function catalogRequestTimeReducer(
  state = catalogRequestTime,
  actions
) {
  switch (actions.type) {
    case types.SET_CATALOG_REQUEST_TIME:
      return actions.catalogRequestTime;
    default:
      return state;
  }
}
