import * as types from '../actions/types'
import { products } from './initialState'

export default function catalogReducer(state = products, actions) {
  switch (actions.type) {
    case types.LOAD_PRODUCTS_SUCCESS:
      return actions.products
    default:
      return state
  }
}
