import React, { useRef } from 'react';
import Radium from 'radium';
import { spacing, sizing } from './../../../../defaults/styles/';
import { withLocalize } from 'react-localize-redux';
import UIContainer from '../../interface/UIContainer';
import UISection from '../../interface/UISection';
import LegalSectionContent from '../../interface/PageSection/LegalSectionContent';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import LegalSectionTableOfContent from '../../interface/PageSection/LegalSectionTableOfContent';

import content from '../../../../main/global-lexicon';

const TOCList = styled.ol`
  margin-top: ${spacing.veryLarge * 2}px;
  margin-bottom: ${spacing.veryLarge * 2}px;
`;

const TermsAndConditions = ({ isMobile, isTablet, translate }) => {
  const t = (tag) => `termsAndCondition.${tag}`;
  const languageData = content.termsAndCondition;

  const scrollToSection = (ref, e) => {
    e.preventDefault();
    window.scrollTo({ top: ref.current.offsetTop - sizing.header.top - sizing.header.mid - sizing.header.btm - 20, behavior: 'smooth' });
  }

  // Cannot create ref with array, need to be done manually...
  const section1 = useRef(null);
  const section2 = useRef(null);
  const section3 = useRef(null);
  const section4 = useRef(null);
  const section5 = useRef(null);
  const section6 = useRef(null);
  const section7 = useRef(null);
  const section8 = useRef(null);
  const section9 = useRef(null);
  const section10 = useRef(null);
  const section11 = useRef(null);
  const section12 = useRef(null);
  const section13 = useRef(null);
  const section14 = useRef(null);

  return (
    <UIContainer noPadding isMobile={isMobile} isTablet={isTablet}>
      <UISection noPadding isMobile={isMobile} isTablet={isTablet}>
        <TOCList>
          <LegalSectionTableOfContent 
            text={translate(t('subtitle1'))} 
            onClick={(event) => scrollToSection(section1, event)}
          />
          <LegalSectionTableOfContent 
            text={translate(t('subtitle2'))} 
            onClick={(event) => scrollToSection(section2, event)}
          />
          <LegalSectionTableOfContent 
            text={translate(t('subtitle3'))} 
            onClick={(event) => scrollToSection(section3, event)}
          />
          <LegalSectionTableOfContent 
            text={translate(t('subtitle4'))} 
            onClick={(event) => scrollToSection(section4, event)}
          />
          <LegalSectionTableOfContent 
            text={translate(t('subtitle5'))} 
            onClick={(event) => scrollToSection(section5, event)}
          />
          <LegalSectionTableOfContent 
            text={translate(t('subtitle6'))} 
            onClick={(event) => scrollToSection(section6, event)}
          />
          <LegalSectionTableOfContent 
            text={translate(t('subtitle7'))} 
            onClick={(event) => scrollToSection(section7, event)}
          />
          <LegalSectionTableOfContent 
            text={translate(t('subtitle8'))} 
            onClick={(event) => scrollToSection(section8, event)}
          />
          <LegalSectionTableOfContent 
            text={translate(t('subtitle9'))} 
            onClick={(event) => scrollToSection(section9, event)}
          />
          <LegalSectionTableOfContent 
            text={translate(t('subtitle10'))} 
            onClick={(event) => scrollToSection(section10, event)}
          />
          <LegalSectionTableOfContent 
            text={translate(t('subtitle11'))} 
            onClick={(event) => scrollToSection(section11, event)}
          />
          <LegalSectionTableOfContent 
            text={translate(t('subtitle12'))} 
            onClick={(event) => scrollToSection(section12, event)}
          />
          <LegalSectionTableOfContent 
            text={translate(t('subtitle13'))} 
            onClick={(event) => scrollToSection(section13, event)}
          />
          <LegalSectionTableOfContent 
            text={translate(t('subtitle14'))} 
            onClick={(event) => scrollToSection(section14, event)}
          />
        </TOCList>

        <section ref={section1}><LegalSectionContent section={1} t={t} languageData={languageData} /></section>
        <section ref={section2}><LegalSectionContent section={2} t={t} languageData={languageData} /></section>
        <section ref={section3}><LegalSectionContent section={3} t={t} languageData={languageData} /></section>
        <section ref={section4}><LegalSectionContent section={4} t={t} languageData={languageData} /></section>
        <section ref={section5}><LegalSectionContent section={5} t={t} languageData={languageData} /></section>
        <section ref={section6}><LegalSectionContent section={6} t={t} languageData={languageData} /></section>
        <section ref={section7}><LegalSectionContent section={7} t={t} languageData={languageData} /></section>
        <section ref={section8}><LegalSectionContent section={8} t={t} languageData={languageData} /></section>
        <section ref={section9}><LegalSectionContent section={9} t={t} languageData={languageData} /></section>
        <section ref={section10}><LegalSectionContent section={10} t={t} languageData={languageData} /></section>
        <section ref={section11}><LegalSectionContent section={11} t={t} languageData={languageData} /></section>
        <section ref={section12}><LegalSectionContent section={12} t={t} languageData={languageData} /></section>
        <section ref={section13}><LegalSectionContent section={13} t={t} languageData={languageData} /></section>
        <section ref={section14}><LegalSectionContent section={14} t={t} languageData={languageData} /></section>
      </UISection>
    </UIContainer>
  );
};

export default Radium(withLocalize(TermsAndConditions));
