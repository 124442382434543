import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { lsStyled as styled } from './../../../../../../../../tools/helpers/lsStyled';
import { colors, fonts } from './../../../../../../../../defaults/styles';

// == Styling == //

const ButtonDiv = styled.div`
  border: 1px solid ${colors.gray.light};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${fonts.sizes.medium}px;
  padding: 10px;

  &:hover {
    color: ${colors.primary.normal};
  }
`;
// == /Styling == //
const UserProfileButton = ({ username, ...props }) => {
  // const [hovering, setHovering] = useState(false);
  return (
    <ButtonDiv
    // onMouseEnter={() => setHovering(true)}
    // onMouseLeave={() => setHovering(false)}
    >
      <FontAwesomeIcon
        icon={faUser}
        style={{ fontSize: 20, paddingRight: 5, color: 'inherit' }}
      />
      {username}
    </ButtonDiv>
  );
};

export default UserProfileButton;
