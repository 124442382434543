import styled from 'styled-components';

const notAvailableImageUrl = `/images/Not_available.jpg`;
const pasDisponibleImageUrl = `/images/pas_disponible.jpg`;

// Wrapper for styled-components
export const lsStyled = function lsStyled(tag) {
    switch (tag) {
        case 'img':
            return _handleImage();
        default:
            return styled[tag];
    }

    // Handle image on creation
    function _handleImage() {
        return styled.img.attrs(props => ({ 
            src: props.src && props.src.includes('/null') ? props.lang === 'fr' ? pasDisponibleImageUrl : notAvailableImageUrl : props.src,
            onError: (event) => { event.target.src = props.lang === 'fr' ? pasDisponibleImageUrl : notAvailableImageUrl }
        }));
    }
}
  
  
  // For creating function.
  // These elements are derived from styled-components library
  var domElements = ['a', 'abbr', 'address', 'area', 'article', 'aside', 'audio', 'b', 'base', 'bdi', 'bdo', 'big', 'blockquote', 'body', 'br', 'button', 'canvas', 'caption', 'cite', 'code', 'col', 'colgroup', 'data', 'datalist', 'dd', 'del', 'details', 'dfn', 'dialog', 'div', 'dl', 'dt', 'em', 'embed', 'fieldset', 'figcaption', 'figure', 'footer', 'form', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'head', 'header', 'hgroup', 'hr', 'html', 'i', 'iframe', 'img', 'input', 'ins', 'kbd', 'keygen', 'label', 'legend', 'li', 'link', 'main', 'map', 'mark', 'marquee', 'menu', 'menuitem', 'meta', 'meter', 'nav', 'noscript', 'object', 'ol', 'optgroup', 'option', 'output', 'p', 'param', 'picture', 'pre', 'progress', 'q', 'rp', 'rt', 'ruby', 's', 'samp', 'script', 'section', 'select', 'small', 'source', 'span', 'strong', 'style', 'sub', 'summary', 'sup', 'table', 'tbody', 'td', 'textarea', 'tfoot', 'th', 'thead', 'time', 'title', 'tr', 'track', 'u', 'ul', 'var', 'video', 'wbr',
  
  // SVG
  'circle', 'clipPath', 'defs', 'ellipse', 'foreignObject', 'g', 'image', 'line', 'linearGradient', 'marker', 'mask', 'path', 'pattern', 'polygon', 'polyline', 'radialGradient', 'rect', 'stop', 'svg', 'text', 'tspan'];
  
  // 
  
  // Shorthands for all valid HTML Elements
  domElements.forEach(function (domElement) {
    lsStyled[domElement] = lsStyled(domElement);
  });