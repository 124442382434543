import * as types from '../actions/types'
import { catalog } from './initialState'

export default function catalogReducer2(state = catalog, actions) {
    switch (actions.type) {
        case types.LOAD_CATALOG_SUCCESS:
            const cloneCatalog = JSON.parse(JSON.stringify(state));
            cloneCatalog[actions.result.page] = actions.result.products;
            return cloneCatalog;
        case types.RESET_CATALOG:
            return actions.result;
        default:
            return state;
    }
}