import React from 'react';
import { lsStyled as styled } from './../../../tools/helpers/lsStyled';
import { spacing } from './../../../defaults/styles';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: ${props => props.isMobile ? '1fr' : 'repeat(4, 1fr)'};
  grid-gap: ${spacing.spacingStandard};
  align-items: ${props => (props.alignItems ? props.alignItems : 'center')};

  /* adds custom styles if necessary*/
  ${props => (props.customStyle ? props.customStyle : null)}
`;

const UIGrid4cols = ({
  children,
  alignItems = 'center',
  customStyle = null,
  isMobile,
  isTablet,
}) => {
  return (
    <Wrapper alignItems={alignItems} customStyle={customStyle} isMobile={isMobile} isTablet={isTablet}>
      {children}
    </Wrapper>
  );
};

export default UIGrid4cols;
