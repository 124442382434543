import React from 'react';
import PageSection from '../../interface/PageSection';
import { Translate, withLocalize } from 'react-localize-redux';

const CustomizableLabelling = () => {
  const t = (tag) => `services.legacy.customizable.${tag}`;
  return (
    <PageSection
      title={<Translate id={t('title')} />}
      paragraphs={[<Translate id={t('paragraph1')} />]}
      contentPosition="left"
      imgSrc="/images/pages/services/gift-wrapping.png"
      imgAlt="gift wrapping"
    />
  );
};

export default withLocalize(CustomizableLabelling);
