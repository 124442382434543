import React, { useContext } from 'react';
import { LoggedInHomeContainer } from '../../../smart/LoggedInHome';
import LoggedInHomePagePresentation from './LoggedInHomePagePresentation';
import { ResizeAwareContext } from '../../../smart/ResizeContext';

const LoggedInHomePage = ({ history }) => {
  const { isMobile, isTablet } = useContext(ResizeAwareContext);
  document.title = 'Loyalty Source';

  return (
    <>
      <LoggedInHomeContainer>
        <LoggedInHomePagePresentation
          history={history}
          isMobile={isMobile}
          isTablet={isTablet}
        />
      </LoggedInHomeContainer>
    </>
  );
};

export default LoggedInHomePage;
