import React from 'react';

import PageReinforcementStatic from './../../interface/PageReinforcementStatic';
import UIContainer from './../../interface/UIContainer';
import UISection from './../../interface/UISection';
import UIParagraph from './../../interface/UIParagraph';
import { Translate, withLocalize } from 'react-localize-redux';

const OurHistory = ({ isMobile, isTablet, translate }) => {
  const t = (tag) => `aboutUs.ourHistory.${tag}`;

  return (
    <UIContainer noPadding isMobile={isMobile} isTablet={isTablet} customStyle={{ marginBottom: '2em' }}>
      <UISection>
        <PageReinforcementStatic
          title={translate(t('title'))}
          alignment={'center'}
          borderRequired={true}
          borderWidth={'short'}
          heading={'h2'}
          type={'2'}
        />
        <UIParagraph textAlign={'center'}>
          <Translate id={t('mainParagraph')} />
        </UIParagraph>
      </UISection>
    </UIContainer>
  );
};

export default withLocalize(OurHistory);
