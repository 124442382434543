// const contentWidth = 1280;
const contentWidth = 1024;
const margin = 12;
const maxWidth = contentWidth + margin * 2;
const mediaQuery = '@media screen and (max-width: ' + maxWidth + 'px)';

export default {
  contentWidth,
  margin,
  paddingEq: 80,
  categoriesAsideWidth: 280,
  cartWidth: 560,
  header: {
    top: 48,
    mid: 93,
    btm: 48,
    container: 72,
    mobileTop: 48,
    mobileBottom: 72,
  },
  tabletWidthThreshold: 1200,
  mobileWidthThreshold: 768,
  boundToContentWidth: {
    maxWidth: contentWidth,
    marginLeft: 'auto',
    marginRight: 'auto',
    [mediaQuery]: {
      marginLeft: 12,
      marginRight: 12,
    },
  },
};
