import React, { useEffect, useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import { spacing, colors } from '../../../../../../defaults/styles';
import { selectThemeOverride } from '../../../../../../tools/helpers/styling';
import Select from 'react-select';
import { lsStyled as styled } from '../../../../../../tools/helpers/lsStyled';
import qs from 'querystring';

const FeatureValueDiv = styled.div`
    border-top: 1px solid ${colors.gray.light};
    padding-top: ${spacing.medium}px;
    ${props => props.isMobile ? 'padding-right: ' + spacing.verySmall + 'px' : ''};
    padding-left: ${spacing.verySmall}px;
    ${props => props.isMobile ? 'margin-bottom:' + spacing.large + 'px' : ''};
`;

const FeatureValueTitleWrapper = styled.div`
    margin-bottom: ${spacing.verySmall}px;
`;

const FeatureValues = ({
    isMobile,
    history,
    translate,
    featureValues,
    selectedOptions,
    selectedOptionsRef,
    setSelectedOptions,
    selfIndex,
    activeLanguage,
    featureNameEn,
    featureNameFr,
    currentQuery,
}) => {
    const t = (tag) => `corporateGifts.feature.${tag}`;
    const [currentSelectedOptions, setCurrentSelectedOptions] = useState([]);
    const separatedQuery = currentQuery ? JSON.parse(JSON.stringify(currentQuery)) : {};
    const productFeatureValueId = separatedQuery.productFeatureValueId;

    var optionsFr = [];
    var optionsEn = [];

    useEffect(() => {

        if (separatedQuery && featureValues) {
            const selected = featureValues.filter((fv) => {
                return productFeatureValueId && productFeatureValueId.includes(fv.id.toString())
            });

            if (selected.length > 0) {
                //Check if the current featureValue is selected
                let newSelectedOptions = selectedOptionsRef.current;

                const index = selectedOptionsRef.current.findIndex(i => i.index === selfIndex);

                if (index !== -1) {
                    newSelectedOptions.splice(index, 1, { ...selected[0], index: selfIndex });
                } else {
                    newSelectedOptions.push({ ...selected[0], index: selfIndex });
                }
                selectedOptionsRef.current = newSelectedOptions;
                setCurrentSelectedOptions({
                    value: selected[0],
                    label: activeLanguage.code === 'fr' ? selected[0].nameFr : selected[0].nameEn
                });
            }
        }
    }, []);

    // Sort by alphabetical order
    if (featureValues) {
        const tempList = [];
        const tempListFr = [];

        featureValues.forEach(fv => tempList.push(fv));
        tempList.sort((a, b) => (a.nameEn > b.nameEn) ? 1 : ((b.nameEn > a.nameEn) ? -1 : 0));

        featureValues.forEach(fv => tempListFr.push(fv));
        tempListFr.sort((a, b) => (a.nameFr > b.nameFr) ? 1 : ((b.nameFr > a.nameFr) ? -1 : 0));

        optionsFr = tempListFr.map(featureValue => ({
            value: featureValue.id,
            label: featureValue.nameFr
        }));

        optionsEn = tempList.map(featureValue => ({
            value: featureValue.id,
            label: featureValue.nameEn
        }));
    }

    // Applying filter, supposed to use `productFeatureValueId` as the array's name
    const onChange = (featureValue) => {
        setCurrentSelectedOptions(featureValue);

        var currentProductFeatureValueIdList = [];
        if (productFeatureValueId) {
            currentProductFeatureValueIdList = Array.isArray(productFeatureValueId) ? productFeatureValueId : [productFeatureValueId]
        }

        // Check if current options is selected:
        if (selectedOptionsRef.current.filter(o => o.index === selfIndex).length > 0) {
            if (!featureValue) {
                currentProductFeatureValueIdList = currentProductFeatureValueIdList
                    .filter((id) => {
                        return id !== selectedOptionsRef.current.filter(o => o.index === selfIndex)[0].id.toString();
                    })
            } else {
                currentProductFeatureValueIdList = currentProductFeatureValueIdList
                    .map((id) => {
                        if (id === selectedOptionsRef.current.filter(o => o.index === selfIndex)[0].id.toString()) {
                            return featureValue.value;
                        } else {
                            return id;
                        }
                    })
            }

            if (separatedQuery) {
                history.push({
                    pathname: history.location.pathname,
                    search: '?' + qs.stringify({ ...separatedQuery, productFeatureValueId: currentProductFeatureValueIdList })
                });
            } else {
                history.push({
                    pathname: history.location.pathname,
                    search: '?' + qs.stringify({ productFeatureValueId: currentProductFeatureValueIdList })
                });
            }
        } else {
            currentProductFeatureValueIdList.push(featureValue.value);

            if (separatedQuery) {
                history.push({
                    pathname: history.location.pathname,
                    search: '?' + qs.stringify({ ...separatedQuery, productFeatureValueId: currentProductFeatureValueIdList })
                });
            } else {
                history.push({
                    pathname: history.location.pathname,
                    search: '?' + qs.stringify({ productFeatureValueId: currentProductFeatureValueIdList })
                });
            }
        }
    }

    return (
        <FeatureValueDiv isMobile={isMobile}>
            <FeatureValueTitleWrapper>
                <span>
                    {activeLanguage.code === 'fr' ? featureNameFr : featureNameEn}
                </span>
            </FeatureValueTitleWrapper>
            <div>
                <Select
                    value={
                        selectedOptions[selfIndex] ||
                        currentSelectedOptions ||
                        null
                    }
                    isClearable={true}
                    options={
                        activeLanguage.code === 'fr' ?
                            optionsFr
                            :
                            optionsEn
                    }
                    placeholder={translate(t('selectPlaceholder'))}
                    theme={theme => selectThemeOverride(theme)}
                    noOptionsMessage={() => translate(t('loading'))}
                    onChange={onChange}
                />
            </div>
        </FeatureValueDiv>
    );
};

export default withRouter(withLocalize(FeatureValues));