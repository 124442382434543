import React from 'react';
import { spacing } from './../../../defaults/styles';
import { lsStyled as styled } from './../../../tools/helpers/lsStyled';

const Aside = styled.aside`
  padding-top: ${props => (!props.noPadding ? spacing.paddingStandard : null)};
  padding-bottom: ${props =>
    !props.noPadding ? spacing.paddingStandard : null};
  ${props => (props.customStyle ? props.customStyle : null)};
`;

const UIAside = ({ children, noPadding, customStyle = null }) => {
  return (
    <Aside noPadding={noPadding} customStyle={customStyle}>
      {children}
    </Aside>
  );
};

export default UIAside;
