import React from 'react'
import { fonts, colors, spacing } from '../../../../../../defaults/styles'

const Name = ({ name }) => (
  <header style={{ marginBottom: spacing.small }}>
    <h2
      style={{
        fontFamily: fonts.title,
        fontWeight: fonts.weights.heading,
        fontSize: 25,
        color: colors.gray.dark,
      }}
    >
      {name}
    </h2>
  </header>
)

export default Name
