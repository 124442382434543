import React from 'react';
import Button from '../../../interface/Button';
import { Translate, withLocalize } from 'react-localize-redux';

const FooterButton = () => {
  const t = (tag) => `labels.${tag}`;

  return (
    <Button url={'/contact-us'} fill={false} color={'white'} size={14}>
      <Translate id={t('contact')} />
    </Button>
  );
};

export default withLocalize(FooterButton);
