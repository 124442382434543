import * as types from '../actions/types';
import { selectedCategory } from './initialState';

export default function categoryReducer(state = selectedCategory, actions) {
  switch (actions.type) {
    case types.SET_SELECTED_CATEGORY:
      return actions.selectedCategory;
    case types.RESET_SELECTED_CATEGORY:
      return selectedCategory;
    default:
      return state;
  }
}
