import React from 'react';
import styled from 'styled-components';

import { Translate, withLocalize } from 'react-localize-redux';
import { colors, spacing, fonts } from '../../../../defaults/styles';

const Paragraph = styled.p`
  background-color: ${colors.gray.light};
  font-size: ${fonts.small};
  padding: ${spacing.small}px;
  width: 100%;
`;

const SuccessMessage = () => {
  const t = (tag) => `forgetPassword.success.${tag}`;

  return (
    <Paragraph>
      <Translate id={t('message')} />
    </Paragraph>
  );
};

export default withLocalize(SuccessMessage);
