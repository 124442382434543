import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { lsStyled as styled } from './../../../../../tools/helpers/lsStyled';
import { colors, fonts, spacing } from '../../../../../defaults/styles';
import { Translate, withLocalize } from 'react-localize-redux';

const SocialMediaLinks = ({isMobile, isTablet}) => {
  const t = (tag) => `footer.social.${tag}`;

  return (
    <Root isMobile={isMobile} isTablet={isTablet}>
      {<Translate id={t('title')} />}
      <ALink
        href="https://www.linkedin.com/company/loyalty-source/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon icon={faLinkedin} />
      </ALink>
    </Root>
  );
};

export default withLocalize(SocialMediaLinks);

const Root = styled.div `
  font-size: ${fonts.sizes.footerItems}px;
  text-align: ${props => props.isMobile || props.isTablet ? 'center' : 'right'};
`;

const ALink = styled.a `
  color: ${colors.gray.normal};
  padding-left: ${spacing.verySmall}px;
  transition: color ease-in-out 0.1s;
  &:hover {
    color: ${colors.black.normal};
  }
`;