import * as types from '../actions/types';
import { vendorAgreement } from './initialState';

export default function vendorAgreementReducer(state = vendorAgreement, actions) {
  switch (actions.type) {
    case types.VENDOR_AGREEMENT_SUCCESS:
      return { ...actions.vendorAgreement };
    default:
      return state;
  }
}