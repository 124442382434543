import React, { useState } from 'react';
import { withLocalize } from 'react-localize-redux';
import { lsStyled as styled } from '../../../../../../../tools/helpers/lsStyled';
import { colors } from '../../../../../../../defaults/styles';
import Popup from '../../../../CatalogPage/ShoppingList/Popup';
import GridLoader from 'react-spinners/GridLoader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStickyNote, faEye } from '@fortawesome/pro-regular-svg-icons';
import { getAutoLoginRedirectUrl } from '../../../../../../../tools/api/zoomCatalog';

const ZoomPopup = ({
    user,
    translate,
    showPopup,
    setShowPopup,
    isMobile,
    editUrl,
    viewUrl,
    zoomStudioUserToken,
    zoomStudioItToken,
    errorMessage,
    appendErrorMessage
}) => {
    const t = (tag) => `product.info.${tag}`;
    const [hovered, setHovered] = useState(false);

    const onViewClick = () => {
        try {
            console.log("Redirect auto login View uri: ", viewUrl);
            window.open(viewUrl);
        } catch {
            appendErrorMessage('Error while jumping to view uri: ');
        }
    }

    const onEditClick = () => {
        // Use short user_token
        const autologinRedirectUrl = getAutoLoginRedirectUrl(zoomStudioUserToken, editUrl);
        console.log("Edit click autologinRedirectUrl: ", autologinRedirectUrl);
        try {
            window.open(autologinRedirectUrl);
        } catch (error) {
            console.error('Error while jumping auto login edit uri: ', error);
            appendErrorMessage('Error while jumping auto login edit uri: ', error);
        }
    }

    return <Popup showPopup={showPopup} setShowPopup={setShowPopup} isMobile={isMobile}>
        {!(viewUrl) ?
            (<GridLoader
                css={{ margin: 'auto' }}
                color={colors.main.normal}
                size={12}
            />)
            : (
                <ContentSectionWrapper style={{ position: 'relative' }}>
                    {!errorMessage ? (
                        <>
                            <ContentSectionHeader>
                                {translate(t('createdZoomCatalog'))}
                            </ContentSectionHeader>

                            <Button
                                onMouseEnter={() => setHovered(true)}
                                onMouseLeave={() => setHovered(false)}
                                isHovered={hovered}
                                isMobile={isMobile}
                                onClick={onViewClick}
                            >
                                <IconBox>
                                    <FontAwesomeIcon icon={faEye} />
                                </IconBox>
                                <ButtonText>
                                    {translate(t('viewZoomCatalog'))}
                                </ButtonText>
                            </Button>

                            <Button
                                onMouseEnter={() => setHovered(true)}
                                onMouseLeave={() => setHovered(false)}
                                isHovered={hovered}
                                isMobile={isMobile}
                                onClick={onEditClick}
                                disabled={zoomStudioUserToken == null || !zoomStudioUserToken}
                            >
                                <IconBox>
                                    <FontAwesomeIcon icon={faStickyNote} />
                                </IconBox>
                                <ButtonText
                                    title={(zoomStudioUserToken == null || !zoomStudioUserToken) ? translate(t('zoomLoginRequired')) : ''}
                                >
                                    {translate(t('editZoomCatalog'))}
                                </ButtonText>
                            </Button>
                        </>
                    ) : (
                        <ContentSectionHeader>
                            {errorMessage}
                        </ContentSectionHeader>
                    )}
                </ContentSectionWrapper>
            )}
    </Popup>
}

const ContentSectionWrapper = styled.section`
    position: relative;
    display: flex;
    justify-content: center; 
    align-items: center; 
    flex-direction: column;
`;

const ContentSectionHeader = styled.h3`
    margin: 1rem 2rem;
`;

const Button = styled.div`
    border-radius: 0.2rem;
    background-color: ${props => props.disabled ? '#CCCCCC' : colors.primary.normal};
    color: ${colors.white.normal};
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    font-size: ${props => props.isMobile ? '0.8rem' : '0.8rem'};
    margin-bottom: 1rem;
    padding: 1em;
    width: 80%;
    min-height: 42px;

    display: grid;
    grid-template-columns: ${props => props.showItemCounter ? 'repeat(3, auto)' : 'repeat(2, auto)'};
    grid-auto-flow: column;
    grid-auto-columns: auto;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: center;

    opacity: ${props => props.discontinued ? 0.5 : 1};
    user-select: ${props => props.discontinued ? 'none' : null};
    transition: all 0.2s;
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    :hover {
        background-color: ${props => props.discontinued ? null : colors.primary.hover};
        border-color: ${props => props.discontinued ? colors.primary.dark : null};
        cursor:  ${props => props.disabled || props.discontinued ? 'not-allowed' : 'pointer'};
        user-select: ${props => props.discontinued ? 'none' : null}
    }
`;

const IconBox = styled.div`
`;

const ButtonText = styled.div`
    text-transform: uppercase;
`;

export default withLocalize(ZoomPopup);