import * as types from '../actions/types'
import { listing } from './initialState'

export default function listingReducer(state = listing, actions) {
    switch (actions.type) {
        case types.LOAD_LISTING_SUCCESS:
            return actions.listing;
        default:
            return state;
    }
}
