import React from 'react';
import UIContainer from '../../../interface/UIContainer';
import { lsStyled as styled } from './../../../../../tools/helpers/lsStyled';
import { colors, spacing } from './../../../../../defaults/styles';

import CompanyInfo from './CompanyInfo';
import LegalInfo from './LegalInfo';
import SocialMediaLinks from './SocialMediaLinks';

// == Styling == //
const Root = styled.div`
  background-color: ${colors.white.normal};
  color: ${colors.gray.normal};
`;
// == /Styling == //

const Bar = ({ isMobile, isTablet }) => {
  return (
    <Root>
      <UIContainer
        customStyle={{
          display: 'grid',
          gridTemplateColumns: isMobile || isTablet ? '1fr' : 'auto auto 1fr',
          gridGap: isMobile || isTablet ? spacing.small : spacing.veryLarge,
          alignItems: 'center',
          paddingTop: spacing.medium,
          paddingBottom: spacing.medium,
        }}
        textAlign={isMobile || isTablet ? 'center' : null}
        isMobile={isMobile}
        isTablet={isTablet}
      >
        <CompanyInfo isMobile={isMobile} />
        <LegalInfo isMobile={isMobile} />
        <SocialMediaLinks isMobile={isMobile} isTablet={isTablet}/>
      </UIContainer>
    </Root>
  );
};

export default Bar;
