import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { colors } from './../../../../../defaults/styles';

// function to close modal when outside of the image is clicked
function useOuterClickNotifier(onOuterClick, innerRef) {
  useEffect(() => {
    if (innerRef.current) {
      document.addEventListener('click', handleClick);
    }
    return () => document.removeEventListener('click', handleClick);

    function handleClick(e) {
      innerRef.current &&
        !innerRef.current.contains(e.target) &&
        onOuterClick(e);
    }
  }, [onOuterClick, innerRef]);
}

const Popup = ({ children, showPopup, setShowPopup, isMobile, isTablet }) => {
  const innerRef = useRef(null);
  useOuterClickNotifier(e => setShowPopup(false), innerRef);

  // const imageH = isMobile || isTablet ? 'auto' : '900px';
  // const imageW = isMobile || isTablet ? '100%' : 'auto';
  const imageH = 'auto';
  const imageW = isMobile ? '100%' : '450px';
  return (
    <PopupOuter showPopup={showPopup}>
      <Mask />
      <PopupInner
        ref={innerRef}
        imageH={imageH}
        imageW={imageW}
        isMobile={isMobile}
        isTablet={isTablet}
      >
        <Contents imageH={imageH} imageW={imageW } isMobile={isMobile} isTablet={isTablet}>
          {children}
        </Contents>
        <CloseButton onClick={() => setShowPopup(false)}>
          <FontAwesomeIcon icon={faTimes} size="3x" />
        </CloseButton>
      </PopupInner>
      {showPopup && <style>{`
          html, body {
              overflow: hidden;
              -webkit-overflow-scrolling: touch;
          }
      `}</style>}
    </PopupOuter>
  );
};
export default Popup;

// == Styling == //
const PopupOuter = styled.div`
  height: ${props => (props.showPopup ? '100vh' : 0)};
  width: ${props => (props.showPopup ? '100vw' : 0)};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: all 0.5s ease-in-out;
  overflow: auto;
`;

const PopupInner = styled.div`
  background-color: transparent;
  height: ${props => props.imageH};
  width: ${props => props.imageW};

  position: absolute;
  /* top: ${props => (props.isMobile || props.isTablet ? '50%' : '2%')}; */
  top: 50%;
  left: 50%;
  /* transform: ${props =>
    props.isMobile || props.isTablet
      ? 'translate(-50%, -50%)'
      : 'translateX(-50%)'}; */
  transform: translate(-50%, -50%);
  z-index: 1100;

  /* for contents inside */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Mask = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
`;

const CloseButton = styled.button`
  background-color: transparent;
  color: ${colors.black.normal};
  cursor: pointer;
  padding: 15px;
  position: absolute;
  top: 0;
  right: 10px;
`;

const Contents = styled.div`
  background-color: ${colors.white.normal};
  border-radius: 3px;
  height: ${props => props.imageH};
  width: ${props => props.imageW};
  /* overflow: ${props => props.isMobile || props.isTablet ? 'auto' : 'hidden'}; */
  overflow: auto;

  img {
    display: block;
    /* the margin is just in case */
    margin: 0 auto;
    height: ${props => props.imageH};
    width: ${props => props.imageW};
  }
`;
// == /Styling == //
