import * as types from '../actions/types';
import { categories } from './initialState';

export default function categoryReducer(state = categories, actions) {
  switch (actions.type) {
    case types.LOAD_CATEGORIES_SUCCESS:
      return actions.categories;
    default:
      return state;
  }
}
