import React from 'react';
import { spacing, fonts, colors } from '../../../../../../defaults/styles';

const Description = ({ title, content }) => (
  <section
    style={{
      borderTop: `1px solid ${colors.gray.light}`,
      marginTop: spacing.small,
      marginBottom: spacing.large,
      paddingTop: spacing.medium,
    }}
  >
    <h3
      style={{
        fontSize: fonts.sizes.largeText,
        fontFamily: fonts.title,
        color: colors.gray.dark,
        marginBottom: spacing.medium,
      }}
    >
      {title}
    </h3>
    <div>
      <div
        style={{
          fontSize: fonts.sizes.text,
          lineHeight: '1.2em',
        }}
        className="dangerously-insert-inner-html-content"
        dangerouslySetInnerHTML={{ __html: content }}
      />
    </div>
  </section>
);

export default Description;
