import React from 'react';
import Button from './../../../../../../presentation/interface/Button';
import { Translate, withLocalize } from 'react-localize-redux';

const ContactButton = () => {
  return (
    <Button url={'/contact-us'} size={16} fill={"true"} isHeader={"true"}>
      {<Translate id={'labels.contact'} />}
    </Button>
  );
};

export default withLocalize(ContactButton);
