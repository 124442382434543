import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';

import { Translate, withLocalize } from 'react-localize-redux';

import { lsStyled as styled } from './../../../../../../../../tools/helpers/lsStyled';
import { colors, fonts } from './../../../../../../../../defaults/styles';

// == Styling == //
const ButtonDiv = styled.div`
  border: 1px solid ${colors.gray.light};
  font-size: ${fonts.sizes.medium}px;
  transition: color 0.5s ease-in-out;

  display: flex;
  align-items: center;
  justify-content: center;
`;
// == /Styling == //

const UserLoginButton = ({ activeLanguage }) => {
  const t = (tag) => `header.${tag}`;

  const [hovering, setHovering] = useState(false);
  const lang =
    activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';

  const theSize = 12;

  const loginUrl = `/${lang}/login`;

  return (
    <ButtonDiv>
      <Link
        to={loginUrl}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        onClick={(e) => { e.preventDefault(); window.location.href = loginUrl; }}
        style={{
          border: `1px solid ${colors.gray.light}`,
          color: hovering ? colors.main.normal : colors.gray.normal,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontSize: `${fonts.sizes.medium}`,
          textDecoration: 'none',
          padding: `${theSize * 0.65}px ${theSize * 1.25}px`,
        }}
      >
        <FontAwesomeIcon
          icon={faUser}
          style={{ fontSize: 20, paddingRight: 5, color: 'inherit' }}
        />
        <Translate id={t('logIn')} />
      </Link>
    </ButtonDiv>
  );
};

export default withLocalize(UserLoginButton);
