import React from 'react';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { colors } from '../../ui/common';

const DisclaimerSection = styled.section`
    background-color: ${colors.gray.light};
    border-radius: 2px;
    padding: .5em;
`;

const DisclaimerContent = styled.div`
    font-size: .8rem;
    line-height: 1.2;
`;

const Disclaimer = ({translate}) => {
    return (
        <DisclaimerSection>
            <DisclaimerContent>
                <tr>
                    <td><FontAwesomeIcon icon={faInfoCircle} />{'\u00A0'}</td>
                    <td>&nbsp;</td>
                    <td style={{lineHeight: 1.2}}>{translate('checkout.disclaimer')}</td>
                </tr>
            </DisclaimerContent>
        </DisclaimerSection>
    );
}

export default withRouter(withLocalize(Disclaimer));