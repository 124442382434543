import React, { useRef } from 'react';
import PageReinforcementStatic from './../../interface/PageReinforcementStatic';
import UIContainer from './../../interface/UIContainer';
import UISection from './../../interface/UISection';
import { withLocalize } from 'react-localize-redux';
import UIParagraph from '../../interface/UIParagraph';
import LegalSectionContent from '../../interface/PageSection/LegalSectionContent';
import { spacing, sizing } from './../../../../defaults/styles/';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import content from '../../../../main/global-lexicon';

const TOCList = styled.ol`
  margin-top: ${spacing.veryLarge * 2}px;
  margin-bottom: ${spacing.veryLarge * 2}px;
`;

const VendorAgreementSection = ({ isMobile, isTablet, translate, vendorAgreement, activeLanguage }) => {
    // Cannot create ref with array, need to be done manually...
    const section1 = useRef(null);
    const section2 = useRef(null);
    const section3 = useRef(null);
    const section4 = useRef(null);
    const section5 = useRef(null);
    const section6 = useRef(null);
    const section7 = useRef(null);
    const section8 = useRef(null);
    const section9 = useRef(null);
    const section10 = useRef(null);
    const section11 = useRef(null);
    const section12 = useRef(null);
    const section13 = useRef(null);
    const section14 = useRef(null);
    const section15 = useRef(null);
    const section16 = useRef(null);
    const section17 = useRef(null);

    const t = (tag) => `vendorAgreement.clauses.${tag}`;
    const t2 = (tag) => `vendorAgreement.${tag}`;
    const language = activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';

    let validToken;
    if (!vendorAgreement || vendorAgreement.invalidToken || vendorAgreement.expiredToken) {
        validToken = false;
    } else {
        validToken = true;
    }

    if (!vendorAgreement) {
        return null;
    }

    const scrollToSection = (ref, e) => {
        e.preventDefault();
        window.scrollTo({ top: ref.current.offsetTop - sizing.header.top - sizing.header.mid - sizing.header.btm - 20, behavior: 'smooth' });
    }

    const languageData = content.vendorAgreement.clauses;

    const { date, companyName, address, city, province, country } = vendorAgreement;

    const paragraph1En = `This Agreement is dated as of ${date} (Effective Date), between Loyalty Source, a division of KLF Media Inc. with offices located at 8010 Devonshire, Mount-Royal, Quebec, H4P 2K3 (collectively “Loyalty Source” or “we“) and ${companyName}, a company with offices located at ${address}, ${city}, ${province}, ${country}, (collectively “Company” or “you“).`;
    const paragraph1Fr = `Cet accord est daté du ${date} (date d'entrée en vigueur), entre Loyalty Source une division de KLF Media Inc. situé au 8010 Devonshire, Mont-Royal, Québec, H4P 2K3 (collectivement « Loyalty Source » ou « nous » ) et ${companyName}, une société dont les bureaux sont situés à ${address}, ${city}, ${province}, ${country}, (collectivement « Société » ou « vous »).`;
    const paragraph1 = language === 'fr' ? paragraph1Fr : paragraph1En;
    return (
        validToken ? (
            <UIContainer fullWidth noPadding>
                <UISection>
                    <UIParagraph
                        customStyle={{marginBottom: 10}}
                        textAlign={'justify'}
                        borderRequired
                        heading={'h2'}
                        isMobile={isMobile}
                        isTablet={isTablet}
                        maxWidth={'1200'}
                    >
                        {paragraph1}
                    </UIParagraph>
                    <section ref={section1}><LegalSectionContent section={1} t={t} languageData={languageData} /></section>
                    <section ref={section2}><LegalSectionContent section={2} t={t} languageData={languageData} /></section>
                    <section ref={section3}><LegalSectionContent section={3} t={t} languageData={languageData} /></section>
                    <section ref={section4}><LegalSectionContent section={4} t={t} languageData={languageData} /></section>
                    <section ref={section5}><LegalSectionContent section={5} t={t} languageData={languageData} /></section>
                    <section ref={section6}><LegalSectionContent section={6} t={t} languageData={languageData} /></section>
                    <section ref={section7}><LegalSectionContent section={7} t={t} languageData={languageData} /></section>
                    <section ref={section8}><LegalSectionContent section={8} t={t} languageData={languageData} /></section>
                    <section ref={section9}><LegalSectionContent section={9} t={t} languageData={languageData} /></section>
                    <section ref={section10}><LegalSectionContent section={10} t={t} languageData={languageData} /></section>
                    <section ref={section11}><LegalSectionContent section={11} t={t} languageData={languageData} /></section>
                    <section ref={section12}><LegalSectionContent section={12} t={t} languageData={languageData} /></section>
                    <section ref={section13}><LegalSectionContent section={13} t={t} languageData={languageData} /></section>
                    <section ref={section14}><LegalSectionContent section={14} t={t} languageData={languageData} /></section>
                    <section ref={section15}><LegalSectionContent section={15} t={t} languageData={languageData} /></section>
                    <section ref={section16}><LegalSectionContent section={16} t={t} languageData={languageData} /></section>
                    <section ref={section17}><LegalSectionContent section={17} t={t} languageData={languageData} /></section>
                </UISection>
            </UIContainer>
        ) : null
    );
};

export default withLocalize(VendorAgreementSection);