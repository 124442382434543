import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { loadProducts } from '../../../redux/actions/catalogActions';
import { withLocalize } from 'react-localize-redux';
import { objectEquivalent } from '../../../tools/helpers';

export const CatalogContainer = ({
  loadProducts,
  pageable,
  totalElements,
  totalPages,
  currentPage,
  products,
  filters,
  previousFilters,
  apiCallsInProgress,
  activeLanguage,
  ...props
}) => {
  useEffect(() => {
    const language =
      activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';

    let found = false;
    for (var key in filters) {
      if (filters[key]) {
        found = true;
        break;
      }
    }

    const newFilters = found ? { ...filters, language } : filters;

    if (!objectEquivalent(newFilters, previousFilters))
      loadProducts(newFilters);
  }, [filters, activeLanguage, loadProducts]);

  const childrenWithProps = React.Children.map(props.children, child =>
    React.cloneElement(child, {
      products,
      pageable,
      totalElements,
      totalPages,
      currentPage,
      filters,
      apiCallsInProgress,
    })
  );
  return <>{childrenWithProps}</>;
};

CatalogContainer.propTypes = {
  loadProducts: propTypes.func.isRequired,
  products: propTypes.array.isRequired,
  filters: propTypes.object.isRequired,
};

function mapStateToProps({
  products,
  filters,
  previousFilters,
  apiCallsInProgress,
}) {
  const {
    content,
    pageable,
    totalElements,
    totalPages,
    number: currentPage,
  } = products;
  return {
    products: content,
    pageable,
    totalElements,
    totalPages,
    filters,
    previousFilters,
    currentPage,
    apiCallsInProgress,
  };
}

const mapDispatchToProps = {
  loadProducts,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(CatalogContainer));
