import React from 'react';

import PageReinforcementStatic from './../../interface/PageReinforcementStatic';
import UIContainer from './../../interface/UIContainer';
import UISection from './../../interface/UISection';
import UIParagraph from './../../interface/UIParagraph';
import UIGridIcons from './../../interface/UIGridIcons';

import { Translate, withLocalize } from 'react-localize-redux';

const BrandsAndRewards = ({isMobile, isTablet, translate}) => {
  const t = (tag) => `services.rewards.brandsAndRewards.${tag}`;

  return (
    <UIContainer isMobile={isMobile} isTablet={isTablet} pageTop>
      <UISection>
        <PageReinforcementStatic
          title={translate(t('title'))}
          subTitle={translate(t('subTitle'))}
          borderRequired={true}
          heading={'h2'}
          alignment={'center'}
        />
        <UIParagraph textAlign={'center'}>
          <Translate id={t('mainParagraph')} />
        </UIParagraph>
      </UISection>

      <UISection noPadding>
        <UIGridIcons
          customStyle={{ marginTop: 0, marginBottom: 0 }}
          isMobile={isMobile}
          isTablet={isTablet}
          iconsAndText={[
            {
              src: 'services-rewards/icons/integration.png',
              alt: 'integration icon',
              text: translate(t('icon1'), null, { renderInnerHtml: true }),
            },
            {
              src: 'services-rewards/icons/reward-options.png',
              alt: 'reward option icon',
              text: translate(t('icon2'), null, { renderInnerHtml: true }),
            },
            {
              src: 'services-rewards/icons/experience.png',
              alt: 'experience icon',
              text: translate(t('icon3'), null, { renderInnerHtml: true }),
            },
            {
              src: 'services-rewards/icons/gift-wrapping.png',
              alt: 'customization icon',
              text: translate(t('icon4'), null, { renderInnerHtml: true }),
            },
          ]}
        />
      </UISection>
    </UIContainer>
  );
};

export default withLocalize(BrandsAndRewards);
