import React, { useState } from 'react';
import UserLoginButton from './UserLoginButton';
import UserProfileButton from './UserProfileButton';
import UserMenu from './UserMenu';
import PulseLoader from 'react-spinners/PulseLoader';
import { lsStyled as styled } from './../../../../../../../../tools/helpers/lsStyled';
import { colors, spacing } from '../../../../../../../../defaults/styles';

// == Styling == //
const Section = styled.section`
  position: relative;
`;

// == /Styling == //
const UserSection = ({ user }) => {
  const [userMenuOpen, setUserMenuOpen] = useState(false);
  return (
    <Section
      onMouseEnter={() => setUserMenuOpen(true)}
      onMouseLeave={() => setUserMenuOpen(false)}
    >
      {user ? (
        user.loggedIn ? (
          <>
            <UserProfileButton username={user.name} />
            {userMenuOpen && <UserMenu />}
          </>
        ) : (
          <UserLoginButton />
        )
      ) : (
        <div style={{ marginLeft: spacing.verySmall }}>
          <PulseLoader size={5} color={colors.gray.light} />
        </div>
      )}
    </Section>
  );
};

export default UserSection;
