import * as types from '../actions/types'
import { shoppingLists, downloadedShoppingList } from './initialState'

export default function shoppingListsReducer(state = shoppingLists, action) {
  switch (action.type) {
    case types.LOAD_SHOPPINGLISTS_SUCCESS:
      return action.shoppingLists;
    case types.UPDATE_SHOPPINGLISTS_SUCCESS:
        return action.shoppingLists;
    default:
      return state
  }
}

export function shoppingListsDownloadReducer(state = downloadedShoppingList, action) {
  switch (action.type) {
    case types.DOWNLOAD_SHOPPING_LIST_EXCEL:
        return action.downloadedShoppingList;
    default:
      return state
  }
}