import React from 'react';
import { spacing, fonts } from '../../../../../defaults/styles';
import { withLocalize, Translate } from 'react-localize-redux';
import qs from 'querystring';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';

const CheckboxWrapper = styled.div`
  padding-left: ${spacing.verySmall}px;
`;

const Checkbox = styled.input`
  cursor: pointer;
  position: relative;
  vertical-align: middle;
`;

const CheckboxLabel = styled.label`
  cursor: pointer;
  position: relative;
  vertical-align: middle;
  font-size: 14px;
`;

const AvailabilityCheckbox = ({
  currentQuery,
  user,
  history,
}) => {
  const t = (tag) => `corporateGifts.${tag}`;

  const availability = currentQuery && currentQuery.availability;
  const loggedIn = user && user.loggedIn;

  const separatedQuery = currentQuery ? JSON.parse(JSON.stringify(currentQuery)) : {};

  delete separatedQuery.availability;

  // Applying filter
  const pushHistory = ({ target: { checked } }) => {
    const param = {};
    if (checked) { param.availability = checked; }

    if (separatedQuery) {
      history.push({
        pathname: history.location.pathname,
        search: '?' + qs.stringify({ ...separatedQuery, ...param })
      });
    } else {
      history.push({
        pathname: history.location.pathname,
        search: '?' + qs.stringify(param)
      });
    }
  }

  return (
    <>
      {loggedIn && (
        <CheckboxWrapper>
          <Checkbox
            type="checkbox"
            name="availability"
            checked={availability}
            onChange={pushHistory}
            id={'show-availability'}
          />
          &nbsp;
          <CheckboxLabel>
            <Translate id={t('availability')} />
          </CheckboxLabel>
        </CheckboxWrapper>
      )}
    </>
  );
};

export default withLocalize(AvailabilityCheckbox);