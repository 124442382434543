import React, { useContext } from 'react';
import { CategoriesContainer } from '../../../smart';
import Categories from './Categories';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import CatalogFilters from './CatalogFilters';
import { ResizeAwareContext } from '../../../smart/ResizeContext';

const CategoriesUIContainer = styled.aside`
    min-height: 100px;
`;

const CategoriesSideBar = ({
    categories,
    currentQuery,
    hideFilters,
    history,
    product,
    brands,
    user,
    features,
    apiCallsInProgress,
    shoppingLists,
}) => {
    const { isMobile } = useContext(ResizeAwareContext);

    return (
        // Add container if categories is null to avoid double api call
        !categories
        ? <CategoriesUIContainer>
            <CategoriesContainer>
                <Categories 
                    history={history} 
                    currentQuery={currentQuery}
                    product={product} />

                {!hideFilters 
                    ? <CatalogFilters
                        isMobile={isMobile}
                        currentQuery={currentQuery}
                        history={history}
                        brands={brands}
                        features={features}
                        user={user}
                        apiCallsInProgress={apiCallsInProgress}
                        shoppingLists={shoppingLists}
                    />
                    : <></>
                }
            </CategoriesContainer>
        </CategoriesUIContainer>
        : <CategoriesUIContainer>
            <Categories 
                categories={categories}
                history={history} 
                currentQuery={currentQuery}
                product={product} />

            {!hideFilters &&
                <CatalogFilters
                    isMobile={isMobile}
                    currentQuery={currentQuery}
                    history={history}
                    brands={brands}
                    features={features}
                    user={user}
                    apiCallsInProgress={apiCallsInProgress}
                    shoppingLists={shoppingLists}
                />
            }
        </CategoriesUIContainer>
    );
};

export default CategoriesSideBar;
