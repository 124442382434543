import React, { useState } from 'react';
import CartSectionTotal from './sections/CartSectionTotal';
import Disclaimer from './Disclaimer';
import { withLocalize } from 'react-localize-redux';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faFileInvoice, faShippingFast, faShoppingBasket, faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { colors } from '../../ui/common';

const CartSummaryWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const CartSummarySection = styled.div`
    border: 1px solid ${colors.gray.light};
    padding: 1.5rem;
    margin-bottom: 2rem;
    margin-top: ${props => props.isMobile ? '2rem' : undefined };
`;

const CartSummaryConfirmButton = styled.button`
    border-radius: 0.2rem;
    background-color: ${colors.primary.normal};
    color: ${colors.white.normal};
    cursor: pointer;
    padding: 1rem;
    font-size: 1rem;
    margin-bottom: 1rem;
    transition: all 200ms ease-in-out 0s;

    :hover {
        background-color: ${colors.primary.dark};
        border-color: ${colors.primary.dark};
    }

    :disabled {
        color: ${colors.gray.light};
        background-color: ${colors.white.normal};
        border-color: ${colors.gray.light};
        cursor: not-allowed;
    }
`;

const CartSummaryContinueButton = styled.button`
    border-radius: 0.2rem;
    background-color: ${colors.white.normal};
    color: ${colors.gray.normal};
    border: 1px solid ${colors.gray.normal};
    padding: 1rem;
    font-size: 1rem;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: all 200ms ease-in-out 0s;

    :hover {
        color: ${colors.primary.normal};
        border-color: ${colors.primary.normal};
    }
`;

const IconWrapper =  styled.span`
    display: inline-block;
    margin-right: ${props => !props.loading ? '0.5rem' : undefined};
    margin-left: ${props => props.loading ? '0.5rem' : undefined};
`;


export const CartSectionUpdateButton = styled.button`
    padding: 0.5rem;
    color: ${colors.white.normal};
    background-color: ${colors.primary.normal};
    border: 1px solid ${colors.primary.normal};
    border-radius: 2px;
    cursor: pointer;
    transition: all 200ms ease-in-out 0s;

    :hover {
        background-color: ${colors.primary.dark};
        border-color: ${colors.primary.dark};
    }
`;

export const CartSectionCancelButton = styled.button`
    padding: 0.5rem;
    color: ${colors.gray.normal};
    background-color: ${colors.white.normal};
    border: 1px solid ${colors.gray.normal};
    border-radius: 2px;
    cursor: ${props => props.disabled ? 'not-allowed' : 'pointer'};
    margin-left: 0.5rem;
    transition: all 200ms ease-in-out 0s;

    :hover {
        color: ${colors.primary.normal};
        border-color: ${colors.primary.normal};
    }
`;

export const CartSectionErrorMessage = styled.div`
    margin-top: 1rem;
    color: ${colors.primary.normal};
    font-size: 0.8rem;
    grid-column-start: 1;
    grid-column-end: 3;
    text-align: right;
`;

const validateMissingRequiredFields = (cart) => {
    return !cart.submitter
        || !cart.submitterPhoneNumber
        || !cart.productOrderNumber
        || !cart.address
        || !cart.address.firstName
        || !cart.address.lastName
        || !cart.address.addressLineOne
        || !cart.address.city
        || !cart.address.province
        || !cart.address.postalCode;
}

const CartSummary = ({
        translate,
        locale,
        cart,
        confirm,
        submit,
        setConfirm,
        setCart,
        setSubmit,
        submitCart,
        history,
        breadIndex,
        setBreadIndex,
        isMobile
    }) => {
    const { items } = cart;
    const [submitLoading, setSubmitLoading] = useState(false);

    const clickContinue = () => {
        if (submit || !confirm) {
            history.push('/catalog');
            setBreadIndex(0);
        } else {
            setConfirm(false);
            setBreadIndex(0);
        }
    };

    const clickConfirm = () => {
        if (!confirm) {
            setConfirm(true);
            setBreadIndex(breadIndex + 1);
        } else {
            setSubmitLoading(true);
            submitCart({...cart, 
                            items:cart.items 
                                  && cart.items
                                            .filter(({statusId})=> statusId !== 4)
                        }, (json) => {
                            if(json) {
                                setSubmit(true);
                                setBreadIndex(breadIndex + 1);
                                setTimeout(() => setSubmitLoading(false),1000);
                            }
            });
        }
    };

    return (
        <CartSummaryWrapper>
            <CartSummarySection isMobile={isMobile}>
                <CartSectionTotal items={items} locale={locale} />
            </CartSummarySection>

            {/* Only show up if there is anything in the cart */}
            {!submit && cart.items && cart.items.length > 0 && 
                <CartSummaryConfirmButton 
                    disabled={confirm && validateMissingRequiredFields(cart)}
                    onClick={clickConfirm}
                >
                    <IconWrapper>
                        <FontAwesomeIcon icon={confirm ? faFileInvoice : faShippingFast} />
                    </IconWrapper>
                    {translate(`checkout.${confirm ? 'confirm' : 'next'}`)}
                    { submitLoading && (
                        <IconWrapper loading={'loading'}>
                            <FontAwesomeIcon icon={faSpinner} spin />
                        </IconWrapper>
                    )}
                </CartSummaryConfirmButton>
            }
            
            <CartSummaryContinueButton onClick={clickContinue}>
                <IconWrapper>
                    {!confirm || submit ? <FontAwesomeIcon icon={faShoppingBasket} /> : <FontAwesomeIcon icon={faEdit} />}
                </IconWrapper>
                {translate(`checkout.${!confirm || submit ? 'continue' : 'back'}`)}
            </CartSummaryContinueButton>
            {/* Disclaimer only when cart is available and not yet submitted */}
            { cart && !submit && !isMobile && (<Disclaimer />) }    
        </CartSummaryWrapper>
    )
}

export default withRouter(withLocalize(CartSummary));