import React from 'react';
import { lsStyled as styled } from '../../../../../tools/helpers/lsStyled';
import { spacing } from '../../../../../defaults/styles';
import { withLocalize } from 'react-localize-redux';

const OrderItemHeaderLabelWrapper = styled.div`
  margin-bottom: ${props => props.isMobile ? spacing.verySmall : '5' }px;
`;

export const OrderItemHeader = withLocalize(({ order, locale, isMobile, translate }) => {
  const t = (tag) => `orders.${tag}`;

  const OrderItemHeaderDate = ({ text, date }) => {
    return (
      isMobile 
      ?
      <>
        <OrderItemHeaderLabelWrapper isMobile={isMobile}>
          <b>{text}</b>
          &nbsp;
          {new Date(date).toLocaleString(locale, {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZoneName: 'short',
          })}
        </OrderItemHeaderLabelWrapper>
      </>
      :
      <>
        <OrderItemHeaderLabelWrapper isMobile={isMobile}>
          <b>{text}</b>
        </OrderItemHeaderLabelWrapper>
        <OrderItemHeaderLabelWrapper isMobile={isMobile}>
          {new Date(date).toLocaleString(locale, {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZoneName: 'short',
          })}
        </OrderItemHeaderLabelWrapper>
      </>
    );
  };

  return (
    <div className="card-header" style={{ fontSize: '12px' }}>
      <div className="row">
        {/* 1st date: Order creation date */}
        <div className="col-lg-3 text-left">
          <OrderItemHeaderDate
            text={translate(t('header.placed'))}
            date={order.creationDate}
          />
        </div>

        {/* 2nd date: Order shipped date or order cancelled date */}
        <div className="col-lg-3 text-left">
          {/* Only if shipped date is available and cancelled date is not available */}
          {order.shippedDate && !order.cancelledDate && (
            <OrderItemHeaderDate
              text={translate(t('header.shipped'))}
              date={order.shippedDate}
            />
          )}

          {/* Only if cancelled date is available */}
          {order.cancelledDate && (
            <OrderItemHeaderDate
              text={translate(t('header.cancelled'))}
              date={order.cancelledDate}
            />
          )}
        </div>

        {/* 3rd date: Order invoiced date only when cancelled date is not present */}
        <div className="col-lg-3 text-left">
          {order.invoicedDate && !order.cancelledDate && (
            <OrderItemHeaderDate
              text={translate(t('header.invoiced'))}
              date={order.invoicedDate}
            />
          )}
        </div>

        {/* PO number and ls order id */}
        <div className="col-lg-3 text-left">
          <OrderItemHeaderLabelWrapper isMobile={isMobile}>
            <b>{translate(t('header.poNumber'))}</b>
            &nbsp;
            {order.poNumber}
          </OrderItemHeaderLabelWrapper>
          <OrderItemHeaderLabelWrapper isMobile={isMobile}>
            <b>{translate(t('header.lsRef'))}</b>
            &nbsp;
            {order.lsOrderId}
          </OrderItemHeaderLabelWrapper>
        </div>
      </div>
    </div>
  );
});
