import React from 'react';
import { lsStyled as styled } from './../../../tools/helpers/lsStyled';
import { spacing, typography } from './../../../defaults/styles';

/*
  PLEASE USE THIS COMPONENT WHEN THERE ARE ICONS TO BE ALIGNED IN A ROW.
  THIS COMPONENT USES 'CSS GRID'. IF FLEXBOX SHOULD BE USED, PLEASE USE 'UIFlexIcons' INSTEAD.
*/

const UIGridIcons = ({
  children,
  iconsAndText = [],
  customStyle = null,
  isMobile,
  isTablet,
}) => {
  const iconLength = iconsAndText.length;
  const items = iconsAndText.map((item, index) => (
    <ItemBox key={'icon-' + item.alt}>
      <IconBox>
        <Icon src={`/images/pages/${item.src}`} alt={item.alt} />
      </IconBox>
      {item.text ? <Text>{item.text}</Text> : null}
    </ItemBox>
  ));

  return (
    <Wrapper
      iconLength={iconLength}
      customStyle={customStyle}
      isMobile={isMobile}
      isTablet={isTablet}
    >
      {items}
      {children}
    </Wrapper>
  );
};

export default UIGridIcons;

// == Styling == //
const Wrapper = styled.div`
  display: grid;
  /* grid-template-columns: repeat(${props => props.iconLength}, 1fr); */
  grid-template-columns: ${props =>
    props.isMobile
      ? '1fr 1fr'
      : props.isTablet
      ? 'repeat(2, 1fr)'
      : `repeat(${props.iconLength}, 1fr)`};
  grid-gap: ${props =>
    props.isMobile ? '3em' : props.isTablet ? '1em' : '3em'};
  margin-bottom: ${spacing.marginStandard};
  padding-right: ${props => (props.isTablet ? '2em' : null)};
  padding-left: ${props => (props.isTablet ? '2em' : null)};
  ${props => (props.customStyle ? props.customStyle : null)};
`;

const ItemBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
`;

const IconBox = styled.div`
  margin: 0 auto 1em auto;
`;

const Icon = styled.img`
  height: auto;
  width: 100%;
`;

const Text = styled.p`
  ${typography.normal};
  font-weight: 600;
  text-align: center;
`;

// == .Styling == //
