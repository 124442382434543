import React from 'react';
import styled from 'styled-components';
import PageReinforcementStatic from './../../interface/PageReinforcementStatic';
import UIContainer from './../../interface/UIContainer';
import UISection from './../../interface/UISection';
import UIParagraph from './../../interface/UIParagraph';
import Button from './../../interface/Button';
import { colors, spacing } from './../../../../defaults/styles';
import { Translate, withLocalize } from 'react-localize-redux';

const LoveLoyaltySource = ({ isMobile, isTablet, translate }) => {
  const t = (tag) => `services.rewards.loveLoyaltySource.${tag}`;

  const imagesSrcAndAlt = [
    {
      src:
        'https://s3.ca-central-1.amazonaws.com/media.loyaltysource.com/catalog/brands/canon_1386340619939.jpg',
      alt: 'Canon logo',
    },
    {
      src:
        'https://s3.ca-central-1.amazonaws.com/media.loyaltysource.com/catalog/brands/riedellogo_1467042613289.jpg',
      alt: 'Riedel logo',
    },
    {
      src:
        'https://s3.ca-central-1.amazonaws.com/media.loyaltysource.com/catalog/brands/yamahalogo_1463771231552.jpg',
      alt: 'Yamaha logo',
    },
    {
      src:
        'https://s3.ca-central-1.amazonaws.com/media.loyaltysource.com/catalog/brands/kate_spade_1458654965151.jpg',
      alt: 'kate spade New York logo',
    },
  ];

  return (
    <UIContainer isMobile={isMobile} isTablet={isTablet}>
      <UISection noPadding>
        <PageReinforcementStatic
          title={translate(t('title'))}
          subTitle={translate(t('subTitle'))}
          borderRequired={true}
          heading={'h2'}
          type={'1'}
          alignment={'center'}
        />
        <UIParagraph textAlign={'center'} customStyle={{ marginBottom: 0 }}>
          <Translate id={t('mainParagraph')} />
        </UIParagraph>
      </UISection>
      <ImageBoxGrid>
        {imagesSrcAndAlt.map((image, i) => (
          <ImageBoxItem Src={image.src} key={'ls-image-' + i} />
        ))}
      </ImageBoxGrid>

      <ButtonGrid isMobile={isMobile} isTablet={isTablet}>
        <Button
          url="/brands"
          borderRadius={3}
          color={colors.primary.normal}
          size={18}
          fill={true}
          cta={true}
          // isMobile={isMobile}
          // isTablet={isTablet}
          // isHomeTop={true}
        >
          <Translate id={t('ctaViewAll')} />
        </Button>
        <Button
          url="/catalog"
          borderRadius={3}
          color={colors.primary.normal}
          size={18}
          fill={true}
          cta={true}
          isMobile={isMobile}
          isTablet={isTablet}
          // isHomeTop={true}
        >
          <Translate id={t('ctaBrowseRewardsCatalogue')} />
        </Button>
      </ButtonGrid>
    </UIContainer>
  );
};

export default withLocalize(LoveLoyaltySource);

const ImageBoxGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  grid-gap: 2em;
  margin-top: 2em;
  /* padding: 1em; */
  margin-bottom: ${spacing.marginStandard};
`;

const ImageBoxItem = styled.div`
  background-image: url(${props => (props.Src ? props.Src : null)});
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  border: 1px solid #eee;
  display: flex;

  /* The following psedo class is used to keep image ratio for responsive. */
  &::before {
    content: ' ';
    display: block;
    height: 0;
    width: 0;
    padding-bottom: calc(1 / 2 * 100%);
  }
`;

const ButtonGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${props => (props.isMobile ? 1 : 2)},
    ${props => (props.isMobile ? '1fr' : 'auto')}
  );
  grid-gap: 1em;
  grid-auto-rows: 1fr;
  align-items: start;
  justify-content: center;
  text-align: ${props => (props.isMobile ? 'center' : null)};
`;
