import React from 'react';
import { connect } from 'react-redux';
import propTypes from 'prop-types';
import { modifyFilters } from '../../../redux/actions/filtersActions';
import { withLocalize } from 'react-localize-redux';

export const FiltersContainer = ({
  modifyFilters,
  filters,
  activeLanguage,
  ...props
}) => {
  const language =
    activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';
  const childrenWithProps = React.Children.map(props.children, child =>
    React.cloneElement(child, {
      filters,
      modifyFilters: (filters, reset = false) =>
        modifyFilters({ ...filters, language }, reset),
    })
  );

  return <>{childrenWithProps}</>;
};

FiltersContainer.propTypes = {
  modifyFilters: propTypes.func.isRequired,
  filters: propTypes.object.isRequired,
};

function mapStateToProps({ filters }) {
  return {
    filters,
  };
}

const mapDispatchToProps = {
  modifyFilters,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withLocalize(FiltersContainer));
