import React from 'react';
import styled from 'styled-components';

import PageReinforcementStatic from '../../interface/PageReinforcementStatic';
import UIContainer from '../../interface/UIContainer';
import UISection from '../../interface/UISection';
import UIGrid2cols from '../../interface/UIGrid2cols';
import UIParagraph from '../../interface/UIParagraph';
import UIBulletListItems from '../../interface/UIListItemBullet';
import Button from '../../interface/Button';
import { Translate, withLocalize } from 'react-localize-redux';

const ButtonWrapper = styled.div`
  text-align: center;
`;
const ImageBox = styled.div`
  max-width: 325px;
  width: 100%;
  order: ${props => (props.isMobile || props.isTablet ? 2 : null)};
  text-align: center;
  justify-content: center;
  align-items: center;
  margin: auto;
`;

const GiftingExperience = ({ isMobile, isTablet, translate }) => {
  const t = (tag) => `services.gifting.giftingExperience.${tag}`;

  return (
    <UIContainer
      isMobile={isMobile}
      isTablet={isTablet}
      pageTop
    >
      <UISection isMobile={isMobile} isTablet={isTablet}>
        <PageReinforcementStatic
          title={translate(t('title'))}
          borderRequired={true}
          heading={'h2'}
          alignment={'center'}
        />
          <PageReinforcementStatic
            subTitle={translate(t('subTitle'))}
            borderRequired={false}
            heading={'h2'}
            type={'1'}
            alignment={'center'}
          />
      </UISection>

      <UIGrid2cols
        isMobile={isMobile}
        isTablet={isTablet}
        mobileOrderReverse={true}
        alignItems={'center'}
        customStyle={{ gridTemplateColumns: !isMobile ? '1fr auto' : undefined }}
      >
        <UISection isMobile={isMobile} isTablet={isTablet} >
          <UIParagraph textAlign={'center'}>
            <Translate id={t('mainParagraph')} />
          </UIParagraph>
          <UIBulletListItems
            text={[
              { text: translate(t('listItems.corporateGifting')) },
              { text: translate(t('listItems.employeeIncentives')) },
              { text: translate(t('listItems.serviceRecognition')), },
              { text: translate(t('listItems.virtualGifting')) },
              { text: translate(t('listItems.decorationRequest')) },
            ]}
          />
          <ButtonWrapper>
            <Button url={'/contact-brief'} size={24} fill={"true"} isHeader={"true"}>
              {<Translate id={t('contact')} />}
            </Button>
          </ButtonWrapper>
        </UISection>

        <UISection>
          <ButtonWrapper>
            <ImageBox isMobile={isMobile} isTablet={isTablet} >
              <img
                src={'/images/pages/services-gifting/Headphones 1.png'}
                alt="temp"
                style={{ maxWidth: isMobile ? '100%' : 350 }}
              />
            </ImageBox>
          </ButtonWrapper>
        </UISection>
      </UIGrid2cols>
    </UIContainer>
  );
};

export default withLocalize(GiftingExperience);
