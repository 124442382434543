import React from 'react';
import { OrderItemHeader, OrderItemBody, OrderItemFooter } from './OrderItem';
import { OrdersPageFooter } from './OrdersPageFooter';
import { OrdersPageHeader } from './OrdersPageHeader';
import { withLocalize } from 'react-localize-redux';
import { lsStyled as styled } from '../../../../tools/helpers/lsStyled';
import { GridLoader } from 'react-spinners';
import { spacing, colors } from '../../../../defaults/styles';
import qs from 'querystring';

const LoaderWrapper = styled.div`
  padding: ${spacing.veryLarge}px;
`;

const ContentSection = styled.section`
  padding-bottom: ${spacing.veryLarge}px;
`;

const LoadingGrid = (
  <LoaderWrapper>
    <GridLoader css={{ margin: 'auto' }} color={colors.main.normal} size={12} />
  </LoaderWrapper>
);

export const OrdersPagePresentation = withLocalize(
  ({
    orders,
    apiCallsInProgress,
    totalElements,
    totalPages,
    history,
    activeLanguage,
    isMobile,
    translate,
  }) => {
    const t = (tag) => `orders.${tag}`;
    const currentQuery = qs.parse(history.location.search.substring(1));

    const locale =
      activeLanguage && activeLanguage.code ? activeLanguage.code : 'en';
    
    document.title = currentQuery.completed 
      ? translate(t('title.open'))
      : translate(t('title.completed'));

    return (
      <ContentSection>
        <div className="container">
          <OrdersPageHeader
            totalElements={totalElements}
            history={history}
            currentQuery={currentQuery}
            isMobile={isMobile}
            lang={locale}
          />

          {/* Section padding */}
          <div className="row">
            <div className="col-lg-12">
              <br />
            </div>
          </div>

          {/* Check if API is loading */}
          {(apiCallsInProgress || !orders)
            ? LoadingGrid
            // API loaded check result
            : orders.length > 0 
            ?
            orders.map(order => {
              return (
                <div key={order.lsOrderId}>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="card">
                        <OrderItemHeader
                          order={order}
                          locale={locale}
                          isMobile={isMobile}
                        />
                        <OrderItemBody
                          order={order}
                          lang={locale}
                        />
                        <OrderItemFooter
                          order={order}
                          locale={locale}
                          isMobile={isMobile}
                        />
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              );
            })
            // No result
            :
            translate(t('no_orders'))
          }
          <OrdersPageFooter
            history={history}
            totalPages={totalPages}
            currentQuery={currentQuery}
            lang={locale}
          />
        </div>
      </ContentSection>
    );
  }
);