import React, { useState, useEffect, useRef, useContext } from 'react';
// import Radium from 'radium';
// import PropTypes from 'prop-types';

import { sizing, colors, spacing } from './../../../../defaults/styles';
import Button from './../Button';
import Title from './Title';
import SubTitle from './SubTitle';
import Paragraph from './Paragraph';
import MobileAwareContainer from '../../../smart/ResizeContext';

// Styles
const styles = {
  withDarkBackground: {
    backgroundColor: colors.darkBackground,
    color: 'white',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    ...sizing.boundToContentWidth,
  },
  withPadding: {
    paddingTop: sizing.paddingEq,
    paddingBottom: sizing.paddingEq,
  },
  contentContainer: {
    flex: 1,
  },
  imageContainer: {
    flex: 1,
    minHeight: 350,
    position: 'relative',
  },
  image: {
    marginBottom: -5,
    width: '85%',
  },
  imagePrimary: {
    position: 'absolute',
    bottom: -50,
    right: 0,
  },
  imagePrimaryAboutUs: {
    position: 'absolute',
    bottom: -60,
    right: 0,
  },
  imgPosition: {
    left: {
      textAlign: 'left',
    },
    right: {
      textAlign: 'right',
    },
  },
};

const stylesMobile = {
  withDarkBackground: {
    ...styles.withDarkBackground,
    // any padding size is ok here, needs any size of padding to show polygons
    paddingTop: spacing.medium,
    paddingBottom: spacing.medium,
  },
  container: {
    ...styles.container,
    ...sizing.boundToContentWidth,
    flexDirection: 'column',
    alignItems: 'inherit',
    padding: spacing.small,
  },
  contentContainer: {
    ...styles.contentContainer,
    flex: '1 1 100%',
    order: 2,
  },
  contentContainerTop: {
    ...styles.contentContainer,
    flex: '1 1 100%',
    order: 1,
  },
  imageContainer: {
    ...styles.imageContainer,
    flex: '1 1 100%',
    marginTop: spacing.medium,
    marginBottom: spacing.medium,
    minHeight: 100,
    order: 1,
    textAlign: 'center',
  },
  imagePrimary: {
    ...styles.imagePrimary,
    bottom: -150,
    right: -spacing.small,
    maxWidth: 300,
  },
};

const stylesTablet = {
  contentContainerTop: {
    ...styles.contentContainer,
  },
  imageContainer: {
    ...styles.imageContainer,
    display: 'flex',
    alignItems: 'center',
    // minHeight: 400,
  },
  imagePrimary: {
    ...styles.imagePrimary,
    width: '100%',
  },
};
// Component
const PageSection = ({
  title,
  subTitle,
  imgSrc,
  imgAlt,
  callToActionUrl,
  callToActionText,
  paragraphs = [],
  contentPosition = 'left',
  darkBackground = false,
  children,
  imgPrimary = false,
  imgPrimaryAboutUs = false,
}) => {
  // for page top section
  // get content container height and setting image container height
  const [contentHeight, setContentHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => { setContentHeight(ref.current.clientHeight); }, [contentHeight]);
  const { isMobile, isTablet } = useContext(MobileAwareContainer);

  return (
    <section
      style={
        darkBackground && isMobile
          ? stylesMobile.withDarkBackground
          : darkBackground
          ? styles.withDarkBackground
          : styles.root
      }
    >
      <div style={styles.polygonTop} />
      <div
        style={
          !darkBackground && isMobile
            ? stylesMobile.container
            : darkBackground && isMobile
            ? {
                ...stylesMobile.container,
                ...styles.withPadding,
              }
            : !darkBackground
            ? {
                ...styles.container,
                ...styles.withPadding,
              }
            : isMobile
            ? stylesMobile.container
            : styles.container
        }
      >
        <div
          style={
            isMobile && imgPrimary
              ? stylesMobile.contentContainerTop
              : isMobile && imgPrimaryAboutUs
              ? stylesMobile.contentContainerTop
              : isMobile
              ? stylesMobile.contentContainer
              : isTablet && imgPrimary
              ? {
                  ...stylesTablet.contentContainerTop,
                  order: contentPosition === 'left' ? 1 : 2,
                }
              : {
                  ...styles.contentContainer,
                  order: contentPosition === 'left' ? 1 : 2,
                }
          }
          ref={ref}
        >
          {title && <Title text={title} isMobile={isMobile} />}
          {subTitle && <SubTitle text={subTitle} isMobile={isMobile} />}
          {children
            ? children
            : paragraphs.map((text, index) => (
                <Paragraph key={index} text={text} isMobile={isMobile} />
              ))}
          {callToActionUrl && (
            <Button
              cta="true"
              url={callToActionUrl}
              isMobile={isMobile}
              isTablet={isTablet}
              order={contentPosition}
            >
              {callToActionText}
            </Button>
          )}
        </div>
        <div
          style={
            isMobile  
            ? stylesMobile.imageContainer
            : isTablet && imgPrimary
            ? {
                ...stylesTablet.imageContainer,
                minHeight: contentHeight+spacing.veryLarge*5,
                order: 2,
              }
            : isTablet
            ? {
                ...stylesTablet.imageContainer,
                justifyContent: contentPosition === 'left' ? 'flex-end' : 'flex-start',
                order: contentPosition === 'left' ? 2 : 1,
                textAlign: contentPosition === 'left' ? 'right' : 'left',

              }
            : {
                ...styles.imageContainer,
                order: contentPosition === 'left' ? 2 : 1,
                textAlign: contentPosition === 'left' ? 'right' : 'left',
              }
          }
        >
          <img
            style={
              imgPrimary && isMobile
                ? { ...styles.image, ...stylesMobile.imagePrimary }
                : imgPrimary
                ? { ...styles.image, ...styles.imagePrimary }
                : imgPrimaryAboutUs && isMobile
                ? { ...styles.image, ...stylesMobile.imagePrimary }
                : imgPrimary && isTablet
                ? stylesTablet.imagePrimary
                : imgPrimaryAboutUs
                ? { ...styles.image, ...styles.imagePrimaryAboutUs }
                : styles.image
            }
            alt={imgAlt}
            src={imgSrc}
          />
        </div>
      </div>
    </section>
  );
};

// PropTypes
// PageSection.propTypes = {
//   title: PropTypes.string.isRequired,
//   subTitle: PropTypes.string,
//   imgSrc: PropTypes.string.isRequired,
//   imgAlt: PropTypes.string.isRequired,
//   callToActionUrl: PropTypes.string,
//   callToActionText: PropTypes.string,
//   paragraphs: PropTypes.array,
//   contentPosition: PropTypes.string,
//   darkBackground: PropTypes.bool,
//   imgPrimary: PropTypes.bool,
//   imgPrimaryAboutUs: PropTypes.bool,
// };

// export default Radium(PageSection);
export default PageSection;
