import React, { useState, useEffect } from 'react';
import { sizing } from '../../../defaults/styles';

function getMobileCondition() {
    return document.documentElement.clientWidth < sizing.mobileWidthThreshold;
}

function getTabletCondition() {
    return document.documentElement.clientWidth >= sizing.mobileWidthThreshold 
        && document.documentElement.clientWidth < sizing.tabletWidthThreshold;
}

export const ResizeAwareContext = React.createContext({ isMobile: getMobileCondition(), isTablet: getTabletCondition() });

export const ResizeAwareProducer = ResizeAwareContext.Provider;
export const ResizeAwareConsumer = ResizeAwareContext.Consumer;

const ResizeAwareContainer = ({ children }) => {
    const [ sizeAware, setSizeAware ] = useState({ isMobile: getMobileCondition(), isTablet: getTabletCondition() });

    return (
        <ResizeAwareProducer value={{ isMobile: sizeAware.isMobile, isTablet: sizeAware.isTablet, setSizeAware }}>
            <ResizeAwareConsumer>
            {value => {
                return <ResizeEventHandler sizeAware={value} />;
            }}
            </ResizeAwareConsumer>
            {children}
        </ResizeAwareProducer>
    );
};

const ResizeEventHandler = ({ sizeAware : { isMobile, isTablet, setSizeAware } }) => {
    const handleResize = () => {
        let change = false;
        const size = {};

        const mobile = getMobileCondition();
        if (mobile !== isMobile) {
            size.isMobile = mobile;
            change = true;
        } else {
            size.isMobile = isMobile;
        }

        const tablet = getTabletCondition();
        if (tablet !== isTablet) {
            size.isTablet = tablet;
            change = true;
        } else {
            size.isTablet = isTablet;
        }

        if (change) {
            setSizeAware(size);
        }
    };
    
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return function cleanup() {
            window.removeEventListener('resize', handleResize);
        }
    }, );

    return (<></>);
}

export default ResizeAwareContainer;