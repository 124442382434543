import React from 'react';
import PageReinforcementStatic from './../../interface/PageReinforcementStatic';
import UIContainer from './../../interface/UIContainer';
import UISection from './../../interface/UISection';
import { withLocalize } from 'react-localize-redux';
import UIParagraph from '../../interface/UIParagraph';

const Intro = ({ isMobile, isTablet, translate }) => {
  const t = (tag) => `thankYou.intro.${tag}`;
  return (
    <UIContainer fullWidth noPadding>
      <UISection>
        <PageReinforcementStatic
          subTitle={translate(t('titleMsg1'))}
          alignment={'center'}
          borderRequired
          heading={'h1'}
          isMobile={isMobile}
          isTablet={isTablet}
        />
        <UIParagraph
          textAlign={'center'}
          borderRequired
          heading={'h1'}
          isMobile={isMobile}
          isTablet={isTablet}
        >
          {translate(t('titleMsg2'))}
        </UIParagraph>
      </UISection>
    </UIContainer>
  );
};

export default withLocalize(Intro);