import React from 'react';
import PageReinforcementStatic from './../../interface/PageReinforcementStatic';
import UIContainer from './../../interface/UIContainer';
import UISection from './../../interface/UISection';
import { withLocalize } from 'react-localize-redux';
import UIParagraph from '../../interface/UIParagraph';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { spacing, colors } from '../../../../defaults/styles';
import styled from 'styled-components';

const styles = {
  icon: {
    marginRight: spacing.small,
  },
};

const LinkHref = styled.a`
  color: ${colors.black.normal};
  display: block;
  text-decoration: none;
  transition: color 0.1s ease-in-out;
  &:hover {
    color: ${colors.primary.normal};
  }
`;

const Intro = ({ isMobile, isTablet, translate, vendorAgreement }) => {
    const t = (tag) => `vendorAgreement.intro.${tag}`;

    let paragraphContent;
    let contactInfo;
    if (!vendorAgreement || vendorAgreement.invalidToken) {
      paragraphContent = translate(t('invalidOrMissingToken'));
    } else if (vendorAgreement.expiredToken) {
      paragraphContent = translate(t('expiredToken'));
      contactInfo =
        <LinkHref
          href={'mailto:info@loyaltysource.com'}
          key="email-link"
          isMobile={isMobile}
          isTablet={isTablet}
        >
        <FontAwesomeIcon icon={faEnvelope} style={styles.icon} />
        info@loyaltysource.com
      </LinkHref>;
    } else {
      paragraphContent = translate(t('validToken'));
    }

    return (
      <UIContainer fullWidth noPadding>
        <UISection>
          <PageReinforcementStatic
            title={translate(t('title'))}
            alignment={'center'}
            borderRequired
            heading={'h1'}
            isMobile={isMobile}
            isTablet={isTablet}
          />
          <UIParagraph
            textAlign={'center'}
            borderRequired
            heading={'h1'}
            isMobile={isMobile}
            isTablet={isTablet}
          >
            {paragraphContent}
            {contactInfo}
          </UIParagraph>
        </UISection>
      </UIContainer>
    );
  };
  
  export default withLocalize(Intro);