import React from 'react';
import { lsStyled as styled } from '../../../tools/helpers/lsStyled';
import { withLocalize } from 'react-localize-redux';
import { colors } from '../ui/common';

const InputDiv = styled.div`
    margin: 0.5rem 0 0.5rem 0;
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const InputWrapper = styled.input`
    border-bottom: 1px solid ${props => props.error ? colors.primary.dark : colors.gray.light};
    padding-top: 0.2rem;
    padding-bottom: 0.5rem;
    font-size: 1rem;

    :focus {
        border: 0px;
        border-bottom: 1px solid ${props => props.required ? colors.primary.normal : colors.gray.normal};
        box-shadow: 0 2px 1px -1px ${props => props.required ? colors.primary.normal : colors.gray.normal};
    }
    
    :disabled {
        background-color: rgb(245, 245, 245);
        cursor: not-allowed;
    }
`;

const InputAreaWrapper = styled.textarea`
    border-bottom: 1px solid ${props => props.error ? colors.primary.dark : colors.gray.light};
    padding-top: 0.2rem;
    padding-bottom: 0.5rem;
    font-size: 1rem;
    resize: none;

    :focus {
        border: 0px;
        border-bottom: 1px solid ${props => props.required ? colors.primary.normal : colors.gray.normal};
        box-shadow: 0 2px 1px -1px ${props => props.required ? colors.primary.normal : colors.gray.normal};
    }
    
    :disabled {
        background-color: rgb(245, 245, 245);
        cursor: not-allowed;
    }
`;

const InputLabel = styled.label`
    color: ${colors.gray.normal};
    font-size: 0.8rem;
`;

const InputLabelRequired = styled.sup`
    color: ${colors.primary.dark};
    margin-left: 0.5rem;
`;

const Input = ({ 
    type = 'text', 
    area = false,
    className, 
    style = {}, 
    disabled, 
    value, 
    onChange = () => {},
    onBlur = () => {},
    onFocus = () => {},
    error = false,
    required = false,
    label,
    translate,
    rows = 1,
}) => {
    return (
        <div style={{ display: 'flex' }}>
            <InputDiv>
                <InputLabel>
                    {label}
                    {required && <InputLabelRequired>* ({translate('labels.required')})</InputLabelRequired>}
                </InputLabel>
                {area ?
                    <InputAreaWrapper
                        type={type}
                        rows={rows}
                        className={className}
                        style={style}
                        disabled={disabled}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        required={required}
                        error={error}
                    />
                    : <InputWrapper
                        type={type}
                        className={className}
                        style={style}
                        disabled={disabled}
                        value={value}
                        onChange={onChange}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        required={required}
                        error={error}
                    />}
            </InputDiv>
        </div>
    )
}

export default withLocalize(Input);