import { LSFetch } from "../helpers";

export const getAllFeatures = (language = "en") => {
  return LSFetch.get("/features/", { params: { language } });
};

export const getFeaturesForCorporateGifts = (language = "en") => {
  return LSFetch.get("/features/corporategifts", { params: { language } });
};

export const getAllFeaturesByCategorical = (dto) => {
  return LSFetch.post("/features/categorical",
    {
      body: JSON.stringify(dto),
      headers: { 'Content-Type': 'application/json;charset=UTF-8' },
    })
}
