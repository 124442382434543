import React from 'react';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTasks,
  faBoxes,
  faHandReceiving,
} from '@fortawesome/pro-solid-svg-icons';

import PageReinforcementStatic from './../../interface/PageReinforcementStatic';
import UIContainer from './../../interface/UIContainer';
import UISection from './../../interface/UISection';
import UIGrid2cols from './../../interface/UIGrid2cols';
import UIParagraph from './../../interface/UIParagraph';
import UIListItemBullet from './../../interface/UIListItemBullet';
import { colors, fonts, spacing } from './../../../../defaults/styles';
import { Translate, withLocalize } from 'react-localize-redux';

const UncomplicatedThings = ({
  activeLanguage,
  isMobile,
  isTablet,
  history,
  translate
}) => {
  const t = (tag) => `home.uncomplicatedThings.${tag}`;

  const lang = activeLanguage ? activeLanguage.code : 'en';

  const iconsList = [
    // the following each icon is a component from fontawesome
    {
      icon: faTasks,
      href: `/${lang}/services-rewards`,
      lexicon: 'linkRewards',
    },
    {
      icon: faHandReceiving,
      href: `/${lang}/services-fulfilment`,
      lexicon: 'linkDTC',
    },
    {
      icon: faBoxes,
      href: `/${lang}/services-wholesale`,
      lexicon: 'linkBulk',
    },
  ];
  return (
    <UIContainer isMobile={isMobile} isTablet={isTablet}>
      <UIGrid2cols alignItems={'start'} isMobile={isMobile} isTablet={isTablet} customStyle={{ gridTemplateColumns: !isMobile && !isTablet ? '1fr auto' : undefined }}>
        <UISection isMobile={isMobile} isTablet={isTablet}>
          <PageReinforcementStatic
            title={translate(t('title'))}
            alignment={'left'}
            borderRequired={true}
            heading={'h2'}
            type={'2'}
            customStyleHeading={{ marginTop: 0 }}
          />
          <UIParagraph isMobile={isMobile} isTablet={isTablet} customStyle={{ marginBottom: '2.5em'}}>
            <Translate id={t('mainParagraph')} />
          </UIParagraph>

          <UIListItemBullet
            text={[
              { text: translate(t('listItems.custom')) },
              { text: translate(t('listItems.personalized')) },
              { text: translate(t('listItems.bestInClass')) },
              { text: translate(t('listItems.dedicated')) },
              { text: translate(t('listItems.seamless')) },
              { text: translate(t('listItems.delivery')) },
            ]}
          />
        </UISection>

        <UISection>
          <UL isMobile={isMobile} isTablet={isTablet}>
            {iconsList.map((iconItem, index) => (
              <LI key={index}>
                <ListItemLink
                  href={iconItem.href}
                  isMobile={isMobile}
                  isTablet={isTablet}
                  onClick={e => {
                    e.preventDefault();
                    history.push(iconItem.href);
                  }}
                >
                  <FontAwesomeIcon
                    icon={iconItem.icon}
                    style={iconStyles}
                    size={'2x'}
                  />
                  {translate(
                    t('linkListItems.' + iconItem.lexicon),
                    null,
                    { renderInnerHtml: true }
                  )}
                </ListItemLink>
              </LI>
            ))}
          </UL>
        </UISection>
      </UIGrid2cols>
    </UIContainer>
  );
};

export default withRouter(withLocalize(UncomplicatedThings));

// == Styling == //
const UL = styled.ul`
  display: grid;
  grid-gap: 2em;
  align-items: center;
  list-style: none;
  margin: ${props => (props.isMobile ? '0 auto' : null)};

  /* PRIMARY OR NON-PRIMARY */
  grid-auto-rows: ${props => (!props.primary ? '1fr' : null)};
  float: ${props => (props.primary || props.isMobile ? null : 'right')};
  padding: ${props =>
    props.primary
      ? `${spacing.large}px ${spacing.large}px ${
          spacing.large
        }px ${spacing.large * 2}px`
      : null};
  width: ${props =>
    props.primary || props.isMobile || props.isTablet ? '100%' : '28em'};
`;

const LI = styled.li`
  height: 100%;
  position: relative;
  width: 100%;

  /* for list items in right col */
  display: ${props => (!props.primary ? 'flex' : null)};
`;

const ListItemLink = styled.a`
  border: 2px dashed ${colors.dashedBoarder.normal};
  border-radius: 5px;
  color: ${colors.gray.dark};
  display: flex;
  align-items: center;
  font-size: ${fonts.sizes.large}px;
  height: 100%;
  line-height: 1.2;
  padding: ${spacing.large}px ${spacing.veryLarge}px ${spacing.large}px
    ${spacing.veryLarge * 3}px;
  text-decoration: none;
  width: 100%;
`;

// this is just styles for FontAwesome compoenent, not using as component
const iconStyles = {
  color: colors.gray.normal,
  position: 'absolute',
  top: '50%',
  left: spacing.veryLarge,
  transform: 'translateY(-50%)',
};

// == Styling == //
